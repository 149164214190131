import gql from 'graphql-tag';

export const FULL_REFUND_FRAGMENT = gql`
  fragment FullRefundFragment on Refund {
  _id
      date
      reportId
      clientId
      vendorId
      company
      sellChannel
      clientName
      import
      comment
      associatedClient
      status
      importId
      imported
      parentReport
      revisionBy
      lines {
        optionSku
        sku
        lineId
        document
        billDate
        promo
        abonable
        quantity
        maxQuantity
        billQuantity
        import
        unitPrice
        billImport
        discount
        billDiscount
        motive
        comment
        manual
        description
        selected
        product(language: $language) {
          optionName
          name
          extraName
          optionImage
          mainLine
          blocked
          image
        }
      }
  }
`;

export const FIND_REFUND_GQL = gql`
  query FindRefund(
    $clientId: String!,
    $sellChannel: String!,
    $status: String!,
    $vendorId: String!,
    $language: String!,
  ) {
    pendingRefund: findRefund(
      clientId: $clientId,
      sellChannel: $sellChannel,
      status: $status,
      vendorId: $vendorId,
    ) {
      ...FullRefundFragment
    }
  }
  ${FULL_REFUND_FRAGMENT}
`;

export const DELETE_LINE_GQL = gql`
  mutation DeleteLineRefund($refund: String!, $id: Int!, $language: String!) {
    pendingRefund: deleteLineRefund(refund: $refund, id: $id) {
      ...FullRefundFragment
    }
  }
  ${FULL_REFUND_FRAGMENT}
`;

export const DELETE_SELECTED_GQL = gql`
  mutation DeleteSelectedRefund($refund: String!, $ids: [Int!]!, $language: String!) {
    pendingRefund: deleteSelectedRefund(refund: $refund, ids: $ids) {
      ...FullRefundFragment
    }
  }
  ${FULL_REFUND_FRAGMENT}
`;
