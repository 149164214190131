<template>
  <f7-popup id="client-selection-popup" @popup:opened="getData(false)">
    <f7-view>
      <f7-page :with-subnavbar="true">
        <f7-navbar>
          <f7-nav-left></f7-nav-left>
          <f7-nav-title>{{$t('CLIENT_SELECTION')}}</f7-nav-title>
          <f7-nav-right>
            <f7-link icon-only icon-f7="xmark" popup-close></f7-link>
          </f7-nav-right>
          <f7-subnavbar :inner="false">
            <f7-searchbar
                custom-search
                :backdrop="false"
                :value="state.query"
                @searchbar:search="filterClients"
                :placeholder="$t('SEARCH_CLIENT')"
                :disable-button-text="$t('CANCEL')"
            ></f7-searchbar>
          </f7-subnavbar>
        </f7-navbar>
        <template v-if="state.loading">
          <page-preloader></page-preloader>
        </template>
        <template v-else>
          <f7-block strong v-if="state.filteredClients.length === 0" inset class="shadow">
            <div class="size-13" v-html="$t('NOT_CLIENTS_FOUND', [state.query])"></div>
          </f7-block>
          <f7-list inset v-else class="shadow">
            <f7-list-item v-for="(client, index) in state.filteredClients" @click="selectClient(client.navisionId)" :key="index">
              <div slot="inner-start" class="display-flex flex-direction-row justify-content-space-between align-items-center size-12" style="flex: 1;">
                <div class="display-flex flex-direction-column align-items-flex-start">
                  <div class="display-flex flex-direction-row">
                    <strong class="margin-right-half">
                      <span v-if="client.navisionId !== '1'" class="text-color-blue">{{client.navisionId}}</span> {{client.navisionId === '1' ? $t('NEW_CLIENT') : `${client.name}`}}
                    </strong>
                  </div>
                  <div class="size-12 text-color-gray" v-if="client.navisionId !== '1'">
                    {{client.address}} {{client.address2}}, {{client.city}} {{client.state}}, {{client.postalCode}}, {{client.country}}
                  </div>
                  <div v-else class="size-12 text-color-red">
                    {{$t('NEW_CLIENT_ADDRESS')}}
                  </div>
                </div>
                <div>
                  <f7-icon f7="checkmark_alt" color="primary" v-if="sessionInfo && (!/associated/.test(popupType) && sessionInfo.selectedClient === client.navisionId)
                    || ('refund-associated' === popupType && client.navisionId === pendingRefund.associatedClient)
                    || ('order-associated' === popupType && client.navisionId === pendingOrder.associatedClient)"></f7-icon>
                </div>
              </div>
            </f7-list-item>
          </f7-list>
        </template>
      </f7-page>
    </f7-view>
  </f7-popup>
</template>
<script>
import PagePreloader from '../../../components/utils/page-preloader';
import { reactive, watch } from 'vue';
import { useActions, useGetters, useState } from '../../../js/stores/hooks';
import { createNotification } from '../../../js/helpers/f7.helpers';
import { ordersService } from '../../../js/api/http/orders.service';
import { clientsService } from '../../../js/api/graphql/services/clients.service';
import { f7 } from 'framework7-vue';
import { useI18n } from 'vue-i18n';
import { Bills } from '../../informs/bills/components/compositions/bills.composition';

export default {
  name: 'client-selection-popup',
  props: ['popupType', 'orderId'],
  components: {
    PagePreloader
  },
  setup: (props) => {
    const { t } = useI18n();
    const { pendingOrder } = useState('pendingOrder', ['pendingOrder']);
    const { getPendingOrder, updateOrder } = useActions('pendingOrder', ['getPendingOrder', 'updateOrder']);
    const { pendingRefund } = useState('pendingRefund', ['pendingRefund']);
    const { updateRefund } = useActions('pendingRefund', ['updateRefund']);
    const { setLoading } = useActions('app', ['setLoading']);
    const { sessionInfo } = useState('session', ['sessionInfo']);
    const { setSessionInfo } = useActions('session', ['setSessionInfo']);
    const { selectedCode } = useGetters('session', ['selectedCode']);
    const state = reactive({
      clients: [],
      filteredClients: [],
      loading: false,
      query: '',
    });
    const { state: billState } = Bills();
    const selectClientCb = (navisionId) => {
      setSessionInfo({ selectedClient: navisionId });
    };
    const selectClient = async (navisionId) => {
      // console.log(navisionId, props.popupType);
      if (props.popupType === 'move') {
        await ordersService.moveOrderToClient(props.orderId, navisionId);
        f7.dialog.confirm(t('MOVE_ORDER_TO_CLIENT_SUCCESS', [navisionId]),
            t('ORDER_MOVED'), () => {
              selectClientCb(navisionId);
            }, () => {
              getPendingOrder({updateTranslations: true});
            });
      }
      if (props.popupType === 'copy') {
        await ordersService.copyOrderToClient(props.orderId, navisionId);
        f7.dialog.confirm(t('MOVE_ORDER_TO_CLIENT_SUCCESS', [navisionId]),
            t('ORDER_COPIED'), () => {
              selectClientCb(navisionId);
            });
      }
      if (props.popupType === 'order-associated') {
        updateOrder({parameter: 'associatedClient', value: navisionId});
      }
      if (props.popupType === 'refund-associated') {
        updateRefund({parameter: 'associatedClient', value: navisionId});
      }
      // if (props.popupType === 'copy-bill') {
      //   // await ordersService.copyBillToClient(props.orderId, navisionId);
      //   createNotification('BILL_COPIED', '200');
      // }
      if (props.popupType === 'add-bill-line' || props.popupType === 'copy-bill') {
        // const [order, id] = props.orderId.split('-');
        // await ordersService.copyLineBillToClient(order, +id, navisionId);
        try {
          const { selectedCode, sellChannel, status, groupPrice } = sessionInfo.value;
          await ordersService.addLines(billState.linesForCopy, selectedCode, navisionId, sellChannel, status, groupPrice, true);
          createNotification('LINE_BILL_COPIED', '200');
        } catch (e) {
          createNotification('ERROR', 400);
        }
      }
      if (props.popupType === 'copy-order') {
        await ordersService.copyOrderToClient(props.orderId, navisionId);
        createNotification('ORDER_COPIED', '200');
      }
      if (props.popupType === 'add-order-line') {
        const [order, id] = props.orderId.split('-');
        await ordersService.copyLineToClient(order, +id, navisionId);
        createNotification('LINE_COPIED', '200');
      }
      f7.popup.get('#client-selection-popup').close();
    };
    const filterClients = (searchbar, query, previousQuery) => {
      state.query = query;
      query = query.replace(/(?:\r\n|\r|\n)/g, '').trim();
      state.filteredClients = state.clients.filter((c) => {
        return query === '' || c.name.toLowerCase().indexOf(query.toLowerCase()) >= 0 ||
            ('' + c.navisionId).toLowerCase().indexOf(query.toLowerCase()) === 0 || (c.cif.toLowerCase().indexOf(query.toLowerCase()) === 0);
      });
    };
    const getData = async (force = false) => {
      state.loading = true;
      try {
        if (force || state.clients.length === 0) {
          await getClients();
        }
        state.filteredClients = state.clients;
      } catch (e) {};
      state.loading = false;
    };
    watch(sessionInfo, (newValue, oldValue) => {
      if (!oldValue || (newValue && newValue.selectedCode !== oldValue.selectedCode)) {
        getData(true);
      }
    });
    const getClients = async (disableCache = false) => {
      // console.log(disableCache);
      if (selectedCode) {
        state.loading = true;
        try {
          const { data: { findClients }} = (await clientsService.getClients(selectedCode.value, disableCache));
          state.clients = findClients;
        } catch (error) {
          // console.log(error);
          state.clients = [];
        }
        state.loading = false;
      }
    };
    return { selectClient, setLoading, state, sessionInfo, filterClients, getData, pendingOrder, pendingRefund };
  }
};
</script>
<style lang="scss">
.client-dialog-confirm {
  width: 80%;
  margin-left: -40%;
  font-size: 13px;
}
</style>
