import { ApolloClient, HttpLink, split } from '@apollo/client/core';
import { InMemoryCache } from '@apollo/client/cache';
import { setContext } from '@apollo/client/link/context';
import { COSMET_API_KEY } from '../config/app.config';
import { store } from '../stores/vuex';
import { Plugins } from '@capacitor/core';
import { f7 } from 'framework7-vue';
import { onError } from '@apollo/client/link/error';
import { createNotification } from '../helpers/f7.helpers';

const { Storage } = Plugins;

// const wsLink = new WebSocketLink({ // subscriptions-transport-ws package needs to be installed also
//   uri: 'ws://localhost:4141/events',
//   options: {
//     reconnect: true
//   }
// });

// HTTP connexion to the API
const httpLink = new HttpLink({
  // You should use an absolute URL here
  // credentials: 'include', // UNCOMMENT FOR HTTPONLY_TOKEN
  uri: (process.env.NODE_ENV === 'production') ? 'https://ofertahe.tahe.es/graphql' : `http://${window.location.hostname}:4000/graphql`
});

// const link = split(
//   // split based on operation type
//   ({ query }) => {
//     const { kind, operation } = getMainDefinition(query)
//     return kind === 'OperationDefinition' && operation === 'subscription'
//   },
//   wsLink,
//   httpLink
// );

const link = onError(({ graphQLErrors, networkError }) => {
  // console.log(graphQLErrors);
  // store.dispatch('session/logout');
  if (networkError) {
    createNotification('errors.networkError', 999);
  }
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      if (err.extensions.exception.status === 401) {
        store.dispatch('session/logout');
        f7.views.main.router.navigate('/');
      }
      // console.dir('graphQLErrors', err.message);
    }
    createNotification(`serverErrors.${graphQLErrors[0].extensions.exception.response.code }`, graphQLErrors[0].extensions.exception.status);
  }
});

// REMOVE authLink FOR HTTPONLY_TOKEN
const authLink = setContext(async (_, { headers }) => {
  const accessToken = await Storage.get({key: 'accessToken'});
  // get the authentication token from local storage if it exists
  // return the headers to the context so httpLink can read them
  // let token = ''
  // const item = localStorage.getItem('session') // survive a refresh
  // if (item) {
  //   const user = JSON.parse(item)
  //   token = user.token
  // }
  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken.value}` : '' // TBD - take into account refresh token and revocation
    }
  }
});

// Cache implementation
const cache = new InMemoryCache();

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: link.concat(authLink).concat(httpLink), // REMOVE authLink FOR HTTPONLY_TOKEN
  cache,
  connectToDevTools: true,
});
