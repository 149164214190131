import * as Hammer from 'hammerjs';

// Import Vue
import { createApp } from 'vue';

import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

// Import Framework7
import Framework7 from 'framework7/lite-bundle';

// Import Framework7-Vue Plugin
import Framework7Vue, { registerComponents } from 'framework7-vue/bundle';

// Import Framework7 Styles
import 'framework7/framework7-bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.scss';

// Import App Component
import App from '../components/layouts/app.vue';

// My Plugins
import { i18n } from './plugins/vue-i18n';
import { store } from './stores/vuex';
import { DefaultApolloClient } from '@vue/apollo-composable';
import { apolloClient } from './plugins/apollo-client';
import Maska from 'maska';
import { registerApexcharts } from './plugins/apexcharts';

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

// Init App
const app = createApp(App);

// Register Framework7 Vue components
registerComponents(app);

registerApexcharts(app);

// Mount the app
app.use(i18n);
app.use(store);
app.use(Maska);
app.provide(DefaultApolloClient, apolloClient);
app.mount('#app');

app.directive('long-press', {
  beforeMount(el, binding, vnode) {
    // el.style.background = binding.value
    // Create a manager to manager the element
    const manager = new Hammer.Manager(el);

    // Create a recognizer
    const Press = new Hammer.Press({
      time: 500
    });

    // Add the recognizer to the manager
    manager.add(Press);

    manager.on('press', e => {
      binding.value();
    });
  }
})
