<template>
  <f7-link :text="isInBreakpoint ? '' : $t('buttons.search')" icon-f7="search" popup-open=".global-search-popup"></f7-link>
  <global-search-popup></global-search-popup>
</template>
<script>
import { isInBreakpoint } from '@/js/helpers/f7.helpers';

import GlobalSearchPopup from '@/components/popups/global-search-popup.vue';

export default {
  name: 'search-button',
  components: {
    'global-search-popup': GlobalSearchPopup,
  },
  setup: () => {
    return { isInBreakpoint };
  },
};
</script>
