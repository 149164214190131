<template>
  <f7-login-screen class="login-screen" :opened="!isAuthenticated" :animate="false">
    <f7-page login-screen>
      <f7-login-screen-title>
        <img src="static/svg/cosmet-logo.svg" height="150" alt="">
      </f7-login-screen-title>
      <f7-list form>
        <f7-list-input
          outline
          class="custom-input"
          :label="$t('login.username.label')"
          type="text"
          :placeholder="$t('login.username.placeholder')"
          :value="state.username"
          @input="state.username = $event.target.value"
          @keyup.enter.native="signIn"
        ></f7-list-input>
        <f7-list-input
          outline
          class="custom-input"
          :label="$t('login.password.label')"
          :type="state.pwdVisible ? 'text' : 'password'"
          :placeholder="$t('login.password.placeholder')"
          :value="state.password"
          @input="state.password = $event.target.value"
          @keyup.enter.native="signIn"
        >
          <template #inner-end>
            <f7-icon class="eye-pwd-icon" :icon="state.pwdVisible ? 'las la-eye-slash' : 'las la-eye'" size="24"
                     @click="state.pwdVisible = !state.pwdVisible"></f7-icon>
          </template>
        </f7-list-input>
      </f7-list>
      <f7-block>
        <f7-button large @click="signIn" :disabled="state.signInDisabled">
          <strong class="font-size-16px">{{ $t('buttons.login') }}</strong>
        </f7-button>
        <f7-block-footer>
          <span v-html="$t('login.information.line1')"></span><br>
          <span v-html="$t('login.information.line2', {year: today, version: f7state.localVersion})"></span>
        </f7-block-footer>
      </f7-block>
    </f7-page>
  </f7-login-screen>
</template>
<script>
import { useActions, useState } from '../../js/stores/hooks';
  import { reactive, computed } from 'vue';
import { APP_VERSION, PANEL_BREAKPOINT } from '../../js/config/app.config';
  import { dateFormat } from '../../js/helpers/filters.helpers';
import { F7 } from '../../js/helpers/f7.helpers';
import { f7 } from 'framework7-vue';

  export default {
    name: 'login',
    setup: () => {
      //// IMPORTS
      const { isAuthenticated } = useState('session', ['isAuthenticated']);
      const { login } = useActions('session', ['login']);
      const { setLoading } = useActions('app', ['setLoading']);

      //// PROPERTIES
      const { state: f7state } = F7();
      const today = dateFormat('YYYY');
      const state = reactive({
        username: '',
        password: '',
        pwdVisible: false,
        signInDisabled: computed(() => state.username === '' || state.password === ''),
      });

      //// METHODS
      const signIn = async () => {
        if (state.signInDisabled) return;
        let { username, password } = state;
        username = username.trim().toLowerCase();
        password = password.trim();
        try {
          setLoading(true);
          await login({ username, password });
          // setTimeout(() => {
            // const panel = f7.panel.get('left');
            // console.log(panel);
            // panel.visibleBreakpoint = PANEL_BREAKPOINT;
            // panel.enableVisibleBreakpoint();
            // panel.open();
          // }, 300);
        } catch (error) {
          // console.log(error);
        }
        setLoading(false);
      };

      return { state, isAuthenticated, today, signIn, f7state };
    }
  }
</script>
<style lang="scss" scoped>
  .eye-pwd-icon {
    position:absolute;
    right: 30px;
    top: 30px;
  }
</style>
