import gql from 'graphql-tag';

export const LOGIN = gql`
  mutation Login($data: LoginInput!) {
    login(data: $data) {
      token
    }
  }
`;

export const TOGGLE_FAVORITE_MENU = gql`
  mutation ToggleFavoriteMenu($favItem: NewFavItemInput!) {
    toggleFavoriteMenu(favItem: $favItem) {
      username
    }
  }
`;

export const TOGGLE_FAV_PRODUCT = gql`
  mutation ToggleFavProduct($favProduct: NewFavProductInput!) {
    toggleFavProduct(favProduct: $favProduct) {
      username
    }
  }
`;

