<template>
  <div v-if="!hasAnyRole([ROLE.DISTRIBUTOR_VENDOR.code, ROLE.DISTRIBUTOR_CHIEFTEAM.code, ROLE.DISTRIBUTOR.code])">
    <f7-block-title>{{ $t('menus.favoritesMenu') }}</f7-block-title>
    <template v-if="favoritesMenu.length">
      <f7-list :inset="menuInset">
        <f7-list-item v-for="(item, index) in favoritesMenu" :key="index"
                      :link="item.link"
                      view=".view-main"
                      :disabled="isLoadingRoute"
                      :class="{'menu-active': currentRoute === item.link}"
                      :panel-close="panelClose"
                      @click="trackearClick(personas, user?.username ?? '', `Click en ${$t(item.title)}`, { sesion: sessionInfo })">
          <div slot="inner-start" class="display-flex flex-direction-row flex-1 align-items-center">
            <div class="margin-right-half"><f7-icon :icon="item.icon" size="24"></f7-icon></div>
            <div>{{$t(item.title)}}</div>
          </div>
        </f7-list-item>
      </f7-list>
    </template>
    <template v-else>
      <f7-block strong inset class="custom-elevation-0">
        <p v-html="$t('info.noFavoritesMenu')"></p>
      </f7-block>
    </template>
  </div>
  <div v-else></div>
</template>
<script>
import { useGetters, useState } from '../../js/stores/hooks';
import { ROLE } from '../../js/config/enums.config';
import { trackearClick } from '../../js/helpers/seguimiento.helpers';

export default {
  name: 'favorites-menu',
  props: {
    menuInset: { type: Boolean, default: false },
    panelClose: { type: Boolean, default: true },
  },
  setup: () => {
    const { favoritesMenu } = useState('userSettings', ['favoritesMenu']);
    const { isLoadingRoute, currentRoute, personas } = useState('app', ['isLoadingRoute', 'currentRoute', 'personas']);
    const { hasAnyRole } = useGetters('session', ['hasAnyRole']);
    const { user, sessionInfo } = useState('session', ['user', 'sessionInfo']);
    return { favoritesMenu, isLoadingRoute, currentRoute, hasAnyRole, ROLE, user, personas, trackearClick, sessionInfo };
  }
}
</script>
