import { instance as axios } from '../../plugins/axios';
import { store } from '../../stores/vuex';

const findClient = (navisionId) => {
  const empresa = store.state.session.sessionInfo?.company ?? '';
  return axios.post('/clients/find_client', {navisionId, empresa });
};

const findNewClient = (navisionId) => {
  const empresa = store.state.session.sessionInfo?.company ?? '';
  return axios.post('/clients/find_new_client', {navisionId, empresa });
};

const findClients = (code) => {
  const empresa = store.state.session.sessionInfo?.company ?? '';
  return axios.post('/clients/find_clients', {code, empresa });
}

const clientInfo = (navisionId) => {
  const empresa = store.state.session.sessionInfo?.company ?? '';
  return axios.post('/clients/client_info', {navisionId, empresa });
}

const findAddress = (navisionId) => {
  const empresa = store.state.session.sessionInfo?.company ?? '';
  return axios.post('/clients/find_address', {navisionId, empresa });
}

const fullClientInfo = (navisionId) => {
  const empresa = store.state.session.sessionInfo?.company ?? '';
  return axios.post('/clients/full_client_info', {navisionId, empresa });
}

const findComments = (navisionId) => {
  const empresa = store.state.session.sessionInfo?.company ?? '';
  return axios.post('/clients/find_comments', {navisionId, empresa });
}

const newClient = (newClient) => {
  return axios.post('/clients/new_client', {newClient});
}

const newDistributorClient = (newClient) => {
  return axios.post('/clients/new_distributor_client', {newClient });
}

const editDistributorClient = (newClient) => {
  return axios.post('/clients/edit_distributor_client', {newClient });
}

const updateEmail = (client, company, newEmail) => {
  return axios.post('/clients/update_email', {client, company, newEmail});
}

const getVerificationToken = (client) => {
  return axios.post('/clients/get_verification_token', {client });
}

const getCooperativas = () => {
  const empresa = store.state.session.sessionInfo?.company ?? '';
  return axios.post('/clients/cooperativas', { empresa });
}

const importClient = (navisionId, vendorCode) => {
  const empresa = store.state.session.sessionInfo?.company ?? '';
  return axios.post('/clients/import_client', {navisionId, empresa, vendorCode});
}

const sendBill = (document, navisionId, company, type) => {
  return axios.post('/clients/send_bill', {document, navisionId, company, type});
}

const sendBills = (bills) => {
  return axios.post('/clients/send_bills', { bills });
}

const getNumAccount = (accountNumber, company, tipo) => {
  return axios.post('/clients/get_account_number', {accountNumber, company, tipo });
}

const getAddress = (clientId, navisionId) => {
  const empresa = store.state.session.sessionInfo?.company ?? '';
  return axios.post('/clients/get_address', { clientId, navisionId, empresa });
}

export const clientsService = {
  findClient,
  findNewClient,
  findClients,
  clientInfo,
  findAddress,
  fullClientInfo,
  findComments,
  newClient,
  newDistributorClient,
  editDistributorClient,
  updateEmail,
  getVerificationToken,
  getCooperativas,
  importClient,
  sendBill,
  sendBills,
  getNumAccount,
  getAddress,
};
