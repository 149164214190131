export const mainRoutes = [
  { path: '/dashboard/'                           , componentPath: 'dashboard/dashboard' },
  { path: '/users/'                               , componentPath: 'admin/users/users' },
  { path: '/employees/'                           , componentPath: 'admin/employees/employees' },
  { path: '/distributors/'                        , componentPath: 'distributors/distributors' },
  { path: '/catalog/'                             , componentPath: 'catalogv2/catalog' },
  { path: '/reports/'                             , componentPath: 'reports/reports' },
  { path: '/statistics/'                          , componentPath: 'statistics/statistics' },
  { path: '/new-client/'                          , componentPath: 'clients/new-client' },
  { path: '/new-client-distributor/'              , componentPath: 'distributors/new-client' },
  { path: '/edit-client-distributor/'             , componentPath: 'distributors/edit-client' },
  { path: '/approval-orders/'                     , componentPath: 'revisers/approval-orders' },
  { path: '/approval-refunds/'                    , componentPath: 'revisers/approval-refunds' },
  { path: '/approval-charges/'                    , componentPath: 'revisers/approval-charges' },
  { path: '/pending-order/'                       , componentPath: 'orders/pending-order' },
  { path: '/previous-orders/'                     , componentPath: 'orders/previous-orders' },
  { path: '/pending-refund/'                      , componentPath: 'refunds/pending-refund' },
  { path: '/previous-refunds/'                    , componentPath: 'refunds/previous-refunds' },
  { path: '/pending-charge/'                      , componentPath: 'charges/pending-charge' },
  { path: '/previous-charges/'                    , componentPath: 'charges/previous-charges' },
  { path: '/client-management/'                   , componentPath: 'clients/client-management' },
  { path: '/vendor-management/'                   , componentPath: 'vendors/vendor-management' },
  { path: '/extracts/'                            , componentPath: 'informs/extracts/extracts' },
  { path: '/bills/'                               , componentPath: 'informs/bills/bill-invoices' },
  { path: '/bills-refunds/'                       , componentPath: 'informs/bills/bill-refunds' },
  { path: '/sold-products/'                       , componentPath: 'informs/sold-products/sold-products' },
  { path: '/expeditions/'                         , componentPath: 'informs/expeditions/expeditions' },
  { path: '/expenses/'                            , componentPath: 'vendors/expenses/expenses' },
  { path: '/incomes/'                             , componentPath: 'vendors/incomes/incomes' },
  { path: '/pending-to-ship/'                     , componentPath: 'informs/pending-to-ship/pending-to-ship' },
  { path: '/pendiente-servir/'                    , componentPath: 'informs/pending-to-ship/pending-to-ship-v2' },
  { path: '/productos-pendientes/'                , componentPath: 'informs/pending-to-ship/productos-pendientes' },
  { path: '/clients-contracts/'                   , componentPath: 'informs/contracts/contracts' },
  { path: '/refunds-status/'                      , componentPath: 'informs/refunds-status/refunds-status' },
  { path: '/commissions/'                         , componentPath: 'informs/commissions/commissions' },
  { path: '/settings/'                            , componentPath: 'settings/settings' },
  { path: '/changelog/'                           , componentPath: 'settings/changelog' },
  { path: '/faq-stores/'                          , componentPath: 'settings/faq-stores' },
  { path: '/faq/'                                 , componentPath: 'settings/faq' },
  { path: '/client-selection/'                    , componentPath: 'selections/client-selection' },
  { path: '/vendor-selection/'                    , componentPath: 'selections/vendor-selection' },
  { path: '/sell-channel-selection/'              , componentPath: 'selections/sell-channel-selection' },
  { path: '/client-previous-orders/'              , componentPath: 'clients/pages/previous-orders' },
  { path: '/client-previous-refunds/'             , componentPath: 'clients/pages/previous-refunds' },
  { path: '/client-previous-charges/'             , componentPath: 'clients/pages/previous-charges' },
  { path: '/client-pending-to-ship/'              , componentPath: 'clients/pages/pending-to-ship' },
  { path: '/client-expeditions/'                  , componentPath: 'clients/pages/expeditions' },
  { path: '/client-giros/'                        , componentPath: 'clients/pages/giros' },
  { path: '/client-pending-bills/'                , componentPath: 'clients/pages/pending-bills' },
  { path: '/client-objectives/'                   , componentPath: 'clients/pages/objectives' },
  { path: '/client-contracts/'                    , componentPath: 'clients/pages/contracts' },
  { path: '/client-extracts/'                     , componentPath: 'clients/pages/extracts' },
  { path: '/client-bills/'                        , componentPath: 'clients/pages/bills' },
  { path: '/client-bills-refunds/'                , componentPath: 'clients/pages/bills-refunds' },
  { path: '/refund-status-details/:id'            , componentPath: 'informs/refunds-status/refund-status-details' },
  { path: '/product-details/'                     , componentPath: 'catalogv2/product-details' },
  { path: '/new-income/'                          , componentPath: 'vendors/incomes/new-income' },
  { path: '/income-details/:id'                   , componentPath: 'vendors/incomes/income-details' },
  { path: '/new-expense/'                         , componentPath: 'vendors/expenses/new-expense' },
  { path: '/expense-details/:id'                  , componentPath: 'vendors/expenses/expense-details' },
  { path: '/report-details/:id'                   , componentPath: 'reports/report-details' },
  { path: '/checkout-order/'                      , componentPath: 'orders/checkout-order' },
  { path: '/checkout-charge/'                     , componentPath: 'charges/checkout-charge' },
  { path: '/bill-invoice-details/:company/:id'    , componentPath: 'informs/bills/bill-invoice-details' },
  { path: '/grouped-invoice-details/:company/:id' , componentPath: 'informs/bills/grouped-invoice-details' },
  { path: '/bill-refund-details/:company/:id'     , componentPath: 'informs/bills/bill-refund-details' },
  { path: '/product-bills/'                       , componentPath: 'informs/product-bills/product-bills' },
  { path: '/search-refund-product/'               , componentPath: 'refunds/search-refund-product' },
  { path: '/search-refund-in-bills/'              , componentPath: 'refunds/search-refund-in-bills' },
  { path: '/refund-product-bills/'                , componentPath: 'refunds/refund-product-bills' },
  { path: '/order-details/:id'                    , componentPath: 'orders/order-details' },
  { path: '/refund-details/:id'                   , componentPath: 'refunds/refund-details' },
  { path: '/charge-details/:id'                   , componentPath: 'charges/charge-details' },
  { path: '/new-client-details/:id'               , componentPath: 'clients/new-client-details' },
  { path: '/distributor-details/:id'              , componentPath: 'distributors/distributor-details' },
  { path: '/new-distributor/'                     , componentPath: 'distributors/new-distributor' },
  { path: '/employee-details/:id'                 , componentPath: 'admin/employees/employee-details' },
  { path: '/new-employee/'                        , componentPath: 'admin/employees/new-employee' },
  { path: '/user-details/:id'                     , componentPath: 'admin/users/user-details' },
  { path: '/new-user/'                            , componentPath: 'admin/users/new-user' },
  { path: '/cursos/'                              , componentPath: 'cursos/CursosListado' },
  { path: '/top-clientes/'                        , componentPath: 'informs/top-clientes/TopClientes' },
  { path: '/clientes-sin-validar/'                , componentPath: 'informs/clientes-sin-validar/ClientesSinValidar' },
];
