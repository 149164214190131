<template>
  <component :is="state.component" v-if="state.component"></component>
</template>
<script>
import { useState } from '../../../js/stores/hooks';
import { defineAsyncComponent, reactive, watch } from 'vue';

export default {
  name: 'app-input',
  setup: () => {
    const { type } = useState('input', ['type']);
    const mounted = () => defineAsyncComponent({
      loader: () => {
        // console.log(type);
        if (type.value === 'calculator') return import (`./input-calculator.vue`);
        return import (`./input-dialog.vue`);
      },
      delay: 200,
      timeout: 3000,
    });
    const state = reactive({ component: mounted() });
    watch(type, (value, oldValue) => state.component = mounted());
    return { state };
  },
};
</script>
