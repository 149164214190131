import gql from 'graphql-tag';

export const GIRO_FRAGMENT = `
  fragment FullGiro on Giro {
    movement
    vendorId
    clientId
    postingDate
    dueDate
    documentType
    document
    description
    import
    remainingImport
    appliesToDocType
    appliesToDoc
    contract
    numPayment
    open
    company
  }
`;

export const PENDING_BILL_FRAGMENT = `
  fragment FullPendingBill on PendingBill {
    vendorId
    document
    description
    carousel
    clientId
    clientName
    address
    date
    amount
    remainingAmount
    documentType
    paymentForm
    contract
    contractType
    totalContr
    company
    lastUpdate
  }
`;

export const LAST_BILL_FRAGMENT = `
  fragment FullLastBill on LastBill {
    vendorId
    document
    clientId
    clientName
    date
    amount
    amountWithIVA
    pricesWithIVA
    company
    yourReference
    type
    lastUpdate
  }
`;

export const FIND_GIROS_GQL = gql`
  query FindGiros($where: GirosWhereInput) {
    giros(where: $where) {
      ...FullGiro
    }
  }
  ${GIRO_FRAGMENT}
`;

export const GET_GIROS_INMEDIATOS = gql`
  query GetGirosInmediatos($clienteCodigo: String!) {
    girosInmediatos(clienteCodigo: $clienteCodigo) {
      ...FullPendingBill
    }
  }
  ${PENDING_BILL_FRAGMENT}
`;

export const FIND_PENDING_BILLS_GQL = gql`
  query FindPendingBills($where: PendingBillsWhereInput) {
    pendingBills(where: $where) {
      ...FullPendingBill
    }
  }
  ${PENDING_BILL_FRAGMENT}
`;

export const FIND_LAST_BILLS_GQL = gql`
  query FindLastBills($where: LastBillsWhereInput) {
    lastBills(where: $where) {
      ...FullLastBill
    }
  }
  ${LAST_BILL_FRAGMENT}
`;
