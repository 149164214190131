import { instance as axios } from '../../plugins/axios';

const findDistributors = () => axios.post('/distributors/find_distributors');
const findDistributor = (distributorId) => axios.post('/distributors/find_one', {distributorId});
const findDistributorByUser = (username) => axios.post('/distributors/find_one_by_user', {username});
const newDistributor = (distributor) => axios.post(`/distributors`, {distributor});
const addTeam = (distributor, team) => axios.post('/distributors/add_team', {distributor, team});
const addUser = (distributor, user) => axios.post('/distributors/add_user', {distributor, user});
const updateDistributor = (distributorId, newDistributorData) => axios.post('/distributors/update', {distributorId, newDistributorData});
const dashboardChiefInfo = (username) => axios.post('/distributors/dashboard_chief_info', {username});
const dashboardInfo = (username) => axios.post('/distributors/dashboard_info', {username});
const dashboardVendorInfo = (username) => axios.post('/distributors/dashboard_vendor_info', {username});
const getStatistics = (username) => axios.post('/distributors/get_statistics', {username});

export const distributorsService = {
  findDistributors,
  findDistributor,
  findDistributorByUser,
  newDistributor,
  addTeam,
  addUser,
  updateDistributor,
  dashboardChiefInfo,
  dashboardInfo,
  dashboardVendorInfo,
  getStatistics,
};
