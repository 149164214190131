import gql from 'graphql-tag';
import { GET_PRODUCT_BY_CATEGORY_PG, PRODUCT_CATALOG_FRAGMENT } from '../products/products.queries';

export const GET_SUBCATEGORIES = gql`
  query GetSubcategories($parentId: Int!, $company: String!, $sellChannel: String!, $language: String!) {
    categories: getSubcategories(
      parentId: $parentId,
      company: $company,
      sellChannel: $sellChannel,
    ) {
      categoryId
      image
      es: translation(language: "es") { name }
      translation(language: $language) {
        name
        metaDescription
        metaKeyword
      }
      subcategories(
        company: $company,
        sellChannel: $sellChannel,
      ) {
        categoryId
        image
        translation(language: $language) {
          name
          metaDescription
          metaKeyword
        }
      }
    }
  }
`;

export const GET_PATH_NAMES = gql`
  query PathNames($path: [Int!]!, $language: String!) {
    pathNames(
      path: $path,
      language: $language,
    ) {
      path
    }
  }
`;

export const GET_CURRENT_PATH = gql`
  query GetSubcategories($parentId: Int!, $company: [String!]!, $sellChannels: [String!]!, $sellChannel: String!, $language: String!,
    $groupPrice: String!, $depth: Int!, $limit: Int, $offset: Int, $lineIds: [Int!], $attributeIds: [[Int!]]) {
    categories: getSubcategories(
      parentId: $parentId,
      company: $company,
      sellChannel: $sellChannels,
    ) {
      categoryId
      image
      es: translation(language: "es") { name }
      translation(language: $language) {
        name
        metaDescription
        metaKeyword
      }
      subcategories(
        company: $company,
        sellChannel: $sellChannels,
      ) {
        categoryId
        image
        translation(language: $language) {
          name
          metaDescription
          metaKeyword
        }
      }
    }
    ${GET_PRODUCT_BY_CATEGORY_PG}
  }
  ${PRODUCT_CATALOG_FRAGMENT}
`;
