<template>
  <f7-block>
    <f7-button :text="$t('buttons.logout')" icon-f7="las la-power-off" color="red" @click="signOut"></f7-button>
  </f7-block>
</template>
<script>
import { useActions } from '../../js/stores/hooks';
import { f7 } from 'framework7-vue';

export default {
  name: 'logout-button',
  setup: () => {
    const { logout } = useActions('session', ['logout']);
    const signOut = () => {
      logout();
      f7.views.main.router.navigate('/', { clearPreviousHistory: true });
    }
    return { signOut };
  }
};
</script>
