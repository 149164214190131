import { apolloClient } from '@/js/plugins/apollo-client';
import {
  FIND_PRODUCTS_GQL, GET_DISPONIBILIDAD_GQL,
  GET_FAV_PRODUCTS_QUERY,
  GET_PRODUCT_QUERY, GET_PRODUCTO_GQL,
  GET_PRODUCTS_BY_CATEGORY,
  GET_PRODUCTS_BY_CATEGORY_PG_QUERY, OBTENER_PRODUCTOS_SIMILARES, SEARCH_PRODUCTS_QUERY, SEARCH_REFUND_PRODUCTS_QUERY
} from '../queries/products/products.queries';

const productsByCategory = async ({ categoryId, company, sellChannel, sellChannels, language, groupPrice, depth }) =>
  apolloClient.query({query: GET_PRODUCTS_BY_CATEGORY, variables: { categoryId, company, sellChannel, sellChannels, language, groupPrice, depth }});
const productsByCategoryPg = async ({ parentId, company, sellChannel, sellChannels, language, groupPrice, depth, limit, offset, lineIds, attributeIds }) =>
  apolloClient.query({query: GET_PRODUCTS_BY_CATEGORY_PG_QUERY,
    variables: { parentId, company, sellChannel, sellChannels, language, depth, groupPrice, limit, offset, lineIds, attributeIds }});
const searchProducts = async ({ search, company, sellChannel, sellChannels, language, groupPrice, limit, offset, lineIds, attributeIds, order }) =>
  apolloClient.query({query: SEARCH_PRODUCTS_QUERY,
    variables: { search, company, sellChannel, sellChannels, language, groupPrice, limit, offset, lineIds, attributeIds, order }});
const searchRefundProducts = async ({ search, company, sellChannel, sellChannels, language, groupPrice, limit, offset, lineIds, attributeIds }) =>
  apolloClient.query({query: SEARCH_REFUND_PRODUCTS_QUERY,
    variables: { search, company, sellChannel, sellChannels, language, groupPrice, limit, offset, lineIds, attributeIds }});
const getProduct = async ({ sku, optionSku, language, groupPrice, sellChannel }) =>
  apolloClient.query({ query: GET_PRODUCT_QUERY, variables: { sku, optionSku, language, groupPrice, sellChannel }});
const favProducts = async ({ company, sellChannel, sellChannels, language, groupPrice }) =>
  apolloClient.query({ query: GET_FAV_PRODUCTS_QUERY, variables: { company, sellChannel, sellChannels, language, groupPrice }});
const findProducts = async ({ products, language }) =>
  apolloClient.query({ query: FIND_PRODUCTS_GQL, variables: { products, language }});
const getProducto = async ({ optionSku, company, groupPrice }) =>
  apolloClient.query({ query: GET_PRODUCTO_GQL, variables: { optionSku, company, groupPrice }});
const getDisponibilidad = async ({ codigos }) =>
  await apolloClient.query({ query: GET_DISPONIBILIDAD_GQL, variables: { codigos }, fetchPolicy: 'no-cache' });
const productosSimilares = async ({ productoId, sellChannel, language, groupPrice }) =>
  await apolloClient.query({ query: OBTENER_PRODUCTOS_SIMILARES, variables: { productoId, sellChannel, language, groupPrice }});

export const productsService = {
  productsByCategory,
  productsByCategoryPg,
  searchProducts,
  searchRefundProducts,
  getProduct,
  favProducts,
  findProducts,
  getProducto,
  getDisponibilidad,
  productosSimilares,
};
