<template>
  <f7-app v-bind="f7params"> <!-- theme-dark -->
    <!-- PRELOADER APP SCREEN -->
    <preloader></preloader>
    <!-- LOGIN APP SCREEN -->
    <login></login>
    <!-- LEFT PANEL APP -->
    <f7-panel left cover :visible-breakpoint="1000" :width="260">
      <f7-view name="leftPanel" url="/left-panel/" :animate="appAnimate" :ios-swipe-back="false" :md-swipe-back="false"></f7-view>
    </f7-panel>
    <!-- RIGHT PANEL APP -->
    <f7-panel right cover>
      <f7-view name="rightPanel" url="/right-panel/" :animate="appAnimate" :ios-swipe-back="false" :md-swipe-back="false"></f7-view>
    </f7-panel>
    <!-- MAIN VIEW APP -->
    <f7-view main class="safe-areas" url="/" :animate="appAnimate"
             :browser-history="state.platform === 'web'"
             :browser-history-animate-on-load="true" :browser-history-animate="appAnimate"
             :ios-swipe-back="false" :md-swipe-back="false"></f7-view> <!-- :animate="false" -->
    <!-- INPUT: Input customizado para que introduzcan la cantidad los vendedores y no se les abra el teclado -->
    <input-app></input-app>
    <view-popup></view-popup>
    <small-popup></small-popup>
    <info-product-popup></info-product-popup>
    <info-promo-popup></info-promo-popup>
    <ocean-blocked-sheet></ocean-blocked-sheet>
    <new-product-stepper-sheet></new-product-stepper-sheet>
    <nav-product-stepper-sheet></nav-product-stepper-sheet>
    <refund-motive-selection-popup :mode="mode"></refund-motive-selection-popup>
    <client-selection-popup :popup-type="clientPopup.type" :order-id="clientPopup.id"></client-selection-popup>
  </f7-app>
</template>
<script>
  import Login from '@/components/popups/login-popup.vue';
  import Preloader from '@/components/sheets/preloader-sheet.vue';
  import InputApp from '@/components/sheets/input/input.vue';
  import ViewPopup from '@/components/popups/view-popup.vue';
  import SmallPopup from '@/components/popups/small-popup.vue';

  import { f7, f7ready } from 'framework7-vue';
  import { routes } from '@/js/routes';
  import { useActions, useState } from '@/js/stores/hooks';
  import { initTransitionGuard } from '@/js/helpers/f7.helpers';
  import { PANEL_BREAKPOINT, LEFT_PANEL_WIDTH } from '@/js/config/app.config';
  import { checkAppVersion, checkAuthentication, hideSplashScreen, initCurrentPath } from '../../js/helpers/f7.helpers';
  import { useI18n } from 'vue-i18n';
  import { APP_ANIMATE } from '../../js/config/app.config';
  import { reactive, onMounted, shallowRef, onBeforeMount, watch } from 'vue';
  import { Plugins } from '@capacitor/core';
  import OceanBlockedSheet from '../sheets/ocean-blocked-sheet';
  import RefundMotiveSelectionPopup from '../../pages/refunds/components/refund-motive-selection-popup';
  import ClientSelectionPopup from '../../pages/selections/components/client-selection-popup';
  import InfoProductPopup from '../../pages/catalogv2/components/product/info-product-popup';
  import InfoPromoPopup from '../../pages/catalogv2/components/product/info-promo-popup';
  import NewProductStepperSheet from '../sheets/new-product-stepper/new-product-stepper-sheet';
  import { getDevice }  from 'framework7/lite-bundle';
  import NavProductStepperSheet from '../sheets/nav-product-stepper/nav-product-stepper-sheet';

  export default {
    name: 'app',
    components: {
      NavProductStepperSheet,
      NewProductStepperSheet,
      InfoProductPopup,
      InfoPromoPopup,
      ClientSelectionPopup,
      RefundMotiveSelectionPopup,
      OceanBlockedSheet,
      'preloader': Preloader,
      'login': Login,
      'input-app': InputApp,
      'view-popup': ViewPopup,
      'small-popup': SmallPopup,
    },
    setup: (context) => {
      const device = getDevice();
      const { Device } = Plugins;
      const { t } = useI18n();
      // Framework7 Parameters
      const f7params = shallowRef({
        name: 'Omni App', // App name
        theme: 'ios', // 'auto' Automatic theme detection
        routes: routes,
        dialog: {
          buttonOk: t('buttons.ok'),
          buttonCancel: t('buttons.cancel'),
        },
        photoBrowser: {
          popupCloseLinkText: t('buttons.cancel'),
        },
        calendar: {
          toolbarCloseText: t('buttons.ok'),
        },
        // Register service worker
        // serviceWorker: {
        //   path: '/service-worker.js',
        // },
        // Input settings
        // input: {
        //   scrollIntoViewOnFocus: device.capacitor,
        //   scrollIntoViewCentered: device.capacitor,
        // },
        // Capacitor Statusbar settings
        // statusbar: {
        //   iosOverlaysWebView: true,
        //   androidOverlaysWebView: false,
        // },
      });
      const visibleBreakpoint = PANEL_BREAKPOINT;
      const panelWidth = `${LEFT_PANEL_WIDTH}px`;
      const appAnimate = APP_ANIMATE;
      const { setWidth, setLoadingRoute, setCurrentRoute, setMessage } = useActions('app', ['setWidth', 'setLoadingRoute', 'setCurrentRoute', 'setMessage']);
      const { setPath } = useActions('catalog', ['setPath', 'getPathNames']);
      const { getProfile, setAccessToken } = useActions('session', ['getProfile', 'setAccessToken']);
      const { sessionInfo, user } = useState('session', ['sessionInfo', 'user']);
      const { clientPopup } = useState('input', ['clientPopup']);
      const { mode } = useState('pendingRefund', ['mode']);
      const state = reactive({
        platform: '',
      });
      // ...
      onBeforeMount(async () => {
        const info = await Device.getInfo();
        // console.log(info);
        state.platform = info.platform;
      });
      onMounted(() => {
        f7ready(async () => {
          // Call F7 APIs here
          document.body.classList.add('noselect');
          window.addEventListener('resize', () => {
            setWidth(window.innerWidth);
          });
          window.addEventListener('orientationchange', () => {
            setWidth(window.innerWidth);
          });
          // ...
          document.addEventListener('visibilitychange', async function() {
            if (!document.hidden) {
              setTimeout(function () {
                const panel = f7.panel.get('left');
                panel.visibleBreakpoint = visibleBreakpoint;
                panel.enableVisibleBreakpoint();
                document.getElementsByClassName('view view-main')[0].style.marginLeft = window.innerWidth < visibleBreakpoint ? 0 : `${panelWidth}`;
              }, 250);
              checkTicaje();
            }
          });
          //...
          if (window.matchMedia('(display-mode: standalone)').matches && window.innerWidth >= visibleBreakpoint) {
            document.body.classList.add('fix-web-app');
            document.getElementsByTagName('html')[0].classList.add('fix-web-app');
          }
          await initCurrentPath({ setPath });
          await checkAuthentication({ getProfile, setAccessToken });
          initTransitionGuard({ setCurrentRoute, setLoadingRoute });
          hideSplashScreen();
        });
      });
      const checkTicaje = async () => {
        //// TODO IMPLEMENTAR CUANDO PASE LA MIGRACIÓN
        // const vendorCode = user.value && user.value.vendor ? (user.value.vendor.sellChannelToCodes.find(sc =>
        //   /(VES|VCA)/.test(sc.code))) : null;
        // if (vendorCode) {
        //   const { data } = await vendorsService.ticajeOcean(vendorCode.code);
        //   console.log(data);
        //   if (data.bloqueo) {
        //     setMessage(data.mensaje_publico);
        //     f7.sheet.get('#ocean-blocked-sheet').open();
        //   }
        // }
      };
      watch(() => sessionInfo.value, (newVal, oldVal) => {
        if (sessionInfo.value) {
          checkTicaje();
        }
      });
      return { f7params, visibleBreakpoint, panelWidth, appAnimate, state, clientPopup, mode };
    },
  };
</script>
<style lang="scss" scoped>
  :root {
    //--f7-panel-width: v-bind(panelWith);
  }
</style>
