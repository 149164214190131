import { createI18n } from 'vue-i18n';
import { es } from '../translations/es.translation';
import { pt } from '../translations/pt.translation';
import { it } from '../translations/it.translation';
import { fr } from '../translations/fr.translation';
import { de } from '../translations/de.translation';
import { en } from '../translations/en.translation';

export const i18n = createI18n({
  locale: 'es',
  messages: {
    es,
    pt,
    it,
    fr,
    de,
    en,
  }
});
