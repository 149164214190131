<template>
  <f7-sheet id="preloader-sheet" :opened="loading" :close-by-backdrop-click="false" :close-on-escape="false" :swipe-to-close="false"
            style="height: 100%; --f7-sheet-bg-color: rgba(0,0,0,0.4);" backdrop :animate="false">
    <f7-page-content>
      <div class="display-flex flex-direction-column align-items-center justify-content-center" style="flex: 1; height: 100%">
        <div class="loading-spinner"></div>
      </div>
    </f7-page-content>
  </f7-sheet>
</template>
<script>
import { useState } from '../../js/stores/hooks';

export default {
  name: 'preloader',
  setup: () => {
    const { loading } = useState('app', ['loading']);
    return { loading };
  }
};
</script>
