import * as moment from 'moment';

export const randomInt = (n) => Math.floor(Math.random() * n);

export const toggleArrayItem = (a, v) => {
  const i = a.indexOf(v);
  if (i === -1) a.push(v);
  else a.splice(i,1);
};

export const round = (number) => {
  return Math.round((number + Number.EPSILON) * 100) / 100;
};

export const horasDesde = (fecha) => {
  const date1 = moment(fecha).toDate();
  const date2 = moment().toDate();
  const diffTime = Math.abs(date2 - date1);
  return Math.ceil(diffTime / (1000 * 60 * 60));
}

export const minutosDesde = (fecha) => {
  const date1 = moment(fecha).toDate();
  const date2 = moment().toDate();
  const diffTime = Math.abs(date2 - date1);
  return Math.ceil(diffTime / (1000 * 60));
}
