import { apolloClient } from '@/js/plugins/apollo-client';
import {
  GET_CLIENT_GQL,
  GET_CLIENT_INFO_GQL,
  GET_CLIENTS_GQL,
  IMPORT_CLIENT_GQL
} from '../queries/clients/clients.queries';
import { store } from '../../../stores/vuex';

const getClientInfo = async (navisionId) => {
  const empresa = store.state.session.sessionInfo?.company ?? '';
  return await apolloClient.query({query: GET_CLIENT_INFO_GQL, variables: { navisionId, empresa }});
}
const getClient = async (navisionId) => {
  const empresa = store.state.session.sessionInfo?.company ?? '';
  return await apolloClient.query({query: GET_CLIENT_GQL,
    variables: { navisionId, empresa }, fetchPolicy: 'no-cache'});
}

const getClients = async (code, disableCache = false) => {
  const empresa = store.state.session.sessionInfo?.company ?? '';
  return await apolloClient.query({
    query: GET_CLIENTS_GQL, variables: { code, empresa },
    ...(disableCache ? { fetchPolicy: 'network-only' } : {}),
  });
};

const importClient = async (navisionId, vendorCode) => {
  const empresa = store.state.session.sessionInfo?.company ?? '';
  return await apolloClient.mutate({ mutation: IMPORT_CLIENT_GQL,
    variables: { navisionId, empresa, vendorCode }});
}

export const clientsService = {
  getClientInfo,
  getClient,
  getClients,
  importClient,
};
