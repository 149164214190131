import { usersService } from '../../api/graphql/services/users.service';
import { usersService as httpUsersService } from '../../api/http/users.service';
import { StoreCatalog } from '../catalog/catalog.index';
import { i18n } from '../../plugins/vue-i18n';
import { mainMenu } from '../../config/menu.config';
import { store } from '../vuex';

const isFavoriteMenu = (state, link) => state.favoritesMenu.findIndex(f => f.link === link) > -1;

export const StoreUserSettings = {
  module: 'userSettings',
  actions: {
    toggleFavoriteMenu: 'toggleFavoriteMenu',
    toggleFavProduct: 'toggleFavProduct',
    setTypeListProduct: 'setTypeListProduct',
    updateUserSettings: 'updateUserSettings',
  },
  mutations: {
    setUserSettings: 'SET_USER_SETTINGS',
    addFavoriteMenu: 'ADD_FAVORITE_MENU',
    removeFavoriteMenu: 'REMOVE_FAVORITE_MENU',
    addFavProduct: 'ADD_FAVORITE_PRODUCT',
    removeFavProduct: 'REMOVE_FAVORITE_PRODUCT',
    setTypeListProduct: 'SET_TYPE_LIST_PRODUCT',
    revertState: 'REVERT_STATE',
    updateUserSettings: 'UPDATE_USER_SETTINGS',
  }
};

const state = {
  favoritesMenu: [],
  favProducts: [],
  language: 'es',
  tourVisited: true,
  hasKeyboard: true,
  color: 'cosmet',
  typeListProduct: 'card',
};

const getters = {
  isFavoriteMenu: (state) => (link) => isFavoriteMenu(state, link),
};

const actions = {
  [StoreUserSettings.actions.toggleFavoriteMenu]: async ({ commit, state }, favItem) => {
    try {
      const {link, title, icon} = favItem;
      await usersService.toggleFavoriteMenu({link, title, icon});
      const favIndex = state.favoritesMenu.findIndex(f => f.link === favItem.link);
      if (favIndex > -1) {
        commit(StoreUserSettings.mutations.removeFavoriteMenu, favIndex);
      } else {
        commit(StoreUserSettings.mutations.addFavoriteMenu, {link, title, icon});
      }
    } catch (error) {
    }
  },
  [StoreUserSettings.actions.toggleFavProduct]: async ({ commit, state, dispatch }, product) => {
    // const revertState = state.userSettings;
    try {
      // const { sku, optionSku, code } = product;
      const code = product.code ?? product.optionSku ?? '';
      await usersService.toggleFavProduct({ code });
      // console.log(code);
      const favIndex = state.favProducts.findIndex(f => f === code);
      if (favIndex > -1) {
        commit(StoreUserSettings.mutations.removeFavProduct, favIndex);
        commit(`${StoreCatalog.module}/${StoreCatalog.mutations.removeFavProduct}`, product, {root: true});
      } else {
        commit(StoreUserSettings.mutations.addFavProduct, product);
        commit(`${StoreCatalog.module}/${StoreCatalog.mutations.addFavProduct}`, product, {root: true});
      }
    } catch (e) {
      // console.log(e);
      // commit(REVERT_STATE, revertState);
    }
  },
  [StoreUserSettings.actions.setTypeListProduct]: ({ commit }, type) => {
    commit(StoreUserSettings.mutations.setTypeListProduct, type);
  },
  [StoreUserSettings.actions.updateUserSettings]: async ({commit, dispatch, state}, partialUserSettings) => {
    // const revertState = state.userSettings;
    try {
      await httpUsersService.updateUserSettings(partialUserSettings);
      commit(StoreUserSettings.mutations.updateUserSettings, partialUserSettings);
      if (typeof partialUserSettings.language !== 'undefined') {
        dispatch('catalog/getPathNames', {language: state.userSettings.language }, {root: true});
        await dispatch('pendingOrder/getPendingOrder', null, {root: true});
        await dispatch('pendingRefund/getPendingRefund', null, {root: true});
      }
      if (typeof partialUserSettings.hasKeyboard !== 'undefined') {
        await dispatch('input/setType', partialUserSettings.hasKeyboard ? 'dialog' : 'calculator', { root: true });
      }
    } catch (e) {
      // console.log(e);
      // commit(StoreUserSettings.mutations.revertState, revertState);
    }
  },
};

const mutations = {
  [StoreUserSettings.mutations.setUserSettings]: (state, userSettings) => {
    const { favoritesMenu, favProducts, language, tourVisited, color, typeListProduct, hasKeyboard } = userSettings;
    i18n.global.locale = language;
    state.favoritesMenu = [...favoritesMenu.filter(f => {
      const menuItem = mainMenu.find(m => m.link === f.link);
      return !!menuItem && !menuItem.blockedChannels.includes(store.state.session.sessionInfo.sellChannel);
    })];
    state.favProducts = [...favProducts];
    state.language = language;
    state.tourVisited = tourVisited;
    state.hasKeyboard = hasKeyboard;
    state.color = color;
    // state.typeListProduct = typeListProduct;
  },
  [StoreUserSettings.mutations.addFavoriteMenu]: (state, favItem) => {
    state.favoritesMenu.push(favItem);
  },
  [StoreUserSettings.mutations.removeFavoriteMenu]: (state, index) => {
    state.favoritesMenu.splice(index, 1);
  },
  [StoreUserSettings.mutations.addFavProduct]: (state, favProduct) => {
    // const { code } = favProduct;
    const code = favProduct.code ?? favProduct.optionSku ?? '';
    state.favProducts.push(code);
  },
  [StoreUserSettings.mutations.removeFavProduct]: (state, index) => {
    state.favProducts.splice(index, 1);
  },
  [StoreUserSettings.mutations.setTypeListProduct]: (state, type) => state.typeListProduct = type,
  [StoreUserSettings.mutations.revertState]: (state, originalState) => {

  },
  [StoreUserSettings.mutations.updateUserSettings]: (state, userSettings) => {
    const { language, tourVisited, color, typeListProduct, hasKeyboard } = userSettings;
    if (userSettings.language) state.language = language;
    if (userSettings.tourVisited) state.tourVisited = tourVisited;
    if (userSettings.hasKeyboard) state.hasKeyboard = hasKeyboard;
    if (userSettings.color) state.color = color;
    // if (userSettings.typeListProduct) state.typeListProduct = typeListProduct;
  },
};

export const userSettingsStore = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
