<template>
  <f7-popup id="small-popup">
    <f7-view name="smallPopup" :animate="false">
      <f7-page></f7-page>
    </f7-view>
  </f7-popup>
</template>
<script>
export default {
  name: 'view-popup',
  setup: () => {},
};
</script>
