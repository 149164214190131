import { apolloClient } from '@/js/plugins/apollo-client';
import { GET_CURRENT_PATH, GET_PATH_NAMES, GET_SUBCATEGORIES } from '../queries/categories/categories.queries';

const getSubcategories = async ({ parentId, company, sellChannel, language }) =>
  apolloClient.query({query: GET_SUBCATEGORIES, variables: { parentId, company, sellChannel, language }});
const getPathNames = async ({ path, language }) => apolloClient.query({ query: GET_PATH_NAMES, variables: { path, language }});
const getCurrentPath = async ({ parentId, company, sellChannel, sellChannels, language, groupPrice, depth, lineIds, attributeIds }) =>
  apolloClient.query({query: GET_CURRENT_PATH, variables: { parentId, company, sellChannel, sellChannels, language, depth, groupPrice, lineIds, attributeIds }});

export const categoriesService = {
  getSubcategories,
  getPathNames,
  getCurrentPath,
};
