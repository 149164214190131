<template>
  <f7-navbar :large="navLarge" :transparent="transparent">
    <f7-nav-left v-if="backButton" :back-link="isInBreakpoint ? '' : $t(backButtonText)">
      <slot name="left">
        <left-menu-button :icon="leftIcon"></left-menu-button>
      </slot>
    </f7-nav-left>
    <f7-nav-left v-else>
      <slot name="left">
        <left-menu-button :icon="leftIcon"></left-menu-button>
      </slot>
    </f7-nav-left>
    <slot name="center">
      <f7-nav-title>{{ title }}</f7-nav-title>
      <f7-nav-title-large v-if="navLarge">{{ title }}</f7-nav-title-large>
    </slot>
    <f7-nav-right v-if="hasRightPanel">
      <slot name="right">
        <right-menu-button :icon="rightIcon"></right-menu-button>
      </slot>
    </f7-nav-right>
    <slot name="subnavbar"></slot>
  </f7-navbar>
</template>
<script>
import LeftMenuButton from './components/left-menu-button.vue';
import RightMenuButton from './components/right-menu-button.vue';
import { isInBreakpoint } from '../../../js/helpers/f7.helpers';

export default {
  name: 'navbar',
  props: {
    title:            { type: String    , default: '' },
    leftIcon:         { type: String    , default: 'las la-bars' },
    rightIcon:        { type: String    , default: 'las la-bars' },
    navLarge:         { type: Boolean   , default: true },
    transparent:      { type: Boolean   , default: true },
    backButton:       { type: Boolean   , default: true },
    hasRightPanel:    { type: Boolean   , default: true },
    backButtonText:   { type: String    , default: 'buttons.back' },
  },
  components: {
    'left-menu-button': LeftMenuButton,
    'right-menu-button': RightMenuButton,
  },
  setup: () => {
    return { isInBreakpoint };
  }
}
</script>
