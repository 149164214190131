import { apolloClient } from '@/js/plugins/apollo-client';
import {
  GET_CHIEFTEAM_GQL,
  GET_INFO_GQL,
  GET_TOP_CLIENTES_GQL, GET_TOP_OBJETIVOS_GQL,
  GET_VENDOR_GQL
} from '../queries/vendors/vendors.queries';

const getVendor = (username) => apolloClient.query({query: GET_VENDOR_GQL, variables: { username }});
const getChiefTeam = (username) => apolloClient.query({query: GET_CHIEFTEAM_GQL, variables: { username }});
const getInfo = ({ vendorCode, companies }) => apolloClient.query({ query: GET_INFO_GQL, variables: { vendorCode, companies }});
const topClientes = ({ vendedorCodigo, empresa }, fetchPolicy = '') => apolloClient.query({ query: GET_TOP_CLIENTES_GQL, variables: { vendedorCodigo, empresa },
  ...(fetchPolicy !== '' ? { fetchPolicy: 'no-cache'} : {})});
const topObjetivos = ({ vendedorCodigo, empresa }) => apolloClient.query({ query: GET_TOP_OBJETIVOS_GQL, variables: { vendedorCodigo, empresa }});

export const vendorsService = {
  getVendor,
  getChiefTeam,
  getInfo,
  topClientes,
  topObjetivos,
};
