import * as moment from 'moment';
import * as _ from 'lodash';
import { chargesService } from '../../api/http/charges.service';
import { round } from '../../helpers/math.helpers';
import { PAYMENT_FORMS, PAYMENT_TERMS } from '../../config/enums.config';
import { createNotification } from '../../helpers/f7.helpers';

export const StorePendingCharge = {
  module: 'pendingCharge',
  actions: {
    initPendingCharge : 'initPendingCharge',
    getPendingCharge  : 'getPendingCharge',
    setPendingCharge  : 'setPendingCharge',
    setProperty       : 'setProperty',
    selectAll         : 'selectAll',
    selectIndex       : 'selectIndex',
    updateCharge      : 'updateCharge',
    addDate           : 'addDate',
    deleteDate        : 'deleteDate',
    setNumCheck       : 'setNumCheck',
  },
  mutations: {
    setLoading          : 'SET_LOADING',
    pendingChargeError  : 'PENDING_CHARGE_ERROR',
    setPendingCharge    : 'SET_PENDING_CHARGE',
    setProperty         : 'SET_PROPERTY',
    selectAll           : 'SELECT_ALL',
    selectIndex         : 'SELECT_INDEX',
    setPropertyCharge   : 'SET_PROPERTY_CHARGE',
    addDate             : 'ADD_DATE_CHARGE',
    deleteDate          : 'DELETE_DATE_CHARGE',
    setNumCheck         : 'SET_NUM_CHECK_CHARGE',
    setChargeParams     : 'SET_CHARGE_PARAMS',
  }
};

const state = {
  loading: false,
  pendingCharge: null,
  pendingChargeInit: false,
};

const selectedLines = (state) => state.pendingCharge ? state.pendingCharge.lines.filter(l => l.selected) : [];
const selectedImport = (state) => state.pendingCharge ? state.pendingCharge.lines.reduce((acc, l) => acc += (l.selected ? l.selectedImport : 0), 0) : 0;
const isAllSelected = (state) => state.pendingCharge ? state.pendingCharge.lines.findIndex(l => !l.selected || l.selected === false) === -1 : false;

const getters = {
  selectedLines: (state) => selectedLines(state),
  selectedImport: (state) => selectedImport(state),
  isAllSelected: (state) => isAllSelected(state),
  comment: (state) => state.pendingCharge ? state.pendingCharge.comment : '',
  numCheck: (state) => state.pendingCharge ? state.pendingCharge.numCheck : '',
};

const actions = {
  [StorePendingCharge.actions.initPendingCharge]: async ({ dispatch, state }) => {
    if (!state.pendingChargeInit) {
      dispatch(StorePendingCharge.actions.getPendingCharge);
    }
  },
  [StorePendingCharge.actions.getPendingCharge]: async ({ commit, state, rootState }) => {
    commit(StorePendingCharge.mutations.setLoading, true);
    try {
      const sessionInfo = rootState.session.sessionInfo;
      const pendingCharge = (await chargesService.findCharge(
        sessionInfo.selectedCode,
        sessionInfo.selectedClient,
        sessionInfo.sellChannel,
        sessionInfo.status,
      )).data;
      commit(StorePendingCharge.mutations.setPendingCharge, pendingCharge);
    } catch (e) {
      commit(StorePendingCharge.mutations.pendingChargeError, e);
    }
    commit(StorePendingCharge.mutations.setLoading, false);
  },
  [StorePendingCharge.actions.setPendingCharge]: ({ commit, dispatch, state, rootState }, pendingCharge) => {
    commit(StorePendingCharge.mutations.setPendingCharge, pendingCharge)
  },
  [StorePendingCharge.actions.setProperty]: ({ commit, dispatch, state, rootState }, { parameter, value, index }) => {
    const oldState = state.pendingCharge;
    try {
      const newParam = { [parameter]: value };
      chargesService.updateLine(state.pendingCharge._id, index, newParam);
      commit(StorePendingCharge.mutations.setProperty, { parameter, value, index });
    } catch (e) {
      commit(StorePendingCharge.mutations.setPendingCharge, oldState);
    }
  },
  [StorePendingCharge.actions.selectAll]: async ({ commit, dispatch, state, rootState }, { selected }) => {
    await chargesService.selectedAll(state.pendingCharge._id, selected);
    commit(StorePendingCharge.mutations.selectAll, { selected });
  },
  [StorePendingCharge.actions.selectIndex]: async ({ commit, dispatch, state, rootState }, { selected, index }) => {
    await chargesService.updateLine(state.pendingCharge._id, index, { selected });
    commit(StorePendingCharge.mutations.selectIndex, { selected, index });
  },
  [StorePendingCharge.actions.updateCharge]: async ({ commit, dispatch, state, rootState }, { parameter, value }) => {
    const oldState = _.cloneDeep(state.pendingCharge);
    try {
      const newParam = { [parameter]: value };
      const extraParams = {};
      if (parameter === 'paymentForm') {
        extraParams.paymentDate = new Date();
        if (!PAYMENT_FORMS[value].hasPaymentTerms) {
          extraParams.paymentTerm = '';
        }
      }
      if (parameter === 'paymentTerm') {
        extraParams.paymentDate = moment().add(PAYMENT_TERMS[value].dateCount, PAYMENT_TERMS[value].dateType).toDate();
      }
      commit(StorePendingCharge.mutations.setChargeParams, {...newParam, ...extraParams});
      await chargesService.updateCharge(state.pendingCharge._id, {...newParam, ...extraParams});
    } catch (e) {
      commit(StorePendingCharge.mutations.setPendingCharge, oldState);
      createNotification(e.message, e.statusCode);
    }
  },
  [StorePendingCharge.actions.addDate]: ({ commit, dispatch, state, rootState }, date) => {
    commit(StorePendingCharge.mutations.addDate, date);
  },
  [StorePendingCharge.actions.deleteDate]: ({ commit, dispatch, state, rootState }, index) => {
    commit(StorePendingCharge.mutations.deleteDate, index);
  },
  [StorePendingCharge.actions.setNumCheck]: ({ commit, dispatch, state, rootState }, numCheck) => {
    commit(StorePendingCharge.mutations.setNumCheck, numCheck);
  },
};

const mutations = {
  [StorePendingCharge.mutations.setLoading]: (state, loading) => {
    state.loading = loading;
  },
  [StorePendingCharge.mutations.pendingChargeError]: (state) => {
    state.pendingCharge = null;
    state.pendingChargeInit = false;
  },
  [StorePendingCharge.mutations.setPendingCharge]: (state, pendingCharge) => {
    state.pendingCharge = pendingCharge;
    state.pendingChargeInit = true;
  },
  [StorePendingCharge.mutations.setProperty]: (state, { parameter, value, index }) => {
    let chargeLine = state.pendingCharge.lines[index];
    const oldImport = chargeLine.selectedImport;
    if (parameter === 'selectedImport') {
      if (chargeLine.maxImport < 0) {
        value = round(Math.min(Math.max(parseFloat(value), chargeLine.maxImport), 0));
      } else {
        value = round(Math.min(Math.max(parseFloat(value), 0), chargeLine.maxImport));
      }
    }
    state.pendingCharge.lines[index][parameter] = value;
    state.pendingCharge.import = round(state.pendingCharge.import + (state.pendingCharge.lines[index].selectedImport - oldImport));
  },
  [StorePendingCharge.mutations.selectAll]: (state, { selected }) => {
    // console.log(selected);
    const charge = _.cloneDeep(state.pendingCharge);
    charge.lines.forEach(l => l.selected = selected);
    state.pendingCharge = { ...state.pendingCharge, ...charge };
  },
  [StorePendingCharge.mutations.selectIndex]: (state, { selected, index }) => {
    state.pendingCharge.lines[index].selected = selected;
  },
  [StorePendingCharge.mutations.setPropertyCharge]: (state, { parameter, value }) => {
    state.pendingCharge[parameter] = value;
  },
  [StorePendingCharge.mutations.addDate]: (state, date) => {
    state.pendingCharge.dates.push({ dueDate: date.dueDate, import: date.import });
  },
  [StorePendingCharge.mutations.deleteDate]: (state, index) => {
    state.pendingCharge.dates.splice(index, 1);
  },
  [StorePendingCharge.mutations.setNumCheck]: (state, numCheck) => {
    state.pendingCharge.numCheck = numCheck;
  },
  [StorePendingCharge.mutations.setChargeParams]: (state, newParams) => {
    state.pendingCharge = { ...state.pendingCharge, ...newParams}
  },
};

export const pendingChargeStore = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
