import { StoreSession } from './session.types';

export const mutations = {
  [StoreSession.mutations.authFailure]: (state) => {
    state.isAuthenticated = false;
    state.accessToken = '';
    state.username = '';
    state.user = null;
    state.sessionInfo = null;
  },
  [StoreSession.mutations.authSuccess]: (state, {accessToken}) => {
    state.accessToken = accessToken;
    state.isAuthenticated = true;
  },
  [StoreSession.mutations.logout]: (state) => {
    state.isAuthenticated = false;
    state.accessToken = '';
    state.username = '';
    state.user = null;
    state.sessionInfo = null;
  },
  [StoreSession.mutations.setAccessToken]: (state, accessToken) => {
    state.accessToken = accessToken;
    state.isAuthenticated = true;
  },
  [StoreSession.mutations.setUser]: (state, { user, sessionInfo }) => {
    state.user = user;
    state.username = user.username;
    state.sessionInfo = sessionInfo;
  },
  [StoreSession.mutations.setSessionInfo]: (state, sessionInfo) => {
    if (!state.sessionInfo) {
      state.sessionInfo = sessionInfo;
    } else {
      state.sessionInfo = { ...state.sessionInfo, ...sessionInfo };
    }
  },
};
