export const StoreSession = {
  module: 'session',
  actions: {
    getProfile: 'getProfile',
    login: 'login',
    logout: 'logout',
    setAccessToken: 'setAccessToken',
    getSessionInfo: 'getSessionInfo',
    setSessionInfo: 'setSessionInfo',
  },
  mutations: {
    authFailure: 'AUTH_FAILURE',
    authSuccess: 'AUTH_SUCCESS',
    logout: 'LOGOUT',
    setAccessToken: 'SET_ACCESS_TOKEN',
    setUser: 'SET_USER',
    setSessionInfo: 'SET_SESSION_INFO',
  }
};
