import gql from 'graphql-tag';

export const PRODUCT_CATALOG_FRAGMENT = gql`
  fragment ProductCatalog on ProductWeb {
    code
    sku
    optionSku
    webModel
    blocked
    new
    fusionWith
    image
    optionImage
    extraImages
    mostrarImagenEnDivisible
    translation(language: $language) {
      name
      optionName
      mainLine
      metaDescription
      metaKeyword
      description
      extraName
    }
    info(groupPrice: $groupPrice, language: $language, sellChannel: $sellChannel) {
      count
      price
      pvp
      optionName 
      promo 
      promoPriceLines 
      optionSku 
      optionImage
      blocked
      discontinued
      description
      disponibilidad
      discontinued
    }
  }
`;

export const GET_PRODUCTS_BY_CATEGORY = gql`
  query ProductsByCategory($categoryId: Int!, $company: [String!]!, $sellChannel: String!, $sellChannels: [String!]!, $depth: Int!, 
    $language: String!, $groupPrice: String!) {
    products: productsByCategory(
      categoryId: $categoryId,
      company: $company,
      sellChannel: $sellChannel,
      depth: $depth
    ) {
      ...ProductCatalog
    }
  }
  ${PRODUCT_CATALOG_FRAGMENT}
`;
export const GET_PRODUCT_BY_CATEGORY_PG = `
  products: productsByCategory(
    categoryId: $parentId,
    company: $company,
    sellChannel: $sellChannels,
    depth: $depth,
    limit: $limit,
    offset: $offset,
    lineIds: $lineIds,
    attributeIds: $attributeIds,
  ) {
    count
    result {
      __typename
      ...on ProductWeb {
        index: sku
        ...ProductCatalog
      }
      ...on Product {
        optionSku
        description
        promo
        promoPriceLines
        precio(grupoPrecio: $groupPrice)
      }
    }
    lines {
      lineIds
      sortOrder
      image
      banner
      count
      translation(language: $language) {
        name
      }
    }
    attributes {
      attributeId
      propertyId
      sortOrder
      count
      translation(language: $language) {
        name
      }
    }
    properties {
      propertyId
      sortOrder
      translation(language: $language) {
        name
      }
    }
  }
`;
export const SEARCH_PRODUCTS = `
  products: searchProducts(
    company: $company,
    sellChannel: $sellChannels,
    limit: $limit,
    offset: $offset,
    lineIds: $lineIds,
    search: $search,
    attributeIds: $attributeIds,
    language: $language,
    orderBy: $order,
  ) {
    count
    result {
      __typename
      ...on ProductWeb {
        index: sku
        ...ProductCatalog
      }
      ...on Product {
        optionSku
        description
        promo
        promoPriceLines
        precio(grupoPrecio: $groupPrice)
      }
    }
    lines {
      lineIds
      sortOrder
      image
      banner
      count
      translation(language: $language) {
        name
      }
    }
    attributes {
      attributeId
      propertyId
      sortOrder
      count
      translation(language: $language) {
        name
      }
    }
    properties {
      propertyId
      sortOrder
      translation(language: $language) {
        name
      }
    },
    suggestions
  }
`;

export const SEARCH_REFUND_PRODUCTS = `
  products: searchRefundProducts(
    company: $company,
    sellChannel: $sellChannels,
    limit: $limit,
    offset: $offset,
    lineIds: $lineIds,
    search: $search,
    attributeIds: $attributeIds,
    language: $language
  ) {
    count
    result {
      __typename
      ...on ProductWeb {
        index: sku
        ...ProductCatalog
      }
      ...on Product {
        optionSku
        description
        promo
        promoPriceLines
        precio(grupoPrecio: $groupPrice)
      }
    }
    lines {
      lineIds
      sortOrder
      image
      banner
      count
      translation(language: $language) {
        name
      }
    }
    attributes {
      attributeId
      propertyId
      sortOrder
      count
      translation(language: $language) {
        name
      }
    }
    properties {
      propertyId
      sortOrder
      translation(language: $language) {
        name
      }
    },
    suggestions
  }
`;

export const GET_PRODUCT = `
  product: findBySku(
      optionSku: $optionSku,
      sku: $sku,
    ) {
      sku
      disponibilidad
      products (groupPrice: $groupPrice, sellChannel: $sellChannel) {
        description
        promo
        promoPriceLines
        optionSku
        blocked
        discontinued
        price
        pvp
        promoProducts {
          unitsPer
          price
          description
          optionSku
        }
        weight
        packings {
          unitType
          width   
          height  
          length  
          unitsPer
        }
      }
      optionList {
        type
        translation(language: $language) {
          language
          name
        }
        options {
          optionSku
          new
          sortOrder
          optionImage
          alt
          translation(language: $language) {
            optionName
            extraName
            language
          }
        }
      }
      
    }
`;
export const GET_FAV_PRODUCTS = `
  favProducts(sellChannel: $sellChannels, company: $company) {
    ...ProductCatalog
  }
`;

export const GET_FAV_PRODUCTS_QUERY = gql`
  query GetFavProducts($sellChannel: String!, $sellChannels: [String!]!, $company: [String!]!, $language: String!, $groupPrice: String!) {
    ${GET_FAV_PRODUCTS}
  }
  ${PRODUCT_CATALOG_FRAGMENT}
`;
export const GET_PRODUCTS_BY_CATEGORY_PG_QUERY = gql`
  query ProductsByCategory($parentId: Int!, $company: [String!]!, $sellChannel: String!, $sellChannels: [String!]!, $language: String!,
    $groupPrice: String!, $depth: Int!, $limit: Int, $offset: Int, $lineIds: [Int!], $attributeIds: [[Int!]]) {
    ${GET_PRODUCT_BY_CATEGORY_PG}
  }
  ${PRODUCT_CATALOG_FRAGMENT}
`;
export const SEARCH_PRODUCTS_QUERY = gql`
  query SearchProducts($search: String!, $company: [String!]!, $sellChannel: String!, $sellChannels: [String!]!, $language: String!,
    $groupPrice: String!, $limit: Int, $offset: Int, $lineIds: [Int!], $attributeIds: [[Int!]], $order: JSON) {
    ${SEARCH_PRODUCTS}
  }
  ${PRODUCT_CATALOG_FRAGMENT}
`;

export const SEARCH_REFUND_PRODUCTS_QUERY = gql`
  query SearchProducts($search: String!, $company: [String!]!, $sellChannel: String!, $sellChannels: [String!]!, $language: String!,
    $groupPrice: String!, $limit: Int, $offset: Int, $lineIds: [Int!], $attributeIds: [[Int!]]) {
    ${SEARCH_REFUND_PRODUCTS}
  }
  ${PRODUCT_CATALOG_FRAGMENT}
`;

export const GET_PRODUCT_QUERY = gql`
  query GetProduct($sku: String!, $optionSku: String!, $groupPrice: String!, $language: String!, $sellChannel: String!) {
    ${GET_PRODUCT}
  }
`;

export const FIND_PRODUCTS_GQL = gql`
  query FindProducts($products: [String!]!, $language: String!) {
    products: findProducts(
      products: $products
    ) {
      image
      optionImage
      optionSku
      translation(language: $language) {
        name
        optionName
        description
        metaDescription
        metaKeyword
        mainLine
        extraName
        tag
      }
    }
  }
`;

export const GET_PRODUCTO_GQL = gql`
  query GetProducto($optionSku: String!, $company: String!, $groupPrice: String!) {
    producto(optionSku: $optionSku, company: $company) {
      optionSku
      description
      promoPriceLines
      promoProducts {
        price
        unitsPer
        optionSku
        company
        description
        line
      }
      precio(grupoPrecio: $groupPrice)
    }
  }
`;

export const GET_DISPONIBILIDAD_GQL = gql`
  query ProductosDisponibilidad($codigos: [String!]!) {
    productosDisponibilidad(codigos: $codigos)
  }
`;

export const OBTENER_PRODUCTOS_SIMILARES = gql`
  query Similares(
    $productoId: String!,
    $language: String!,
    $sellChannel: String!,
    $groupPrice: String!,
  ) {
  similares(
    productoId: $productoId,
    language: $language,
    sellChannel: $sellChannel,
    groupPrice: $groupPrice
  ) {
      ...ProductCatalog
    }
  }
  ${PRODUCT_CATALOG_FRAGMENT}
`;

