<template>
  <f7-popup id="refund-motive-selection-popup">
    <f7-page>
      <f7-navbar :title="$t('REFUND_MOTIVE')">
        <f7-nav-right>
          <f7-link popup-close>{{$t('CLOSE')}}</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <f7-block-title>{{$t('MOTIVES')}}</f7-block-title>
      <f7-list>
        <f7-list-item v-for="(motive, index) in state.motives" :key="index"
                      :title="motive.name" @click="addLines(motive)"></f7-list-item>
      </f7-list>
    </f7-page>
  </f7-popup>
</template>
<script>
import { REFUND_MOTIVES } from '../../../js/config/enums.config';
import { refundsService } from '../../../js/api/http/refunds.service';
import { useActions, useState } from '../../../js/stores/hooks';
import { reactive } from 'vue';
import { createNotification } from '../../../js/helpers/f7.helpers';
import { f7 } from 'framework7-vue';

export default {
  name: 'refund-motive-selection-popup',
  props: {
    lines: Array,
    mode: { type: String, default: 'add' }
  },
  setup: (props) => {
    const { sessionInfo } = useState('session', ['sessionInfo']);
    const { getPendingRefund, updateMotive, clearSelectedLines } = useActions('pendingRefund', ['getPendingRefund', 'updateMotive', 'clearSelectedLines']);
    const { selectedLines } = useState('pendingRefund', ['selectedLines']);
    const state = reactive({
      motives: REFUND_MOTIVES,
    });
    const addLines = async (motive) => {
      if (props.mode === 'add') {
        try {
          const {selectedCode, selectedClient, sellChannel, status} = sessionInfo.value;
          (await refundsService.addLines(selectedLines.value.map(l => ({...l, motive: motive.value})), selectedCode, selectedClient, sellChannel, status)).data;
          getPendingRefund();
          clearSelectedLines();
          createNotification('REFUND_LINES_ADDED', '200');
          f7.popup.get('#refund-motive-selection-popup').close();
        } catch (e) {
          createNotification(e.message, e.statusCode);
        }
      }
      if (props.mode === 'edit') {
        updateMotive({motive: motive.value});
        f7.popup.get('#refund-motive-selection-popup').close();
      }
    };
    return { state, addLines };
  }
}
</script>