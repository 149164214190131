export const PANEL_BREAKPOINT = 1000;
export const LEFT_PANEL_WIDTH = 260;
export const APP_TRANSITION = ''; // f7-dive
export const APP_PANEL_TRANSITION = 'f7-dive';
export const COSMET_API_KEY = 'e4c973fb35ab1ba9bd46beb10acedeb998fa7cbd';
export const APP_VERSION = '1.0.0';
export const APP_ANIMATE = false;
export const MONTHS = ['ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO', 'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE'];
export const DEFAULT_LANGUAGE = 'es';

export const IMAGE_SIZES = {
  CATEGORY: { medium: 300 },
  PRODUCT: { medium: 500, small: 200 },
  OPTION: { medium: 328, small: 40 },
};
