import { instance as axios } from '../../plugins/axios';

const profile = () => axios.get('/users/profile');
const vendor = () => axios.get('/users/vendor');
const reviser = () => axios.get('/users/reviser');
const chiefTeam = () => axios.get('/users/chiefteam');
const distributor = () => axios.get('/users/distributor');
const admin = () => axios.get('/users/admin');
const userSettings = () => axios.get('/users/user_settings');
const getSessionInfo = () => axios.get('/users/session_info');
const toggleFavoriteMenu = (favoriteMenu) => axios.post('/users/toggle_favorite_menu', favoriteMenu);
const toggleFavProduct = (sku) => axios.post('/users/toggle_fav_product', {sku});
const updateSessionInfo = (sessionInfo) => axios.post('/users/update_session_info', sessionInfo);
const updateUserSettings = (userSettings) => axios.post('/users/update_user_settings', userSettings);
const changePassword = (username, oldPassword, newPassword) => axios.post('/users/change_password', {username, oldPassword, newPassword});

export const usersService = {
  profile,
  vendor,
  reviser,
  chiefTeam,
  distributor,
  admin,
  userSettings,
  getSessionInfo,
  toggleFavoriteMenu,
  toggleFavProduct,
  updateSessionInfo,
  updateUserSettings,
  changePassword,
};
