export const SELL_CHANNEL = {
  TAHE_ESPANA:        { code: 'TAHE_ESPANA'         , company: 'TAHE_2016'             , groupPrice: '-GENERAL'    , codePrefix: 'VES' },
  TAHE_DISTRIBUIDOR:  { code: 'TAHE_DISTRIBUIDOR'   , company: 'TAHE_2016'             , groupPrice: '-GENERAL'    , codePrefix: 'DES' },
  TAHE_CANARIAS:      { code: 'TAHE_CANARIAS'       , company: 'TAHE_2016'             , groupPrice: '-GENERAL'    , codePrefix: 'VCA' },
  TAHE_PORTUGAL:      { code: 'TAHE_PORTUGAL'       , company: 'TAHE_2016'             , groupPrice: '-TAHEPORT'   , codePrefix: 'VPT' },
  TAHE_ITALIA:        { code: 'TAHE_ITALIA'         , company: 'TAHE_2016'             , groupPrice: '-SALON-IT'   , codePrefix: 'VIN' },
  TAHE_INTERNACIONAL: { code: 'TAHE_INTERNACIONAL'  , company: 'TAHE_2016'             , groupPrice: '-GENERAL'    , codePrefix: 'VIN' },
  TAHE_OUTLET:        { code: 'TAHE_OUTLET'         , company: 'TAHE_2016'             , groupPrice: '-GENERAL'    , codePrefix: 'VES' },
  TAHE_FRANCIA:       { code: 'TAHE_FRANCIA'        , company: 'TAHE_2016'             , groupPrice: '-SALON-FR'   , codePrefix: 'VFR' },
  TAHE_ALEMANIA:       { code: 'TAHE_ALEMANIA'        , company: 'TAHE_2016'             , groupPrice: '-SALON-DE'   , codePrefix: 'VDE' },
  TH_ESPANA:          { code: 'TH_ESPANA'           , company: 'TH_PHARMA_2016'        , groupPrice: '-GENERAL'    , codePrefix: 'AES' },
  TH_PORTUGAL:        { code: 'TH_PORTUGAL'         , company: 'TH_PHARMA_2016'        , groupPrice: '-THFARPORT'  , codePrefix: 'VPTH' },
  TH_ITALIA:          { code: 'TH_ITALIA'           , company: 'TH_PHARMA_2016'        , groupPrice: '-GENERAL'    , codePrefix: 'VIN' },
  TH_INTERNACIONAL:   { code: 'TH_INTERNACIONAL'    , company: 'TH_PHARMA_2016'        , groupPrice: '-GENERAL'    , codePrefix: 'VIN' },
  TH_OUTLET:          { code: 'TH_OUTLET'           , company: 'TH_PHARMA_2016'        , groupPrice: '-GENERAL'    , codePrefix: 'AES' },
  MER_ESPANA:         { code: 'MER_ESPANA'          , company: 'MERCACOSMETICA'        , groupPrice: '-GENERAL'    , codePrefix: 'MER' },
  DVF_ESPANA:         { code: 'DVF_ESPANA'          , company: 'DV_FARMACEUTIC'        , groupPrice: '-GENERAL'    , codePrefix: 'DVF' },
  KELAYA_ESPANA:      { code: 'KELAYA_ESPANA'       , company: 'KELAYA_2016'           , groupPrice: '-GENERAL'    , codePrefix: 'VESK' },
  KELAYA_CANARIAS:    { code: 'KELAYA_CANARIAS'     , company: 'KELAYA_2016'           , groupPrice: '-GENERAL'    , codePrefix: 'VCAK' },
  KELAYA_PORTUGAL:    { code: 'KELAYA_PORTUGAL'     , company: 'KELAYA_2016'           , groupPrice: '-GENERAL'    , codePrefix: 'VPTK' },
  KELAYA_FRANCIA:     { code: 'KELAYA_FRANCIA'      , company: 'KELAYA_2016'           , groupPrice: '-SALON-FR'   , codePrefix: 'VFRK' },
  KELAYA_ALEMANIA:     { code: 'KELAYA_ALEMANIA'      , company: 'KELAYA_2016'           , groupPrice: '-SALON-DE'   , codePrefix: 'VDEK' },
  BOCARE_ESPANA:      { code: 'BOCARE_ESPANA'       , company: 'BOCARE_2016'           , groupPrice: '-GENERAL'    , codePrefix: 'VESB' },
  BOCARE_CANARIAS:    { code: 'BOCARE_CANARIAS'     , company: 'BOCARE_2016'           , groupPrice: '-GENERAL'    , codePrefix: 'VCAB' },
  BOCARE_PORTUGAL:    { code: 'BOCARE_PORTUGAL'     , company: 'BOCARE_2016'           , groupPrice: '-GENERAL'    , codePrefix: 'VPTB' },
  BOCARE_FRANCIA:     { code: 'BOCARE_FRANCIA'      , company: 'BOCARE_2016'           , groupPrice: '-SALON-FR'   , codePrefix: 'VFRB' },
  BOCARE_ALEMANIA:     { code: 'BOCARE_ALEMANIA'      , company: 'BOCARE_2016'           , groupPrice: '-SALON-DE'   , codePrefix: 'VDEB' },
};

export const COMPANY = {
  TAHE:             {
    type: 'NAV360',
    code: 'TAHE',
    database: 'MAGAÑA',
    billsDatabases: ['MAGAÑA', 'NEO-DEPIL LASER'],
    image: 'static/images/tahe-logo.png',
    orderLogo: 'static/images/logo-tahe.png',
    billUrl: 'soporte.tahe.es',
    sellChannels: [
      SELL_CHANNEL.TAHE_ESPANA.code,
      SELL_CHANNEL.TAHE_DISTRIBUIDOR.code,
      SELL_CHANNEL.TAHE_CANARIAS.code,
      SELL_CHANNEL.TAHE_PORTUGAL.code,
      SELL_CHANNEL.TAHE_ITALIA.code,
      SELL_CHANNEL.TAHE_INTERNACIONAL.code,
      SELL_CHANNEL.TAHE_OUTLET.code,
      SELL_CHANNEL.TAHE_FRANCIA.code,
      SELL_CHANNEL.TAHE_ALEMANIA.code,
    ]
  },
  TH_PHARMA:        {
    type: 'NAV360',
    code: 'TH_PHARMA',
    database: 'TH PHARMA',
    billsDatabases: ['TH PHARMA'],
    image: 'static/images/thpharma.png',
    orderLogo: 'static/images/thpharma.png',
    billUrl: 'soporte.thpharma.es',
    sellChannels: [
      SELL_CHANNEL.TH_ESPANA.code,
      SELL_CHANNEL.TH_PORTUGAL.code,
      SELL_CHANNEL.TH_ITALIA.code,
      SELL_CHANNEL.TH_INTERNACIONAL.code,
      SELL_CHANNEL.TH_OUTLET.code,
    ],
  },
  MERCACOSMETICA:   {
    type: 'NAV2016',
    code: 'MERCACOSMETICA',
    database: 'MERCACOSMETICA',
    billsDatabases: ['MERCACOSMETICA'],
    image: 'static/images/test-image.png',
    billUrl: 'soporte.mixprofesional.com',
    sellChannels: [
      SELL_CHANNEL.MER_ESPANA.code,
    ]
  },
  DV_FARMACEUTIC:   {
    type: 'NAV2016',
    code: 'DV_FARMACEUTIC',
    database: 'DV-FARMACEUTIC',
    billsDatabases: ['DV-FARMACEUTIC'],
    image: 'static/images/test-image.png',
    billUrl: 'soporte.mixprofesional.com',
    sellChannels: [
      SELL_CHANNEL.DVF_ESPANA.code,
    ]
  },
  TAHE_2016:             {
    type: 'NAV2016',
    code: 'TAHE_2016',
    database: 'Tahe Productos Cosméticos S_L_',
    billsDatabases: ['Tahe Productos Cosméticos S_L_', 'NEO-DEPIL LASER'],
    image: 'static/images/tahe-logo.png',
    orderLogo: 'static/images/logo-tahe.png',
    billUrl: 'soporte.tahe.es',
    sellChannels: [
      SELL_CHANNEL.TAHE_ESPANA.code,
      SELL_CHANNEL.TAHE_DISTRIBUIDOR.code,
      SELL_CHANNEL.TAHE_CANARIAS.code,
      SELL_CHANNEL.TAHE_PORTUGAL.code,
      SELL_CHANNEL.TAHE_ITALIA.code,
      SELL_CHANNEL.TAHE_INTERNACIONAL.code,
      SELL_CHANNEL.TAHE_OUTLET.code,
      SELL_CHANNEL.TAHE_FRANCIA.code,
      SELL_CHANNEL.TAHE_ALEMANIA.code,
    ]
  },
  TH_PHARMA_2016:        {
    type: 'NAV2016',
    code: 'TH_PHARMA_2016',
    database: 'TH Pharma S_L_',
    billsDatabases: ['TH Pharma S_L_'],
    image: 'static/images/thpharma.png',
    orderLogo: 'static/images/thpharma.png',
    billUrl: 'soporte.thpharma.es',
    sellChannels: [
      SELL_CHANNEL.TH_ESPANA.code,
      SELL_CHANNEL.TH_PORTUGAL.code,
      SELL_CHANNEL.TH_ITALIA.code,
      SELL_CHANNEL.TH_INTERNACIONAL.code,
      SELL_CHANNEL.TH_OUTLET.code,
    ],
  },
  KELAYA_2016:        {
    type: 'NAV2016',
    code      : 'KELAYA_2016',
    database        : 'KELAYA',
    billsDatabases  : ['KELAYA'],
    image: 'static/images/tecnoceutical-logo.png',
    orderLogo: 'static/images/tecnoceutical-logo.png',
    billUrl: 'soporte.tecnoceutical.com',
    sellChannels: [
      SELL_CHANNEL.KELAYA_ESPANA.code,
      SELL_CHANNEL.KELAYA_CANARIAS.code,
      SELL_CHANNEL.KELAYA_PORTUGAL.code,
      SELL_CHANNEL.KELAYA_FRANCIA.code,
      SELL_CHANNEL.KELAYA_ALEMANIA.code,
    ],
  },
  'NEO-DEPIL LASER': {
    type: 'NAV360',
    code: 'TAHE',
    database: 'NEO-DEPIL LASER',
    billsDatabases: ['NEO-DEPIL LASER'],
    image: 'static/images/tahe-logo.png',
    orderLogo: 'static/images/logo-tahe.png',
    billUrl: 'soporte.tahelaser.com',
    sellChannels: []
  },
  BOCARE_2016:        {
    type: 'NAV2016',
    code      : 'BOCARE_2016',
    database        : 'BOCARE',
    billsDatabases  : ['BOCARE'],
    image: 'static/images/bocare-logo.png',
    orderLogo: 'static/images/bocare-logo.png',
    billUrl: 'soporte.bocarecosmetics.com',
    sellChannels: [
      SELL_CHANNEL.BOCARE_ESPANA.code,
      SELL_CHANNEL.BOCARE_CANARIAS.code,
      SELL_CHANNEL.BOCARE_PORTUGAL.code,
      SELL_CHANNEL.BOCARE_FRANCIA.code,
      SELL_CHANNEL.BOCARE_ALEMANIA.code,
    ],
  },
};

export const ROLE = {
  ADMIN:                  { code: 'ADMIN' },
  OFFICE:                 { code: 'OFICINA' },
  DISTRIBUTOR:            { code: 'DISTRIBUIDOR' },
  DISTRIBUTOR_VENDOR:     { code: 'DIST_VENDEDOR' },
  DISTRIBUTOR_CHIEFTEAM:  { code: 'DIST_JEFE' },
  REVISER:                { code: 'REVISOR' },
  CHIEFTEAM:              { code: 'JEFE_EQUIPO' },
  VENDOR:                 { code: 'VENDEDOR' },
  VENDOR_OUTLET:          { code: 'VENDEDOR_OUTLET' },
  EMPLOYEE:               { code: 'EMPLEADO' },
  CLIENT:                 { code: 'CLIENTE' },
  CATALOG_REVISER:        { code: 'MARKETING' },
  GUEST:                  { code: 'INVITADO' }
};

export const STATUS = {
  PENDING:                { code: 'PENDING'               , icon: 'schedule'              , color: 'orange' },
  PENDING_TO_SEND:        { code: 'PENDING_TO_SEND'       , icon: 'update'                , color: 'orange' },
  PENDING_APPROVAL:       { code: 'PENDING_APPROVAL'      , icon: 'more_horiz'            , color: 'orange' },
  PENDING_APPROVAL_DIST:  { code: 'PENDING_APPROVAL_DIST' , icon: 'more_horiz'            , color: 'orange' },
  SENDED:                 { code: 'SENDED'                , icon: 'done'                  , color: 'green' },
  OMNI_RECEIVED:          { code: 'OMNI_RECEIVED'         , icon: 'done'                  , color: 'green' },
  IMPORT_ERROR:           { code: 'IMPORT_ERROR'          , icon: 'report'                , color: 'red' },
  ERROR_IMPORTACION:      { code: 'ERROR_IMPORTACION'     , icon: 'done'                  , color: 'green' },
  RECEIVED:               { code: 'RECEIVED'              , icon: 'done_all'              , color: 'blue' },
  ACCEPTED:               { code: 'ACCEPTED'              , icon: 'verified'              , color: 'green' },
  CANCELED:               { code: 'CANCELED'              , icon: 'close'                 , color: 'red' },
  UNKNOWN:                { code: 'UNKNOWN'               , icon: 'help'                  , color: 'black' },
};

export const TYPE_ORDER_LINE = {
  LINE:      { code: 'LINE'   , noLinesText: 'info.noOrderLines'    , title: 'info.orderLines' },
  ORDER:      { code: 'ORDER'   , noLinesText: 'info.noOrderLines'    , title: 'info.orderLines' },
  SAMPLE:     { code: 'SAMPLE'  , noLinesText: 'info.noSampleLines'   , title: 'info.sampleLines' },
  INCIDENT:   { code: 'INCIDENT', noLinesText: 'info.noIncidentLines' , title: 'info.incidentLines' },
};

export const TYPE_PAYMENT = {
  ORDER:    { code: 'OFERTA' },
  CHARGE:   { code: 'COBRO' },
};

export const PAYMENT_FORMS = {
  'CONTADO': { // 1 3
    code: 'CONTADO',
    navCode: 'CONTADO',
    type: TYPE_PAYMENT.ORDER.code,
    sellChannels: [
      { code: SELL_CHANNEL.TAHE_ESPANA.code     , blocked: false },
      { code: SELL_CHANNEL.TAHE_CANARIAS.code   , blocked: false },
      { code: SELL_CHANNEL.TH_ESPANA.code       , blocked: false },
      { code: SELL_CHANNEL.TAHE_OUTLET.code     , blocked: false },
      { code: SELL_CHANNEL.TH_OUTLET.code       , blocked: false },
    ]},
  'GIRO': { // 1
    code: 'GIRO',
    navCode: 'GIRO',
    type: TYPE_PAYMENT.ORDER.code,
    hasPaymentTerms: true,
    sellChannels: [
      { code: SELL_CHANNEL.TAHE_ESPANA.code       , blocked: false },
      { code: SELL_CHANNEL.TAHE_CANARIAS.code     , blocked: false },
      { code: SELL_CHANNEL.TH_ESPANA.code         , blocked: false },
    ]},
  'CONTRAREEM': { // 1
    code: 'CONTRAREEM',
    navCode: 'CONTRAREEM',
    type: TYPE_PAYMENT.ORDER.code,
    sellChannels: [
      { code: SELL_CHANNEL.TAHE_ESPANA.code       , blocked: false },
      { code: SELL_CHANNEL.TAHE_CANARIAS.code     , blocked: false },
      { code: SELL_CHANNEL.TH_ESPANA.code         , blocked: false },
    ]},
  'GIROPT': { // 2 4
    code: 'GIROPT',
    navCode: 'GIROPT',
    type: TYPE_PAYMENT.ORDER.code,
    hasPaymentTerms: true,
    sellChannels: [
      { code: SELL_CHANNEL.TAHE_PORTUGAL.code     , blocked: false },
      { code: SELL_CHANNEL.TH_PORTUGAL.code       , blocked: false },
    ]},
  'GIROFR': { // 2 4
    code: 'GIROFR',
    navCode: 'GIROFR',
    type: TYPE_PAYMENT.ORDER.code,
    hasPaymentTerms: true,
    sellChannels: [
      { code: SELL_CHANNEL.TAHE_FRANCIA.code     , blocked: false },
      { code: SELL_CHANNEL.KELAYA_FRANCIA.code     , blocked: false },
      { code: SELL_CHANNEL.BOCARE_FRANCIA.code     , blocked: false },
    ]},
  'CONTRARPOR': { // 2 4
    code: 'CONTRARPOR',
    navCode: 'CONTRARPOR',
    type: TYPE_PAYMENT.ORDER.code,
    sellChannels: [
      { code: SELL_CHANNEL.TAHE_PORTUGAL.code     , blocked: false },
      { code: SELL_CHANNEL.TH_PORTUGAL.code       , blocked: false },
    ]},
  'CHEQUEPT': { // 2 4
    code: 'CHEQUEPT',
    navCode: 'CHEQUEPT',
    type: TYPE_PAYMENT.ORDER.code,
    sellChannels: [
      { code: SELL_CHANNEL.TAHE_PORTUGAL.code     , blocked: false },
      { code: SELL_CHANNEL.TH_PORTUGAL.code       , blocked: false },
    ]},
  'TARJETAPT': { // 2 4
    code: 'TARJETAPT',
    navCode: 'TARJETAPT',
    type: TYPE_PAYMENT.ORDER.code,
    sellChannels: [
      { code: SELL_CHANNEL.TAHE_PORTUGAL.code     , blocked: true },
      { code: SELL_CHANNEL.TH_PORTUGAL.code       , blocked: true },
    ]},
  'CONTADOPT': { // 2
    code: 'CONTADOPT',
    navCode: 'CONTADO',
    type: TYPE_PAYMENT.ORDER.code,
    sellChannels: [
      { code: SELL_CHANNEL.TAHE_PORTUGAL.code     , blocked: false },
    ]},
  'TARJ OUTLE': { // 3
    code: 'TARJ OUTLE',
    navCode: 'TARJ OUTLE',
    type: TYPE_PAYMENT.ORDER.code,
    sellChannels: [
      { code: SELL_CHANNEL.TAHE_OUTLET.code       , blocked: false },
      { code: SELL_CHANNEL.TH_OUTLET.code         , blocked: false },
    ]},
  'TRANSFPORT': { // 4
    code: 'TRANSFPORT',
    navCode: 'TRANSFPORT',
    type: TYPE_PAYMENT.ORDER.code,
    sellChannels: [
      { code: SELL_CHANNEL.TH_PORTUGAL.code       , blocked: false },
    ]},
  'TRANSFERIT': {
    code: 'TRANSFERIT',
    navCode: 'TRANSFERIT',
    type: TYPE_PAYMENT.ORDER.code,
    sellChannels: [
      { code: SELL_CHANNEL.TAHE_ITALIA.code       , blocked: false },
      { code: SELL_CHANNEL.TH_ITALIA.code         , blocked: false },
    ]
  },
  'TRANSFRANC': {
    code: 'TRANSFRANC',
    navCode: 'TRANSFRANC',
    type: TYPE_PAYMENT.ORDER.code,
    sellChannels: [
      { code: SELL_CHANNEL.TAHE_FRANCIA.code       , blocked: false },
      { code: SELL_CHANNEL.KELAYA_FRANCIA.code       , blocked: false },
      { code: SELL_CHANNEL.BOCARE_FRANCIA.code       , blocked: false },
    ]
  },
  'EFECTIVO': { // COBROS
    code: 'EFECTIVO',
    navCode: 'Efectivo',
    type: TYPE_PAYMENT.CHARGE.code,
    sellChannels: [
      { code: SELL_CHANNEL.TAHE_ESPANA.code       , blocked: false },
      { code: SELL_CHANNEL.TAHE_CANARIAS.code     , blocked: false },
      { code: SELL_CHANNEL.TAHE_PORTUGAL.code     , blocked: false },
    ]},
  'TARJETA': { // COBROS
    code: 'TARJETA',
    navCode: 'Tarjeta',
    type: TYPE_PAYMENT.CHARGE.code,
    sellChannels: [
      { code: SELL_CHANNEL.TAHE_ESPANA.code       , blocked: true },
      { code: SELL_CHANNEL.TAHE_CANARIAS.code     , blocked: true },
      { code: SELL_CHANNEL.TAHE_PORTUGAL.code     , blocked: true },
    ]},
  'GIROCOBRO': { // COBROS
    code: 'GIROCOBRO',
    navCode: 'Giro',
    hasPaymentTerms: true,
    type: TYPE_PAYMENT.CHARGE.code,
    sellChannels: [
      { code: SELL_CHANNEL.TAHE_ESPANA.code     , blocked: false },
      { code: SELL_CHANNEL.TAHE_CANARIAS.code   , blocked: false },
      { code: SELL_CHANNEL.TAHE_PORTUGAL.code   , blocked: false },
    ]},
  'CHEQUE': { // COBROS
    code: 'CHEQUE',
    navCode: 'Cheque',
    type: TYPE_PAYMENT.CHARGE.code,
    sellChannels: [
      { code: SELL_CHANNEL.TAHE_ESPANA.code     , blocked: false },
      { code: SELL_CHANNEL.TAHE_CANARIAS.code   , blocked: false },
      { code: SELL_CHANNEL.TAHE_PORTUGAL.code   , blocked: false },
    ]},
  'DESCUENTO': { // COBROS
    code: 'DESCUENTO',
    navCode: 'Descuento',
    type: TYPE_PAYMENT.CHARGE.code,
    sellChannels: [
      { code: SELL_CHANNEL.TAHE_ESPANA.code     , blocked: false },
      { code: SELL_CHANNEL.TAHE_CANARIAS.code   , blocked: false },
      { code: SELL_CHANNEL.TAHE_PORTUGAL.code   , blocked: true },
    ]},
};

export const PAYMENT_TERMS = {
  'PERSONALIZADO': {
    code: 'PERSONALIZADO', navCode: '', dateCount: 1, dateType: 'day',
    types: [TYPE_PAYMENT.CHARGE.code], // TYPE_PAYMENT.ORDER.code,
    sellChannels: [
      { code: SELL_CHANNEL.TAHE_ESPANA.code     , blocked: false },
      { code: SELL_CHANNEL.TAHE_CANARIAS.code   , blocked: false },
      { code: SELL_CHANNEL.TAHE_PORTUGAL.code   , blocked: false },
      { code: SELL_CHANNEL.TH_PORTUGAL.code     , blocked: false },
    ]
  },
  '1X7': {
    code: '1X7', navCode: '1X7', dateCount: 7, dateType: 'day',
    types: [TYPE_PAYMENT.ORDER.code, TYPE_PAYMENT.CHARGE.code],
    sellChannels: [
      { code: SELL_CHANNEL.TAHE_ESPANA.code       , blocked: false },
      { code: SELL_CHANNEL.TAHE_CANARIAS.code     , blocked: false },
      { code: SELL_CHANNEL.TAHE_PORTUGAL.code     , blocked: false },
      { code: SELL_CHANNEL.TH_PORTUGAL.code       , blocked: false },
    ]
  },
  '1X15': {
    code: '1X15', navCode: '1X15', dateCount: 15, dateType: 'day',
    types: [TYPE_PAYMENT.ORDER.code, TYPE_PAYMENT.CHARGE.code],
    sellChannels: [
      { code: SELL_CHANNEL.TAHE_ESPANA.code       , blocked: false },
      { code: SELL_CHANNEL.TAHE_CANARIAS.code     , blocked: false },
      { code: SELL_CHANNEL.TAHE_PORTUGAL.code     , blocked: false },
      { code: SELL_CHANNEL.TH_PORTUGAL.code       , blocked: false },
      { code: SELL_CHANNEL.TAHE_FRANCIA.code     , blocked: false },
      { code: SELL_CHANNEL.KELAYA_FRANCIA.code     , blocked: false },
      { code: SELL_CHANNEL.BOCARE_FRANCIA.code     , blocked: false },
    ]
  },
  '1X20': {
    code: '1X20', navCode: '1X20', dateCount: 20, dateType: 'day',
    types: [TYPE_PAYMENT.ORDER.code],
    sellChannels: [
      { code: SELL_CHANNEL.TAHE_ESPANA.code     , blocked: false },
      { code: SELL_CHANNEL.TAHE_CANARIAS.code   , blocked: false },
      { code: SELL_CHANNEL.TAHE_PORTUGAL.code   , blocked: false },
      { code: SELL_CHANNEL.TH_PORTUGAL.code     , blocked: false },
    ]
  },
  '1X21': {
    code: '1X21', navCode: '1X21', dateCount: 21, dateType: 'day',
    types: [TYPE_PAYMENT.ORDER.code],
    sellChannels: [
      { code: SELL_CHANNEL.TAHE_ESPANA.code       , blocked: false },
      { code: SELL_CHANNEL.TAHE_CANARIAS.code     , blocked: false },
      { code: SELL_CHANNEL.TAHE_PORTUGAL.code     , blocked: false },
      { code: SELL_CHANNEL.TH_PORTUGAL.code       , blocked: false },
    ]
  },
  '1X30': {
    code: '1X30', navCode: '1X30', dateCount: 1, dateType: 'month',
    types: [TYPE_PAYMENT.ORDER.code, TYPE_PAYMENT.CHARGE.code],
    sellChannels: [
      { code: SELL_CHANNEL.TAHE_ESPANA.code       , blocked: false },
      { code: SELL_CHANNEL.TAHE_CANARIAS.code     , blocked: false },
      { code: SELL_CHANNEL.TH_ESPANA.code         , blocked: false },
      { code: SELL_CHANNEL.TAHE_PORTUGAL.code     , blocked: false },
      { code: SELL_CHANNEL.TH_PORTUGAL.code       , blocked: false },
      { code: SELL_CHANNEL.TAHE_FRANCIA.code     , blocked: false },
      { code: SELL_CHANNEL.KELAYA_FRANCIA.code     , blocked: false },
      { code: SELL_CHANNEL.BOCARE_FRANCIA.code     , blocked: false },
    ]
  },
  '1X40': {
    code: '1X40', navCode: '1X40', dateCount: 40, dateType: 'day',
    types: [TYPE_PAYMENT.ORDER.code],
    sellChannels: [
      { code: SELL_CHANNEL.TAHE_ESPANA.code       , blocked: false },
      { code: SELL_CHANNEL.TAHE_CANARIAS.code     , blocked: false },
      { code: SELL_CHANNEL.TAHE_PORTUGAL.code     , blocked: false },
      { code: SELL_CHANNEL.TH_PORTUGAL.code       , blocked: false },
    ]
  },
  '1X45': {
    code: '1X45', navCode: '1X45', dateCount: 45, dateType: 'day',
    types: [TYPE_PAYMENT.ORDER.code],
    sellChannels: [
      { code: SELL_CHANNEL.TAHE_ESPANA.code       , blocked: false },
      { code: SELL_CHANNEL.TAHE_CANARIAS.code     , blocked: false },
      { code: SELL_CHANNEL.TH_ESPANA.code         , blocked: false },
      { code: SELL_CHANNEL.TAHE_PORTUGAL.code     , blocked: false },
      { code: SELL_CHANNEL.TH_PORTUGAL.code       , blocked: false },
    ]
  },
  '1X60': {
    code: '1X60', navCode: '1X60', dateCount: 2, dateType: 'month',
    types: [TYPE_PAYMENT.ORDER.code, TYPE_PAYMENT.CHARGE.code],
    sellChannels: [
      { code: SELL_CHANNEL.TAHE_ESPANA.code         , blocked: false },
      { code: SELL_CHANNEL.TAHE_CANARIAS.code       , blocked: false },
      { code: SELL_CHANNEL.TH_ESPANA.code           , blocked: false },
      { code: SELL_CHANNEL.TAHE_PORTUGAL.code       , blocked: false },
      { code: SELL_CHANNEL.TH_PORTUGAL.code         , blocked: false },
      { code: SELL_CHANNEL.TAHE_FRANCIA.code     , blocked: false },
      { code: SELL_CHANNEL.KELAYA_FRANCIA.code     , blocked: false },
      { code: SELL_CHANNEL.BOCARE_FRANCIA.code     , blocked: false },
    ]
  },
  '1X75': {
    code: '1X75', navCode: '1X75', dateCount: 75, dateType: 'day',
    types: [TYPE_PAYMENT.ORDER.code],
    sellChannels: [
      { code: SELL_CHANNEL.TAHE_ESPANA.code       , blocked: false },
      { code: SELL_CHANNEL.TAHE_CANARIAS.code     , blocked: false },
      { code: SELL_CHANNEL.TAHE_PORTUGAL.code     , blocked: false },
      { code: SELL_CHANNEL.TH_PORTUGAL.code       , blocked: false },
    ]
  },
  '1X90': {
    code: '1X90', navCode: '1X90', dateCount: 3, dateType: 'month',
    types: [TYPE_PAYMENT.ORDER.code, TYPE_PAYMENT.CHARGE.code],
    sellChannels: [
      { code: SELL_CHANNEL.TAHE_ESPANA.code       , blocked: false },
      { code: SELL_CHANNEL.TAHE_CANARIAS.code     , blocked: false },
      { code: SELL_CHANNEL.TAHE_PORTUGAL.code     , blocked: false },
      { code: SELL_CHANNEL.TH_PORTUGAL.code       , blocked: false },
    ]
  },
  '2X7': {
    code: '2X7', navCode: '2X7', dateCount: 7, dateType: 'day',
    types: [TYPE_PAYMENT.ORDER.code],
    sellChannels: [
      { code: SELL_CHANNEL.TAHE_ESPANA.code       , blocked: false },
      { code: SELL_CHANNEL.TAHE_CANARIAS.code     , blocked: false },
    ]
  },
  '2X15': {
    code: '2X15', navCode: '2X15', dateCount: 15, dateType: 'day',
    types: [TYPE_PAYMENT.ORDER.code],
    sellChannels: [
      { code: SELL_CHANNEL.TAHE_ESPANA.code       , blocked: false },
      { code: SELL_CHANNEL.TAHE_CANARIAS.code     , blocked: false },
      { code: SELL_CHANNEL.TAHE_FRANCIA.code     , blocked: false },
      { code: SELL_CHANNEL.KELAYA_FRANCIA.code     , blocked: false },
      { code: SELL_CHANNEL.BOCARE_FRANCIA.code     , blocked: false },
    ]
  },
  '2X20': {
    code: '2X20', navCode: '2X20', dateCount: 20, dateType: 'day',
    types: [TYPE_PAYMENT.ORDER.code],
    sellChannels: [
      { code: SELL_CHANNEL.TAHE_ESPANA.code       , blocked: false },
      { code: SELL_CHANNEL.TAHE_CANARIAS.code     , blocked: false },
    ]
  },
  '2X30': {
    code: '2X30', navCode: '2X30', dateCount: 1, dateType: 'month',
    types: [TYPE_PAYMENT.ORDER.code, TYPE_PAYMENT.CHARGE.code],
    sellChannels: [
      { code: SELL_CHANNEL.TAHE_ESPANA.code       , blocked: false },
      { code: SELL_CHANNEL.TAHE_CANARIAS.code     , blocked: false },
      { code: SELL_CHANNEL.TAHE_PORTUGAL.code     , blocked: false },
      { code: SELL_CHANNEL.TH_PORTUGAL.code       , blocked: false },
      { code: SELL_CHANNEL.TAHE_FRANCIA.code     , blocked: false },
      { code: SELL_CHANNEL.KELAYA_FRANCIA.code     , blocked: false },
      { code: SELL_CHANNEL.BOCARE_FRANCIA.code     , blocked: false },
    ]
  },
  '2X45': {
    code: '2X45', navCode: '2X45', dateCount: 45, dateType: 'day',
    types: [TYPE_PAYMENT.ORDER.code],
    sellChannels: [
      { code: SELL_CHANNEL.TAHE_ESPANA.code     , blocked: false },
      { code: SELL_CHANNEL.TAHE_CANARIAS.code   , blocked: false },
    ]
  },
  '2X45B': {
    code: '2X45B', navCode: '2X45B', dateCount: 45, dateType: 'day',
    types: [TYPE_PAYMENT.ORDER.code],
    sellChannels: [
      { code: SELL_CHANNEL.TAHE_ESPANA.code     , blocked: false },
      { code: SELL_CHANNEL.TAHE_CANARIAS.code   , blocked: false },
    ]
  },
  '2X60': {
    code: '2X60', navCode: '2X60', dateCount: 2, dateType: 'month',
    types: [TYPE_PAYMENT.ORDER.code, TYPE_PAYMENT.CHARGE.code],
    sellChannels: [
      { code: SELL_CHANNEL.TAHE_ESPANA.code       , blocked: false },
      { code: SELL_CHANNEL.TAHE_CANARIAS.code     , blocked: false },
      { code: SELL_CHANNEL.TAHE_PORTUGAL.code     , blocked: false },
      { code: SELL_CHANNEL.TH_PORTUGAL.code       , blocked: false },
    ]
  },
  '2X60X120': {
    code: '2X60X120', navCode: '2X60X120', dateCount: 2, dateType: 'month',
    types: [TYPE_PAYMENT.ORDER.code],
    sellChannels: [
      { code: SELL_CHANNEL.TAHE_ESPANA.code     , blocked: false },
      { code: SELL_CHANNEL.TAHE_CANARIAS.code   , blocked: false },
    ]
  },
  '2X90': {
    code: '2X90', navCode: '2X90', dateCount: 3, dateType: 'month',
    types: [TYPE_PAYMENT.ORDER.code, TYPE_PAYMENT.CHARGE.code],
    sellChannels: [
      { code: SELL_CHANNEL.TAHE_ESPANA.code     , blocked: false },
      { code: SELL_CHANNEL.TAHE_CANARIAS.code   , blocked: false },
      { code: SELL_CHANNEL.TAHE_PORTUGAL.code   , blocked: false },
      { code: SELL_CHANNEL.TH_PORTUGAL.code     , blocked: false },
    ]
  },
  '3X10': {
    code: '3X10', navCode: '3X10', dateCount: 10, dateType: 'day',
    types: [TYPE_PAYMENT.ORDER.code],
    sellChannels: [
      { code: SELL_CHANNEL.TAHE_ESPANA.code     , blocked: false },
      { code: SELL_CHANNEL.TAHE_CANARIAS.code   , blocked: false },
    ]
  },
  '3X15': {
    code: '3X15', navCode: '3X15', dateCount: 15, dateType: 'day',
    types: [TYPE_PAYMENT.ORDER.code],
    sellChannels: [
      { code: SELL_CHANNEL.TAHE_ESPANA.code     , blocked: false },
      { code: SELL_CHANNEL.TAHE_CANARIAS.code   , blocked: false },
    ]
  },
  '3X3': {
    code: '3X3', navCode: '3X3', dateCount: 45, dateType: 'day',
    types: [TYPE_PAYMENT.ORDER.code],
    sellChannels: [
      { code: SELL_CHANNEL.TAHE_ESPANA.code     , blocked: false },
      { code: SELL_CHANNEL.TAHE_CANARIAS.code   , blocked: false },
    ]
  },
  '3X30': {
    code: '3X30', navCode: '3X30', dateCount: 1, dateType: 'month',
    types: [TYPE_PAYMENT.ORDER.code, TYPE_PAYMENT.CHARGE.code],
    sellChannels: [
      { code: SELL_CHANNEL.TAHE_ESPANA.code     , blocked: false },
      { code: SELL_CHANNEL.TAHE_CANARIAS.code   , blocked: false },
    ]
  },
  '3X60': {
    code: '3X60', navCode: '3X60', dateCount: 2, dateType: 'month',
    types: [TYPE_PAYMENT.ORDER.code],
    sellChannels: [
      { code: SELL_CHANNEL.TAHE_ESPANA.code     , blocked: false },
      { code: SELL_CHANNEL.TAHE_CANARIAS.code   , blocked: false },
    ]
  },
  '4X30': {
    code: '4X30', navCode: '4X30', dateCount: 1, dateType: 'month',
    types: [TYPE_PAYMENT.ORDER.code, TYPE_PAYMENT.CHARGE.code],
    sellChannels: [
      { code: SELL_CHANNEL.TAHE_ESPANA.code     , blocked: false },
      { code: SELL_CHANNEL.TAHE_CANARIAS.code   , blocked: false },
    ]
  },
  '4X3': {
    code: '4X3', navCode: '4X3', dateCount: 15, dateType: 'day',
    types: [TYPE_PAYMENT.ORDER.code],
    sellChannels: [
      { code: SELL_CHANNEL.TAHE_ESPANA.code     , blocked: false },
      { code: SELL_CHANNEL.TAHE_CANARIAS.code   , blocked: false },
    ]
  },
  '4X4': {
    code: '4X4', navCode: '4X4', dateCount: 15, dateType: 'day',
    types: [TYPE_PAYMENT.ORDER.code],
    sellChannels: [
      { code: SELL_CHANNEL.TAHE_ESPANA.code     , blocked: false },
      { code: SELL_CHANNEL.TAHE_CANARIAS.code   , blocked: false },
    ]
  },
  '1X120': {
    code: '1X120', navCode: '1X120', dateCount: 4, dateType: 'month',
    types: [TYPE_PAYMENT.ORDER.code],
    sellChannels: [
      { code: SELL_CHANNEL.TAHE_ESPANA.code     , blocked: false },
      { code: SELL_CHANNEL.TAHE_CANARIAS.code   , blocked: false },
    ]
  },
};

export const REFUND_MOTIVES = {
  //'ACCION COM': {name: 'ACCION COMERCIAL',                                 value: 'ACCION COM'  },
  //'AJUS CON':   {name: 'AJUSTES CONTABLES INTERNACIONAL',                  value: 'AJUS CONT'   },
  'CALIDAD'     : {name: 'PROBLEMAS DE CALIDAD EN EL PRODUCTO',                 value: 'CALIDAD'     },
  'CAMBIO'      : {name: 'CAMBIO DE PRODUCTO',                                  value: 'CAMBIO'      },
  'CIERRE'      : {name: 'DEVOLUCIONES POR CIERRE/LIQUIDACION',                 value: 'CIERRE'      },
  'CLIENTE'     : {name: 'ERROR PEDIDO CLIENTE',                                value: 'CLIENTE'     },
  'DEVOLUCION'  : {name: 'DEVOLUCION DE GENERO',                                value: 'DEVOLUCION'  },
  'ERROR E'     : {name: 'ERROR ADMINISTRACION',                                value: 'ERROR E'     },
  'ERROR COME'  : {name: 'ERROR DEL COMERCIAL',                                 value: 'ERROR COME'  },
  'ERROR PICK'  : {name: 'ERROR PICKING O EXPEDICIONES',                        value: 'ERROR PICK'  },
  'PLAZO'       : {name: 'INCUMPLIMIENTO PLAZO DE ENTREGA',                     value: 'PLAZO'       },
  'REHUSADO'    : {name: 'REHUSADO POR EL CLIENTE',                             value: 'REHUSADO'    },
  'TRANSPORTE'  : {name: 'PROBLEMA AGENCIA TRANSPORTES',                        value: 'TRANSPORTE'  },
};
