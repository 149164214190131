import * as moment from 'moment';
import { computed, reactive } from 'vue';
import { store } from '../../../../../js/stores/vuex';
import { extractsService } from '../../../../../js/api/http/extracts.service';
import { createNotification } from '../../../../../js/helpers/f7.helpers';
import { i18n } from '../../../../../js/plugins/vue-i18n';
import { selectConnection } from '../../../../../js/helpers/nav.helpers';
import { COMPANY } from '../../../../../js/config/enums.config';

const { t } = i18n.global;

const state = reactive({
  skip: 0,
  take: 10,
  type: 'REFUND',
  page: computed(() => (state.skip / state.take) + 1),
  company: computed(() => store.state.app.company),
  companyIndex: 0,
  loading: true,
  initialized: false,
  bills: { result: [], count: 0 },
  currentSort: 'date-desc',
  search: {},
  sortMenu: computed(() => [
    { code: 'date-desc'   , title: `${t('fecha')} (${t('descendente')})` , sort: { date: -1 } },
    { code: 'date-asc'    , title: `${t('fecha')} (${t('ascendente')})` , sort: { date: 1 } },
    { code: 'client-asc'  , title: `${t('codigo_cliente')} (${t('ascendente')})` , sort: { clientId: 1 } },
    { code: 'client-desc' , title: `${t('codigo_cliente')} (${t('descendente')})` , sort: { clientId: -1 } },
    { code: 'name-asc'    , title: `${t('nombre_cliente')} (${t('ascendente')})` , sort: { clientName: 1 } },
    { code: 'name-desc'   , title: `${t('nombre_cliente')} (${t('descendente')})` , sort: { clientName: -1 } },
    { code: 'code-asc'    , title: `${t('num_documento')} (${t('ascendente')})` , sort: { document: 1 } },
    { code: 'code-desc'   , title: `${t('num_documento')} (${t('descendente')})` , sort: { document: -1 } },
  ]),
  currentSortObj: computed(() => state.sortMenu.find(s => s.code === state.currentSort)),
  navError: false,
  selectCompany: computed(() => selectConnection(store.state.session.sessionInfo.company)),
  conType: store.state.app.company ? store.state.app.company.type : 'NAV2016',
  selectedCompany: {
    company: store.state.app.company ? store.state.app.company.database : COMPANY.TAHE_2016.database,
    type: store.state.app.company ? store.state.app.company.type : 'NAV2016',
  },
  linesForCopy: [],
});

const getData = async () => {
  if (store.state.session.sessionInfo) {
    const company = store.state.app.company;
    const extraFilters = store.state.input.extraFilters;
    const filters = store.state.input.filters;
    state.loading = true;
    state.search = {...extraFilters, ...getJsonFilters()};
    const sort = state.sortMenu.find(s => s.code === state.currentSort);
    try {
      state.bills = (await extractsService.findBills(state.type,
        company.billsDatabases[filters.companyIndex],
        state.skip,
        state.take, JSON.stringify(state.search), sort.sort, state.selectedCompany.type)).data;
      state.navError = false;
    } catch (err) {
      state.navError = true;
      createNotification(t('errors.navisionError'), 500);
    }
    state.loading = false;
    state.initialized = true;
    // console.log('salgo0');
  }
};

const updateData = () => {
  state.skip = 0;
  state.take = 10;
  getData();
};

const changePage = (page) => {
  state.skip = (page - 1) * state.take;
  getData();
};

const updateSort = (newSort) => {
  state.currentSort = newSort;
  getData();
};

const clear = () => {
  store.dispatch('input/clearFilters');
  updateData();
};

const selectCon = (con) => {
  // console.log(con);
  con = state.selectCompany.find(c => c.type === con);
  state.selectedCompany = { company: con.company, type: con.type };
  getData();
};

const getJsonFilters = () => {
  const filters = store.state.input.filters;
  const extraFilters = store.state.input.extraFilters;
  const search = {};
  if (filters.clientSearch !== '') {
    const client = filters.clientSearch.toUpperCase().trim();
    if (!extraFilters.clientId) {
      search.clientSearch = client;
    }
  }
  if (filters.documentSearch !== '') { search.documentSearch = filters.documentSearch.toUpperCase().trim(); }
  if (filters.fromDate.length > 0) {
    search.date = { $gte: moment(filters.fromDate[0]).format('YYYYMMDD') };
  }
  if (filters.toDate.length > 0) {
    search.date = { ...search.date, $lte: moment(filters.toDate[0]).format('YYYYMMDD') };
  }
  // console.log(search);
  return search;
};

const addLinesForCopy = (lines) => {
  state.linesForCopy = lines;
};

export const Bills = () => {
  return { state, getData, changePage, updateSort, updateData, clear, selectCon, addLinesForCopy };
}
