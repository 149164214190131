import { clientsService } from '../../api/graphql/services/clients.service';
import { clientsService as httpClientsService } from '../../api/http/clients.service';
import { SELL_CHANNEL } from '../../config/enums.config';

export const StoreClients = {
  module: 'clients',
  actions: {
    getCurrentClient: 'getCurrentClient',
    getCooperativas: 'getCooperativas',
    setNewClient: 'setNewClient',
    setFormFilled: 'setFormFilled',
    resetNewClient: 'resetNewClient',
    changeEmail: 'changeEmail',
  },
  mutations: {
    setCurrentClient: 'SET_CURRENT_CLIENT',
    setCooperativas: 'SET_COOPERATIVAS',
    setNewClient: 'SET_NEW_CLIENT',
    setFormFilled: 'SET_FORM_FILLED',
    resetNewClient: 'RESET_NEW_CLIENT',
    changeEmail: 'CHANGE_EMAIL',
  }
};

const state = {
  currentClient: null,
  newClient: {
    cif                   : '',
    socialReason          : '',
    name                  : '',
    name2                 : '',
    address               : '',
    address2              : '',
    city                  : '',
    state                 : '',
    country               : '',
    postalCode            : '',
    contact               : '',
    closeDays             : '',
    email                 : '',
    phone                 : '',
    mobile                : '',
    fax                   : '',
    bankAccount           : '',
    esxx                  : '',
    bank                  : '',
    office                : '',
    digit                 : '',
    numAccount            : '',
    preClient             : true,
    typeClient            : '',
    enviarFolleto         : false,
  },
  formFilled: false,
  cooperativas: [],
};

const getters = {};

const actions = {
  [StoreClients.actions.getCurrentClient]: async ({ commit }, navisionId) => {
    try {
      // console.log(navisionId);
      const { data } = await clientsService.getClient(navisionId);
      const { client } = data;
      commit(StoreClients.mutations.setCurrentClient, { ...client });
    } catch (error) {
      // console.log(error);
    }
  },
  [StoreClients.actions.getCooperativas]: async ({ commit, rootState }) => {
    if (SELL_CHANNEL.TH_PORTUGAL.code === rootState.session.sessionInfo.sellChannel) {
      const cooperativas = (await httpClientsService.getCooperativas()).data.map(c => c.navisionId);
      commit(StoreClients.mutations.setCooperativas, cooperativas);
    }
  },
  [StoreClients.actions.setNewClient]: ({ commit }, partialNewClient) => {
    commit(StoreClients.mutations.setNewClient, partialNewClient);
  },
  [StoreClients.actions.setFormFilled]: ({ commit }, formFilled) => {
    commit(StoreClients.mutations.setFormFilled, formFilled);
  },
  [StoreClients.actions.resetNewClient]: ({ commit }) => {
    commit(StoreClients.mutations.resetNewClient);
  },
  [StoreClients.actions.changeEmail]: ({ commit }, newEmail) => {
    commit(StoreClients.mutations.changeEmail, newEmail);
  },
};

const mutations = {
  [StoreClients.mutations.setCurrentClient]: (state, currentClient) => state.currentClient = currentClient,
  [StoreClients.mutations.setCooperativas]: (state, cooperativas) => state.cooperativas = cooperativas,
  [StoreClients.mutations.setNewClient]: (state, partialNewClient) => {
    state.newClient = {...state.newClient, ...partialNewClient};
  },
  [StoreClients.mutations.setFormFilled]: (state, formFilled) => {
    state.formFilled = formFilled;
  },
  [StoreClients.mutations.resetNewClient]: (state) => {
    state.newClient = {
      cif                   : '',
      socialReason          : '',
      name                  : '',
      name2                 : '',
      address               : '',
      address2              : '',
      city                  : '',
      state                 : '',
      country               : '',
      postalCode            : '',
      contact               : '',
      closeDays             : '',
      email                 : '',
      phone                 : '',
      mobile                : '',
      fax                   : '',
      bankAccount           : '',
      esxx                  : '',
      bank                  : '',
      office                : '',
      digit                 : '',
      numAccount            : '',
      preClient             : true,
      typeClient            : '',
    };
  },
  [StoreClients.mutations.changeEmail]: (state, newEmail) => {
    // console.log(state.currentClient);
    state.currentClient.email = newEmail;
  },
};

export const clientsStore = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
