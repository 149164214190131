import { refundsService } from '../../api/graphql/services/refunds.service';
import { refundsService as httpRefundsService } from '../../api/http/refunds.service';
import { productsService } from '../../api/graphql/services/products.service';
import { FILTER_TYPE } from '../catalog/catalog.index';
import * as _ from 'lodash';
import { toggleArrayItem } from '../../helpers/math.helpers';
import { f7 } from 'framework7-vue';
import { createNotification } from '../../helpers/f7.helpers';
import { StorePendingOrder } from '../pending-order/pending-order.index';
import { round } from '../../helpers/math.helpers';

export const StorePendingRefund = {
  module: 'pendingRefund',
  actions: {
    getPendingRefund: 'getPendingRefund',
    setPendingRefund: 'setPendingRefund',
    setProperty: 'setProperty',
    selectAll: 'selectAll',
    selectIndex: 'selectIndex',
    selectFromTo: 'selectFromTo',
    updateRefund: 'updateRefund',
    updateMotive: 'updateMotive',
    setSearch: 'setSearch',
    clearActiveFilters: 'clearActiveFilters',
    changeActiveFilters: 'changeActiveFilters',
    refundProduct: 'refundProduct',
    setProduct: 'setProduct',
    pushLines: 'pushLines',
    clearSelectedLines: 'clearSelectedLines',
    setMode: 'setMode',
    openInput: 'openInput',
  },
  mutations: {
    setLoading: 'SET_LOADING',
    pendingRefundError: 'PENDING_REFUND_ERROR',
    setPendingRefund: 'SET_PENDING_REFUND',
    setProperty: 'SET_PROPERTY',
    selectAll: 'SELECT_ALL',
    selectIndex: 'SELECT_INDEX',
    selectFromTo: 'SELECT_FROM_TO',
    setPropertyRefund: 'SET_PROPERTY_REFUND',
    setRefundParams: 'SET_REFUND_PARAMS',
    setSelectedLine: 'SET_SELECTED_LINE',
    setSearchQuery: 'SET_SEARCH_QUERY',
    setNewPage: 'SET_NEW_PAGE',
    clearActiveFilters: 'CLEAR_ACTIVE_FILTERS',
    setActiveFilters: 'SET_ACTIVE_FILTERS',
    setSearch: 'SET_SEARCH',
    toggleActiveFilters: 'TOGGLE_ACTIVE_FILTERS',
    setProduct: 'SET_PRODUCT',
    pushLines: 'PUSH_LINES',
    clearSelectedLines: 'CLEAR_SELECTED_LINES',
    setMode: 'SET_MODE',
  }
};

const state = {
  pendingRefund: null,
  loading: false,
  selectedLine: null,
  products: [],
  productsCount: 0,
  searchQuery: '',
  productsPageInfo: {
    isCursor: false,
    page: 1,
    perPage: 16,
  },
  filters: {
    lines: [],
    properties: {},
    linesCount: [],
    attributesCount: {},
  },
  activeFilters: {
    lineIds: [],
    attributeIds: {},
  },
  lastFilter: {
    filterType: '',
    filterId: null,
  },
  suggestions: [],
  selectedProduct: null,
  mode: 'add',
  selectedLines: [],
};

const isActiveLine = (state, lineId) => state.activeFilters.lineIds.includes(lineId);
const isActiveAttribute = (state, {propertyId, attributeId}) => state.activeFilters.attributeIds[propertyId] && state.activeFilters.attributeIds[propertyId].includes(attributeId);
const getKeepFilter = (state) => {
  if (state.lastFilter.filterType === FILTER_TYPE.LINE) return [...state.filters.lines];
  else if (state.lastFilter.filterType === FILTER_TYPE.PROPERTY) return state.filters.properties[state.lastFilter.filterId];
};
const selectedLines = (state) => state.pendingRefund !== null ? state.pendingRefund.lines.filter(l => l.selected) : [];
const selectedImport = (state) => selectedLines(state).reduce((acc, l) => acc += (l.selected && !l.free ? l.import : 0), 0);
const selectedQuantity = (state) => selectedLines(state).reduce((acc, v) => acc += v.quantity, 0);
const isAllSelected = (state) => state.pendingRefund !== null ? state.pendingRefund.lines.findIndex(l => !l.selected || l.selected === false) === -1 : false;

const getters = {
  page: (state) => state.productsPageInfo.page,
  perPage: (state) => state.productsPageInfo.perPage,
  isActiveLine: (state) => (lineId) => isActiveLine(state, lineId),
  isActiveAttribute: (state) => ({propertyId, attributeId}) => isActiveAttribute(state, {propertyId, attributeId}),
  selectedLines: (state) => selectedLines(state),
  selectedImport: (state) => selectedImport(state),
  selectedQuantity: (state) => selectedQuantity(state),
  isAllSelected: (state) => isAllSelected(state),
};

const actions = {
  [StorePendingRefund.actions.getPendingRefund]: async ({ commit, rootState }) => {
    commit(StorePendingRefund.mutations.setLoading, true);
    try {
      const { selectedClient: clientId, selectedCode: vendorId, sellChannel, status } = rootState.session.sessionInfo;
      const { language } = rootState.userSettings;
      const { data: { pendingRefund }} = await refundsService.findRefund({ clientId, vendorId, status, sellChannel, language });
      commit(StorePendingRefund.mutations.setPendingRefund, pendingRefund);
    } catch (e) {
      // console.log(e);
      commit(StorePendingRefund.mutations.pendingRefundError);
    }
    commit(StorePendingRefund.mutations.setLoading, false);
  },
  [StorePendingRefund.actions.setPendingRefund]: async ({ commit }, pendingRefund) => {
    commit(StorePendingRefund.mutations.setPendingRefund, pendingRefund);
  },
  [StorePendingRefund.actions.setProperty]: async ({ commit }, { parameter, value, lineId }) => {
    // console.log('setProperty');
    const oldState = _.cloneDeep(state.pendingRefund);
    try {
      const newParam = { [parameter]: value };
      commit(StorePendingOrder.mutations.setProperty, {parameter, value, lineId});
      await httpRefundsService.updateLine(state.pendingRefund._id, lineId, newParam);
    } catch (e) {
      // console.log(e);
      commit(StorePendingOrder.mutations.setPendingOrder, oldState);
      createNotification(e.message, e.statusCode);
    }
  },
  [StorePendingRefund.actions.selectAll]: async ({ commit }, { selected }) => {
    await httpRefundsService.selectedAll(state.pendingRefund._id, selected);
    commit(StorePendingRefund.mutations.selectAll, { selected });
  },
  [StorePendingRefund.actions.selectIndex]: async ({ commit }, { selected, lineId }) => {
    await httpRefundsService.updateLine(state.pendingRefund._id, lineId, {selected});
    commit(StorePendingRefund.mutations.selectIndex, {selected, lineId});
  },
  [StorePendingRefund.actions.selectFromTo]: ({ commit }, { lineId }) => {
    // console.log(lineId);
    let indexTo = state.pendingRefund.lines.findIndex(l => l.lineId === lineId);
    let indexFrom = 0;
    const selected = !state.pendingRefund.lines[indexTo].selected;
    for(let i = indexTo - 1; i >= 0; i--) {
      if (state.pendingRefund.lines[i].selected === selected) {
        indexFrom = i;
        break;
      }
    }
    httpRefundsService.selectFromTo(state.pendingRefund._id, indexFrom, indexTo, selected);
    commit(StorePendingRefund.mutations.selectFromTo, {indexFrom, indexTo, selected});
  },
  [StorePendingRefund.actions.updateRefund]: async ({ commit }, { parameter, value }) => {
    const oldState = _.cloneDeep(state.pendingRefund);
    try {
      const newParam = { [parameter]: value };
      const extraParams = {};
      commit(StorePendingRefund.mutations.setRefundParams, {...newParam, ...extraParams});
      await httpRefundsService.updateRefund(state.pendingRefund._id, {...newParam, ...extraParams});
    } catch (e) {
      // console.log(e);
      commit(StorePendingRefund.mutations.setPendingRefund, oldState);
      createNotification(e.message, e.statusCode);
    }
  },
  [StorePendingRefund.actions.updateMotive]: ({ commit, dispatch }, { lineId, motive }) => {
    try {
      if (state.selectedLine) {
        dispatch('setProperty', {parameter: 'motive', value: motive, lineId: state.selectedLine});
        commit(StorePendingRefund.mutations.setSelectedLine, null);
      } else {
        commit(StorePendingRefund.mutations.setSelectedLine, lineId);
      }
    } catch (e) {
      // console.log(e);
      commit(StorePendingRefund.mutations.pendingRefundError, e);
    }
  },
  [StorePendingRefund.actions.setSearch]: async ({ commit, rootState, state }, { searchQuery, newPage = 1, clearFilters = true }) => {
    // commit(StorePendingRefund.mutations.setLoading, true);
    // console.log(searchQuery);
    commit(StorePendingRefund.mutations.setSearchQuery, searchQuery);
    const { company, sellChannel, groupPrice } = rootState.session.sessionInfo;
    const language = rootState.userSettings.language;
    let sellChannelLastPart = sellChannel.split('_');
    sellChannelLastPart = sellChannelLastPart[sellChannelLastPart.length - 1];
    commit(StorePendingRefund.mutations.setNewPage, newPage);
    if (clearFilters) commit(StorePendingRefund.mutations.clearActiveFilters);
    const { data } = await productsService.searchRefundProducts(
        {
          search: searchQuery, sellChannel, language, groupPrice,
          company: rootState.session.sessionInfo.catalogos?.length > 0 ? rootState.session.sessionInfo.catalogos.map((c) => `${c}_2016`) : [company],
          sellChannels: rootState.session.sessionInfo.catalogos?.length > 0 ? rootState.session.sessionInfo.catalogos.map((c) => `${c}_${sellChannelLastPart}`) : [sellChannel],
          offset: ((newPage - 1) * state.productsPageInfo.perPage), limit: state.productsPageInfo.perPage,
      lineIds: state.activeFilters.lineIds, attributeIds: Object.keys(state.activeFilters.attributeIds).reduce((a, v) => {
        a.push(state.activeFilters.attributeIds[v]);
        return a;
      }, [])});
    const { result, count: productsCount, lines, attributes, properties, suggestions } = data.products;
    const products = [...result];
    const filters = {
      lines: lines.map(l => ({...l})),
      linesCount: lines.reduce((o, v) => ({...o, [v.lineIds.join('-')]: v.count }), {}),
      properties: properties.reduce((o, p) => {
        const property = {...p};
        property.attributes = attributes.filter(a => a.propertyId === p.propertyId);
        o[property.propertyId] = property;
        return o;
      }, {}),
      attributesCount: properties.reduce((o, p) => {
        const attrs = attributes.filter(a => a.propertyId === p.propertyId);
        o[p.propertyId] = attrs.reduce((o2, a) => {
          o2[a.attributeId] = a.count;
          return o2;
        }, {});
        return o;
      }, {}),
    };
    commit(StorePendingRefund.mutations.setSearch, { products, productsCount, filters, clearFilters, suggestions });
    // commit(StorePendingRefund.mutations.setLoading, false);
  },
  [StorePendingRefund.actions.changeActiveFilters]: async ({ commit, state, dispatch }, { filterType, filterId }) => {
    commit(StorePendingRefund.mutations.toggleActiveFilters, { filterType, filterId });
    await dispatch(StorePendingRefund.actions.setSearch, { searchQuery: state.searchQuery, newPage: 1, clearFilters: false });
  },
  [StorePendingRefund.actions.clearActiveFilters]: async ({ commit, dispatch }) => {
    commit(StorePendingRefund.mutations.clearActiveFilters);
    await dispatch(StorePendingRefund.actions.setSearch, { searchQuery: state.searchQuery, newPage: 1 });
  },
  [StorePendingRefund.actions.refundProduct]: async ({ commit, state, dispatch }, { manual = false }) => {

  },
  [StorePendingRefund.actions.setProduct]: async ({ commit, state, dispatch }, product) => {
    commit(StorePendingRefund.mutations.setProduct, product);
  },
  [StorePendingRefund.actions.pushLines]: async ({ commit }, lines) => {
    commit(StorePendingRefund.mutations.pushLines, lines);
  },
  [StorePendingRefund.actions.clearSelectedLines]: async ({ commit }) => {
    commit(StorePendingRefund.mutations.clearSelectedLines);
  },
  [StorePendingRefund.actions.setMode]: ({ commit }, type) => {
    commit(StorePendingRefund.mutations.setMode, type);
  },
  [StorePendingRefund.actions.openInput]: ({ state }) => {
    // console.log(state.mode);
    f7.sheet.get(`#refund-motive-selection-popup`).open();
  },
};

const mutations = {
  [StorePendingRefund.mutations.setLoading]: (state, loading) => {
    state.loading = loading;
  },
  [StorePendingRefund.mutations.pendingRefundError]: (state) => {
    state.pendingRefund = null;
  },
  [StorePendingRefund.mutations.setPendingRefund]: (state, pendingRefund) => {
    if (!pendingRefund) state.pendingRefund = null;
    else state.pendingRefund = { ...pendingRefund, lines: pendingRefund.lines.map(l => ({...l}))};
  },
  [StorePendingRefund.mutations.setProperty]: (state, { parameter, value, lineId }) => {
    const index = state.pendingRefund.lines.findIndex(l => l.lineId === lineId);
    const line = state.pendingRefund.lines[index];
    let oldImport = line.import;
    state.pendingRefund.lines[index][parameter] = value;
    const importWD = line.quantity * line.unitPrice;
    state.pendingRefund.lines[index].import = round(importWD - round(importWD * (line.discount / 100)));
    state.pendingRefund.import += round(line.import - oldImport);
  },
  [StorePendingRefund.mutations.selectAll]: (state, { selected }) => {
    const refund = _.cloneDeep(state.pendingRefund);
    refund.lines.forEach(l => l.selected = selected);
    state.pendingRefund = {...state.pendingRefund, ...refund};
  },
  [StorePendingRefund.mutations.selectIndex]: (state, { selected, lineId }) => {
    const index = state.pendingRefund.lines.findIndex(l => l.lineId === lineId);
    const line = state.pendingRefund.lines[index];
    state.pendingRefund.lines[index].selected = selected;
  },
  [StorePendingRefund.mutations.selectFromTo]: (state, { indexFrom, indexTo, selected }) => {
    const refund = _.cloneDeep(state.pendingRefund);
    for (let j = indexFrom; j <= indexTo; j++) {
      refund.lines[j].selected = selected;
    }
    state.pendingRefund = {...state.pendingRefund, ...refund};
  },
  [StorePendingRefund.mutations.setPropertyRefund]: (state, { parameter, value }) => {
    state.pendingRefund[parameter] = value;
  },
  [StorePendingRefund.mutations.setRefundParams]: (state, newRefundParams) => {
    state.pendingRefund = {...state.pendingRefund, ...newRefundParams};
  },
  [StorePendingRefund.mutations.setSelectedLine]: (state, lineId) => {
    state.selectedLine = lineId;
  },
  [StorePendingRefund.mutations.setSearchQuery]: (state, searchQuery) => state.searchQuery = searchQuery,
  [StorePendingRefund.mutations.setNewPage]: (state, newPage) => state.productsPageInfo.page = newPage,
  [StorePendingRefund.mutations.clearActiveFilters]: (state) => state.activeFilters = { lineIds: [], attributeIds: {} },
  [StorePendingRefund.mutations.setActiveFilters]: (state, newFilters) => state.activeFilters = {...state.activeFilters, ...newFilters},
  [StorePendingRefund.mutations.setSearch]: (state, { products, productsCount, filters, clearFilters, suggestions }) => {
    state.products = products;
    // console.log(state.products);
    state.productsCount = productsCount;
    if (suggestions) state.suggestions = suggestions;
    if (filters) {
      if (clearFilters) state.filters = { ...state.filters, ...filters };
      else {
        // console.log('FILTROS');
        // console.log(filters);
        const hasAttrFilters = Object.keys(state.activeFilters.attributeIds).length > 0;
        if (!hasAttrFilters || state.lastFilter.filterId !== null) {
          for (const line of state.filters.lines) {
            const l = filters.lines.find(o => o.lineIds.some(i => line.lineIds.includes(i)) );
            if (l && filters.linesCount[`${l.lineIds.join('-')}`]) {
              // console.log(l);
              state.filters.linesCount[`${l.lineIds.join('-')}`] = l.count; // (hasAttrFilters ? 0 : l.count);
            } else {
              state.filters.linesCount[`${line.lineIds.join('-')}`] = (hasAttrFilters ? 0 : line.count);
            }
          }
        }
        // console.log(state.filters);
        let savedProperties = {};
        if (state.lastFilter.filterId !== null) {
          savedProperties = {[`${state.lastFilter.filterId}`]: state.filters.attributesCount[`${state.lastFilter.filterId}`]};
        }
        state.filters.attributesCount = { ...savedProperties };
        for (const k of Object.keys(filters.properties)) {
          const p = filters.properties[k];
          if (state.lastFilter.filterType === FILTER_TYPE.PROPERTY && state.lastFilter.filterId === p.propertyId) {
          } else {
            const temp = state.filters.attributesCount[`${p.propertyId}`];
            if (!temp) state.filters.attributesCount[`${p.propertyId}`] = {};
            for (const attribute of filters.properties[k].attributes) {
              // console.log(attribute);
              // console.log(state.filters.attributesCount[`${attribute.propertyId}`]);
              state.filters.attributesCount[`${attribute.propertyId}`][`${attribute.attributeId}`] = attribute.count;
            }
            // const l = filters.lines.find(o => o.lineIds.some(i => line.lineIds.includes(i)) );
            // console.log(line);
            // if (l) {
            //   state.filters.linesCount[index] = l.count;
            // } else state.filters.linesCount[index] = 0;
          }
        }
      }
    }
  },
  [StorePendingRefund.mutations.toggleActiveFilters]: (state, { filterType, filterId }) => {
    if (filterType === FILTER_TYPE.LINE) {
      const ol = state.activeFilters.lineIds.length;
      state.activeFilters.lineIds = _.xor(state.activeFilters.lineIds, filterId);
      state.lastFilter = { filterType: (ol - state.activeFilters.lineIds.length >= 0) ? '' : FILTER_TYPE.LINE, filterId: null };
    } else if (filterType === FILTER_TYPE.ATTRIBUTE) {
      const filter = state.activeFilters.attributeIds[filterId.propertyId];
      if (!filter) {
        state.activeFilters.attributeIds[filterId.propertyId] = [filterId.attributeId];
      } else {
        toggleArrayItem(filter, filterId.attributeId);
      }
      if (!state.activeFilters.attributeIds[filterId.propertyId].length) {
        delete state.activeFilters.attributeIds[filterId.propertyId];
        state.lastFilter = { filterType: '', filterId: filterId.propertyId};
      } else {
        state.lastFilter = { filterType: FILTER_TYPE.PROPERTY, filterId: filterId.propertyId};
      }
      // state.lastFilter = { filterType: FILTER_TYPE.PROPERTY, filterId: filterId.propertyId};
    }
  },
  [StorePendingRefund.mutations.setProduct]: (state, product) => {
    state.selectedProduct = product;
  },
  [StorePendingRefund.mutations.pushLines]: (state, lines) => {
    state.selectedLines = state.selectedLines.concat(lines);
  },
  [StorePendingRefund.mutations.clearSelectedLines]: (state) => {
    state.selectedLines = [];
  },
  [StorePendingRefund.mutations.setMode]: (state, mode) => state.mode = mode,
};

export const pendingRefundStore = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
