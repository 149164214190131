export const hasRole = (state, role) => state.user && state.user.roles ? state.user.roles.includes(role) : false;
export const hasAnyRole = (state, roles) => {
  if (!state.user) return false;
  for (const role of roles) {
    if (hasRole(state, role)) return true;
  }
  return false;
};
export const selectedCode = (state) => state.sessionInfo ? state.sessionInfo.selectedCode : '';
export const selectedClient = (state) => state.sessionInfo ? state.sessionInfo.selectedClient : '';

export const getters = {
  hasRole: (state) => (role) => hasRole(state, role),
  hasAnyRole: (state) => (roles) => hasAnyRole(state, roles),
  selectedCode: (state) => selectedCode(state),
  selectedClient: (state) => selectedClient(state),
  code: (state) => state.user && state.user.vendor ? state.user.vendor.codes.find(c => state.sessionInfo.selectedCode) : null,
};
