import gql from 'graphql-tag';

export const FULL_ORDER_FRAGMENT = gql`
  fragment FullOrderFragment on Order {
     _id
    disponibilidad
    date
    reportId
    clientId
    vendorId
    company
    sellChannel
    groupPrice
    clientName
    import
    importWithIVA
    comment
    paymentForm
    paymentTerm
    paymentDate
    fixedDueDate
    sendDate
    shipmentAddress
    newClient
    associatedClient
    appliesToDoc
    status
    importId
    imported
    parentReport
    revisionBy
    descPP
    dates {
      paymentForm
      percentage
      dueDate
      import
    }
    lines {
      optionSku
      sku
      lineId
      parentId
      sortId
      quantity
      discount
      unitPrice
      import
      importWithIVA
      iva
      free
      sku
      optionSku
      closed
      promo
      description
      unitType
      unitsPer
      selected
      type
      product(language: $language) {
        optionName
        name
        extraName
        optionImage
        mainLine
        blocked
        image
      }
    }
  }
`;
export const LINE_ORDER_FRAGMENT = gql`
  fragment FullLineFragment on OrderLine {
    optionSku
    sku
    lineId
    parentId
    sortId
    quantity
    discount
    unitPrice
    import
    importWithIVA
    iva
    free
    sku
    optionSku
    closed
    promo
    description
    unitType
    unitsPer
    selected
    type
    product(language: $language) {
      optionName
      name
      extraName
      optionImage
      mainLine
      blocked
      image
    }
  }
`;

export const FIND_ORDER_GQL = gql`
  query FindOrder(
    $clientId: String!,
    $sellChannel: String!,
    $status: String!,
    $vendorId: String!,
    $language: String!,
  ) {
    pendingOrder: findOrder(
      clientId: $clientId,
      sellChannel: $sellChannel,
      status: $status,
      vendorId: $vendorId,
    ) {
      ...FullOrderFragment
    }
  }
  ${FULL_ORDER_FRAGMENT}
`;

export const COPY_LINE_GQL = gql`
  mutation CopyLine($order: String!, $id: Int!, $language: String!) {
    pendingOrder: copyLine(order: $order, id: $id) {
      ...FullOrderFragment
    }
  }
  ${FULL_ORDER_FRAGMENT}
`;

export const DUPLICATE_LINE_GQL = gql`
  mutation DuplicateLine($order: String!, $id: Int!, $language: String!,) {
    pendingOrder: duplicateLine(order: $order, id: $id) {
      ...FullOrderFragment
    }
  }
  ${FULL_ORDER_FRAGMENT}
`;

export const DELETE_LINE_GQL = gql`
  mutation DeleteLine($order: String!, $id: Int!, $onlyHeader: Boolean!, $prices: Boolean!, $groupPrice: String!, $language: String!) {
    pendingOrder: deleteLine(order: $order, id: $id, onlyHeader: $onlyHeader, prices: $prices, groupPrice: $groupPrice) {
      ...FullOrderFragment
    }
  }
  ${FULL_ORDER_FRAGMENT}
`;

export const UPDATE_SELECTED_GQL = gql`
  mutation UpdateSelected($order: String!, $ids: [Int!]!, $newParams: JSON!, $language: String!) {
    pendingOrder: updateSelected(order: $order, ids: $ids, newParams: $newParams) {
      ...FullOrderFragment
    }
  }
  ${FULL_ORDER_FRAGMENT}
`;

export const COPY_SELECTED_GQL = gql`
  mutation CopySelected($order: String!, $ids: [Int!]!, $language: String!) {
    pendingOrder: copySelected(order: $order, ids: $ids) {
      ...FullOrderFragment
    }
  }
  ${FULL_ORDER_FRAGMENT}
`;

export const DUPLICATE_SELECTED_GQL = gql`
  mutation DuplicateSelected($order: String!, $ids: [Int!]!, $language: String!,) {
    pendingOrder: duplicateSelected(order: $order, ids: $ids) {
      ...FullOrderFragment
    }
  }
  ${FULL_ORDER_FRAGMENT}
`;

export const DELETE_SELECTED_GQL = gql`
  mutation DeleteSelected($order: String!, $ids: [Int!]!, $language: String!) {
    pendingOrder: deleteSelected(order: $order, ids: $ids) {
      ...FullOrderFragment
    }
  }
  ${FULL_ORDER_FRAGMENT}
`;

export const REORDER_LINES_GQL = gql`
  mutation ReorderLines($order: String!, $copiedId: Int!, $to: Int!, $language: String!) {
    lines: reorder(order: $order, copiedId: $copiedId, to: $to) {
      ...FullLineFragment
    }
  }
  ${LINE_ORDER_FRAGMENT}
`;

export const BLOQUEADO_POR_RGPD_GQL = gql`
  query BloqueadoPorRGPD(
    $clientId: String!,
    $vendorId: String!,
    $company: String!
  ) {
    bloqueado: bloqueadoPorRGPD(
      clientId: $clientId
      vendorId: $vendorId
      company: $company
    )
  }
`;