import { instance as axios } from '../../plugins/axios';

const findOrder = (vendorId, clientId, sellChannel, status) =>
  axios.post('/orders/find_order', {vendorId, clientId, sellChannel, status});

const findOrderById = (id) =>
  axios.post('/orders/find_order_by_id', {id});

const findOrders = (skip, take, searchParams, orderParams) =>
  axios.post('/orders/find_orders_v2', {skip, take, searchParams, orderParams});

const addLines = (lines, vendorId, clientId, sellChannel, status, groupPrice, ignoreBlocked = false) =>
  axios.post('/orders/add_lines', {lines, vendorId, clientId, sellChannel, status, groupPrice, ignoreBlocked});

const updateOrder = (order, newParams) =>
  axios.post('/orders/update_order', {order, newParams});

const processOrder = (order, newParams, reservas = []) =>
  axios.post('/orders/process', {order, newParams, reservas });

const updateLine = (order, id, newParam) =>
  axios.post('/orders/update_line', {order, id, newParam});

const duplicateLine = (order, id) =>
  axios.post('/orders/duplicate_line', {order, id});

const copyLine = (order, id) =>
  axios.post('/orders/copy_line', {order, id});

const deleteLine = (order, id, onlyHeader = false, prices = false, groupPrice) =>
  axios.post('/orders/delete_line', {order, id, onlyHeader, prices, groupPrice});

const updateSelected = (order, ids, newParams) =>
  axios.post('/orders/update_selected', {order, ids, newParams});

const duplicateSelected = (order, ids) =>
  axios.post('/orders/duplicate_selected', {order, ids});

const copySelected = (order, ids) =>
  axios.post('/orders/copy_selected', {order, ids});

const deleteSelected = (order, ids) =>
  axios.post('/orders/delete_selected', {order, ids});

const reorder = (order, lineIdFrom, lineIdTo) =>
  axios.post('/orders/reorder', {order, lineIdFrom, lineIdTo});

const copyLineToClient = (order, id, client) =>
  axios.post('/orders/copy_line_to_client', {order, id, client});

const copyLineBillToClient = (document, lineNo, client) =>
  axios.post('/orders/copy_line_bill_to_client', {document, lineNo, client});

const moveOrderToClient = (order, client) =>
  axios.post('/orders/move_order_to_client', {order, client});

const copyOrderToClient = (order, client) =>
  axios.post('/orders/copy_order_to_client', {order, client});

const copyBillToClient = (document, client) =>
  axios.post('/orders/copy_bill_to_client', {document, client});

const copyProposalToOrder = (proposal) =>
  axios.post('/orders/copy_proposal_to_order', {proposal});

const selectedAll = (order, selected) =>
  axios.post('/orders/selected_all', {order, selected});

const selectFromTo = (order, indexFrom, indexTo, selected) =>
  axios.post('/orders/select_from_to', {order, indexFrom, indexTo, selected});

const countOrders = ({ clientId, vendorId }) =>
    axios.post('/orders/count_orders', { clientId, vendorId });

export const ordersService = {
  findOrder,
  findOrderById,
  findOrders,
  addLines,
  updateOrder,
  processOrder,
  updateLine,
  duplicateLine,
  copyLine,
  deleteLine,
  updateSelected,
  duplicateSelected,
  copySelected,
  deleteSelected,
  reorder,
  copyLineToClient,
  moveOrderToClient,
  copyOrderToClient,
  selectedAll,
  selectFromTo,
  copyLineBillToClient,
  copyBillToClient,
  copyProposalToOrder,
  countOrders,
};
