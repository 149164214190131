<template>
  <f7-page :name="name" :page-content="false" :with-subnavbar="withSubnavbar" @page:beforein="loadPanels" @page:afterin="emitAfterInCb">
    <navbar :title="title" :transparent="transparent" :nav-large="navLarge" :back-button="backButton" :back-button-text="backButtonText">
      <template v-slot:left v-bind:leftIcon="leftIcon">
        <left-menu-button :icon="leftIcon"></left-menu-button>
        <slot name="left"></slot>
      </template>
      <template v-slot:center>
        <slot name="center"></slot>
      </template>
      <template v-slot:right v-bind:rightIcon="rightIcon" v-bind:hasRightPanel="hasRightPanel">
        <slot name="right">
          <div>
            <search-button v-if="vendorRole('VENDOR') && showSearchButton"></search-button>
          </div>
        </slot>
        <template v-if="hasRightPanel">
          <right-menu-button :icon="rightIcon"></right-menu-button>
        </template>
      </template>
      <template v-slot:subnavbar>
        <slot name="subnavbar"></slot>
      </template>
    </navbar>
    <f7-page-content v-if="pageContent" :class="{'page-with-navbar-large': navLarge }">
      <slot></slot>
    </f7-page-content>
    <template v-else>
      <slot></slot>
    </template>
  </f7-page>
</template>
<script>
import Navbar from './navbar/navbar.vue';
import LeftMenuButton from './navbar/components/left-menu-button.vue';
import RightMenuButton from './navbar/components/right-menu-button.vue';
import SearchButton from './navbar/components/search-button.vue';
import { f7 } from 'framework7-vue';
import { useStore } from 'vuex';

export default {
  name: 'page',
  props: {
    pageContent:      { type: Boolean , default: true },
    name:             { type: String  , default: '' },
    title:            { type: String  , default: '' },
    rightIcon:        { type: String  , default: 'las la-bars' },
    leftIcon:         { type: String  , default: 'las la-bars' },
    hasRightPanel:    { type: Boolean , default: false },
    navLarge:         { type: Boolean , default: true },
    transparent:      { type: Boolean , default: false },
    backButton:       { type: Boolean , default: true },
    withSubnavbar:    { type: Boolean , default: false },
    leftPanelRoute:   { type: String  , default: '/left-panel/' },
    rightPanelRoute:  { type: String  , default: '/right-panel/' },
    backButtonText:   { type: String  , default: 'buttons.back'},
    showSearchButton: { type: Boolean , default: false },
    keepLeftRoute:    { type: Boolean , default: false },
  },
  emits: ['page-after-in'],
  components: {
    'navbar': Navbar,
    'left-menu-button': LeftMenuButton,
    'right-menu-button': RightMenuButton,
    'search-button': SearchButton,
  },
  setup: (props, { context, emit }) => {
    const store = useStore();
    const vendorRole = (vendor) => store.getters['session/hasRole'](vendor);
    const loadPanels = () => {
      if (!props.keepLeftRoute) f7.views.leftPanel.router.navigate(props.leftPanelRoute);
      // console.log(props.rightPanelRoute);
      f7.views.rightPanel.router.navigate(props.rightPanelRoute);
    };
    const emitAfterInCb = async () => {
      // console.log('...');
      emit('page-after-in');
    };
    return { vendorRole, loadPanels, emitAfterInCb };
  },
};
</script>
