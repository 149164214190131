export const resizeImage = (image, size, original = false) => {
  if (image !== '') {
    if (original) {
      const extension = `.${image.split('.').pop()}`;
      const replace = image.replace(extension, '');
      return `https://tahecosmetics.com/image/${replace}${extension}`;
    } else {
      const extension = `.${image.split('.').pop()}`;
      const replace = image.replace(extension, '');
      return `https://tahecosmetics.com/image/cache/${replace}-${size}x${size}${extension}`;
    }
  } else {
    return '';
  }
};

export const resizeImageAndGetExtension = (image, size, original = false) => {
  if (image !== '') {
    if (original) {
      const extension = `.${image.split('.').pop()}`;
      const replace = image.replace(extension, '');
      return [`https://tahecosmetics.com/image/${replace}${extension}`, extension];
    } else {
      const extension = `.${image.split('.').pop()}`;
      const replace = image.replace(extension, '');
      return [`https://tahecosmetics.com/image/cache/${replace}-${size}x${size}${extension}`, extension];
    }
  } else {
    return ['', 'PNG'];
  }
};

export const getImage = (imageFirst, imageSecond, sizeFirst, sizeSecond, withExtension = false) => {
  if (withExtension) {
    if (imageFirst !== '') return resizeImageAndGetExtension(imageFirst, sizeFirst);
    return resizeImageAndGetExtension(imageSecond, sizeSecond);
  } else {
    if (imageFirst !== '') return resizeImage(imageFirst, sizeFirst);
    return resizeImage(imageSecond, sizeSecond);
  }
};
