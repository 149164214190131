<template>
  <f7-button outline color="primary" class="text-capitalize" @click="openSheet()">{{state.quantity > 0 ? `(${state.quantity}) ${$t('text.added')}` : $t('buttons.add') }}</f7-button>
</template>
<script>
  import { f7 } from 'framework7-vue';
  import { useActions, useGetters, useState } from '../../../js/stores/hooks';
  import { computed, reactive } from 'vue';
  import $$ from 'dom7';
  import { store } from '../../../js/stores/vuex';
  import { createNotification } from '../../../js/helpers/f7.helpers';
  import { ordersService } from '../../../js/api/http/orders.service';

  export default {
    props: {
      type: { type: String, default: 'catalog' }, // catalog, cart
      product: { type: Object, default: null },
      directo: { type: Boolean, default: false },
    },
    setup: (props) => {
      const { value } = useState('input', ['value']);
      const { setInput } = useActions('input', ['setInput']);
      const { editPendingCart } = useActions('shoppingCart', ['editPendingCart']);
      const { setLoading } = useActions('app', ['setLoading']);
      const { sessionInfo } = useState('session', ['sessionInfo']);
      const { getPendingOrder } = useActions('pendingOrder', ['getPendingOrder']);
      const state = reactive({
        adding: false,
        quantity: computed(() => store.getters['shoppingCart/getQuantity'](props.product.optionSku)),
      })
      const openSheet = () => {
        setInput({ value: Math.max(1, state.quantity), units: props.product.optionSku})
        $$('#nav-product-stepper-sheet').on('sheet:closed', async e => {
          let newVal = 0;
          try {
            newVal = eval(value.value);
          } catch (e) { newVal = 0; }
          newVal = !isNaN(newVal) ? parseInt(newVal) : 0;
          await changePendingCart(newVal);
          $$('#nav-product-stepper-sheet').off('sheet:closed');
        });
        f7.sheet.get('#nav-product-stepper-sheet').open();
      };
      const changePendingCart = async (quantity) => {
        if (props.directo) {
          setLoading(true);
          try {
            const { selectedClient, sellChannel, status, groupPrice, selectedCode } = sessionInfo.value;
            (await ordersService.addLines([
              {
                // line -> quantity discount optionSku sku unitPrice unitType unitsPer
                quantity: 1, discount: 0, optionSku: props.product.optionSku, sku: '', unitType: 'UNIT', unitsPer: 1,
              }
            ], selectedCode, selectedClient, sellChannel, status, groupPrice)).data;
            await getPendingOrder();
          } catch (error) {
            createNotification(error.message, error.statusCode);
          }
          setLoading(false);
        } else {
          // console.log(quantity);
          if (state.adding) return;
          state.adding = true;
          quantity = Math.max(0, quantity);
          // console.log(props.product);
          if (props.type === 'card') {
            await editPendingCart({ optionSku: props.product.optionSku, quantity });
          } else {
            await editPendingCart({
              optionSku: props.product.optionSku,
              sku: '',
              quantity: quantity,
              unitPrice: props.product.precio + props.product.promoPriceLines,
              product: {
                blocked: props.product.blocked,
                extraName: '',
                image: '',
                mainLine: '',
                name: props.product.description,
                optionImage: '',
                optionName: '',
              }
            });
          }
          state.adding = false;
        }
      };
      return { openSheet, state };
    }
  }
</script>
