import * as axiosGeneral from 'axios';
import { instance as axios } from '../../plugins/axios';

const cancelToken = axiosGeneral.CancelToken;
const productCall = cancelToken.source();
const pTranslationsCall = cancelToken.source();
const pSearchCall = cancelToken.source();

const productsByCategory = (categoryId, company, sellChannel, language, depth) =>
  axios.post('/products/by_category', {categoryId, company, sellChannel, language, depth}, { cancelToken: productCall.token });

const translations = (skus, optionSkus, language) =>
  axios.post('/products/get_translations', {skus, optionSkus, language}, { cancelToken: pTranslationsCall.token });

const search = (search, language, company, sellChannel) =>
  axios.post('/products/search', {search, language, company, sellChannel}, { cancelToken: pSearchCall.token });

const searchRefund = (search, language, company, sellChannel) =>
  axios.post('/products/search_refund', {search, language, company, sellChannel});

const findBySku = (sku, company, sellChannel, language) =>
  axios.post('/products/find_by_sku', {sku, language, company, sellChannel});

const favProducts = (skus, company, sellChannel, language, groupPrice) =>
  axios.post('/products/fav_products', {skus, language, company, sellChannel, groupPrice});

const productInfo = (sku, language, sellChannel) =>
  axios.post('/products/product_info', {sku, language, sellChannel});

const productsInfos = (skus, language) =>
  axios.post('/products/products_infos', {skus, language});

const whereIs = (optionSku) =>
  axios.post('/products/where_is', {optionSku});

const sendError = (optionSku, description, vendorCode) =>
  axios.post('/products/send_error', {optionSku, description, vendorCode});

const updateWebProduct = (productId) =>
  axios.post('/products/update_web_product', {productId});

export const productsService = {
  productsByCategory,
  translations,
  search,
  searchRefund,
  findBySku,
  favProducts,
  productInfo,
  productsInfos,
  whereIs,
  sendError,
  updateWebProduct,
};
