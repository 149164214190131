export const de = {
  contratoAparatologia: 'Lasergerätevertrag',
  "WARNING_CONTRAREEM": "Rückerstattungen über 1000€ sind nicht möglich",

  "alert": "Warnung",
  "abono_contrareembolso_no_permitido": "Du kannst keine Rückerstattung mit der Bestellung abgleichen, wenn die Zahlungsart nicht Giro oder Barzahlung ist.",
  "lunes": "Montag",
  "martes": "Dienstag",
  "miercoles": "Mittwoch",
  "jueves": "Donnerstag",
  "viernes": "Freitag",
  "sabado": "Samstag",
  "domingo": "Sonntag",
  "no_cierra": "Schließt nicht",

  "nuevo_partner_hcc": "Neuer HCC-Partner",
  "inscribir_como_partner_hcc": "Als HCC-Partner einschreiben",

  "otro": "andere",
  "telefono": "Telefon",
  "atencion_rgpd": "ACHTUNG, RGPD FEHLT NOCH",
  "atencion_rgpd_info": "Denke daran, dass der Kunde 3 Monate nach dem Datum des ersten Verkaufs für\nKunden nach dem [Datum nicht definiert] und für alte Kunden 3 Monate ab dem [Datum nicht definiert] gesperrt wird",

  "descatalogado_hasta_fin": "Aus dem Katalog genommen bis zum Abverkauf",

  "enviar_rgpd_a_email": "Die Datenzustimmung wird per E-Mail gesendet",
  "rgpd_necesita_validar_email": "Um das RGPD per E-Mail zu senden, muss der Kunde zuerst seine E-Mail validiert haben.",
  "clientes_sin_validar": "Nicht validierte Kunden",
  "rgpd_validado": "RGPD Validiert",
  "mail_validado": "E-Mail Validiert",
  "formato_factura": "Rechnungsformat",
  "sin_confirmar": "Unbestätigt",
  "factura_electronica": "Elektronische Rechnung",
  "factura_papel": "Papierrechnung",
  "enviar_rgpd": "RGPD senden",
  "enviar_rgpd_info": "RGPD an den Kunden senden",
  "confirmar_envio_rgpd_SMS": "Eine Nachricht wird gesendet, um das RGPD an die Telefonnummer zu validieren",
  "rgpd_verificacion_enviado_SMS": "Eine Nachricht wurde an die Telefonnummer gesendet",
  "confirmar_envio_rgpd_EMAIL": "Eine E-Mail wird gesendet, um das RGPD an die E-Mail-Adresse zu validieren",
  "rgpd_verificacion_enviado_EMAIL": "Eine E-Mail wurde an die E-Mail-Adresse gesendet",
  "no_cliente_seleccionado": "Es ist kein Kunde ausgewählt",
  "telefonos_disponibles": "Verfügbare Telefonnummern",
  "confirmar_envio_email": "Du wirst eine E-Mail-Validierung an die E-Mail-Adresse senden",
  "confirmacion": "Bestätigung",
  "productos_similares": "Ähnliche Produkte",
  "cambiar_email": "E-Mail ändern",

  "cif_vacio_oferta": "Da der Kunde keine CIF hat, musst du obligatorisch einen Kommentar eingeben. Wenn du ihn kennst, gib ihn in den Kommentaren ein und wenn nicht, schreibe etwas.",
  "revisado_y_sin_info": "Geprüft und ohne Info",
  "ps_nuevo": "NEU!",
  "ps_pend_servir_texto_card": "Klicke hier, um dein ausstehendes Servieren zu überprüfen",
  "real": "Echt",
  "aprox": "Ungefähr",
  "llegada": "Ankunft",
  "hace_x_minutos": "Vor {x} Minuten",
  "ver_por_producto": "Nach Produkt anzeigen",
  "ps_info": "Die Verfügbarkeitsdaten sind unverbindlich. Wenn du mehr Informationen zu Produkten benötigst, die nicht serviert werden und kein Verfügbarkeitsdatum haben, wende dich bitte an die Verwaltung.",
  "ps_productos_envasandose": "Produkte aus deinem ausstehenden Servieren werden verpackt",
  "ps_no_productos_envasandose": "Es gibt keine Produkte aus deinem ausstehenden Servieren, die verpackt werden",
  "productos_pendientes_servir": "Produkte zum Servieren ausstehend",
  "sirviendose": "Wird serviert",
  "enRevision": "In Überprüfung",
  "ps_clientes_envasando": "Die folgenden Kunden haben Produkte, die verpackt werden.",
  "disponible": "Verfügbar",

  "pedidos": "Bestellungen",
  "nuevo_email": "Neue E-Mail",
  "email_actual_es": "Die aktuelle E-Mail des Kunden ist {email}. Ist das korrekt?",
  "factura_a_email": "ACHTUNG: Der Kunde hat eine validierte E-Mail und seine elektronische Rechnung wird an {email} gesendet.",
  "datos_correctos": "Sind diese Daten korrekt?",
  "cliente_no_encontrado": "Nicht gefunden",
  "cliente_click_cambiar": "Es scheint, dass der ausgewählte Kunde verschwunden ist. Klicke hier, um einen anderen Kunden auszuwählen.",
  "email_validado": "Die E-Mail des Kunden ist validiert",
  "email_no_validado": "Die E-Mail des Kunden ist nicht validiert, er kann keine elektronischen Rechnungen empfangen.",
  "validar_email": "E-Mail validieren",
  "leyenda": "Legende",
  "oferta_pendiente": "Angebot ausstehend",
  "exito": "Erfolg",
  "contrasena_cambiada": "Das Passwort wurde erfolgreich geändert",
  "hace_x_horas": "Vor {x} Stunden",
  "hace_x_dias": "Vor {x} Tagen",
  "no_pendiente_servir": "Keine ausstehenden Servieren",
  "codigo_cliente": "Kundennummer",
  "nombre_cliente": "Kundenname",
  "ascendente": "Aufsteigend",
  "descendente": "Absteigend",
  "fecha": "Datum",
  "num_expedicion": "Expeditionsnummer",
  "num_documento": "Dokumentnummer",
  "no_expediciones_2_semanas": "Keine Expeditionen innerhalb von 2 Wochen",
  "no_facturas_anteriores": "Keine vorherigen Rechnungen gefunden",
  "no_abonos_anteriores": "Keine vorherigen Gutschriften gefunden",
  "num_facturas": "Rechnungsnummer",
  "num_vendedor": "Verkäufernummer",
  "fecha_factura": "Rechnungsdatum",
  "num_cliente": "Kundennummer",
  "forma_pago": "Zahlungsweise",
  "facturar_a": "Rechnung an",
  "fecha_pedido": "Bestelldatum",
  "direccion_envio": "Lieferadresse",
  "num_pedido": "Bestellnummer",
  "base": "Basis",
  "importe_iva": "MwSt.-Betrag",
  "total_iva_inc": "Gesamtbetrag inkl. MwSt.",
  "importe_base": "Basisbetrag",
  "porc_iva": "% MwSt.",
  "porc_re": "% RE",
  "importe_re": "RE-Betrag",
  "dto_factura_pagos": "Rechnungs- und Zahlungsrabatt",
  "no_facturas_mes": "Keine aktuellen Rechnungen in diesem Monat gefunden",
  "no_facturas_pendientes": "Keine ausstehenden Rechnungen von Kunden",
  "seleccionar_mes": "Monat auswählen",
  "seleccionar_ano": "Jahr auswählen",
  "pago": "Zahlung",
  "factura": "Rechnung",
  "impago": "Unbezahlt",
  "total_pendiente": "Gesamtbetrag ausstehend",
  "cobros_pendientes": "Ausstehende Zahlungen",
  "sin_explicar_comentario": "Nicht erklärt, bitte Kommentar senden",
  "importe_pendiente": "Ausstehender Betrag",
  "saldo_acumulado": "Kumuliertes Guthaben",
  "documentos_pendientes": "Ausstehende Dokumente",
  "tipo_doc": "Dokumenttyp",
  "importe_original": "Ursprünglicher Betrag",
  "num_contrato": "Vertragsnummer",
  "tipo_contrato": "Vertragstyp",
  "importe_contrareembolso": "Nachnahmebetrag",
  "anadir_comentario": "Kommentar hinzufügen",
  "cerrar": "Schließen",
  "introduce_comentario": "Kommentar eingeben",
  "aun_no_comentarios": "Noch keine Kommentare",
  "client_no_cuenta": "Der Kunde hat keine Kontonummer",
  "actualizar_email": "E-Mail aktualisieren",
  "atencion_factura_electronica": "ACHTUNG: Von nun an wird eine elektronische Rechnung gesendet. Du musst überprüfen, ob die E-Mail des Kunden korrekt ist, und falls nicht, die neue E-Mail senden.",
  "cliente_no_email_asociado": "Der Kunde hat keine zugeordnete E-Mail. Ist das korrekt?",
  "email_cliente_incorrecto": "Die E-Mail des Kunden ist NICHT KORREKT",
  "email_cliente_correcto": "Die E-Mail des Kunden ist korrekt",
  "introduce_nuevo_email": "Neue E-Mail eingeben",
  "email_no_valido": "Die E-Mail ist keine gültige E-Mail",
  "email_verificacion_enviado": "Eine Bestätigungsnachricht wurde an die angegebene E-Mail gesendet",
  "cancelar": "Abbrechen",
  "no_puedes_girar": "Du kannst diesem Kunden keine Lastschrift erteilen, da er kein Bankkonto zugeordnet hat. Bitte kontaktiere die Verwaltung, um ein Bankkonto hinzuzufügen und versuche es erneut. Denke daran, dass die Anwendung nachts aktualisiert wird.",
  "informacion": "Information",
  "eliminar_promocion": "Promotion entfernen",
  "que_quieres_hacer": "Was möchtest du tun?",
  "eliminar_todo": "Alles löschen",
  "eliminar_solo_cabecera": "Nur Kopfzeile löschen",
  "eliminar_cabecera_poner_precios": "Kopfzeile löschen und Basispreise setzen",
  "buscar_direccion": "Adresse suchen",
  "volver_hacer_pedido": "Bestellung erneut aufgeben",
  "no_giros_mostrar": "Keine Lastschriften anzuzeigen",
  "son_correctos_datos": "Sind diese Daten korrekt?",
  "puedes_cambiar_mes_ano": "Du kannst Monat und Jahr ändern",
  "obtener_facturas": "Rechnungen abrufen",
  "mes": "Monat",
  "ano": "Jahr",
  "desde_el": "Von",
  "hasta_el": "Bis",
  "total_ventas": "Gesamtverkäufe",
  "total_impagos": "Gesamtbetrag der unbezahlten Rechnungen",
  "total_cobros_impagos": "Gesamtbetrag der unbezahlten Zahlungen",
  "buscar_en_documentos": "In Dokumenten suchen",
  "ventas_abonos_anulaciones": "Verkäufe-Gutschriften-Stornierungen",
  "impagos": "Unbezahlte Rechnungen",
  "cobros_impagos": "Unbezahlte Zahlungen",

  "newClient": {
    "cifError": "Du musst das Feld NIF/CIF ausfüllen",
    "nameError": "Du musst das Feld Name ausfüllen",
    "addressError": "Du musst das Feld Adresse ausfüllen",
    "stateError": "Du musst das Feld Stadt ausfüllen",
    "cityError": "Du musst das Feld Provinz ausfüllen",
    "cpError": "Du musst das Feld Postleitzahl ausfüllen",
    "contactError": "Du musst das Feld Kontaktperson ausfüllen",
    "closeDaysError": "Du musst das Feld Schließtage ausfüllen",
    "emailError": "Du musst das Feld E-Mail ausfüllen",
    "phoneError": "Du musst das Feld Telefon oder Handy ausfüllen",
    "infoFill": "Du wirst die Daten für eine Bestellung für einen neuen Kunden eingeben, jetzt musst du die Daten ausfüllen. Klicke auf die folgende Schaltfläche, um die Daten auszufüllen.",
    "fillData": "Kundendaten ausfüllen"
  },

  "errores": "Fehler",
  "aceptar": "Akzeptieren",
  "enviar": "Senden",
  "actualizarPagina": "Seite aktualisieren",
  "topClientes": "Top Kunden",
  "actualizacion": "Aktualisierung",
  "vamosAActualizar": "Wir werden aktualisieren",
  "relevancia": "Relevanz",
  "masVisitados": "Meistbesucht",
  "novedades": "Neuheiten",
  "TAHE_FRANCIA": "Tahe EU",
  "TAHE_ALEMANIA": "Tahe EU",
  "informacionYMetodos": "Information und Zahlungsmethoden",
  "resumenPedido": "Bestellübersicht",
  "cliente": "Kunde",
  "fechaDeOferta": "Angebotsdatum",
  "empresaYCanal": "Unternehmen und Verkaufskanal",
  "direccionEnvio": "Lieferadresse",

  "proximosCursos": "Nächste Kurse",
  "seleccionCursos": "Auswahl der Kurse",
  "Relevancia": "Relevanz",
  "Más visitados": "Meistbesucht",
  "Novedades": "Neuheiten",
  "reporteDescendente": "Bericht (Absteigend)",
  "reporteAscendente": "Bericht (Aufsteigend)",
  "fechaDescendente": "Datum (Absteigend)",
  "fechaAscendente": "Datum (Aufsteigend)",
  "codigoClienteDescendente": "Kundennummer (Absteigend)",
  "codigoClienteAscendente": "Kundennummer (Aufsteigend)",
  "importeAscendente": "Betrag (Aufsteigend)",
  "importeDescendente": "Betrag (Absteigend)",
  "noDocumentoAscendente": "Dokumentnummer (Aufsteigend)",
  "noDocumentoDescendente": "Dokumentnummer (Absteigend)",
  "totalEquipos": "Gesamte Teams",
  "estadoPedidos": "Bestellstatus",
  "total": "Gesamt",
  "pendientes": "Ausstehend",
  "porAprobar": "Zu genehmigen",
  "pendientesAprobar": "Ausstehende Genehmigungen",
  "enviadas": "Gesendet",
  "impTotal": "Gesamtbetrag",
  "impPendiente": "Ausstehender Betrag",
  "impAprob": "Zu genehmigender Betrag",
  "impEnv": "Gesendeter Betrag",
  "equipo": "Team",
  "jefeEquipo": "Teamleiter",
  "distribuidor": "Vertriebspartner",
  "ofertaPendiente": "Angebot ausstehend",
  "pendienteAprobar": "Ausstehende Genehmigung",
  "pendienteAprobarEquipos": "Ausstehende Genehmigung in den Teams",
  "editarCliente": "Kunde bearbeiten",
  "pages": {
    "productosPendientes": "Ausstehende Produkte",
    "cursos": "Kurse",
    "updatePage": "Seite aktualisieren",
    "home": "Hauptmenü",
    "dashboard": "Dashboard",
    "distributors": "Vertriebspartner",
    "catalog": "Katalog",
    "reports": "Tagesberichte",
    "statistics": "Statistiken",
    "addPreClient": "Pre-Kunde hinzufügen",
    "addDistributorClient": "Kunde hinzufügen",
    "approvalOrders": "Bestellungen genehmigen",
    "approvalRefunds": "Gutschriften genehmigen",
    "approvalCharges": "Zahlungen genehmigen",
    "pendingOrder": "Ausstehende Bestellung",
    "previousOrders": "Frühere Bestellungen",
    "pendingCharge": "Ausstehende Zahlung",
    "previousCharges": "Frühere Zahlungen",
    "pendingRefund": "Ausstehende Gutschrift",
    "previousRefunds": "Frühere Gutschriften",
    "clientManagement": "Kundenmanagement",
    "vendorManagement": "Verkäuferinformationen",
    "extracts": "Verkäuferbewegungen",
    "bills": "Rechnungen",
    "billsRefunds": "Gutgeschriebene Rechnungen",
    "billsInvoices": "Rechnungen",
    "soldProducts": "Verkaufte Produkte",
    "expeditions": "Expeditionen",
    "expenses": "Ausgaben",
    "incomes": "Einnahmen",
    "pendingToShip": "Zum Versand ausstehend",
    "clientsContracts": "Verträge",
    "refundsStatus": "Gutschriftenstatus",
    "commissions": "Provisionen",
    "settings": "Einstellungen",
    "changelog": "Änderungsprotokoll",
    "faqStores": "Hilfe für Friseurgeschäfte",
    "faq": "Hilfe",
    "notFound": "Nicht gefunden",
    "vendorSelection": "Verkäuferauswahl",
    "clientSelection": "Kundenauswahl",
    "sellChannelSelection": "Verkaufskanal",
    "clientPendingOrder": "Ausstehende Bestellung",
    "clientPreviousOrders": "Frühere Bestellungen",
    "clientPendingCharge": "Ausstehende Zahlung",
    "clientPreviousCharges": "Frühere Zahlungen",
    "clientPendingRefund": "Ausstehende Gutschrift",
    "clientPreviousRefunds": "Frühere Gutschriften",
    "clientPendingToShip": "Zum Versand ausstehend",
    "clientExpeditions": "Expeditionen",
    "clientGiros": "Lastschriften",
    "clientPendingBills": "Ausstehende Rechnungen",
    "clientObjectives": "Ziele",
    "clientContracts": "Verträge",
    "clientExtracts": "Bewegungen",
    "clientBills": "Rechnungen",
    "clientBillsRefunds": "Gutgeschriebene Rechnungen",
    "productDetails": "Produktdetails",
    "notFoundClient": "Kunde nicht gefunden",
    "refundStatusDetails": "Gutschriftenstatus",
    "searchRefundProduct": "Produkt zur Gutschrift suchen",
    "orderDetails": "Bestelldetails",
    "productBills": "Produktrechnungen",
    "checkoutCharge": "Zahlung abwickeln",
    "newClientDetails": "Details des neuen Kunden",
    "newIncome": "Neue Einnahme",
    "newExpense": "Neue Ausgabe",
    "checkoutOrder": "Bestellung abwickeln",
    "newDistributorClients": "Neuer Kunde",
    "employees": "Mitarbeiter",
    "users": "Benutzer",
    "topClientes": "Top Kunden"
  },
  "menus": {
    "menu": "Menü",
    "mainMenu": "Hauptmenü",
    "favoritesMenu": "Favoriten",
    "clientManagementMenu": "Kundenmanagement"
  },
  "buttons": {
    "add": "Hinzufügen",
    "changePassword": "Passwort ändern",
    "copyToOtherClient": "Zu einem anderen Kunden kopieren",
    "moveToOtherClient": "Zu einem anderen Kunden verschieben",
    "duplicate": "Duplizieren",
    "copy": "Kopieren",
    "checkInOcean": "In Ocean einchecken",
    "addBillManual": "Rechnung manuell hinzufügen",
    "addRemaining": "Restbetrag hinzufügen",
    "addChargeDate": "Zahlungsdatum hinzufügen",
    "save": "Speichern",
    "ok": "OK",
    "update": "Aktualisieren",
    "search": "Suchen",
    "close": "Schließen",
    "logout": "Abmelden",
    "searchClient": "Kunden suchen",
    "searchProductInBills": "In Rechnungen suchen",
    "back": "Zurück",
    "cancel": "Abbrechen",
    "login": "Anmelden",
    "clean": "Reinigen",
    "confirm": "Bestätigen",
    "findClient": "Kunde finden",
    "updateEmail": "E-Mail aktualisieren",
    "addToOrder": "Zur Bestellung hinzufügen",
    "delete": "Löschen",
    "addIncome": "Einnahme hinzufügen",
    "addExpense": "Ausgabe hinzufügen",
    "checkoutOrder": "Bestellung abwickeln",
    "selectAll": "Alle auswählen",
    "activateSort": "Sortierung aktivieren",
    "deactivateSort": "Sortierung deaktivieren",
    "addCharge": "Zahlung hinzufügen",
    "createAdvice": "Ratschlag erstellen",
    "addPendingBill": "Ausstehende Rechnung hinzufügen",
    "accept": "Akzeptieren",
    "done": "Erledigt",
    "getObjective": "Ziel erhalten",
    "getSoldProducts": "Verkaufte Produkte erhalten",
    "updateWebProduct": "Produkt aktualisieren",
    "updateWebCategory": "Kategorie aktualisieren",
    "addProduct": "Produkt hinzufügen",
    "searchInBills": "In Rechnungen suchen",
    "sendRefund": "Gutschrift senden",
    "showAccount": "Kontonummer anzeigen",
    "addObjective": "Ziel hinzufügen",
    "addPreclient": "Pre-Kunde hinzufügen",
    "checkoutCharge": "Zahlung abwickeln",
    "deleteSelected": "Ausgewählte löschen",
    "send": "Senden",
    "refund": "Gutschrift",
    "refundAll": "Alles gutschreiben",
    "reshopAll": "Alles erneut kaufen",
    "reshop": "Erneut kaufen",
    "newUser": "Neuer Benutzer"
  },
  "popups": {
    "globalSearch": "Suchfunktion"
  },
  "inputs": {
    "password": { "label": "Passwort", "placeholder": "Dein aktuelles Passwort" },
    "newPassword": { "label": "Neues Passwort", "placeholder": "Das neue Passwort" },
    "newPasswordConfirmation": { "label": "Neues Passwort wiederholen", "placeholder": "Das neue Passwort wiederholen" },
    "searchClient": { "label": "Kunde", "placeholder": "Kunden nach Name, Kunden-ID oder CIF suchen" },
    "searchProductInBills": { "label": "Produkt", "placeholder": "Produkt nach Code oder Name suchen" },
    "searchMainMenu": { "label": "Suchen", "placeholder": "Was möchtest du tun?" },
    "searchProduct": { "label": "Produkt suchen", "placeholder": "Produkt nach Code, Name suchen..." },
    "searchFaq": { "label": "In Hilfe suchen", "placeholder": "In der Hilfe suchen" },
    "searchOption": { "label": "Option suchen", "placeholder": "Optionen nach Name oder Code filtern" },
    "searchVendorCode": { "label": "Verkäufercode suchen", "placeholder": "Verkäufer nach Code, Name suchen..." },
    "searchReports": { "label": "Bericht suchen", "placeholder": "Bericht nach Name oder Kunden-Code suchen..." },
    "motive": { "label": "Grund", "placeholder": "Gib eine kurze Beschreibung ein" },
    "advice": { "label": "Betrag", "placeholder": "Gib den Betrag des Vorschusses ein" },
    "comment": { "label": "Kommentar", "placeholder": "Schreibe hier deinen Kommentar, falls du zusätzliche Informationen angeben musst" }
  },
  "login": {
    "title": "Cosmet",
    "username": { "label": "Benutzer", "placeholder": "Dein Benutzername" },
    "password": { "label": "Passwort", "placeholder": "Dein Passwort" },
    "information": {
      "line1": "Melde dich mit deinem Benutzernamen und Passwort an, um auf Cosmet zuzugreifen<br><strong>(Ehemals OferTAHE und OferTH)</strong>",
      "line2": "Cosmet &copy; {year} {version}"
    }
  },
  "info": {
    "selectAddressFill": "Wähle eine Lieferadresse",
    "cardProduct": "Die Produkte im Katalog werden im Kartenstil angezeigt",
    "listProduct": "Die Produkte im Katalog werden im Listenstil angezeigt",
    "contactWithRRHH": "Wenn du Fragen oder Probleme damit hast, wende dich bitte an die Personalabteilung und NICHT an den Admin der Anwendung",
    "noBillsFound": "Keine Rechnungen gefunden",
    "searchRefundProducts": "Suche zuerst das Produkt, das du gutschreiben möchtest",
    "addChargeDates": "Wähle den zu zahlenden Betrag und das gewünschte Datum und klicke auf Zahlung hinzufügen",
    "selectPaymentFormFill": "Du musst eine Zahlungsart wählen",
    "selectPaymentTermFill": "Du musst einen Zahlungstermin wählen",
    "noFavoritesMenu": "Du hast noch keine Links zu den Favoriten hinzugefügt. Versuche, auf einen der Sterne <icon class=\"icon f7-icons\" style=\"font-size: 20px\">star</icon> in den Menülinks zu klicken.",
    "searchResults": "Suchergebnisse",
    "isSelectedCode": "Du hast diesen Code und diesen Verkaufskanal ausgewählt",
    "newClientUse": "Nutze diesen Kunden, um eine Bestellung für einen neuen Kunden oder einen Pre-Kunden einzugeben",
    "noClientsFound": "Ops! Keine Kunden mit dieser Suche gefunden",
    "noCodesFound": "Keine Codes mit deiner Suche gefunden",
    "clientConfirm": "Bist du sicher, dass du zu diesem Kunden wechseln möchtest?",
    "clientConfirmNew": "Möchtest du zum NEUEN KUNDEN wechseln (Code: 1)?",
    "notFoundClient": "Wenn der Kunde heute hinzugefügt wurde, ist es normal, dass er nicht angezeigt wird. Aber keine Sorge! Wenn du den Kunden-Code kennst, kannst du ihn selbst von hier aus hinzufügen, auch wenn seine Informationen wie Vertragskommentare, zusätzliche Adressen usw. erst morgen angezeigt werden. Wenn du dennoch eine Bestellung, Gutschrift oder Zahlung vornehmen musst, versuche, ihn jetzt selbst hinzuzufügen, um nicht bis morgen zu warten.",
    "noPromoProducts": "Er hat keine feste Materialliste",
    "orderLines": "Bestellzeilen",
    "sampleLines": "Bestellmuster",
    "incidentLines": "Produkte mit Bestellvorfällen",
    "noOrderLines": "Du hast noch keine Zeilen zur Bestellung hinzugefügt",
    "noRefundLines": "Du hast noch keine Zeilen zur Gutschrift hinzugefügt",
    "noSampleLines": "Du hast noch keine Muster zur Bestellung hinzugefügt",
    "noIncidentLines": "Es gibt noch keine Vorfallzeilen in der Bestellung",
    "chargeFor": "Zahlung für",
    "noPendingCharge": "Du hast noch keine Zeile zur Zahlung hinzugefügt. Klicke auf die Schaltfläche, um die ausstehenden Zahlungen des Kunden zu sehen.",
    "refundFor": "Gutschrift für",
    "noPendingRefund": "Du hast noch keine Zeile zur Gutschrift hinzugefügt. Klicke auf die Schaltfläche, um ein Produkt zu suchen.",
    "noPendingBills": "Der Kunde hat keine ausstehenden Rechnungen",
    "noPreviousOrders": "Keine früheren Angebote gefunden",
    "noPendingOrders": "Keine ausstehenden Angebote gefunden",
    "noPreviousRefunds": "Keine früheren Gutschriften gefunden",
    "noPendingRefunds": "Keine ausstehenden Gutschriften gefunden",
    "noPreviousCharges": "Keine früheren Zahlungen gefunden",
    "noPendingCharges": "Keine ausstehenden Zahlungen gefunden",
    "selectVendorCode": "Wähle einen Verkäufercode",
    "productsSoldInfo": "Die Berechnung der verkauften Produkte erfolgt durch die fakturierten Produkte abzüglich der gutgeschriebenen Produkte",
    "noSoldProductsFound": "Keine verkauften Produkte für diesen Kunden oder diesen Datumsbereich gefunden",
    "noApprovalOrders": "Keine ausstehenden Bestellungen zur Genehmigung",
    "noApprovalRefunds": "Keine ausstehenden Gutschriften zur Genehmigung",
    "noApprovalCharges": "Keine ausstehenden Zahlungen zur Genehmigung",
    "noExpeditions": "Keine Expeditionen in den letzten 2 Wochen",
    "refundLineManual": "Dieses Produkt ist manuell mit der Rechnung verbunden, du musst angeben warum",
    "refundLineQuantityExceeded": "Du hast eine größere Menge als auf der Rechnung eingegeben, du musst angeben warum",
    "refundLineNoAbonable": "Das Produkt hat die zulässige Zeit für eine Gutschrift überschritten, du musst angeben warum",
    "isRefundLinesFilled": "Hast du alle Gründe für die Gutschrift JEDES Produkts angegeben?",
    "noRefundForNewClient": "Du hast keinen bestehenden Kunden ausgewählt, wähle einen Kunden aus, um eine Gutschrift zu bearbeiten",
    "noChargeForNewClient": "Du hast keinen bestehenden Kunden ausgewählt, wähle einen Kunden aus, um eine Zahlung zu bearbeiten",
    "noAccountFound": "Keine Kontonummer des Kunden gefunden",
    "noComments": "Der Kunde hat keine Kommentare",
    "noPendingToShip": "Keine ausstehenden Lieferungen",
    "noGiros": "Keine Lastschriften zum Anzeigen",
    "noReports": "Keine Berichte gefunden",
    "fillFieldsAndSave": "Fülle die unteren Felder aus und klicke auf Speichern",
    "passwordSuccessfullChanged": "Das Passwort wurde erfolgreich geändert",
    "revisitTour": "Deaktiviere diese Option, wenn du die Tour erneut sehen möchtest.",
    "hasKeyboard": "Wenn du nicht mit einer Tastatur arbeitest, deaktiviere diese Option, um einen Touch-Rechner zu haben.",
    "noIncomes": "Keine früheren Einnahmen gefunden, klicke auf die obere Schaltfläche, um eine neue Einnahme hinzuzufügen",
    "noExpenses": "Keine früheren Ausgaben gefunden, klicke auf die obere Schaltfläche, um eine neue Ausgabe hinzuzufügen",
    "noPreviousBills": "Keine früheren Rechnungen gefunden",
    "noProductsFound": "Keine Produkte mit der Suche <em>{0}</em> gefunden",
    "pleaseFixErrors": "Behebe die folgenden Fehler"
  },
  "text": {
    "underConstruction": "Im Bau",
    "suggestions": "Vielleicht meinten Sie",
    "lastUpdate": "Letzte Version",
    "added": "hinzugefügt",
    "addressSelection": "Adresse auswählen",
    "send": "Senden",
    "type": "Typ",
    "importWithImp": "Betrag (inkl. MwSt.)",
    "changePassword": "Passwort ändern",
    "accesibility": "Barrierefreiheit",
    "hasKeyboard": "Arbeiten mit Tastatur",
    "tour": "Tour durchgeführt",
    "languages": "Sprachen",
    "catalog": "Katalog",
    "card": "Karte",
    "list": "Liste",
    "bonifyItems": "Bonifizierte Artikel",
    "promoCount": "Anzahl Promos",
    "editSelected": "Auswahl bearbeiten",
    "copySelected": "Auswahl kopieren",
    "duplicateSelected": "Auswahl duplizieren",
    "deleteSelected": "Auswahl löschen",
    "seeBills": "Rechnungen anzeigen",
    "dates": "Daten",
    "remainingIVAInc": "Restbetrag (inkl. MwSt.)",
    "companyAndSellChannel": "Unternehmen und Verkaufskanal",
    "numCheck": "Scheck-Nr.",
    "paymentDates": "Zahlungstermine",
    "addChargeDates": "Zahlungsdatum hinzufügen",
    "paymentFormSelection": "Zahlungsart",
    "paymentTermSelection": "Zahlungsfrist",
    "orderDate": "Bestelldatum",
    "abonable": "Gutschreibbar",
    "sellChannel": "Verkaufskanal",
    "news": "Neuigkeiten",
    "info": "Information",
    "infoShort": "Info",
    "importantInfo": "Wichtige Information",
    "new": "Neu",
    "soldOut": "Ausverkauft",
    "products": "Produkte",
    "navigation": "Navigation",
    "favorites": "Favoriten",
    "lines": "Zeilen",
    "categories": "Kategorien",
    "first": "Erste",
    "last": "Letzte",
    "seeOptions": "Optionen anzeigen",
    "price": "Preis",
    "of": "von",
    "user": "Benutzer",
    "codes": "Codes",
    "newClient": "NEUER KUNDE",
    "cif": "CIF",
    "confirm": "Bestätigen",
    "notFoundClient": "Nicht gefunden",
    "comments": "Kommentare",
    "cod": "Code",
    "orderBy": "Sortieren nach",
    "code": "Code",
    "description": "Beschreibung",
    "quantity": "Menge",
    "unitsPer": "Einheiten pro",
    "total": "Gesamt",
    "totalQuantities": "Einheiten",
    "discount": "Rabatt",
    "actions": "Aktionen",
    "addWithDiscount": "Mit Rabatt hinzufügen",
    "productItems": "Artikel",
    "import": "Betrag",
    "orders": "Bestellungen",
    "refunds": "Gutschriften",
    "charges": "Zahlungen",
    "expenses": "Ausgaben",
    "newClients": "Neue Kunden",
    "income": "Einnahmen",
    "yes": "Ja",
    "no": "Nein",
    "numb": "Nr.",
    "date": "Datum",
    "reportId": "Berichts-Nr.",
    "addProduct": "Produkt hinzufügen",
    "addByCode": "Nach Code hinzufügen",
    "searchProduct": "Produkt suchen",
    "order": "Bestellung",
    "samples": "Muster",
    "incidents": "Vorfälle",
    "ivaInc": "+ (inkl. MwSt.)",
    "freeItems": "Gratisartikel",
    "numPromos": "Anzahl Promos",
    "selec": "Auswahl",
    "impSelect": "Betrag Auswahl",
    "pendingBills": "Ausstehende Rechnungen",
    "company": "Unternehmen",
    "postingDate": "Buchungsdatum",
    "dueDate": "Fälligkeitsdatum",
    "documentNo": "Dokument-Nr.",
    "paymentForm": "Zahlungsart",
    "paymentMethod": "Zahlungsmethode",
    "paymentTerm": "Zahlungsfrist",
    "amount": "Betrag",
    "remainingAmount": "Restbetrag",
    "totalContrareembolso": "Nachnahmebetrag",
    "unfinished": "Unvollständig",
    "paymentDate": "Zahlungsdatum",
    "status": "Status",
    "statuses": "Stati",
    "client": "Kunde",
    "clients": "Kunden",
    "from": "Von",
    "to": "Bis",
    "fromDate": "Datum von",
    "toDate": "Datum bis",
    "item": "Artikel",
    "items": "Artikel",
    "update": "Aktualisieren",
    "statistics": "Statistiken",
    "pendingOrders": "Ausstehende Angebote",
    "previousOrders": "Frühere Angebote",
    "pendingRefunds": "Ausstehende Gutschriften",
    "previousRefunds": "Frühere Gutschriften",
    "pendingCharges": "Ausstehende Zahlungen",
    "previousCharges": "Frühere Zahlungen",
    "vendorCodeSelection": "Verkäufer-Code",
    "selectForStatistics": "Du kannst Monat und Jahr ändern",
    "month": "Monat",
    "year": "Jahr",
    "objective": "Ziel",
    "billed": "Fakturiert",
    "ideal": "Ideal bis heute",
    "cumplimentObj": "des Ziels erreicht",
    "numPacks": "Pakete",
    "appliesToDoc": "Bezahlt durch",
    "dateRange": "Datumsbereich",
    "selectDate": "Datum auswählen",
    "pendingItems": "Ausstehende Einheiten",
    "units": "Einheiten",
    "reviser": "Prüfer",
    "quant": "Menge",
    "unitPrice": "Einzelpreis",
    "totalImport": "Gesamtbetrag",
    "totalPromo": "Gesamt Promo",
    "totalIVA": "+ (inkl. MwSt.)",
    "free": "Gratis",
    "sample": "Muster",
    "incident": "Vorfall",
    "bill": "Rechnung",
    "bills": "Rechnungen",
    "motive": "Grund",
    "information": "Information",
    "select": "Ausgewählt",
    "comment": "Kommentar",
    "account": "Kontonummer",
    "warnings": "Warnungen",
    "disc": "Rabatt %",
    "discImp": "Rabattbetrag",
    "promo": "Promo",
    "lastBills": "Letzte Rechnungen",
    "lateBills": "Überfällige Salden",
    "report": "Bericht",
    "vendor": "Verkäufer",
    "share": "Teilen über",
    "shareTelegram": "Telegram",
    "shareWhatsapp": "Whatsapp",
    "shareEmail": "Per E-Mail senden",
    "download": "Herunterladen",
    "searchResult": "Ergebnisse für",
    "totalPending": "Gesamt ausstehend",
    "createAdvice": "Vorschuss erstellen",
    "advance": "Vorschuss",
    "moveNo": "Bewegung",
    "toLiquid": "Zu liquidieren",
    "pendingImport": "Ausstehender Betrag",
    "selectedImport": "Ausgewählter Betrag"
  },
  "fnText": {
    "showResults": "Zeige ({0} - {1}) von {2} Ergebnissen",
    "total": "Gesamt {0}€",
    "totalCash": "Bar {0}€"
  },
  "filters": {
    "lines": "Zeilen"
  },
  "sellChannel": {
    "TAHE_ESPANA": "Tahe Spanien",
    "TAHE_DISTRIBUTOR": "Tahe Distributor",
    "TAHE_DISTRIBUIDOR": "Tahe Distributor",
    "TAHE_CANARIAS": "Tahe Kanarische Inseln",
    "TAHE_PORTUGAL": "Tahe Portugal",
    "TAHE_ITALIA": "Tahe Italien",
    "TAHE_INTERNACIONAL": "Tahe International",
    "TAHE_OUTLET": "Tahe Outlet",
    "TAHE_FRANCIA": "Tahe EU",
    "TAHE_ALEMANIA": "Tahe EU",
    "TH_ESPANA": "Th Pharma Spanien",
    "TH_PORTUGAL": "Th Pharma Portugal",
    "TH_ITALIA": "Th Pharma Italien",
    "TH_INTERNATIONAL": "Th Pharma International",
    "TH_INTERNACIONAL": "Th Pharma International",
    "TH_OUTLET": "Th Pharma Outlet",
    "MER_ESPANA": "Mercacosmética Spanien",
    "DVF_ESPANA": "DV Farmaceutic Spanien",
    "KELAYA_ESPANA": "KELAYA Spanien",
    "KELAYA_CANARIAS": "KELAYA Kanarische Inseln",
    "KELAYA_PORTUGAL": "KELAYA Portugal",
    "KELAYA_FRANCIA": "KELAYA EU",
    "BOCARE_ESPANA": "BOCARE Spanien",
    "BOCARE_CANARIAS": "BOCARE Kanarische Inseln",
    "BOCARE_PORTUGAL": "BOCARE Portugal",
    "BOCARE_FRANCIA": "BOCARE EU"
  },
  "status": {
    "PENDING": "Ausstehend",
    "PENDING_TO_SEND": "Versand ausstehend",
    "PENDING_APPROVAL": "Genehmigung ausstehend",
    "PENDING_APPROVAL_DIST": "Genehmigung Vertrieb ausstehend",
    "SENDED": "Gesendet",
    "IMPORT_ERROR": "Importfehler",
    "RECEIVED": "Empfangen",
    "ACCEPTED": "Akzeptiert",
    "CANCELED": "Storniert",
    "UNKNOWN": "Unbekannt",
    "OMNI_RECEIVED": "Gesendet",
    "ERROR_IMPORTACION": "Empfang ausstehend"
  },
  "company": {
    "TAHE": "Tahe",
    "TH_PHARMA": "Th Pharma",
    "TAHE_2016": "Tahe",
    "TH_PHARMA_2016": "Th Pharma",
    "MERCACOSMETICA": "Mercacosmética",
    "DV_FARMACEUTIC": "DV Farmaceutic",
    "NEO-DEPIL LASER": "Neo Depil Laser",
    "MAGAÑA": "Tahe Productos Cosméticos S.L.",
    "Tahe Productos Cosméticos S_L_": "Tahe Productos Cosméticos S.L.",
    "TH Pharma S_L_": "Th Pharma",
    "KELAYA_2016": "KELAYA",
    "BOCARE_2016": "BOCARÉ"
  },
  "packing": {
    "BOX": "Kiste",
    "PACK": "Palette",
    "CAJA": "Kiste",
    "PALET": "Palette"
  },
  "paymentForm": {
    "CONTADO": "Bar",
    "GIRO": "Wechsel",
    "CONTRAREEM": "Nachnahme",
    "GIROPT": "Wechsel",
    "CONTRARPOR": "Nachnahme",
    "CHEQUEPT": "Scheck",
    "TARJETAPT": "Kundenkarte",
    "CONTADOPT": "Scheckvertrag (Bar)",
    "TARJ OUTLE": "Outlet-Karte",
    "TRANSFPORT": "Überweisung",
    "TRANSFERIT": "Überweisung",
    "EFECTIVO": "Bargeld",
    "TARJETA": "Karte",
    "GIROCOBRO": "Wechsel",
    "CHEQUE": "Scheck",
    "DESCUENTO": "Rabatt"
  },
  "paymentTerm": {
    "PERSONALIZADO": "Angepasst",
    "1X7": "Wechsel 7 Tage",
    "1X15": "Wechsel 15 Tage",
    "1X20": "Wechsel 20 Tage",
    "1X21": "Wechsel 21 Tage",
    "1X30": "Wechsel 30 Tage",
    "1X40": "Wechsel 40 Tage",
    "1X45": "Wechsel 45 Tage",
    "1X60": "Wechsel 60 Tage",
    "1X75": "Wechsel 75 Tage",
    "1X90": "Wechsel 90 Tage",
    "2X7": "Wechsel 7 und 30 Tage",
    "2X15": "Wechsel 15 und 30 Tage",
    "2X20": "Wechsel 20 und 40 Tage",
    "2X30": "Wechsel 30 und 60 Tage",
    "2X45": "Wechsel 45 und 90 Tage",
    "2X45B": "Wechsel 45 und 60 Tage",
    "2X60": "Wechsel 60 und 90 Tage",
    "2X60X120": "Wechsel 60 und 120 Tage",
    "2X90": "Wechsel 90 und 120 Tage",
    "3X10": "Wechsel 10, 20 und 30 Tage",
    "3X15": "Wechsel 15, 30 und 45 Tage",
    "3X3": "Wechsel 45, 75 und 105 Tage",
    "3X30": "Wechsel 30, 60 und 90 Tage",
    "3X60": "Wechsel 60, 90 und 120 Tage",
    "4X30": "Wechsel 30, 60, 90 und 120 Tage",
    "4X3": "Wechsel 15, 60 und 90 Tage",
    "4X4": "Wechsel 15, 30, 60 und 90 Tage",
    "1X120": "Wechsel 120 Tage"
  },
  "desconTime": {
    "0": "Schau nicht auf den Bildschirm, jetzt ist nicht die Zeit dafür, sondern deine. Entspann dich!",
    "1": "Versteckte Sonne, wohlverdiente Pause.",
    "2": "Abschalten und Ausruhen ist notwendig, um ein gesundes Leben zu führen.",
    "3": "Zeit für: Kunden ... Reisen ... Bestellungen ... Abschalten! <icon class=\"icon f7-icons text-color-green\" style=\"font-size: 16px\">checkmark_alt</icon>",
    "4": "Jetzt ist Familienzeit: Genieße und nutze sie!",
    "5": "Jetzt beginnt dein Familienabend! Genieße ihn!",
    "6": "Um 100% Leistung während deiner Arbeitszeit zu erbringen, brauchst du eine 100%ige Abschaltung.",
    "7": "Um produktiv zu sein, muss man sich ausruhen! Einen schönen Ruhetag!",
    "8": "Nach der Arbeit hilft dir Zeit mit deiner Familie, ein gesundes Leben und gute Lektüre, dich besser zu fühlen.",
    "9": "Halt! Diese Zeit ist für dich, widme sie dir!"
  },
  "client": {
    "code": "Bericht",
    "mainAddress": "Hauptadresse",
    "navisionId": "Code",
    "cif": "CIF",
    "socialReason": "Firmenname",
    "name": "Name",
    "name2": "Name 2",
    "closeDays": "Schließtage",
    "email": "E-Mail",
    "phone": "Telefon",
    "mobile": "Mobil",
    "fax": "Fax",
    "address": "Adresse",
    "address2": "Adresse 2",
    "city": "Stadt",
    "state": "Provinz",
    "postalCode": "Postleitzahl",
    "country": "Land",
    "clientComments": "Kundenkommentare",
    "contractComments": "Vertragskommentare",
    "info": "Kundeninformationen",
    "addresses": "Adressen",
    "contact": "Kontakt",
    "typeClient": "Kundentyp",
    "newClient": "Neuer Kunde",
    "surnameName": "Nachname, Vorname",
    "bankAccount": "Bankkonto"
  },
  "typeClient": {
    "Peluquería": "Friseur",
    "Estética": "Kosmetik",
    "Estética+Peluquería": "Kosmetik+Friseur",
    "Barbería": "Barbier",
    "Otros": "Andere"
  },
  "country": {
    "ES": "Spanien",
    "PT": "Portugal",
    "IT": "Italien",
    "FR": "Frankreich",
    "BE": "Belgien",
    "DE": "Deutschland"
  },
  "errors": {
    "networkError": "Es konnte keine Verbindung zum Server hergestellt werden. Bitte kontaktiere den Administrator der Anwendung.",
    "notVendorInTeam": "Dieser Verkäufercode ist nicht in deinen Teams",
    "dateExistError": "Das Datum {0} wurde bereits zu den Fälligkeitsdaten hinzugefügt",
    "navisionError": "Es ist ein Fehler bei der Verbindung mit Navision aufgetreten. Möglicherweise besteht keine Verbindung. Versuche es später erneut."
  },
  "serverErrors": {
    "NAV_INACCESSIBLE": "Cosmet kann nicht auf den Navision-Server zugreifen",
    "NAV_UNKNOWN_ERROR": "Es ist ein unbekannter Fehler bei der Verbindung mit Navision aufgetreten",
    "SERVER_NOT_RESPONDING": "Der Server ist momentan sehr beschäftigt. Versuche es später erneut.",
    "CHARGE_ALREADY_PROCESSED": "Diese Zeile wurde bereits bearbeitet",
    "AUTH_USER_BLOCKED": "Der Benutzer ist gesperrt, kontaktiere einen Vorgesetzten, um das Problem zu lösen",
    "AUTH_PASSWORD_INCORRECT": "Das Passwort ist falsch",
    "LINE_ALREADY_ADDED": "Diese Zeile wurde bereits bearbeitet oder zum ausstehenden Betrag hinzugefügt",
    "Internal server error": "Der Server hat ein Problem, bitte kontaktiere den Administrator der Anwendung oder versuche es später erneut."
  },
  "statusCode": {
    "999": "Verbindungsfehler",
    "200": "Erfolg",
    "201": "Erfolg",
    "404": "Nicht gefunden",
    "401": "Authentifizierungsfehler",
    "422": "Serverfehler",
    "499": "Existiert bereits",
    "403": "Existiert bereits",
    "500": "Verbindungsfehler",
    "503": "Wartung"
  },
  "success": {
    "chargeLineAdded": "Zu zahlende Zeile erfolgreich hinzugefügt",
    "categoryAdded": "Kategorie erfolgreich hinzugefügt",
    "productAdded": "Produkt erfolgreich hinzugefügt"
  },
  "prompt": {
    "updateWebProduct": {
      "text": "Gib die Produkt-ID in OpenCart ein",
      "title": "Web-Produkt"
    },
    "updateWebCategory": {
      "text": "Gib die Kategorie-ID in OpenCart ein",
      "title": "Web-Kategorie"
    },
    "deleteCharges": {
      "text": "Bist du sicher, dass du die ausgewählten Zahlungen löschen möchtest?",
      "title": "Bestätigen"
    }
  },
  "warning": {
    "mustAddObjective": "Denke daran, dass du das Ziel für das nächste Jahr für diesen Kunden hinzufügen musst",
    "emailMoreThanAWeek": "Die E-Mail wurde seit mehr als 7 Tagen nicht bestätigt",
    "waitingForConfirm": "Warten auf E-Mail-Bestätigung",
    "notValidatedEmail": "Der Kunde hat eine E-Mail, aber sie ist noch nicht bestätigt. Bitte sende ihm eine Bestätigungs-E-Mail.",
    "notEmail": "Der Kunde hat keine E-Mail. Um ihm Rechnungen zu senden, musst du ihm eine E-Mail hinzufügen und der Kunde muss sie bestätigen. Lass uns das angehen!"
  },
  "languages": {
    "es": "Spanisch",
    "it": "Italienisch",
    "pt": "Portugiesisch",
    "fr": "Französisch",
    "de": "Deutsch",
    "en": "Englisch"
  },
  "ES": "Spanien",
  "PT": "Portugal",
  "FR": "Frankreich",
  "DE": "Deutschland",
  "BE": "Belgien",
  "MAGAÑA": "Tahe",
  "TH PHARMA": "Th Pharma",
  "NEO-DEPIL LASER": "Neo Depil",
  "TAHE": "Tahe Productos Cosméticos S.L.",
  "TAHE_2016": "Tahe Productos Cosméticos S.L.",
  "TH_PHARMA": "Th Pharma",
  "TH_PHARMA_2016": "Th Pharma",
  "MIXPROFESSIONAL": "Mix Professional",
  "DVFARMACEUTIC": "DV Farmaceutic",
  "TAHE_ESPANA": "Tahe Spanien",
  "TAHE_CANARIAS": "Tahe Kanarische Inseln",
  "TAHE_PORTUGAL": "Tahe Portugal",
  "TAHE_ITALIA": "Tahe Italien",
  "TH_ESPANA": "Th Spanien",
  "TH_PORTUGAL": "Th Portugal",
  "TH_ITALIA": "Th Italien",
  "TAHE_INTERNACIONAL": "Tahe International",
  "TH_INTERNACIONAL": "Th International",
  "MIXP_ESPANA": "Mix Professional Spanien",
  "DVF_ESPANA": "DV Farmaceutic Spanien",
  "NEW_CLIENT": "NEUER KUNDE",
  "COMPANY": "Firma",
  "SELL_CHANNEL": "Vertriebskanal",
  "USER": "Benutzer",
  "COMPANY_SELECTION": "Code-Auswahl",
  "CLIENT_SELECTION": "Kundenauswahl",
  "DASHBOARD": "Dashboard",
  "REPORTS": "Tagesberichte",
  "PENDING_PROPOSAL": "Vorschlag für Kunden",
  "PREVIOUS_PROPOSALS": "Status der Vorschläge",
  "PENDING_ORDER": "Ausstehende Bestellung",
  "PENDING_APPROVAL_ORDERS": "Ausstehende Genehmigungen für Bestellungen",
  "PENDING_APPROVAL_REFUNDS": "Ausstehende Genehmigungen für Gutschriften",
  "PREVIOUS_ORDERS": "Frühere Bestellungen",
  "PENDING_CHARGE": "Ausstehende Zahlung",
  "PREVIOUS_CHARGES": "Frühere Zahlungen",
  "PENDING_REFUND": "Ausstehende Gutschrift",
  "PREVIOUS_REFUNDS": "Frühere Gutschriften",
  "CLIENT_MANAGEMENT": "Kundeninformationen",
  "VENDOR_INFO": "Verkäuferinformationen",
  "EXTRACTS": "Verkäuferbewegungen",
  "BILLS": "Rechnungen",
  "BILLS_REFUNDS": "Gutgeschriebene Rechnungen",
  "EXPEDITIONS": "Expeditionen",
  "EXPENSES": "Ausgaben",
  "INCOMES": "Einnahmen",
  "PENDING_TO_SHIP": "Zum Versand ausstehend",
  "REFUNDS_STATUS": "Gutschriftenstatus",
  "COMMISSIONS": "Provisionen",
  "SETTINGS": "Einstellungen",
  "CHANGELOG": "Änderungsprotokoll",
  "FAQ_STORES": "Hilfe für Friseurgeschäfte",
  "FAQ": "Hilfe",
  "PROFILE": "Profil",
  "BACK_LINK": "Zurück",
  "MENU": "Menü",
  "LOGOUT": "Abmelden",
  "LOGIN": "Anmelden",
  "CANCEL": "Abbrechen",
  "NO_FAVORITES_MENU": "Du hast noch keine Links zu den Favoriten hinzugefügt. Versuche, auf einen der Sterne <icon class=\"icon f7-icons\" style=\"font-size: 20px\">star</icon> in den Menülinks zu klicken.",
  "NO_RESULTS_FOUND": "Keine Ergebnisse gefunden",
  "COD": "Code",
  "CIF": "CIF",
  "PROPOSAL_FOR": "Vorschlag für ",
  "ORDER_FOR": "Angebot für ",
  "CHARGE_FOR": "Zahlung für ",
  "REFUND_FOR": "Gutschrift für ",
  "PENDING_PROPOSAL_EMPTY": "Du hast noch keine Zeile zum Vorschlag hinzugefügt. Klicke auf die Schaltfläche, um zum Katalog zu gehen.",
  "PENDING_ORDER_EMPTY": "Du hast noch keine Zeile zur Bestellung hinzugefügt. Klicke auf die Schaltfläche, um zum Katalog zu gehen.",
  "PENDING_CHARGE_EMPTY": "Du hast noch keine Zeile zur Zahlung hinzugefügt. Klicke auf die Schaltfläche, um die ausstehenden Zahlungen des Kunden zu sehen.",
  "PENDING_REFUND_EMPTY": "Du hast noch keine Zeile zur Gutschrift hinzugefügt. Klicke auf die Schaltfläche, um ein Produkt zu suchen.",
  "SEARCH_CLIENT": "Kunden nach Code, Name, CIF suchen...",
  "SEARCH_VENDOR": "Verkäufer nach Code, Name suchen...",
  "SEARCH_PRODUCT": "Produkt nach Code, Name suchen...",
  "ADD_PRODUCT": "Produkt hinzufügen",
  "ADD_CHARGE": "Zahlung hinzufügen",
  "SEARCH_IN_BILLS": "In den Rechnungen suchen",
  "GENERATE_ADVICE": "Vorschuss generieren",
  "CATALOG": "Katalog",
  "PRODUCTS": "Produkte",
  "LINES": "Zeilen",
  "CATEGORIES": "Kategorien",
  "IF_OPTION": "Nach Option",
  "PRICE": "Preis",
  "MAIN_MENU": "Hauptmenü",
  "ORDERS": "Bestellungen",
  "REFUNDS": "Gutschriften",
  "CHARGES": "Zahlungen",
  "NEW_CLIENTS": "Neue Kunden",
  "INCOME": "Einnahmen",
  "YES": "Ja",
  "NO": "Nein",
  "REPORT_DETAILS": "Bericht {0}",
  "REPORT_DETAILS_NOTFOUND": "Keine Berichtsdaten für {0} gefunden",
  "INFO": "Information",
  "REPORT_NO": "Bericht Nr.",
  "DATE": "Datum",
  "VENDOR": "Verkäufer",
  "IMPORT": "Betrag",
  "TOTAL": "Gesamt {0}€",
  "TOTAL_CASH": "Bar {0}€",
  "ADD_PRECLIENT_INFO": "Du hast keinen bestehenden Kunden ausgewählt. Wenn du einen Kunden ohne Bestellung hinzufügen möchtest, klicke auf die Schaltfläche Pre-Kunde hinzufügen.",
  "ADD_PRECLIENT": "Pre-Kunde hinzufügen",
  "NEW_CLIENT_FORM": "Neukundenformular",
  "TYPE_CLIENT": "Kundentyp",
  "NIF_CIF": "NIF/CIF",
  "NAME": "Name",
  "NAME_2": "Name 2",
  "NAME2": "Name 2",
  "SOCIAL_REASON": "Firmenname",
  "ADDRESS": "Adresse",
  "ADDRESS_2": "Adresse 2",
  "CITY": "Ort",
  "STATE": "Provinz",
  "COUNTRY": "Land",
  "POSTAL_CODE": "Postleitzahl",
  "CONTACT": "Kontakt",
  "CLOSE_DAYS": "Schließtage",
  "CLOSEDAYS": "Schließtage",
  "EMAIL": "E-Mail",
  "PHONE": "Telefon",
  "MOBILE": "Mobil",
  "FAX": "Fax",
  "NAVISIONID": "Code",
  "SOCIALREASON": "Firmenname",
  "ADDRESS2": "Adresse 2",
  "POSTALCODE": "Postleitzahl",
  "BANK_ACC": "Bankkonto",
  "PRECLIENT": "Pre-Kunde",
  "TYPECLIENT": "Kundentyp",
  "UNFINISHED": "Unvollständig",
  "STATISTICS": "Statistiken",
  "NO_PENDING_APPROVAL_ORDERS": "Keine ausstehenden Bestellungen zur Genehmigung",
  "NO_PENDING_APPROVAL_REFUNDS": "Keine ausstehenden Gutschriften zur Genehmigung",
  "PENDING_ORDERS": "Ausstehende Bestellungen",
  "PENDING_REFUNDS": "Ausstehende Gutschriften",
  "PENDING_CHARGES": "Ausstehende Zahlungen",
  "NO_PENDING_ORDERS": "Keine ausstehenden Bestellungen",
  "NO_PREVIOUS_ORDERS": "Noch keine früheren Bestellungen",
  "NO_PENDING_REFUNDS": "Keine ausstehenden Gutschriften",
  "NO_PREVIOUS_REFUNDS": "Noch keine früheren Gutschriften",
  "NO_PENDING_CHARGES": "Keine ausstehenden Zahlungen",
  "NO_PREVIOUS_CHARGES": "Noch keine früheren Zahlungen",
  "OBJECTIVE": "Ziel",
  "BILLED": "Fakturiert",
  "IDEAL": "Ideal bis heute",
  "CUMPLIMENT_OBJ": "des Ziels erreicht",
  "SELECT_FOR_STATISTICS": "Du kannst Monat und Jahr ändern",
  "MONTH": "Monat",
  "YEAR": "Jahr",
  "BACK": "Zurück",
  "ACTIONS": "Aktionen",
  "SELECT_ALL": "Alle auswählen",
  "DISELECT_ALL": "Alle abwählen",
  "DEACTIVATE_SORT": "Sortierung deaktivieren",
  "ACTIVATE_SORT": "Sortierung aktivieren",
  "CHECKOUT_ORDER": "Bestellung abwickeln",
  "TOTAL_IMPORT": "Gesamtbetrag",
  "TOTAL_IVA": "+ (inkl. MwSt.)",
  "BONIFY_ITEMS": "Bonifizierte Artikel",
  "PROMO_COUNT": "Anzahl Promos",
  "SELECT_QUANTITY": "Auswahl",
  "SELECT_IMPORT": "Betrag Auswahl",
  "CLIENT": "Kunde",
  "ITEMS": "Artikel",
  "ORDER_DETAILS": "Bestelldetails",
  "CHARGE_DETAILS": "Zahlungsdetails",
  "REFUND_DETAILS": "Gutschriftdetails",
  "FAVORITES": "Favoriten",
  "ADD_WITH_DISCOUNT": "Mit Rabatt hinzufügen",
  "UNITS": "Einheiten",
  "ADD_TO_ORDER": "Zur Bestellung hinzufügen",
  "ADD_TO_PROPOSAL": "Zum Vorschlag hinzufügen",
  "WEB_STATUS": "Webstatus",
  "NAV_STATUS": "Navision-Status",
  "BLOCKED": "Blockiert",
  "ACTIVE": "Aktiv",
  "PENDING_ORDER_BACK": "Bestellung",
  "DESCRIPTION": "Beschreibung",
  "QUANTITY": "Menge",
  "CODE": "Code",
  "NO_PROMO_PRODUCTS": "Diese Aktion hat keine feste Materialliste",
  "CLOSE": "Schließen",
  "UNITS_PER_BOX": "Einheiten pro Kiste",
  "UNITS_PER_PACK": "Einheiten pro Palette",
  "NO_PRODUCTS_FOUND": "Keine Produkte für die Suche <em>{0}</em> gefunden",
  "SELL_CHANNEL_SELECTED": "Du hast diese Firma und diesen Verkaufskanal ausgewählt",
  "NOT_CLIENTS_FOUND": "Keine Kunden für die Suche <em>{0}</em> gefunden",
  "CONFIRM": "Bestätigen",
  "PERSONALIZADO": "Angepasst",
  "DELETE": "Löschen",
  "DISCOUNT": "Rabatt",
  "DUPLICATE": "Duplizieren",
  "COPY": "Kopieren",
  "QUANT": "Menge",
  "UNIT_PRICE": "Stückpreis",
  "TOTAL_PROMO": "Gesamt Promo",
  "FREE": "Gratis",
  "EDIT_SELECTED": "Ausgewählte bearbeiten",
  "COPY_SELECTED": "Ausgewählte kopieren",
  "DUPLICATE_SELECTED": "Ausgewählte duplizieren",
  "DELETE_SELECTED": "Ausgewählte löschen",
  "NO_REPORTS": "Noch keine Tagesberichte, diese Berichte werden automatisch generiert",
  "NEW_CLIENT_ADDRESS": "Nutze diesen Kunden, um eine Bestellung für einen neuen Kunden oder Pre-Kunden einzugeben",
  "es": "Spanisch",
  "it": "Italienisch",
  "pt": "Portugiesisch",
  "fr": "Französisch",
  "CHANGE_PASSWORD": "Passwort ändern",
  "LANGUAGES": "Sprachen",
  "ACCEPT": "Akzeptieren",
  "DELETE_SELECTED_TEXT": "Bist du sicher, dass du die folgenden Artikel löschen möchtest?",
  "DELETE_PROMO": "Promotion löschen",
  "DELETE_PROMO_TEXT": "Was möchtest du tun?",
  "DELETE_ALL": "Alles löschen",
  "DELETE_ONLY_HEADER": "Nur Kopfzeile löschen",
  "DELETE_HEADER_AND_PUT_PRICES": "Kopfzeile löschen und Listenpreise setzen",
  "SEARCH_BY_CODE": "Nach Code hinzufügen",
  "NOT_EXIST_PRODUCT": "Es tut mir leid, aber das Produkt existiert nicht in der Datenbank. Bist du sicher, dass es korrekt ist?",
  "PRODUCT_BLOCKED": "Es tut mir leid, dieses Produkt ist in Navision blockiert, du kannst es nicht hinzufügen",
  "SELECT_MODIFIERS": "Wähle die Felder aus, die du bearbeiten möchtest, ändere sie und klicke auf Speichern",
  "SAVE": "Speichern",
  "OK": "Ok",
  "TIMEOUT": "Zeitüberschreitung",
  "UNAUTHORIZED": "Authentifizierungsfehler",
  "USER_BLOCKED": "Der Benutzer ist gesperrt, kontaktiere einen Vorgesetzten, um das Problem zu lösen",
  "PASSWORD_INCORRECT": "Das Passwort ist falsch",
  "NOT_USER_FOUND": "Es wurde kein Benutzer mit diesen Daten gefunden, <span class=\"text-color-red\">denk daran, dass der Benutzer <strong>NICHT</strong> dein Verkäufercode oder deine DNI ist</span>",
  "ALERT": "Achtung!",
  "MOVE_TO_OTHER_CLIENT_INFO": "Du wirst diese Bestellung zu einem anderen Kunden verschieben. Wenn du die Bestellung verschiebst, wird sie <strong>GELÖSCHT</strong> von diesem Kunden und die Zeilen werden auf den anderen Kunden übertragen. Wenn die Bestellung bereits existiert, werden sie zur bestehenden Bestellung hinzugefügt, und wenn nicht, wird eine neue erstellt. Bist du sicher, dass du fortfahren möchtest?",
  "COPY_TO_OTHER_CLIENT_INFO": "Du wirst diese Bestellung zu einem anderen Kunden kopieren. Wenn du die Bestellung kopierst, wird sie <strong>BLEIBEN</strong> bei diesem Kunden und die Zeilen werden beim anderen Kunden erstellt. Wenn die Bestellung bereits existiert, werden sie zur bestehenden Bestellung hinzugefügt, und wenn nicht, wird eine neue erstellt. Bist du sicher, dass du fortfahren möchtest?",
  "MOVE_ORDER_TO_CLIENT_SUCCESS": "Die Bestellung wurde zu Kunde Nr. {0} verschoben, möchtest du zu diesem Kunden wechseln?",
  "COPY_ORDER_TO_CLIENT_SUCCESS": "Die Bestellung wurde zu Kunde Nr. {0} kopiert, möchtest du zu diesem Kunden wechseln?",
  "SERVER_TOO_BUSY": "Der Server verarbeitet derzeit viele Anfragen, bitte versuche es später erneut",
  "SERVER_NOT_RESPONDING": "Es scheint, dass der Server derzeit nicht erreichbar ist. Überprüfe zuerst deine Internetverbindung und ob andere Anwendungen funktionieren, und kontaktiere dann einen Administrator",
  "UPDATE": "Aktualisieren",
  "ORDER_MOVED": "Bestellung verschoben",
  "ORDER_COPIED": "Bestellung kopiert",
  "INFO_METHODS": "Information und Zahlungsmethoden",
  "RESUME_ORDER": "Bestellübersicht",
  "SEND": "Senden",
  "NOT_FOUND": "Nicht gefunden",
  "SORRY": "Entschuldigung",
  "CONTENT_NOT_FOUND": "Ich habe nicht gefunden, wonach du gesucht hast. Gehe zurück oder klicke auf das Hauptmenü, um zum Dashboard zurückzukehren.",
  "ADDRESS_SELECTION": "Adresse auswählen",
  "PAYMENT_FORM_SELECTION": "Zahlungsart",
  "PAYMENT_TERM_SELECTION": "Zahlungsfrist",
  "SELECT_ADDRESS_FILL": "Der Kunde hat mehr als eine Adresse, klicke hier und wähle eine aus.",
  "SEARCH_ADDRESS": "Adressen filtern...",
  "NO_ADDRESSES_FOUND": "Keine Adressen gefunden",
  "SELECT_PAYMENT_FORM_FILL": "Du musst eine Zahlungsart wählen",
  "SELECT_PAYMENT_TERM_FILL": "Du musst eine Zahlungsfrist wählen",
  "ADD_ORDER_DATES": "Zahlungstermine hinzufügen",
  "REMAINING_IVA_INC": "Restbetrag (inkl. MwSt.)",
  "ADD_CHARGE_DATE": "Zahlungsdatum hinzufügen",
  "ADD_CHARGE_DATES": "Zahlungsdatum hinzufügen",
  "ADD_REMAINING": "Restbetrag hinzufügen",
  "ADD_ORDER_DATES_INFO": "Diese Angaben sind unverbindlich. Denke daran, dass sich der endgültige Angebotspreis ändern kann.",
  "ADD_CHARGE_DATES_INFO": "Wähle den zu zahlenden Betrag und das gewünschte Datum und klicke auf Zahlung hinzufügen",
  "COMMENT": "Kommentar",
  "COMMENT_FOR_CLIENT": "Kommentar für den Kunden",
  "SAVE_COMMENT": "Kommentar speichern",
  "PENDING_BILLS": "Ausstehende Rechnungen",
  "NO_PREVIOUS_MOVEMENTS": "Keine früheren Bewegungen des ausgewählten Kunden gefunden.",
  "NOT_MOVEMENTS_NEW_CLIENT": "Du hast den neuen Kunden ausgewählt, wähle einen bestehenden Kunden, um seine Bewegungen zu sehen",
  "NOT_BILLS_REFUNDS_NEW_CLIENT": "Du hast den neuen Kunden ausgewählt, wähle einen bestehenden Kunden, um seine gutgeschriebenen Rechnungen zu sehen",
  "NO_BILLS_NEW_CLIENT": "Du hast den neuen Kunden ausgewählt, wähle einen bestehenden Kunden, um seine Rechnungen zu sehen",
  "BILL_DETAILS": "Rechnungsdetails",
  "SEARCH_PRODUCTS": "Rechnungszeilen filtern",
  "SEARCH_REFUND_PRODUCTS": "Produkt suchen",
  "SEARCH_REFUND_BILLS": "Rechnung suchen",
  "NO_CHARGES_FOR_NEW_CLIENT": "Du hast keinen bestehenden Kunden ausgewählt, wähle einen Kunden, um eine Zahlung zu bearbeiten",
  "NO_REFUNDS_FOR_NEW_CLIENT": "Du hast keinen bestehenden Kunden ausgewählt, wähle einen Kunden, um eine Gutschrift zu bearbeiten",
  "NO_PENDING_BILLS": "Der Kunde hat keine ausstehenden Rechnungen",
  "NO_MOV": "Nr. Bewegung",
  "BILL": "Rechnung",
  "TO_LIQUID": "Zu liquidieren",
  "PENDING_IMPORT": "Ausstehender Betrag",
  "ADVICE": "Vorschuss",
  "CHECKOUT_CHARGE": "Zahlung abwickeln",
  "CHARGE_ALREADY_PROCESSED": "Diese Zeile wurde bereits bearbeitet",
  "CHARGE_LINE_ADDED": "Zu zahlende Zeile erfolgreich hinzugefügt",
  "ORDER": "Bestellung",
  "UNITS_PENDING": "Ausstehende Einheiten",
  "NUM_PACKS": "Pakete",
  "WRITE_COMMENT": "Schreibe hier deinen Kommentar",
  "CONTENT": "Inhalt",
  "NEW_CLIENT_DETAILS": "Details zum neuen Kunden",
  "NO_NEW_CLIENT_DATA": "Keine Daten zu diesem neuen Kunden gefunden, versuche es zu aktualisieren",
  "COMMENTS": "Kommentare",
  "OBJECTIVES": "Ziele",
  "EXP_PEND": "Expeditionen",
  "PEND_BILLS": "Ausstehende Rechnungen",
  "DROP_PLACEHOLDER": "Dateien hierher ziehen",
  "SELECTED_FILE": "Ausgewähltes Bild",
  "ADD_IMAGE": "Dokument hinzufügen",
  "NEW_INCOME": "Neue Einnahme",
  "ADD_INCOME": "Einnahme hinzufügen",
  "NO_INCOMES": "Keine früheren Einnahmen gefunden, klicke auf die obere Schaltfläche, um eine neue Einnahme hinzuzufügen",
  "CHOOSE_FILE_INCOME": "Wähle eine Datei (Einnahmebeleg)",
  "NEW_EXPENSE": "Neue Ausgabe",
  "ADD_EXPENSE": "Ausgabe hinzufügen",
  "NO_EXPENSES": "Keine früheren Ausgaben gefunden, klicke auf die obere Schaltfläche, um eine neue Ausgabe hinzuzufügen",
  "CHOOSE_FILE_EXPENSE": "Wähle eine Datei (Beleg der Ausgabe)",
  "TAP_HERE_IMG": "Klicke hier, um ein Foto auszuwählen oder aufzunehmen",
  "DATE_INCOME": "Datum der Einnahme",
  "DATE_EXPENSE": "Datum der Ausgabe",
  "EXPENSE_ADDED": "Ausgabe erfolgreich hinzugefügt",
  "INCOME_ADDED": "Einnahme erfolgreich hinzugefügt",
  "IMPORT_ERROR_MESSAGE": "Importfehler. Bitte kontaktiere den Administrator der Anwendung",
  "ERRORS": "Fehler",
  "PLEASE_FIX_ERRORS": "Bitte behebe die folgenden Fehler",
  "INCOME_DETAILS": "Einnahmedetails",
  "NO_INCOME_FOUND": "Die gesuchte Einnahme wurde nicht gefunden.",
  "PAYMENT_FORM": "Zahlungsart",
  "GROUP": "Gruppe",
  "EXPENSE_DETAILS": "Ausgabedetails",
  "PREVIEW": "Vorschau",
  "PROPOSAL_DETAILS": "Vorschlagsdetails",
  "COMMERCIAL_COMMENT": "Kommentare des Verkäufers",
  "NO_PROPOSAL": "Dieser Vorschlag wurde nicht gefunden",
  "RESPONSE": "Antwort an den Verkäufer",
  "SURNAME_NAME": "Nachname, Vorname",
  "UNDER_CONSTRUCTION": "Im Bau",
  "ORDER_DATE": "Angebotsdatum",
  "REFUND_DATE": "Gutschriftsdatum",
  "COMPANY_SELL_CHANNEL": "Unternehmen und Verkaufskanal",
  "SEND_ADDRESS": "Lieferadresse",
  "SEND_DATE": "Lieferung ab dem Tag",
  "PROPOSALS": "Vorschläge",
  "PROPOSAL": "Vorschlag",
  "PROPOSAL_CONFIRMATION": "Ein Link wird generiert, um den Vorschlag an den Kunden zu senden. Der Kunde kann ihn akzeptieren oder ablehnen und alle Informationen zu den Produkten im Vorschlag sehen. Bist du einverstanden?",
  "PAYMENT_TERM": "Zahlungsfrist",
  "DATES": "Daten",
  "PAYMENT_DATE": "Zahlungsdatum",
  "PASSWORDS_NOT_MATCH": "Das eingegebene Passwort ist nicht dein aktuelles Passwort",
  "PASSWORD_SUCCESSFULL_CHANGED": "Du hast dein Passwort erfolgreich geändert",
  "ADDRESSES": "Adressen",
  "CLIENT_COMMENTS": "Kundenkommentare",
  "CONTRACT_COMMENTS": "Vertragskommentare",
  "NO_COMMENTS": "Keine Kommentare",
  "FILL_NEW_CLIENT_DATA": "Du wirst eine Bestellung für einen neuen Kunden eingeben, jetzt musst du die Daten ausfüllen. Klicke auf die folgende Schaltfläche und fülle die Daten aus.",
  "FILL_PRECLIENT": "Kundendaten ausfüllen",
  "UPDATE_PRECLIENT": "Kundendaten aktualisieren",
  "FIXED_DUE_DATE": "Fester Fälligkeitstermin",
  "PRODUCT_BILLS": "Produktrechnungen",
  "REFUND_SELECTED": "Ausgewählte gutschreiben",
  "APPROVAL_ORDERS": "Bestellungen genehmigen",
  "APPROVAL_REFUNDS": "Gutschriften genehmigen",
  "VENDOR_SELECTION": "Verkäuferauswahl",
  "VENDOR_SELECTED": "Du hast diesen Verkäufer ausgewählt",
  "CONTADO_DISABLED": "Du kannst keine Barzahlung für einen neuen Kunden durchführen",
  "GIRO_DISABLED": "Du kannst diesen Kunden nicht belasten, da kein Bankkonto hinterlegt ist. Bitte kontaktiere die Verwaltung, um ein Bankkonto hinzuzufügen und versuche es erneut. Denke daran, dass die Anwendung nachts aktualisiert wird.",
  "TALON_C_DISABLED": "Du kannst diese Zahlungsart nicht verwenden, da der Kunde keinen Vertrag für Vorauszahlungsschecks hat",
  "TRANSFER_DISABLED": "Du kannst diese Zahlungsart nicht verwenden, da der Kunde keine Genossenschaft ist",
  "CONFIRM_SEND_ORDER": "Bist du sicher, dass du das Angebot senden möchtest?",
  "SEND_ORDER": "Angebot senden",
  "CHARGE_DATE": "Zahlungsdatum",
  "DATE_EXISTS_ERROR": "Das Datum {0} wurde bereits zu den Fälligkeitsdaten hinzugefügt",
  "ZERO_IMPORT": "Um eine Zahlung auf 0 zu setzen, musst du Bargeld auswählen",
  "CONFIRM_SEND_CHARGE": "Bist du sicher, dass du die Zahlung senden möchtest?",
  "SEND_CHARGE": "Zahlung senden",
  "SEARCH_REFUND_PRODUCT": "Suche ein Produkt in der oberen Leiste",
  "ADD": "Hinzufügen",
  "REFUND_MOTIVE": "Gutschriftsgrund",
  "MOTIVES": "Gründe",
  "REFUND_LINES_ADDED": "Die ausgewählten Zeilen wurden zur Gutschrift hinzugefügt",
  "MOTIVE": "Grund",
  "SET_LITTLE_DESCRIPTION": "Gib eine kurze Beschreibung ein",
  "SEND_REFUND": "Gutschrift senden",
  "CONFIRM_SEND_REFUND": "Bist du sicher, dass du die Gutschrift senden möchtest? Überprüfe sie vorher",
  "REFUND_MOTIVE_SUGGEST": "Hast du alle Gutschriftsgründe für JEDES Produkt ausgefüllt?",
  "NO_REFUND_FOUND": "Die Gutschrift wurde nicht gefunden",
  "OBJECTIVES_FROM_COSMET": "Ziele aus Ofertahe hinzugefügt",
  "ADD_OBJECTIVE": "Ziel hinzufügen",
  "PREVIOUS_OBJECTIVES": "Frühere Ziele",
  "OBJECTIVE_ADDED": "Ziel erfolgreich hinzugefügt",
  "200": "Erfolg",
  "201": "Erfolg",
  "404": "Nicht gefunden",
  "400": "Fehler",
  "401": "Authentifizierungsfehler",
  "422": "Serverfehler",
  "499": "Existiert bereits",
  "CONTADO": "Bar",
  "GIRO": "Wechsel",
  "CONTRAREEM": "Nachnahme",
  "GIROPT": "Wechsel Portugal",
  "CONTRARPOR": "Nachnahme Portugal",
  "CHEQUEPT": "Scheck Portugal",
  "TARJETAPT": "Kundenkarte Portugal",
  "TRANSFPORT": "Überweisung",
  "TRANSFERIT": "Überweisung",
  "TARJ OUTLE": "Karte",
  "CONTADOPT": "Scheckvertrag",
  "EFECTIVO": "Bargeld",
  "TARJETA": "Karte",
  "GIROCOBRO": "Wechsel",
  "CHEQUE": "Scheck",
  "DESCUENTO": "Rabatt",
  "1X7": "Wechsel 7 Tage",
  "1X15": "Wechsel 15 Tage",
  "1X20": "Wechsel 20 Tage",
  "1X21": "Wechsel 21 Tage",
  "1X30": "Wechsel 30 Tage",
  "1X40": "Wechsel 40 Tage",
  "1X45": "Wechsel 45 Tage",
  "1X60": "Wechsel 60 Tage",
  "1X75": "Wechsel 75 Tage",
  "1X90": "Wechsel 90 Tage",
  "2X7": "Wechsel 7 und 30 Tage",
  "2X15": "Wechsel 15 und 30 Tage",
  "2X20": "Wechsel 20 und 40 Tage",
  "2X30": "Wechsel 30 und 60 Tage",
  "2X45": "Wechsel 45 und 90 Tage",
  "2X45B": "Wechsel 45 und 60 Tage",
  "2X60": "Wechsel 60 und 90 Tage",
  "2X60X120": "Wechsel 60 und 120 Tage",
  "2X90": "Wechsel 90 und 120 Tage",
  "3X10": "Wechsel 10, 20 und 30 Tage",
  "3X15": "Wechsel 15, 30 und 45 Tage",
  "3X3": "Wechsel 45, 75 und 105 Tage",
  "3X30": "Wechsel 30, 60 und 90 Tage",
  "3X60": "Wechsel 60, 90 und 120 Tage",
  "4X30": "Wechsel 30, 60, 90 und 120 Tage",
  "4X3": "Wechsel 15, 60 und 90 Tage",
  "4X4": "Wechsel 15, 30, 60 und 90 Tage",
  "1X120": "Wechsel 120 Tage",
  "MAINTENANCE_MODE": "Der Server wird gewartet. Bitte habe Geduld, er wird bald wieder betriebsbereit sein.",
  "503": "WARTUNG",
  "NO_PREVIOUS_BILLS": "Keine früheren Rechnungen gefunden",
  "NO_PREVIOUS_BILLS_REFUNDS": "Keine früheren gutgeschriebenen Rechnungen gefunden",
  "SEARCH_MENU": "Was möchtest du tun?",
  "PENDING": "Ausstehend",
  "PENDING_TO_SEND": "Ausstehend zu senden",
  "PENDING_APPROVAL": "Ausstehende Genehmigung",
  "PENDING_APPROVAL_DIS": "Ausstehende Genehmigung Vertrieb",
  "SENDED": "Gesendet",
  "IMPORT_ERROR": "Importfehler",
  "RECEIVED": "Empfangen",
  "ACCEPTED": "Akzeptiert",
  "CANCELED": "Storniert",
  "UNKNOWN": "Unbekannt",
  "STATUS": "Status",
  "ENERO": "JANUAR",
  "FEBRERO": "FEBRUAR",
  "MARZO": "MÄRZ",
  "ABRIL": "APRIL",
  "MAYO": "MAI",
  "JUNIO": "JUNI",
  "JULIO": "JULI",
  "AGOSTO": "AUGUST",
  "SEPTIEMBRE": "SEPTEMBER",
  "OCTUBRE": "OKTOBER",
  "NOVIEMBRE": "NOVEMBER",
  "DICIEMBRE": "DEZEMBER",
  "Peluquería": "Friseur",
  "Estética": "Kosmetik",
  "Estética+Peluquería": "Kosmetik+Friseur",
  "Barbería": "Barbier",
  "Otros": "Andere",
  "NOT_EMAIL": "Der Kunde hat keine E-Mail. Um ihm Rechnungen zu senden, musst du eine E-Mail hinzufügen und der Kunde muss sie bestätigen. Lass uns das angehen!",
  "NOT_VALIDATED_EMAIL": "Der Kunde hat eine E-Mail, aber sie ist noch nicht bestätigt. Bitte sende ihm eine Bestätigungs-E-Mail.",
  "MUST_ADD_OBJECTIVE": "Denke daran, dass du das Ziel für das nächste Jahr für diesen Kunden hinzufügen musst.",
  "VALIDATE_EMAIL": "E-Mail bestätigen",
  "UPDATE_EMAIL": "E-Mail aktualisieren",
  "EMAIL_MORE_THAN_WEEK": "Die E-Mail wurde seit mehr als 7 Tagen nicht bestätigt",
  "WAITING_FOR_CONFIRM": "Warten auf E-Mail-Bestätigung",
  "SEND_ORDER_CONFIRMATION": "Versandbestätigung",
  "COOPERATIVA": "GENOSSENSCHAFT",
  "ASSOCIATED_CLIENT": "Verbundener Kunde",
  "SELECT_ASSOCIATED_CLIENT": "Wähle den verbundenen Kunden",
  "NO_BILLS_FOUND": "Keine Rechnungen gefunden",
  "ADD_BILL_MANUALLY": "Rechnung manuell hinzufügen",
  "BUY_AGAIN": "Erneut bestellen",
  "REBUY": "Wieder kaufen",
  "REFUND": "Gutschrift",
  "TYPE_CLIENT_EMPTY": "Du musst das Feld Kundentyp ausfüllen",
  "CIF_EMPTY": "Du musst das Feld NIF/CIF ausfüllen",
  "CIF_NOT_VALID": "Die CIF ist ungültig, überprüfe das Feld NIF/CIF",
  "NAME_EMPTY": "Du musst das Feld Name ausfüllen",
  "NAME_TOO_LONG": "Der Name und die Nachnamen zusammen sind zu lang, versuche sie zu kürzen oder erwäge, sie in Name 2 aufzuteilen.",
  "NAME2_TOO_LONG": "Das Feld Name 2 ist zu lang",
  "SOCIAL_REASON_TOO_LONG": "Das Feld Firmenname ist zu lang",
  "ADDRESS_EMPTY": "Du musst das Feld Adresse ausfüllen",
  "ADDRESS_TOO_LONG": "Das Feld Adresse ist zu lang, erwäge, es in das Feld Adresse 2 aufzuteilen",
  "ADDRESS2_TOO_LONG": "Das Feld Adresse 2 ist zu lang, versuche es zu kürzen",
  "CITY_EMPTY": "Du musst das Feld Ort ausfüllen",
  "CITY_TOO_LONG": "Das Feld Ort ist zu lang, versuche es zu kürzen",
  "STATE_EMPTY": "Du musst das Feld Provinz ausfüllen",
  "STATE_TOO_LONG": "Das Feld Provinz ist zu lang, versuche es zu kürzen",
  "COUNTRY_EMPTY": "Du musst ein Land auswählen",
  "POSTAL_CODE_EMPTY": "Du musst das Feld Postleitzahl ausfüllen",
  "POSTAL_CODE_WRONG": "Das Feld Postleitzahl ist falsch, überprüfe es",
  "CONTACT_EMPTY": "Du musst das Feld Kontaktperson ausfüllen",
  "CONTACT_TOO_LONG": "Das Feld Kontakt ist zu lang, versuche es zu kürzen",
  "CLOSE_DAYS_EMPTY": "Du musst das Feld Schließtage ausfüllen",
  "CLOSE_DAYS_TOO_LONG": "Das Feld Schließtage ist zu lang, versuche es zu kürzen",
  "EMAIL_EMPTY": "Du musst das Feld E-Mail ausfüllen",
  "EMAIL_NOT_VALID": "Die E-Mail hat ein ungültiges Format.",
  "EMAIL_TOO_LONG": "Die E-Mail ist zu lang",
  "MOBILE_OR_PHONE_E": "Du musst das Feld Telefon oder das Feld Mobil ausfüllen",
  "FAX_TOO_LONG": "Das Feld Fax ist zu lang",
  "NOT_VALID_ACCOUNT": "Das Feld Kontonummer muss dieses Format haben SSXX XXXX XXXX XX XXXXXXXXXX",
  "FILL_BANK_ACCOUNT": "Wenn du einem neuen Kunden eine Lastschrift erteilen möchtest, musst du auch die Kontonummer angeben, bitte fülle sie aus",
  "BANKACCOUNT": "Bankkonto",
  "CHECK_NO": "Scheck-Nr.",
  "CHECK_NO_PLACEHOLDER": "Gib die Schecknummer ein",
  "NO_ORDER_FOUND": "Keine Bestellung gefunden",
  "NO_REPORT_DETAILS": "Keine Berichtsdaten gefunden",
  "NO_PENDING_TO_SHIP_FOR_NEW_CLIENT": "Du hast keinen bestehenden Kunden ausgewählt, wähle einen Kunden, um seine ausstehenden Lieferungen zu sehen",
  "NO_EXPEDITION_FOR_NEW_CLIENT": "Du hast keinen bestehenden Kunden ausgewählt, wähle einen Kunden, um seine Expeditionen zu sehen",
  "LINE_COPIED": "Zeile in die Bestellung kopiert",
  "COMPANY_NOT_MATCH": "Die Unternehmen der Zeilen, die du zur Zahlung hinzufügen möchtest, stimmen nicht überein",
  "DESCON_0": "Schau nicht auf den Bildschirm, jetzt ist nicht die Zeit dafür, sondern deine. Entspann dich!",
  "DESCON_1": "Versteckte Sonne, wohlverdiente Pause.",
  "DESCON_2": "Abschalten und Ausruhen ist notwendig, um ein gesundes Leben zu führen.",
  "DESCON_3": "Zeit für: Kunden ... Reisen ... Bestellungen ... Abschalten! <icon class=\"icon f7-icons text-color-green\" style=\"font-size: 16px\">checkmark_alt</icon>",
  "DESCON_4": "Jetzt ist Familienzeit: Genieße und nutze sie!",
  "DESCON_5": "Jetzt beginnt dein Familienabend! Genieße ihn!",
  "DESCON_6": "Um 100% Leistung während deiner Arbeitszeit zu erbringen, brauchst du eine 100%ige Abschaltung.",
  "DESCON_7": "Um produktiv zu sein, muss man sich ausruhen! Einen schönen Ruhetag!",
  "DESCON_8": "Nach der Arbeit hilft dir Zeit mit deiner Familie, ein gesundes Leben und gute Lektüre, dich besser zu fühlen.",
  "DESCON_9": "Halt! Diese Zeit ist für dich, widme sie dir!",
  "SOLD_PRODUCTS": "Verkaufte Produkte",
  "NO_SOLD_PRODUCTS_FOR_NEW_CLIENT": "Du hast keinen bestehenden Kunden ausgewählt, wähle einen Kunden, um seine verkauften Produkte zu sehen",
  "DATE_RANGE": "Datumsbereich",
  "SELECT_DATES": "Daten auswählen",
  "GET_SOLD_PRODUCTS": "Verkaufte Produkte abrufen",
  "NO_SOLD_PRODUCTS_FOUND": "Keine verkauften Produkte für diesen Kunden oder diesen Zeitraum gefunden",
  "SEARCH_FAQ": "In der Hilfe suchen",
  "MY_CODES": "Meine Codes",
  "VENDORS": "Verkäufer",
  "SEARCH_REPORTS": "In den Berichten suchen",
  "REPORTS_RESULTS": "Ergebnisse für die Suche {0}",
  "NOT_FOUND_CLIENT": "Nicht gefunden",
  "NOT_FOUND_CLIENT_INFO": "Wenn der Kunde heute hinzugefügt wurde, ist es normal, dass er nicht erscheint. Aber keine Sorge! Wenn du den Kunden-Code kennst, kannst du ihn selbst hier hinzufügen. Beachte, dass seine Informationen wie Vertragskommentare, zusätzliche Adressen usw. morgen erscheinen. Trotzdem, wenn du eine Bestellung, Gutschrift oder Zahlung für ihn machen musst, versuche ihn jetzt selbst hinzuzufügen, damit du nicht bis morgen warten musst.",
  "FIND_CLIENT": "Kunden finden",
  "CLIENT_IMPORT_FAILED": "Entschuldigung, aber entweder existiert der eingegebene Code nicht oder dieser Kunde ist nicht mit deinem Verkäufercode verbunden. Bitte kontaktiere die Verwaltung und versuche es erneut :)",
  "CLIENT_IMPORT_SUCESFULL": "Gut! Du hast den Kunden zu deiner Auswahl hinzugefügt",
  "NO_PREVIOUS_PROPOSALS": "Keine früheren Vorschläge gefunden.",
  "PRODUCT_ERROR": "Produktfehler",
  "PRODUCT_ERROR_INFO": "Gibt es ein Problem mit einem Produkt? Ob es ein Fehler bei einem bestehenden Produkt gibt oder du ein Produkt nicht findest, hier ist dein Platz. Sag mir, was los ist und ich helfe dir, es zu lösen.",
  "FIND_PRODUCT_CODE": "Code finden",
  "WHAT_PROBLEM": "Was ist das Problem?",
  "APPLIES_TO_DOC": "Mit Gutschrift verbunden",
  "SENDED_ERROR_MESSAGE": "Eine Nachricht mit dem erkannten Fehler wurde gesendet, vielen Dank!",
  "COSMET_NOT_PRODUCT_FOUND": "Das Produkt wurde in der Datenbank nicht gefunden, überprüfe, ob der Code korrekt ist und wenn ja, kontaktiere den Administrator",
  "CONTRACTS": "Verträge",
  "NO_EXPEDITIONS_CLIENT": "Der Kunde hat keine aktuellen Expeditionen",
  "NO_PENDING_BILLS_CLIENT": "Der Kunde hat keine ausstehenden Rechnungen",
  "NO_LAST_BILLS": "Keine früheren Rechnungen gefunden",
  "UPDATE_PRODUCT": "Produkt hinzufügen/aktualisieren",
  "UPDATE_CATEGORY": "Kategorie hinzufügen/aktualisieren",
  "NO_LAST_BILLS_VENDOR": "Keine Informationen zu den letzten Rechnungen",
  "NO_LATE_BILLS_VENDOR": "Du hast keine überfälligen Salden von Kunden",
  "NO_PENDING_BILLS_VENDOR": "Keine ausstehenden Rechnungen von Kunden",
  "SELL_CHANNEL_SELECTION": "Vertriebskanal Auswahl",
  "TAHE_OUTLET": "Tahe Outlet",
  "TH_OUTLET": "Th Outlet",
  "PROPOSAL_ADDED_TO_ORDER": "Vorschlag erfolgreich zur Bestellung hinzugefügt",
  "NUM_CHECK": "Scheck-Nr.",
  "NOT_EXIST_ONE_OR_MORE_PRODUCTS": "Die Produkte wurden zur Gutschrift hinzugefügt, aber einige davon konnten nicht hinzugefügt werden, da sie nicht in der Datenbank existieren",
  "PRODUCT_BILL_ALREADY_ADDED": "Es scheint, dass du bereits einige der ausgewählten Produkte zu dieser Rechnung gutgeschrieben hast",
  "DISTRIBUTORS": "Distributoren",
  "NO_DISTRIBUTORS": "Keine Distributoren",
  "ADD_DISTRIBUTOR": "Distributor hinzufügen",
  "NEW_DISTRIBUTOR": "Neuer Distributor",
  "PRODUCTS_SOLD_INFO": "Die Berechnung der verkauften Produkte erfolgt, indem die fakturierten Produkte genommen und die gutgeschriebenen Produkte abgezogen werden",
  "ADD_DIST_CLIENT": "Kunden hinzufügen",
  "SEE_MORE": "Mehr sehen",
  "cif": "CIF",
  "socialReason": "Firmenname",
  "name": "Name",
  "name2": "Name 2",
  "surname": "Nachname",
  "address": "Adresse",
  "address2": "Adresse 2",
  "city": "Stadt",
  "state": "Provinz",
  "postalCode": "PLZ",
  "contact": "Kontakt",
  "closeDays": "Schließtage",
  "email": "E-Mail",
  "phone": "Telefon",
  "mobile": "Mobil",
  "fax": "Fax",
  "bankAccount": "Bankkonto",
  "esxx": "ESXX",
  "bank": "Bank",
  "office": "Büro",
  "digit": "Ziffer",
  "numAccount": "Kontonummer",
  "cosmet": "Kosmet",
  "red": "Rot",
  "green": "Grün",
  "blue": "Blau",
  "pink": "Rosa",
  "yellow": "Gelb",
  "orange": "Orange",
  "purple": "Lila",
  "deeppurple": "Indigo",
  "lightblue": "Hellblau",
  "teal": "Türkis",
  "lime": "Limette",
  "deeporange": "Dunkelorange",
  "DESC_PP": "% Rabatt P.P.",
  "ACT_CANTIDAD": "Menge aktualisieren",
  "ACT_PRECIO_UNIDAD": "Stückpreis aktualisieren",
  "ACT_DESC": "Rabatt aktualisieren",
  "ACT_GRATIS": "Gratis aktualisieren",
  "LINEAS_BONIFICADAS": "Bonifizierte Zeilen",
  "CERRAR": "Schließen",
  "GUARDAR": "Speichern"
};
