import gql from 'graphql-tag';

export const GET_REVISER = `
  reviser(username: $username) {
    name
    sellChannels
    sellChannelToCodes {
      sellChannel
      company
      code
      name
      blocked
    }
  }
`;

export const GET_APPROVAL_ORDERS = `
  approvalOrders(username: $username) {
    _id
    import
    clientId
    clientName
    paymentForm
    paymentTerm
    paymentDate
    reportId
    status
    date
    vendorId
    sellChannel
    company
    groupPrice
    revisionBy
  }
`;

export const GET_APPROVAL_REFUNDS = `
  approvalRefunds(username: $username) {
    _id
    import
    clientId
    clientName
    reportId
    status
    date
    vendorId
    sellChannel
    company
    revisionBy
  }
`;

export const GET_APPROVAL_CHARGES = `
  approvalCharges(username: $username) {
    _id
    import
    clientId
    clientName
    paymentForm
    paymentTerm
    paymentDate
    reportId
    status
    date
    vendorId
    sellChannel
    company
    revisionBy
  }
`;

export const GET_REVISER_GQL = gql`
  query GetReviser($username: String!) {
    ${GET_REVISER}
  }
`;

export const GET_APPROVAL_ORDERS_GQL = gql`
  query GetApprovalOrders($username: String!) {
    ${GET_APPROVAL_ORDERS}
  }
`;

export const GET_APPROVAL_REFUNDS_GQL = gql`
  query GetApprovalRefunds($username: String!) {
    ${GET_APPROVAL_REFUNDS}
  }
`;

export const GET_APPROVAL_CHARGES_GQL = gql`
  query GetApprovalCharges($username: String!) {
    ${GET_APPROVAL_CHARGES}
  }
`;
