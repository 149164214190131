import { apolloClient } from '@/js/plugins/apollo-client';
import {
  GET_APPROVAL_CHARGES_GQL,
  GET_APPROVAL_ORDERS_GQL,
  GET_APPROVAL_REFUNDS_GQL,
  GET_REVISER_GQL
} from '../queries/revisers/revisers.queries';

export const getReviser = (username) => apolloClient.query({query: GET_REVISER_GQL, variables: { username }});
export const approvalOrders = (username) => apolloClient.query({ query: GET_APPROVAL_ORDERS_GQL, variables: { username }});
export const approvalRefunds = (username) => apolloClient.query({ query: GET_APPROVAL_REFUNDS_GQL, variables: { username }});
export const approvalCharges = (username) => apolloClient.query({ query: GET_APPROVAL_CHARGES_GQL, variables: { username }});

export const revisersService = {
  getReviser,
  approvalOrders,
  approvalRefunds,
  approvalCharges,
};
