<template>
  <page name="not-found" :title="$t('pages.notFound')">
    <template v-slot:default>

    </template>
  </page>
</template>
<script>
import Page from '@/components/layouts/page.vue';

export default {
  components: {
    'page': Page,
  },
  setup: (props, context) => {
  }
}
</script>
