import gql from 'graphql-tag';
import { LAST_BILL_FRAGMENT, PENDING_BILL_FRAGMENT } from '../informs/informs.queries';

export const GET_VENDOR = `
  vendor(username: $username) {
    employeeId
    dni
    sellChannelToCodes {
      sellChannel
      company
      code
      name
      manual
    }
    codes {
      commission
      code
      name
    }
    impagos
  }
`;

export const GET_VENDOR_TEAMS = `
  vendorTeams(chiefTeam: $username) {
    name
    sellChannelToCodes {
      sellChannel
      company
      code
      name
      manual
    }
  }
`;

export const GET_VENDOR_GQL = gql`
  query GetVendor($username: String!) {
    ${GET_VENDOR}
  }
`;

export const GET_CHIEFTEAM_GQL = gql`
  query GetChieftTeam($username: String!) {
    ${GET_VENDOR}
    ${GET_VENDOR_TEAMS}
  }
`;

export const GET_INFO_GQL = gql`
  query GetInfo($vendorCode: String!, $companies: [String!]!) {
    lastBills(where: {vendorId: $vendorCode, companies: $companies}) {
      ...FullLastBill
    }
    pendingBills(where: {vendorId: $vendorCode, companies: $companies}) {
      ...FullPendingBill
    }
  }
  ${LAST_BILL_FRAGMENT}
  ${PENDING_BILL_FRAGMENT}
`;

export const GET_TOP_CLIENTES_GQL = gql`
  query GetTopClientes($vendedorCodigo: String!, $empresa: String!) {
    topClientes
    (vendedorCodigo: $vendedorCodigo, empresa: $empresa) 
    {
      clienteCodigo
      clienteNombre
      ciudad
      facturado
      facturadoAnterior
      objetivoActual
      objetivoNuevo
    }
  }
`;

export const GET_TOP_OBJETIVOS_GQL = gql`
  query GetTopObjetivos($vendedorCodigo: String!, $empresa: String!) {
    topObjetivos
    (vendedorCodigo: $vendedorCodigo, empresa: $empresa) 
    {
      clienteCodigo
      vendedorCodigo
      fecha
      objetivo
    }
  }
`;
