import { apolloSeguimiento } from '../../plugins/apollo-seguimiento';
import gql from 'graphql-tag';

export const GET_CONFIGURACION_GQL = gql`
  query GetConfiguracion($key: String!) {
    configuracion: getConfiguracion(key: $key) {
      valor
    }
  }
`;

const getConfiguracion = (key) =>
  apolloSeguimiento.query({ query: GET_CONFIGURACION_GQL, variables: { key }, fetchPolicy: 'network-only' });

export const configuracionService = {
  getConfiguracion,
};
