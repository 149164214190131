import { instance as axios } from '../../plugins/axios';

const statistics = (code, date) => axios.post('/vendors/statistics', {code, date});
const dashboardInfo = (code) => axios.post('/vendors/dashboard_info', {code});
const getInfo = (code) => axios.post('/vendors/vendor_info', {code});
const ticajeOcean = (vendorCode) => axios.post('/vendors/ticaje_ocean', { vendorCode });
const associatedClient = (code) => axios.post('/vendors/associated_client', { code });

export const vendorsService = {
  statistics,
  dashboardInfo,
  getInfo,
  ticajeOcean,
  associatedClient,
};
