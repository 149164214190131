export const it = {
  contratoAparatologia: 'CONTRATO APARATOLOGÍA',
  WARNING_CONTRAREEM: 'Non è possibile effettuare rimborsi superiori a 1000€.',

  alert: 'Alerta',
  abono_contrareembolso_no_permitido: 'No puedes casar un abono con el pedido si la forma de pago no es giro o contado.',
  nuevo_partner_hcc: 'Nuevo Partner HCC',
  inscribir_como_partner_hcc: 'Inscribir como Partner HCC',

  cif_vacio_oferta: 'Como el cliente no tiene CIF tienes que introducir un comentario obligatoriamente, si te lo sabes ponlo en los comentarios y si no escribe algo',
  revisado_y_sin_info: 'Orden rev. y sin info',
  ps_nuevo: '¡NUEVO!',
  ps_pend_servir_texto_card: 'Haz click aqui para revisar tu pendiente de servir',
  real: 'Real',
  aprox: 'Aprox',
  llegada: 'Llegada',
  hace_x_minutos: 'Hace {x} minutos',
  ver_por_producto: 'Ver por producto',
  ps_info: 'Las fechas de disponibilidad son orientativas. Si necesitáis más información de los productos que no están sirviendose y no tienen fecha de disponibilidad consultar con administración.',
  ps_productos_envasandose: 'Productos envasándose',
  ps_no_productos_envasandose: 'No hay productos envasandose de tu pendiente de servir',
  productos_pendientes_servir: 'Productos pendientes de servir',
  sirviendose: 'Sirviendose',
  enRevision: 'En revisión',
  ps_clientes_envasando: 'Los siguientes clientes tienen producto que se está envasando.',
  disponible: 'Disponible',
  pedidos: 'Pedidos',

  nuevo_email: 'Nuevo email',
  email_actual_es: 'El email actual del cliente es {email} ¿es correcto?:',
  factura_a_email: 'ATENCIÓN: El cliente tiene un correo validado, y su factura electrónica se enviará a {email}:',
  datos_correctos: '¿Son correctos estos datos?',
  cliente_no_encontrado: 'No encontrado',
  cliente_click_cambiar: 'Parece que el cliente que tenías seleccionado ha desaparecido, haz click aqui para cambiar a otro cliente',
  email_validado: 'El email del cliente está validado',
  email_no_validado: 'El email del cliente no está validado, no podrá recibir facturas electrónicas',
  validar_email: 'Validar email',
  leyenda: 'Leggenda',
  oferta_pendiente: 'Oferta pendiente',
  exito: 'Éxito',
  contrasena_cambiada: 'La contraseña se ha cambiado correctamente',
  hace_x_horas: 'Hace {x} horas',
  hace_x_dias: 'Hace {x} dias',
  no_pendiente_servir: 'No hay pendiente de servir',
  codigo_cliente: 'Código cliente',
  nombre_cliente: 'Nombre cliente',
  ascendente: 'Ascendente',
  descendente: 'Descendente',
  fecha: 'Fecha',
  num_expedicion: 'Nº expedición',
  num_documento: 'Nº documento',
  no_expediciones_2_semanas: 'No hay expediciones de menos de 2 semanas',
  no_facturas_anteriores: 'No se han encontrado facturas anteriores',
  no_abonos_anteriores: 'No se han encontrado abonos anteriores',
  num_facturas: 'Nº factura',
  num_vendedor: 'Nº vendedor',
  fecha_factura: 'Fecha factura',
  num_cliente: 'Nº cliente',
  forma_pago: 'Forma pago',
  facturar_a: 'Facturar a',
  fecha_pedido: 'Fecha pedido',
  direccion_envio: 'Dirección envío',
  num_pedido: 'Nº pedido',
  base: 'Base',
  importe_iva: 'Importe IVA',
  total_iva_inc: 'Total IVA incluido',
  importe_base: 'Importe base',
  porc_iva: '% IVA',
  porc_re: '% RE',
  importe_re: 'Importe RE',
  dto_factura_pagos: 'Dto. Factura y pagos',
  no_facturas_mes: 'No se han encontrado últimas facturas de este mes',
  no_facturas_pendientes: 'No hay facturas pendientes de ningún cliente',
  seleccionar_mes: 'Seleccionar mes',
  seleccionar_ano: 'Seleccionar año',
  pago: 'Pago',
  factura: 'Factura',
  impago: 'Impago',
  total_pendiente: 'Total pendiente',
  cobros_pendientes: 'Cobros pendientes',
  sin_explicar_comentario: 'Sin explicar, por favor manda un comentario',
  importe_pendiente: 'Importe pendiente',
  saldo_acumulado: 'Saldo acumulado',
  documentos_pendientes: 'Docs. pendientes',
  tipo_doc: 'Tipo doc',
  importe_original: 'Importe original',
  num_contrato: 'Nº contrato',
  tipo_contrato: 'Tipo contrato',
  importe_contrareembolso: 'Importe contrareembolso',
  anadir_comentario: 'Añadir comentario',
  cerrar: 'Cerrar',
  introduce_comentario: 'Introduce un comentario',
  aun_no_comentarios: 'Aún no hay comentarios',
  client_no_cuenta: 'El cliente no tiene ningún nº de cuenta',
  actualizar_email: 'Actualizar email',
  atencion_factura_electronica: 'ATENCIÓN: A partir de ahora se enviará una factura electrónica, tienes que comprobar que el email del cliente es correcto, y si no, enviar el nuevo email',
  cliente_no_email_asociado: 'El cliente no tiene ningún email asociado ¿Es correcto?',
  email_cliente_incorrecto: 'El email del cliente NO ES CORRECTO',
  email_cliente_correcto: 'El email del cliente es correcto',
  introduce_nuevo_email: 'Introduce el nuevo email',
  email_no_valido: 'El email no es un email válido',
  email_verificacion_enviado: 'Se ha mandado un mensaje de verificación al correo proporcionado',
  cancelar: 'Cancelar',
  no_puedes_girar: 'No puedes girarle a este cliente porque no tiene una cuenta bancaria asociada. Por favor, contacta con administración para añadirle una cuenta bancaria y vuélvelo a intentar. Recuerda que la aplicación se actualiza por las noches',
  informacion: 'Información',
  eliminar_promocion: 'Eliminar promoción',
  que_quieres_hacer: '¿Qué quieres hacer?',
  eliminar_todo: 'Eliminar todo',
  eliminar_solo_cabecera: 'Eliminar solo cabecera',
  eliminar_cabecera_poner_precios: 'Eliminar cabecera y poner precios base',
  buscar_direccion: 'Buscar dirección',
  volver_hacer_pedido: 'Volver a hacer pedido',
  no_giros_mostrar: 'No hay giros que mostrar',
  son_correctos_datos: '¿Son correctos estos datos?',
  puedes_cambiar_mes_ano: 'Puedes cambiar mes y año',
  obtener_facturas: 'Obtener facturas',
  mes: 'Mes',
  ano: 'Año',
  desde_el: 'Desde el',
  hasta_el: 'Hasta el',
  total_ventas: 'Total ventas',
  total_impagos: 'Total impagos',
  total_cobros_impagos: 'Total cobros impagos',
  buscar_en_documentos: 'Buscar en los documentos',
  ventas_abonos_anulaciones: 'Ventas-Abonos-Anulaciones',
  impagos: 'Impagos',
  cobros_impagos: 'Cobros impagos',

  ACT_CANTIDAD: 'Actualizar cantidad',
  ACT_PRECIO_UNIDAD: 'Actualizar precio unidad',
  ACT_DESC: 'Actualizar descuento',
  ACT_GRATIS: 'Actualizar gratis',
  LINEAS_BONIFICADAS: 'Líneas bonificadas',
  CERRAR: 'Cerrar',
  GUARDAR: 'Guardar',

  newClient: {
    cifError: 'Tienes que rellenar el campo NIF/CIF',
    nameError: 'Tienes que rellenar el campo Nombre',
    addressError: 'Tienes que rellenar el campo Dirección',
    stateError: 'Tienes que rellenar el campo Población',
    cityError: 'Tienes que rellenar el campo Provincia',
    cpError: 'Tienes que rellenar el campo Código Postal',
    contactError: 'Tienes que rellenar el campo Pers. Contacto',
    closeDaysError: 'Tienes que rellenar el campo Días cierre',
    emailError: 'Tienes que rellenar el campo Email',
    phoneError: 'Tienes que rellenar el campo Teléfono o el campo Móvil',
    infoFill: 'Vas a pasar los datos un pedido para un Cliente Nuevo, ahora tienes que rellenar los datos.' +
      'Pulsa en el siguiente botón para rellenarlos.',
    fillData: 'Rellenar datos cliente',
  },

  errores: 'Fautes',
  aceptar: 'Aceptar',
  enviar: 'Enviar',
  actualizarPagina: 'Actualizar Página',
  topClientes: 'Top Clientes',
  actualizacion: 'Actualizacion',
  vamosAActualizar: 'Vamos a actualizar',
  relevancia: 'Relevancia',
  masVisitados: 'Más visitados',
  novedades: 'Novedades',
  TAHE_FRANCIA: 'Tahe EU',
  informacionYMetodos: 'Información y métodos de pago',
  resumenPedido: 'Resumen del pedido',
  cliente: 'Cliente',
  fechaDeOferta: 'Fecha de la oferta',
  empresaYCanal: 'Empresa y canal de venta',
  direccionEnvio: 'Dirección de envío',

  'Relevancia': 'Rilevanza',
  'Más visitados': 'Più visitati',
  'Novedades': 'Novità',
  reporteDescendente: 'Rapporto (Decrescente)',
  reporteAscendente: 'Rapporto (Crescente)',
  fechaDescendente: 'Data (Decrescente)',
  fechaAscendente: 'Data (Crescente)',
  codigoClienteDescendente: 'Codice Cliente (Decrescente)',
  codigoClienteAscendente: 'Codice Cliente (Crescente)',
  importeAscendente: 'Importo (Crescente)',
  importeDescendente: 'Importo (Decrescente)',
  noDocumentoAscendente: 'N. documento (Crescente)',
  noDocumentoDescendente: 'N. documento (Decrescente)',
  totalEquipos: 'Totale per gruppo',
  estadoPedidos: 'Stato dell`ordine',
  total: 'Totale',
  pendientes: 'In attesa di',
  porAprobar: 'Essere approvazione',
  pendientesAprobar: 'In attesa di approvazione',
  enviadas: 'Inviato',
  impTotal: 'Totale',
  impPendiente: 'Imp. in sospeso',
  impAprob: 'Imp. da approvare',
  impEnv: 'Imp. Inviato',
  equipo: 'Gruppo',
  jefeEquipo: 'Guida del gruppo',
  distribuidor: 'Distributore',
  ofertaPendiente: 'Ordine in Attesa',
  pendienteAprobar: 'In attesa di approvazione',
  pendienteAprobarEquipos: 'In attesa di approvazione del distribuitore', // per il
  editarCliente: 'Modifica cliente selezionato',
  pages: {
    updatePage: 'Aggiornare pagina',
    home: 'Menú Principale',
    dashboard: 'Pannello di Controllo',
    distributors: 'Distributori',
    catalog: 'Catalogo',
    reports: 'Rapporti giornalieri',
    statistics: 'Statistiche',
    addPreClient: 'Aggiungere precliente',
    addDistributorClient: 'Aggiungere cliente',
    approvalOrders: 'Confermare ordini',
    approvalRefunds: 'Confermare resi',
    approvalCharges: 'Confermare addebiti',
    pendingOrder: 'Ordine',
    previousOrders: 'Ordini precedenti',
    pendingCharge: 'Addebito pendente',
    previousCharges: 'Pagamenti precedenti',
    pendingRefund: 'Reso pendente',
    previousRefunds: 'Resi precedenti',
    clientManagement: 'Gestione del Cliente',
    vendorManagement: 'Informazioni del Venditore',
    extracts: 'Movimenti del cliente',
    bills: 'Fatture',
    billsRefunds: 'Note di Credito',
    billsInvoices: 'Fatture',
    soldProducts: 'Prodotti venduti',
    expeditions: 'Spedizioni',
    expenses: 'Spese',
    incomes: 'Versamenti',
    pendingToShip: 'Invio pendente',
    clientsContracts: 'Contratti',
    refundsStatus: 'Stato Resi',
    commissions: 'Commissioni',
    settings: 'Impostazioni',
    changelog: 'Novità',
    faqStores: 'Aiuto negozio parrucchiere',
    faq: 'Aiuto',
    notFound: 'Non trovato',
    vendorSelection: 'Selezione del Venditore',
    clientSelection: 'Selezione del Cliente',
    sellChannelSelection: 'Canale di Vendita',
    clientPendingOrder: 'Ordine pendente',
    clientPreviousOrders: 'Ordini precedenti',
    clientPendingCharge: 'Pagamento pendente',
    clientPreviousCharges: 'Pagamenti precedenti',
    clientPendingRefund: 'Reso pendente',
    clientPreviousRefunds: 'Resi precedenti',
    clientPendingToShip: 'Invio pendente',
    clientExpeditions: 'Spedizioni',
    clientGiros: 'Addebiti',
    clientPendingBills: 'Fatture pendenti',
    clientObjectives: 'Obiettivi',
    clientContracts: 'Contratti',
    clientExtracts: 'Movimenti',
    clientBills: 'Fatture',
    clientBillsRefunds: 'Note di Credito',
    productDetails: 'Prodotto',
    notFoundClient: 'Cliente non trovato',
    refundStatusDetails: 'Stato del Reso',
    searchRefundProduct: 'Cercare prodotto da rendere',
    orderDetails: 'Dettagli dell’ordine',
    productBills: 'Fatture prodotti',
    checkoutCharge: 'Inviare pagamento',
    newClientDetails: 'Dettagli del nuovo cliente',
    newIncome: 'Nuovo incasso',
    newExpense: 'Nuova spesa',
    checkoutOrder: 'Inviare ordine',
    newDistributorClients: 'Nuovo cliente',
    editDistributorClients: 'Modifica cliente',
    employees: 'Dipendenti',
    users: 'Utenti',
  },
  menus: {
    menu: 'Menú',
    mainMenu: 'Menú Principale',
    favoritesMenu: 'Preferiti',
    clientManagementMenu: 'Gestione del cliente',
  },
  buttons: {
    add: 'Aggiungere',
    changePassword: 'Cambiare password',
    copyToOtherClient: 'Copiare ad altro cliente',
    moveToOtherClient: 'Spostare ad altro cliente',
    duplicate: 'Duplicare',
    copy: 'Copiare',
    checkInOcean: 'Entrare in Ocean',
    addBillManual: 'Aggiungere fattura manualmente',
    addRemaining: 'Aggiungere resto',
    addChargeDate: 'Aggiungere data di addebito',
    save: 'Salvare',
    ok: 'OK',
    update: 'Aggiornare',
    search: 'Cercare',
    close: 'Chiudere',
    logout: 'Uscire',
    searchClient: 'Cercare Cliente',
    searchProductInBills: 'Cercare nella Fattura',
    back: 'Indietro',
    cancel: 'Cancellare',
    login: 'Entrare',
    clean: 'Annullare',
    confirm: 'Confermare',
    findClient: 'Trovare cliente',
    updateEmail: 'Aggiornare Email',
    addToOrder: 'Aggiungere all’ordine',
    delete: 'Cancellare',
    addIncome: 'Aggiungere incasso',
    addExpense: 'Aggiungere spesa',
    checkoutOrder: 'Inviare ordine',
    selectAll: 'Selezionare tutto',
    activateSort: 'Attivare ordinamento',
    deactivateSort: 'Disattivare ordinamento',
    addCharge: 'Aggiungere Addebito',
    createAdvice: 'Generare Acconto',
    addPendingBill: 'Aggiungere Pagamento',
    accept: 'Accettare',
    done: 'Fatto',
    getObjective: 'Ottenere Obiettivo',
    getSoldProducts: 'Ottenere prodotti venduti',
    updateWebProduct: 'Aggiornare Prodotto',
    updateWebCategory: 'Aggiornare Categoria',
    addProduct: 'Aggiungere prodotto',
    searchInBills: 'Cercare nelle fatture',
    sendRefund: 'Inviare reso',
    showAccount: 'Vedere Nº Conto',
    addObjective: 'Aggiungere obiettivo',
    addPreclient: 'Aggiungere Precliente',
    checkoutCharge: 'Inviare Addebito',
    deleteSelected: 'Cancellare selezionati',
    send: 'Inviare',
    refund: 'Rimborsare',
    refundAll: 'Rimborsare tutto',
    reshopAll: 'Comprare di nuovo tutto',
    reshop: 'Ricomprare',
    newUser: 'Nuovo utente',
  },
  popups: {
    globalSearch: 'Cerca',
  },
  inputs: {
    password: { label: 'Password', placeholder: 'La tua password attuale' },
    newPassword: { label: 'Nuova password', placeholder: 'La nuova password' },
    newPasswordConfirmation: { label: 'Ripeti nuova password', placeholder: 'Ripeti nuova password' },
    searchClient          : { label: 'Cliente'                , placeholder: 'Cercare il cliente per nome, ID del cliente o P.I.' },
    searchProductInBills  : { label: 'Prodotto'               , placeholder: 'Cercare un prodotto per codice o per nome' },
    searchMainMenu        : { label: 'Cercare'                 , placeholder: 'Cosa vuoi fare?' },
    searchProduct         : { label: 'Cercare Prodotto'        , placeholder: 'Cerca un prodotto per codice, nome...' },
    searchFaq             : { label: 'Cercare in Aiuto'        , placeholder: 'Cercare in Aiuto' },
    searchOption          : { label: 'Cercare Opzione'          , placeholder: 'Filtra opcioni per nome o codice' },
    searchVendorCode      : { label: 'Cercare Codice Venditore' , placeholder: 'Cercare venditore per codice, nome...' },
    searchReports         : { label: 'Cercare rapporto'         , placeholder: 'Cercare un rapporto per nome o codice del cliente...'},
    motive                : { label: 'Motivo'                 , placeholder: 'Inserire una breve descrizione' },
    advice                : { label: 'Quantità'               , placeholder: 'Inserire l’importo dell’acconto' },
    comment               : { label: 'Note'             , placeholder: 'Scrivi qui le tue note se vuoi inserire informazioni aggiuntive' },
  },
  login: {
    title: 'Cosmet',
    username: { label: 'Utente', placeholder: 'Il tuo nome utente' },
    password: { label: 'Password', placeholder: 'La tua password' },
    information: {
      line1: `Accedi con il tuo nome utente e password per entrare in Cosmet<br><strong>(Antiguo OferTAHE y OferTH)</strong>`,
      line2: `Cosmet &copy; {year} {version}`,
    }
  },
  info: {
    selectAddressFill: 'Selecciona una dirección de envío',
    cardProduct: 'Saranno visualizzate le schede prodotti',
    listProduct: 'Sarà visualizzata la lista prodotti',
    contactWithRRHH: 'Se hai domande o problemi si prega contattare le Risorse Umane e non l’admin dell’applicazione',
    noBillsFound: 'Non sono stati trovate fatture',
    searchRefundProducts: 'Prima cerca il prodotto che vuoi rendere',
    addChargeDates: 'Selezionare l’importo da addebitare e la data desiderata e cliccare su aggiungere pagamento',
    selectPaymentFormFill: 'Scegliere una forma di pagamento',
    selectPaymentTermFill: 'Scegliere un termine di pagamento',
    noFavoritesMenu: 'Non hai ancora aggiunto nessun link ai preferiti. Prova a cliccare su una delle stelle' +
      '<icon class="icon f7-icons" style="font-size: 20px">star</icon> dei link del Menú.',
    searchResults: 'Risultati della ricerca',
    isSelectedCode: 'Hai selezionato questo codice e questo canale di vendita',
    newClientUse: 'Utilizza questo cliente per aggiungere un ordine a un cliente nuovo o un precliente',
    noClientsFound: 'Ops! Nessun cliente trovato con questa ricerca',
    noCodesFound: 'Nessun codice trovato con la tua ricerca',
    clientConfirm: 'Sei sicuro di voler modificare questo cliente?',
    clientConfirmNew: 'Vuoi modificare il CLIENTE NUOVO (Codice: 1)?',
    notFoundClient: 'Se il cliente è stato aggiunto oggi, é normale che non sia ancora visibile, ma tranquillo! Se conosci il codice cliente puoi aggiungerlo da qui' +
      'ricorda però che le informazioni come note contrattuali, indirizzi aggiuntivi, ecc,... saranno visibili domani. Comunque, se hai bisogno di fare un ordine, reso ' +
      'o pagamento prova ad aggiungerlo tu stesso ora, senza aspettare domani',
    noPromoProducts: 'Non c’è una lista fissa di materiali',
    orderLines: 'Righe dell’Ordine',
    sampleLines: 'Campioni dell’Ordine',
    incidentLines: 'Eventi dell’Ordine',
    noOrderLines: 'Non hai ancora aggiunto righe all’ordine',
    noRefundLines: 'Non hai ancora aggiunto righe al reso',
    noSampleLines: 'Non hai ancora aggiunto campioni all’ordine',
    noIncidentLines: 'Non ci sono righe di eventi nell’ordine',
    chargeFor: 'Addebito per',
    noPendingCharge: 'Non hai ancora aggiunto righe al pagamento. Clicca sul pulsante per vedere gli addebiti pendenti del cliente.',
    refundFor: 'Reso per',
    noPendingRefund: 'Non hai ancora aggiunto righe al reso. Clicca sul pulsante per cercare un prodotto.',
    noPendingBills: 'Il cliente non ha fatture pendenti',
    noPreviousOrders: 'Non sono stati trovati ordini precedenti',
    noPendingOrders: 'Non sono stati trovati ordini pendenti',
    noPreviousRefunds: 'Non sono stati trovati resi precedenti',
    noPendingRefunds: 'Non sono stati trovati resi pendenti',
    noPreviousCharges: 'Non sono stati trovati addebiti precedenti',
    noPendingCharges: 'Non sono stati trovati addebiti pendenti',
    selectVendorCode: 'Seleziona un codice venditore',
    productsSoldInfo: 'Il calcolo dei prodotti venduti si ottiene prendendo i prodotti fatturati e sottraendo i prodotti resi',
    noSoldProductsFound:  'Non sono stati trovati prodotti venduti per quel cliente o per quell’intervallo di date',
    noApprovalOrders: 'Non ci sono ordini pendenti da confermare',
    noApprovalRefunds: 'Non ci sono resi pendenti da confermare',
    noApprovalCharges: 'Non ci sono addebiti pendenti da confermare',
    noExpeditions: 'Non ci sono spedizioni da 2 settimane',
    refundLineManual: 'Questo prodotto è associato manualmente alla fattura, è necessario indicare il motivo',
    refundLineQuantityExceeded: 'Hai inserito un importo superiore a quello della fattura, è necessario indicare il motivo',
    refundLineNoAbonable: 'Il prodotto ha superato il tempo concesso per il reso, è necessario indicare il motivo',
    isRefundLinesFilled: 'Hai indicato tutti i motivi del reso  di OGNI prodotto?',
    noRefundForNewClient: 'Non hai selezionato alcun cliente esistente, seleziona un cliente per inviargli un reso',
    noChargeForNewClient: 'Non hai selezionato alcun cliente esistente, seleziona un cliente per inviargli un addebito',
    noAccountFound: 'Non è stato trovato il nº di conto del cliente',
    noComments: 'Il cliente non ha annotazioni',
    noPendingToShip: 'Non ci sono pendenze da inviare',
    noGiros: 'Non ci sono addebiti da mostrare',
    noReports: 'Non sono stati trovati rapporti',
    fillFieldsAndSave: 'Compila i campi della parte inferiore, e clicca su salvare',
    passwordSuccessfullChanged: 'La password è stata modificata correttamente',
    revisitTour: 'Deseleziona questa opzione se vuoi rivedere il tour.',
    hasKeyboard: 'Se non lavori con una tastiera, deseleziona questa opzione per avere una calcolatrice tattile.',
    noIncomes: 'Non sono stati trovati incassi precedenti, clicca sul pulsante qui sopra per aggiungere un nuovo incasso',
    noExpenses: 'Non sono stati trovate spese precedenti, clicca sul pulsante qui sopra per aggiungere una nuova spesa',
    noPreviousBills: 'Non sono stati trovate fatture precedenti',
    noProductsFound: `Non sono stati trovati prodotti con la ricerca <em>{0}</em>`,
  },
  text: {
    vendorId: '',
    underConstruction: 'In costruzione',
    suggestions: 'Forse volevi dire',
    lastUpdate: 'Ultima versione',
    added: 'aggiunto',
    addressSelection: 'Selezionare indirizzo',
    send: 'Inviare',
    type: 'Tipo',
    importWithImp: 'Importo (imp. incl.)',
    changePassword: 'Cambiare password',
    accesibility: 'Accessibilità',
    hasKeyboard: 'Lavoro con tastiera',
    tour: 'Tour completato',
    languages: 'Lingue',
    catalog: 'Catalogo',
    card: 'Scheda',
    list: 'Lista',
    bonifyItems: 'Art. omaggio',
    promoCount: 'Nº Promozioni',
    editSelected: 'Modificare selezione',
    copySelected: 'Copiare selezione',
    duplicateSelected: 'Duplicare selezione',
    deleteSelected: 'Eliminare selezione',
    seeBills: 'Vedere Fatture',
    dates: 'Date',
    remainingIVAInc: 'Residuo (IVA incl)',
    companyAndSellChannel: 'Azienda e Canale di Vendita',
    numCheck: 'Nº Assegno',
    paymentDates: 'Date di Pagamento',
    addChargeDates: 'Aggiungere data di addebito',
    paymentFormSelection: 'Modalità di Pagamento',
    paymentTermSelection: 'Termini di Pagamento',
    orderDate: 'Data Ordine',
    abonable: 'Ammesso il reso',
    sellChannel: 'Canale di Vendita',
    news: 'Novità',
    info: 'Informazioni',
    infoShort: 'Info',
    importantInfo: 'Informazione importante',
    new: 'Nuovo',
    soldOut: 'Esaurito',
    products: 'Prodotti',
    navigation: 'Navigazione',
    favorites: 'Preferiti',
    lines: 'Linee',
    categories: 'Categorie',
    first: 'Prima',
    last: 'Ultima',
    seeOptions: 'Vedere Opzioni',
    price: 'Prezzo',
    of: 'di',
    user: 'Utente',
    codes: 'Codici',
    newClient: 'NUOVO CLIENTE',
    cif: 'P.I.',
    confirm: 'Confermare',
    notFoundClient: 'Non trovato',
    comments: 'Note',
    cod: 'Cod',
    orderBy: 'Ordinare per',
    code: 'Codice',
    description: 'Descrizione',
    quantity: 'Quantità',
    unitsPer: 'unità per',
    total: 'Totale',
    totalQuantities: 'Unità',
    discount: 'Sconto',
    actions: 'Azioni',
    addWithDiscount: 'Aggiungere con sconto',
    productItems: 'Articoli',
    import: 'Importo',
    orders: 'Ordini',
    refunds: 'Resi',
    charges: 'Addebiti',
    expenses: 'Spese',
    newClients: 'Clienti nuovi',
    income: 'Incasso',
    yes: 'Si',
    no: 'No',
    numb: 'Nº',
    date: 'Data',
    reportId: 'Nº di rapporto',
    addProduct: 'Aggiungere Prodotto',
    addByCode: 'Aggiungere per codice',
    searchProduct: 'Cercare Prodotto',
    order: 'Ordine',
    samples: 'Campioni',
    incidents: 'Eventi',
    ivaInc: '+ (IVA inc.)',
    freeItems: 'Art. Omaggio',
    numPromos: 'Nº Promozioni',
    selec: 'Selez',
    impSelect: 'Imp. Selez',
    pendingBills: 'Fatture Pendenti',
    company: 'Azienda',
    postingDate: 'Data di Registrazione',
    dueDate: 'Data di Scadenza',
    documentNo: 'Nº Documento',
    paymentForm: 'Forma di Pagamento',
    paymentMethod: 'Modalità di Pagamento',
    paymentTerm: 'Termini di Pagamento',
    amount: 'Importo',
    remainingAmount: 'Importo Pendente',
    totalContrareembolso: 'Contrassegno',
    unfinished: 'Incompiuto',
    paymentDate: 'Data di Pagamento',
    status: 'Stato',
    statuses: 'Stati',
    client: 'Cliente',
    clients: 'Clienti',
    from: 'Da',
    to: 'A',
    fromDate: 'Dalla Data',
    toDate: 'Alla Data',
    item: 'articolo',
    items: 'articoli',
    update: 'Aggiornare',
    statistics: 'Statistiche',
    pendingOrders: 'Ordini Pendenti',
    previousOrders: 'Ordini Precedenti',
    pendingRefunds: 'Resi Pendenti',
    previousRefunds: 'Resi Precedenti',
    pendingCharges: 'Addebiti Pendenti',
    previousCharges: 'Addebiti Precedenti',
    vendorCodeSelection: 'Cod. Venditore',
    selectForStatistics: 'Puoi cambiare mese e anno',
    month: 'Mese',
    year: 'Anno',
    objective: 'Obiettivo',
    billed: 'Fatturato',
    ideal: 'Ideale al giorno',
    cumplimentObj: 'di obiettivo raggiunto',
    numPacks: 'Colli',
    appliesToDoc: 'Riferito a',
    dateRange: 'Intervallo di date',
    selectDate: 'Seleziona la data',
    pendingItems: 'Unità pendenti',
    units: 'Unità',
    reviser: 'Revisore',
    quant: 'Quant.',
    unitPrice: 'Prezzo',
    totalImport: 'Totale',
    totalPromo: 'Totale Promo',
    totalIVA: '+ (IVA inc.)',
    free: 'Omaggio',
    sample: 'Campione',
    incident: 'Evento',
    bill: 'Fattura',
    bills: 'Fatture',
    motive: 'Motivo',
    information: 'Informazione',
    select: 'Selezionato',
    comment: 'Note',
    account: 'Nº Conto',
    warnings: 'Avvisi',
    disc: 'Sconto. %',
    discImp: 'Imp. Sc.',
    promo: 'Promo',
    lastBills: 'Ultime Fatture',
    lateBills: 'Saldi Arretrati',
    report: 'Rapporto',
    vendor: 'Venditore',
    share: 'Condividere con',
    shareTelegram: 'Telegram',
    shareWhatsapp: 'Whatsapp',
    shareEmail: 'Posta elettronica',
    download: 'Scaricare',
    searchResult: 'Risultati per',
    totalPending: 'Totale Pendente',
    createAdvice: 'Creare acconto',
    advance: 'Acconto',
    moveNo: 'Movimento',
    toLiquid: 'Da Saldare',
    pendingImport: 'Imp. Pendente',
    selectedImport: 'Imp. Selezionato',
  },
  fnText: {
    showResults: 'Mostrando ({0} - {1}) di {2} risultati',
    total: 'Totale senza IVA {0}€',
    totalCash: 'Contanti {0}€',
  },
  filters: {
    lines: 'Righe',
  },
  sellChannel: {
    TAHE_ESPANA:        'Tahe Spagna',
    TAHE_DISTRIBUTOR:   'Tahe Distributore',
    TAHE_DISTRIBUIDOR:   'Tahe Distributore',
    TAHE_CANARIAS:      'Tahe Canarie',
    TAHE_PORTUGAL:      'Tahe Portogallo',
    TAHE_ITALIA:        'Tahe Italia',
    TAHE_INTERNACIONAL: 'Tahe Internazionale',
    TAHE_OUTLET:        'Tahe Outlet',
    TH_ESPANA:          'Th Pharma Spagna',
    TH_PORTUGAL:        'Th Pharma Portogallo',
    TH_ITALIA:          'Th Pharma Italia',
    TH_INTERNATIONAL:   'Th Pharma Internazionale',
    TH_INTERNACIONAL:   'Th Pharma Internazionale',
    TH_OUTLET:          'Th Pharma Outlet',
    MER_ESPANA:         'Mercacosmética Spagna',
    DVF_ESPANA:         'DV Farmaceutic Spagna',
    KELAYA_ESPANA:      'KELAYA España',
    KELAYA_CANARIAS:    'KELAYA Canarias',
    KELAYA_PORTUGAL:    'KELAYA Portugal',
    BOCARE_ESPANA:      'BOCARE España',
    BOCARE_CANARIAS:    'BOCARE Canarias',
    BOCARE_PORTUGAL:    'BOCARE Portugal',
  },
  status: {
    PENDING:              'Pendente',
    PENDING_TO_SEND:      'Invio Pend.',
    PENDING_APPROVAL:     'Da confermare',
    PENDING_APPROVAL_DIST: 'Da Confermare Dist.',
    SENDED:               'Inviato',
    IMPORT_ERROR:         'Errore Importazione',
    RECEIVED:             'Ricevuto',
    ACCEPTED:             'Accettato',
    CANCELED:             'Cancellato',
    UNKNOWN:              'Sconosciuto',
    OMNI_RECEIVED:        'Inviato',
    ERROR_IMPORTACION:    'Ricez. Pend.',
  },
  company: {
    TAHE: 'Tahe',
    TH_PHARMA: 'Th Pharma',
    TAHE_2016: 'Tahe',
    TH_PHARMA_2016: 'Th Pharma',
    MERCACOSMETICA: 'Mercacosmética',
    DV_FARMACEUTIC: 'DV Farmaceutic',
    'NEO-DEPIL LASER': 'Neo Depil Láser',
    'MAGAÑA': 'Tahe Productos Cosméticos S.L.',
    'Tahe Productos Cosméticos S_L_': 'Tahe Productos Cosméticos S.L.',
    'TH Pharma S_L_': 'Th Pharma',
  },
  packing: {
    BOX: 'scatola',
    PACK: 'pallet',
  },
  paymentForm: {
    CONTADO: 'Contanti',
    GIRO: 'Addebito',
    CONTRAREEM: 'Contrassegno',
    GIROPT: 'Addebito',
    CONTRARPOR: 'Contrassegno',
    CHEQUEPT: 'Assegno',
    TARJETAPT: 'Carta di Credito',
    CONTADOPT: 'Contratto Assegno',
    'TARJ OUTLE': 'Carta Outlet',
    TRANSFPORT: 'Bonifico',
    TRANSFERIT: 'Bonifico',
    EFECTIVO: 'Contanti',
    TARJETA: 'Carta',
    GIROCOBRO: 'Addebito',
    CHEQUE: 'Assegno',
    DESCUENTO: 'Sconto',
  },
  paymentTerm: {
    PERSONALIZADO: 'Personalizzato',
    '1X7': 'Addebito 7 giorni',
    '1X15': 'Addebito 15 giorni',
    '1X20': 'Addebito 20 giorni',
    '1X21': 'Addebito 21 giorni',
    '1X30': 'Addebito 30 giorni',
    '1X40': 'Addebito 40 giorni',
    '1X45': 'Addebito 45 giorni',
    '1X60': 'Addebito 60 giorni',
    '1X75': 'Addebito 75 giorni',
    '1X90': 'Addebito 90 giorni',
    '2X7': 'Addebito 7 e 30 giorni',
    '2X15': 'Addebito 15 e 30 giorni',
    '2X20': 'Addebito 20 e 40 giorni',
    '2X30': 'Addebito 30 e 60 giorni',
    '2X45': 'Addebito 45 e 90 giorni',
    '2X45B': 'Addebito 45 e 60 giorni',
    '2X60': 'Addebito 60 e 90 giorni',
    '2X60X120': 'Addebito 60 e 120 giorni',
    '2X90': 'Addebito 90 e 120 giorni',
    '3X10': 'Addebito 10, 20 e 30 giorni',
    '3X15': 'Addebito 15, 30 e 45 giorni',
    '3X3': 'Addebito 45, 75 e 105 giorni',
    '3X30': 'Addebito 30, 60 e 90 giorni',
    '3X60': 'Addebito 60, 90 e 120 giorni',
    '4X30': 'Addebito 30, 60, 90 e 120 giorni',
    '4X3': 'Addebito 15, 60 e 90 giorni',
    '4X4': 'Addebito 15, 30, 60 e 90 giorni',
    '1X120': 'Addebito 120 giorni',
  },
  desconTime: {
    0: 'Non è il momento di guardare lo schermo, è il tuo momento. Rilassati!',
    1: 'Sole tramontato, riposo meritato.',
    2: 'Disconnettersi da tutto e riposare è necessario per condurre una vita sana',
    3: 'Momento di: Clienti ... Spostamenti ... Ordini ... Disconnettersi! <icon class="icon f7-icons text-color-green" style="font-size: 16px">checkmark_alt</icon>',
    4: 'Ora é tempo di stare con la tua famiglia: approfitta e goditela!',
    5: 'Ora inizia la tua giornata in famiglia!! Goditela!!',
    6: 'Per poter rendere al 100% nella tua giornata lavorativa hai bisogno di disconnetterti al 100%',
    7: 'Per essere produttivi bisogna riposare!! Buona giornata di riposo!!',
    8: 'Dopo il lavoro, goditi la tua famiglia, una vita sana e una buona lettura. Ti aiuterà a sentirti meglio',
    9: 'Fermati! Questo tempo è per te, dedicalo a te stesso!',
  },
  client: {
    code: 'Rapporto',
    mainAddress: 'Indirizzo Principale',
    navisionId: 'Codice',
    cif: 'P.I.',
    socialReason: 'Ragione Sociale',
    name: 'Nome',
    name2: 'Nome 2',
    closeDays: 'Giorno di chiusura',
    email: 'E-mail',
    phone: 'Telefono',
    mobile: 'Cellulare',
    fax: 'Fax',
    address: 'Indirizzo',
    address2: 'Indirizzo 2',
    city: 'Città',
    state: 'Provincia',
    postalCode: 'Codice Postale',
    country: 'Stato',
    clientComments: 'Note Cliente',
    contractComments: 'Note Contratto',
    info: 'Informazioni Cliente',
    addresses: 'Indirizzi',
    contact: 'Contatto',
    typeClient: 'Tipo di Cliente',
    newClient: 'Nuovo Cliente',
    surnameName: 'Cognome, Nome',
    bankAccount: 'Conto Banc.',
  },
  typeClient: {
    'Peluquería': 'Parrucchiere',
    'Estética': 'Estetica',
    'Estética+Peluquería': 'Estetica+Parrucchiere',
    'Barbería': 'Barbiere',
    'Otros': 'Altro',
  },
  country: {
    ES: 'Spagna',
    PT: 'Portogallo',
    IT: 'Italia',
  },
  errors: {
    networkError: 'Impossibile stabilire la connessione con il server. Si prega di contattare l’amministratore dell’applicazione',
    notVendorInTeam: 'Questo codice venditore non è nel tuo gruppo',
    dateExistError: 'Hai già inserito la data {0} alle scadenze',
    navisionError: 'Si è verificato un errore di connessione con Navision, é possibile che non ci sia connessione, riprovare più tardi',
  },
  serverErrors: {
    NAV_INACCESSIBLE: 'Cosmet non può accedere al server di Navision',
    NAV_UNKNOWN_ERROR: 'Si è verificato un errore sconosciuto durante il tentativo di connessione a Navision',
    SERVER_NOT_RESPONDING: 'Il server è molto occupato al momento. Si prega di riprovare più tardi',
    CHARGE_ALREADY_PROCESSED: 'Questa riga è già stata elaborata',
    AUTH_USER_BLOCKED: 'L’utente è bloccato, contattare un superiore per risolvere il problema',
    AUTH_PASSWORD_INCORRECT: 'La password non è corretta',
    LINE_ALREADY_ADDED: 'La riga é già stata trasmessa o è già stata aggiunta all’addebito in sospeso',
    'Errore interno del server': 'Il server sta avendo un problema, si prega di contattare l’amministratore dell’applicazione o riprovare più tardi',
  },
  statusCode: {
    999: 'Errore di connessione',
    200: 'Riuscito',
    201: 'Riuscito',
    404: 'Non trovato',
    401: 'Errore di autenticazione',
    422: 'Errore del server',
    499: 'Già presente',
    403: 'Già presente',
    500: 'Errore di connessione',
    503: 'Manutenzione',
  },
  success: {
    chargeLineAdded: 'Riga da addebitare aggiunta correttamente',
    categoryAdded: 'Categoria aggiunta correttamente',
    productAdded: 'Prodotto aggiunto correttamente',
  },
  prompt: {
    updateWebProduct: {
      text: 'Immettere l’ID del Prodotto in OpenCart',
      title: 'Prodotto Web',
    },
    updateWebCategory: {
      text: 'Immettere l’ID della Categoria in OpenCart',
      title: 'Categoria Web',
    },
    deleteCharges: {
      text: 'Sei sicuro di voler cancellare gli addebiti selezionati?',
      title: 'Confermare',
    }
  },
  warning: {
    mustAddObjective: 'Ricorda che è necessario immettere l’obiettivo annuale per questo cliente',
    emailMoreThanAWeek: 'L’E-mail non è stata confermata da più di 7 giorni',
    waitingForConfirm: 'In attesa di conferma dell’E-mail',
    notValidatedEmail: 'Il cliente ha una E-mail, ma non è ancora confermata. Si prega di inviargli una E-mail di conferma',
    notEmail: 'Il cliente non ha una E-mail. Per emettere fattura é necessario immettere una e-mail e farla convalidare dal cliente, avanti!',
  },
  languages: {
    es: 'Spagnolo',
    it: 'Italiano',
    pt: 'Portoghese',
  },
  ES: 'Spagna',
  PT: 'Portogallo',
  'MAGAÑA': 'Tahe',
  'TH PHARMA': 'Th Pharma',
  'NEO-DEPIL LASER': 'Neo Depil',
  TAHE: 'Tahe Productos Cosméticos S.L.',
  TAHE_2016: 'Tahe Productos Cosméticos S.L.',
  TH_PHARMA: 'Th Pharma',
  TH_PHARMA_2016: 'Th Pharma',
  MIXPROFESSIONAL: 'Mix Professional',
  DVFARMACEUTIC: 'DV Farmaceutic',
  TAHE_ESPANA: 'Tahe Spagna',
  TAHE_CANARIAS: 'Tahe Canarie',
  TAHE_PORTUGAL: 'Tahe Portogallo',
  TAHE_ITALIA: 'Tahe Italia',
  TH_ESPANA: 'Th Spagna',
  TH_PORTUGAL: 'Th Portogallo',
  TH_ITALIA: 'Th Italia',
  TAHE_INTERNACIONAL: 'Tahe Internazionale',
  TH_INTERNACIONAL: 'Th Internazionale',
  MIXP_ESPANA: 'Mix Professional Spagna',
  DVF_ESPANA: 'DV Farmaceutic Spagna',
  NEW_CLIENT: 'NUOVO CLIENTE',
  COMPANY: 'Azienda',
  SELL_CHANNEL: 'Canale di Vendita',
  USER: 'Utente',
  COMPANY_SELECTION: 'Selezione del Codice',
  CLIENT_SELECTION: 'Selezione del Cliente',
  DASHBOARD: 'Pannello di Controllo',
  REPORTS: 'Rapporti giornalieri',
  PENDING_PROPOSAL: 'Proposta per il Cliente',
  PREVIOUS_PROPOSALS: 'Stato delle proposte',
  PENDING_ORDER: 'Ordine Pendente',
  PENDING_APPROVAL_ORDERS: 'Ordini Pendenti da confermare',
  PENDING_APPROVAL_REFUNDS: 'Resi Pendenti da confermare',
  PREVIOUS_ORDERS: 'Ordini Precedenti',
  PENDING_CHARGE: 'Addebiti Pendenti',
  PREVIOUS_CHARGES: 'Addebiti Precedenti',
  PENDING_REFUND: 'Resi Pendente',
  PREVIOUS_REFUNDS: 'Resi Precedenti',
  CLIENT_MANAGEMENT: 'Informazioni del Cliente',
  VENDOR_INFO: 'Informazioni del Venditore',
  EXTRACTS: 'Movimenti del Venditore',
  BILLS: 'Fatture',
  BILLS_REFUNDS: 'Note di Credito',
  EXPEDITIONS: 'Spedizioni',
  EXPENSES: 'Spese',
  INCOMES: 'Incassi',
  PENDING_TO_SHIP: 'Pendente da inviare',
  REFUNDS_STATUS: 'Stato Resi',
  COMMISSIONS: 'Commissioni',
  SETTINGS: 'Impostazioni',
  CHANGELOG: 'Novità',
  FAQ_STORES: 'Aiuto Negozi Parrucchieri',
  FAQ: 'Aiuto',
  PROFILE: 'Profilo',
  BACK_LINK: 'Indietro',
  MENU: 'Menú',
  LOGOUT: 'Uscire',
  LOGIN: 'Entrare',
  CANCEL: 'Cancellare',
  NO_FAVORITES_MENU: 'Non hai ancora aggiunto nessun lunk ai preferiti. Prova a cliccare su una delle stelle <icon class="icon f7-icons" style="font-size: 20px">star</icon> dei link del Menú.',
  NO_RESULTS_FOUND: 'Non sono stati trovati risultati',
  COD: 'Cod',
  CIF: 'P.I.',
  PROPOSAL_FOR: 'Proposta per ',
  ORDER_FOR: 'Ordine per ',
  CHARGE_FOR: 'Addebito per ',
  REFUND_FOR: 'Reso per ',
  PENDING_PROPOSAL_EMPTY: 'Non hai ancora aggiunto alcuna riga alla proposta. Clicca sul pulsante per andare al catalogo.',
  PENDING_ORDER_EMPTY: 'Non hai ancora aggiunto alcuna riga all’ordine. Clicca sul pulsante per andare al catalogo.',
  PENDING_CHARGE_EMPTY: 'Non hai ancora aggiunto alcuna riga al pagamento. Clicca sul pulsante per vedere i pagamenti pendenti del cliente.',
  PENDING_REFUND_EMPTY: 'Non hai ancora aggiunto alcuna riga al reso. Clicca sul pulsante per cercare un prodotto.',
  SEARCH_CLIENT: 'Cercare Cliente per Codice, Nome, P.I...',
  SEARCH_VENDOR: 'Cercare Venditore per Codice, Nome...',
  SEARCH_PRODUCT: 'Cercare prodotto per Codice, Nome...',
  ADD_PRODUCT: 'Aggiungere Prodotto',
  ADD_CHARGE: 'Aggiungere addebito',
  SEARCH_IN_BILLS: 'Cercare nelle fatture',
  GENERATE_ADVICE: 'Generare Acconto',
  CATALOG: 'Catalogo',
  PRODUCTS: 'Prodotti',
  LINES: 'Righe',
  CATEGORIES: 'Categorie',
  IF_OPTION: 'In base all’opzione',
  PRICE: 'Prezzo',
  MAIN_MENU: 'Menú Principale',
  ORDERS: 'Ordini',
  REFUNDS: 'Resi',
  CHARGES: 'Addebiti',
  NEW_CLIENTS: 'Clienti',
  INCOME: 'Incasso',
  YES: 'Si',
  NO: 'No',
  REPORT_DETAILS: 'Rapporto {0}',
  REPORT_DETAILS_NOTFOUND: 'Nessun dato di rapporto trovato {0}',
  INFO: 'Informazioni',
  REPORT_NO: 'Rapporto Nº',
  DATE: 'Data',
  VENDOR: 'Venditore',
  IMPORT: 'Importo',
  TOTAL: 'Totale senza IVA {0}€',
  TOTAL_CASH: 'Contanti {0}€',
  ADD_PRECLIENT_INFO: 'Non hai selezionato alcun cliente esistente. Se vuoi aggiungere un cliente senza effettuare l’ordine clicca sul pulsante Aggiungere Precliente',
  ADD_PRECLIENT: 'Aggiungere Precliente',
  NEW_CLIENT_FORM: 'Formulario Nuovo Cliente',
  TYPE_CLIENT: 'Tipo Cliente',
  NIF_CIF: 'C.F./P.I.',
  NAME: 'Nome',
  NAME_2: 'Nome 2',
  NAME2: 'Nome 2',
  SOCIAL_REASON: 'Ragione Sociale',
  ADDRESS: 'Indirizzo',
  ADDRESS_2: 'Indirizzo 2',
  CITY: 'Città',
  STATE: 'Provincia',
  COUNTRY: 'Stato',
  POSTAL_CODE: 'Codice Postale',
  CONTACT: 'Contatto',
  CLOSE_DAYS: 'Giorni di chiusura',
  CLOSEDAYS: 'Giorni di chiusura',
  EMAIL: 'E-mail',
  PHONE: 'Telefono',
  MOBILE: 'Cellulare',
  FAX: 'Fax',
  NAVISIONID: 'Codice',
  SOCIALREASON: 'Ragione Sociale',
  ADDRESS2: 'Indirizzo 2',
  POSTALCODE: 'Codice Postale',
  BANK_ACC: 'Conto Banc.',
  PRECLIENT: 'Precliente',
  TYPECLIENT: 'Tipo di Cliente',
  UNFINISHED: 'Incompiuto',
  STATISTICS: 'Stats',
  NO_PENDING_APPROVAL_ORDERS: 'Non ci sono ordini pendenti da confermare',
  NO_PENDING_APPROVAL_REFUNDS: 'Non ci sono resi pendenti da confermare',
  PENDING_ORDERS: 'Ordini Pendenti',
  PENDING_REFUNDS: 'Resi Pendenti',
  PENDING_CHARGES: 'Addebiti Pendenti',
  NO_PENDING_ORDERS: 'Non ci sono ordini pendenti',
  NO_PREVIOUS_ORDERS: 'Non ci sono ordini precedenti',
  NO_PENDING_REFUNDS: 'Non  ci sono resi pendenti',
  NO_PREVIOUS_REFUNDS: 'Non ci sono resi precedenti',
  NO_PENDING_CHARGES: 'Non ci sono addebiti pendenti',
  NO_PREVIOUS_CHARGES: 'Non ci sono addebiti precedenti',
  OBJECTIVE: 'Obiettivo',
  BILLED: 'Fatturato',
  IDEAL: 'Ideale al giorno',
  CUMPLIMENT_OBJ: 'obiettivo raggiunto',
  SELECT_FOR_STATISTICS: 'E’ possibile cambiare mese e anno',
  MONTH: 'Mese',
  YEAR: 'Anno',
  BACK: 'Indietro',
  ACTIONS: 'Azioni',
  SELECT_ALL: 'Seleziona tutto',
  DISELECT_ALL: 'Deseleziona tutto',
  DEACTIVATE_SORT: 'Disattiva Ordinamento',
  ACTIVATE_SORT: 'Attiva ordinamento',
  CHECKOUT_ORDER: 'Invia ordine',
  TOTAL_IMPORT: 'Totale',
  TOTAL_IVA: '+ (IVA inc.)',
  BONIFY_ITEMS: 'Art. Omaggio',
  PROMO_COUNT: 'Nº Promo',
  SELECT_QUANTITY: 'Seleziona',
  SELECT_IMPORT: 'Imp. Selezione',
  CLIENT: 'Cliente',
  ITEMS: 'Articoli',
  ORDER_DETAILS: 'Dettagli dell’Ordine',
  CHARGE_DETAILS: 'Dettagli dell’Addebito',
  REFUND_DETAILS: 'Dettagli del Reso',
  FAVORITES: 'Preferiti',
  ADD_WITH_DISCOUNT: 'Aggiungere con sconto',
  UNITS: 'Unità',
  ADD_TO_ORDER: 'Aggiungere all’ordine',
  ADD_TO_PROPOSAL: 'Aggiungere alla proposta',
  WEB_STATUS: 'Stato rete',
  NAV_STATUS: 'Stato Navision',
  BLOCKED: 'Bloccato',
  ACTIVE: 'Attivo',
  PENDING_ORDER_BACK: 'Ordine',
  DESCRIPTION: 'Descrizione',
  QUANTITY: 'Quantità',
  CODE: 'Codice',
  NO_PROMO_PRODUCTS: 'Questa promozione non ha una lista di materiali fissa.',
  CLOSE: 'Chiudere',
  UNITS_PER_BOX: 'Unità per scatola',
  UNITS_PER_PACK: 'Unità per pallet',
  NO_PRODUCTS_FOUND: `Non sono stati trovati prodotti con la ricerca <em>{0}</em>`,
  SELL_CHANNEL_SELECTED: 'Hai selezionato questa azienda e questo canale di vendita',
  NOT_CLIENTS_FOUND: `Non sono stati trovati clienti con la ricerca <em>{0}</em>`,
  CONFIRM: 'Confermare',
  PERSONALIZADO: 'Personalizzato',
  DELETE: 'Eliminare',
  DISCOUNT: 'Sconto',
  DUPLICATE: 'Duplicare',
  COPY: 'Copiare',
  QUANT: 'Quant.',
  UNIT_PRICE: 'Prezzo unit.',
  TOTAL_PROMO: 'Totale Promo',
  FREE: 'Omaggio',
  EDIT_SELECTED: 'Modificare Selezionati',
  COPY_SELECTED: 'Copiare Selezionati',
  DUPLICATE_SELECTED: 'Duplicare Selezionati',
  DELETE_SELECTED: 'Eliminare Selezionati',
  NO_REPORTS: 'Non hai ancora alcun rapporto giornaliero, questi rapporti vengono generati automaticamente',
  NEW_CLIENT_ADDRESS: 'Utilizza questo cliente per aggiungere un ordine a un cliente nuovo o un precliente',
  es: 'Spagnolo',
  it: 'Italiano',
  pt: 'Portoghese',
  fr: 'Francese',
  CHANGE_PASSWORD: 'Cambiare Password',
  LANGUAGES: 'Lingue',
  ACCEPT: 'Conferma',
  DELETE_SELECTED_TEXT: 'Sei sicuro di voler eliminare i seguenti articoli?',
  DELETE_PROMO: 'Eliminare Promozione',
  DELETE_PROMO_TEXT: 'Cosa vuoi fare?',
  DELETE_ALL: 'Eliminare tutto',
  DELETE_ONLY_HEADER: 'Eliminare solo l’intestazione',
  DELETE_HEADER_AND_PUT_PRICES: 'Eliminare intestazione e inserire prezzi di listino',
  SEARCH_BY_CODE: 'Aggiungere per codice',
  NOT_EXIST_PRODUCT: 'Spiacente, il prodotto non esiste nella base dati, sei sicuro che sia corretto?',
  PRODUCT_BLOCKED: 'Spiacente, questo prodotto è bloccato in Navision, non può essere aggiunto',
  SELECT_MODIFIERS: 'Seleziona i campi che vuoi modificare, effettua le modifiche e salva',
  SAVE: 'Salva',
  OK: 'Ok',
  TIMEOUT: 'Timeout',
  UNAUTHORIZED: 'Errore di autenticazione',
  USER_BLOCKED: 'L’utente è bloccato, contatta un superiore per risolvere il problema',
  PASSWORD_INCORRECT: 'La password non è corretta',
  NOT_USER_FOUND: 'Non è stato trovato alcun utente con questi dati,  <span class="text-color-red">ricorda che l’utente <strong>NO</strong> é il tuo codice venditore non la tua ID </span>',
  ALERT: 'Attenzione!',
  MOVE_TO_OTHER_CLIENT_INFO: 'Stai per SPOSTARE questo ordine a un altro cliente. Se sposti l’ordine <strong>SARÀ CANCELLATO</strong> da questo cliente, e le righe' +
'passeranno all’altro cliente. Se esiste già l’ordine saranno aggiunte all’ordine esistente, se non esiste ne sarà creato uno nuovo. Sei sicuro ' +
'di voler continuare?',
  COPY_TO_OTHER_CLIENT_INFO: 'Stai per COPIARE questo ordine a un altro cliente. Se copi l’ordine <strong>RIMARRÀ</strong> in questo cliente, e le righe' +
'saranno create nell’altro cliente. Se esiste già l’ordine saranno aggiunte all’ordine esistente, se non esiste ne sarà creato uno nuovo. Sei sicuro ' +
'di voler continuare?',
  MOVE_ORDER_TO_CLIENT_SUCCESS: `E’ stato spostato l’ordine al cliente Nº {0}, Vuoi cambiare quel cliente?`,
  COPY_ORDER_TO_CLIENT_SUCCESS: `E’ stato copiato l’ordine al cliente Nº {0}, Vuoi cambiare quel cliente?`,
  SERVER_TOO_BUSY: 'Il server sta elaborando molte richieste in questo momento, riprova',
  SERVER_NOT_RESPONDING: 'Sembra che non sia possibile accedere al server, verifica la tua connessione a internet' +
'e che altre applicazioni funzionino, se è così contatta un amministratore',
  UPDATE: 'Aggiornare',
  ORDER_MOVED: 'Ordine Spostato',
  ORDER_COPIED: 'Ordine Copiato',
  INFO_METHODS: 'Informazioni',
  RESUME_ORDER: 'Riepilogo dell’Ordine',
  SEND: 'Invia',
  NOT_FOUND: 'Non trovato',
  SORRY: 'Spiacente',
  CONTENT_NOT_FOUND: 'Non ho trovato quello che cercavi, torna indietro o clicca sul menù principale per tornare al pannello di controllo',
  ADDRESS_SELECTION: 'Seleziona un indirizzo',
  PAYMENT_FORM_SELECTION: 'Modalità di Pagamento',
  PAYMENT_TERM_SELECTION: 'Termini di Pagamento',
  SELECT_ADDRESS_FILL: 'Il cliente ha più di un indirizzo, clicca qui e selezionane uno.',
  SEARCH_ADDRESS: 'Filtra indirizzi...',
  NO_ADDRESSES_FOUND: 'Non sono stati trovati indirizzi',
  SELECT_PAYMENT_FORM_FILL: 'Scegliere una modalità di pagamento',
  SELECT_PAYMENT_TERM_FILL: 'Scegliere i termini di pagamento',
  ADD_ORDER_DATES: 'Aggiungere date di Addebito',
  REMAINING_IVA_INC: 'Residuo (IVA incl)',
  ADD_CHARGE_DATE: 'Aggiungere data di Addebito',
  ADD_CHARGE_DATES: 'Aggiungere data di Addebito',
  ADD_REMAINING: 'Aggiungere residuo',
  ADD_ORDER_DATES_INFO: 'Questi numeri sono orientativi, ricorda che il prezzo finale dell’offerta può variare',
  ADD_CHARGE_DATES_INFO: 'Seleziona l’importo da addebitare e la data desiderata e clicca su aggiungere addebito',
  COMMENT: 'Note',
  COMMENT_FOR_CLIENT: 'Note per il Cliente',
  SAVE_COMMENT: 'Salvare le note',
  PENDING_BILLS: 'Fatture Pendenti',
  NO_PREVIOUS_MOVEMENTS: 'Non sono stati trovati movimenti precedenti del cliente selezionato.',
  NOT_MOVEMENTS_NEW_CLIENT: 'Hai selezionato un cliente nuovo, seleziona un cliente esistente per vedere i suoi movimenti',
  NOT_BILLS_REFUNDS_NEW_CLIENT: 'Hai selezionato un cliente nuovo, seleziona un cliente esistente per vedere le sue note di credito',
  NO_BILLS_NEW_CLIENT: 'Hai selezionato un cliente nuovo, seleziona un cliente esistente per vedere le sue fatture',
  BILL_DETAILS: 'Dettagli della Fattura',
  SEARCH_PRODUCTS: 'Filtra righe della fattura',
  SEARCH_REFUND_PRODUCTS: 'Cerca prodotto',
  SEARCH_REFUND_BILLS: 'Cerca Fattura',
  NO_CHARGES_FOR_NEW_CLIENT: 'Non hai selezionato alcun cliente esistente, seleziona un cliente per inviargli un addebito',
  NO_REFUNDS_FOR_NEW_CLIENT: 'Non hai selezionato alcun cliente esistente, seleziona un cliente per inviargli un reso',
  NO_PENDING_BILLS: 'Il cliente non ha fatture pendenti',
  NO_MOV: 'Nº Mov',
  BILL: 'Fattura',
  TO_LIQUID: 'Da saldare',
  PENDING_IMPORT: 'Importo Pendente',
  ADVICE: 'Acconto',
  CHECKOUT_CHARGE: 'Inviare addebito',
  CHARGE_ALREADY_PROCESSED: 'Questa riga è già stata elaborata',
  CHARGE_LINE_ADDED: 'Riga da addebitare aggiunta correttamente',
  ORDER: 'Ordine',
  UNITS_PENDING: 'Unità Pendenti',
  NUM_PACKS: 'Colli',
  WRITE_COMMENT: 'Scrivi qui le tue annotazioni',
  CONTENT: 'Contenuto',
  NEW_CLIENT_DETAILS: 'Dettagli del Nuovo Cliente',
  NO_NEW_CLIENT_DATA: 'Non sono stati trovati dati di questo Cliente Nuovo, prova ad aggiornare',
  COMMENTS: 'Note',
  OBJECTIVES: 'Obiettivi',
  EXP_PEND: 'Spedizioni',
  PEND_BILLS: 'Fatt. Pendenti',
  DROP_PLACEHOLDER: 'Trascina il file qui',
  SELECTED_FILE: 'Immagine selezionata',
  ADD_IMAGE: 'Aggiungere Doc.',
  NEW_INCOME: 'Nuovo Incasso',
  ADD_INCOME: 'Aggiungere Incasso',
  NO_INCOMES: 'Non sono stati trovati incassi precedenti, clicca sul pulsante qui sopra per aggiungere un nuovo incasso',
  CHOOSE_FILE_INCOME: 'Scegliere un file (ricevuta di versamento)',
  NEW_EXPENSE: 'Nuova Spesa',
  ADD_EXPENSE: 'Aggiungere Spesa',
  NO_EXPENSES: 'Non sono stati trovate spese precedenti, clicca sul pulsante qui sopra per aggiungere una nuova spesa',
  CHOOSE_FILE_EXPENSE: 'Scegliere un file (scontrino della spesa)',
  TAP_HERE_IMG: 'Clicca qui per selezionare una foto <br>o scattarne una',
  DATE_INCOME: 'Data del Versamento',
  DATE_EXPENSE: 'Data della Spesa',
  EXPENSE_ADDED: 'Pagamento aggiunto correttamente',
  INCOME_ADDED: 'Incasso aggiunto correttamente',
  IMPORT_ERROR_MESSAGE: 'Errore di Importazione. Si prega di consultare l’amministrazione dell’applicazione',
  ERRORS: 'Errori',
  PLEASE_FIX_ERRORS: 'Si prega di correggere i seguenti errori',
  INCOME_DETAILS: 'Dettagli Versamento',
  NO_INCOME_FOUND: 'No é stato trovato il versamento che cercavi',
  PAYMENT_FORM: 'Modalità di Pagamento',
  GROUP: 'Gruppo',
  EXPENSE_DETAILS: 'Dettagli di Spesa',
  PREVIEW: 'Anteprima',
  PROPOSAL_DETAILS: 'Dettagli della Proposta',
  COMMERCIAL_COMMENT: 'Note del Venditore',
  NO_PROPOSAL: 'Non è stata trovata questa proposta',
  RESPONSE: 'Risposta per il Venditore',
  SURNAME_NAME: 'Cognome, Nome',
  UNDER_CONSTRUCTION: 'In costruzione',
  ORDER_DATE: 'Data dell’Offerta',
  REFUND_DATE: 'Data del Reso',
  COMPANY_SELL_CHANNEL: 'Azienda e Canale di vendita',
  SEND_ADDRESS: 'Indirizzo di invio',
  SEND_DATE: 'Consegnare a partire dal giorno',
  PROPOSALS: 'Proposte',
  PROPOSAL: 'Proposta',
  PROPOSAL_CONFIRMATION: 'Sarà generato un link per inviare la proposta al cliente, che potrà accettarla o rifiutarla,' +
'inoltre potrà vedere tutte le informazioni sui prodotti della proposta, sei d’accordo?',
  PAYMENT_TERM: 'Termini di Pagamento',
  DATES: 'Date',
  PAYMENT_DATE: 'Data di Pagamento',
  PASSWORDS_NOT_MATCH: 'La password che hai inserito non è la tua password attuale',
  PASSWORD_SUCCESSFULL_CHANGED: 'Hai cambiato la tua password',
  ADDRESSES: 'Indirizzi',
  CLIENT_COMMENTS: 'Note Cliente',
  CONTRACT_COMMENTS: 'Note Contratto',
  NO_COMMENTS: 'Non ci sono note',
  FILL_NEW_CLIENT_DATA: 'Stai per inoltrare un ordine per un Cliente Nuevo, adesso devi compilare i dati. Clicca sul seguente ' +
'pulsante e inserisci i dati.',
  FILL_PRECLIENT: 'Inserire dati cliente',
  UPDATE_PRECLIENT: 'Aggiornare dati cliente',
  FIXED_DUE_DATE: 'Scadenza Fissa',
  PRODUCT_BILLS: 'Fattura di Prodotti',
  REFUND_SELECTED: 'Rendere Selezionati',
  APPROVAL_ORDERS: 'Confermare Ordini',
  APPROVAL_REFUNDS: 'Confermare Resi',
  VENDOR_SELECTION: 'Selezione del Venditore',
  VENDOR_SELECTED: 'Hai selezionato questo venditore',
  GIRO_DISABLED: 'Non é possibile emettere pagamenti a questo cliente perchè non ha un conto bancario associato. Per favor contatta l’amministrazione' +
' per aggiungere un conto bancario e riprovare. Ricorda che l’applicazione si aggiorna la notte.',
  TALON_C_DISABLED: 'Non é possibile utilizzare questa modalità di pagamento perchè il cliente non ha un contratto con assegni anticipati',
  TRANSFER_DISABLED: 'Non é possibile utilizzare questa modalità di pagamento perchè il cliente non è una cooperativa',
  CONFIRM_SEND_ORDER: 'Sei sicuro di voler inviare l’offerta?',
  SEND_ORDER: 'Inviare Offerta',
  CHARGE_DATE: 'Data di Addebito',
  DATE_EXISTS_ERROR: 'E’ già stata aggiunta la data {0} alle scadenze',
  ZERO_IMPORT: 'Per inviare un addebito a 0 é necessario selezionare contanti',
  CONFIRM_SEND_CHARGE: 'Sei sicuro di voler inviare l’addebito?',
  SEND_CHARGE: 'Inviare Addebito',
  SEARCH_REFUND_PRODUCT: 'Trova un prodotto nella barra superiore',
  ADD: 'Aggiungere',
  REFUND_MOTIVE: 'Motivo del Reso',
  MOTIVES: 'Motivi',
  REFUND_LINES_ADDED: 'La/e riga/e selezionata/e é stata aggiunta/e al reso',
  MOTIVE: 'Motivo',
  SET_LITTLE_DESCRIPTION: 'Includere una breve descrizione',
  SEND_REFUND: 'Inviare Reso',
  CONFIRM_SEND_REFUND: 'Sei sicuro di voler inviare il reso? Ricorda di verificarlo prima dell’invio',
  REFUND_MOTIVE_SUGGEST: 'Hai inserito tutti i motivi del reso di OGNI prodotto?',
  NO_REFUND_FOUND: 'Non è stato trovato il reso',
  OBJECTIVES_FROM_COSMET: 'Obiettivi aggiunti da Ofertahe',
  ADD_OBJECTIVE: 'Aggiungere obiettivo',
  PREVIOUS_OBJECTIVES: 'Obiettivi Precedenti',
  OBJECTIVE_ADDED: 'Obiettivo aggiunto correttamente',
  '200': 'Riuscito',
  '201': 'Riuscito',
  '404': 'Non trovato',
  '400': 'Errore',
  '401': 'Errore di autenticazione',
  '422': 'Errore del server',
  '499': 'Già presente',
  'CONTADO': 'Contanti',
  'GIRO': 'Addebito',
  'CONTRAREEM': 'Contrassegno',
  'GIROPT': 'Addebito Portogallo',
  'CONTRARPOR': 'Contrassegno Portogallo',
  'CHEQUEPT': 'Assegno Portogallo',
  'TARJETAPT': 'Carta Portogallo',
  'TRANSFPORT': 'Bonifico',
  'TRANSFERIT': 'Bonifico',
  'TARJ OUTLE': 'Carta',
  'CONTADOPT': 'Contratto Assegno',
  'EFECTIVO': 'Contanti',
  'TARJETA': 'Carta',
  'GIROCOBRO': 'Addebito',
  'CHEQUE': 'Assegno',
  'DESCUENTO': 'Sconto',
  '1X7': 'Addebito 7 giorni',
  '1X15': 'Addebito 15 giorni',
  '1X20': 'Addebito 20 giorni',
  '1X21': 'Addebito 21 giorni',
  '1X30': 'Addebito 30 giorni',
  '1X40': 'Addebito 40 giorni',
  '1X45': 'Addebito 45 giorni',
  '1X60': 'Addebito 60 giorni',
  '1X75': 'Addebito 75 giorni',
  '1X90': 'Addebito 90 giorni',
  '2X7': 'Addebito 7 e 30 giorni',
  '2X15': 'Addebito 15 e 30 giorni',
  '2X20': 'Addebito 20 e 40 giorni',
  '2X30': 'Addebito 30 e 60 giorni',
  '2X45': 'Addebito 45 e 90 giorni',
  '2X45B': 'Addebito 45 e 60 giorni',
  '2X60': 'Addebito 60 e 90 giorni',
  '2X60X120': 'Addebito 60 e 120 giorni',
  '2X90': 'Addebito 90 e 120 giorni',
  '3X10': 'Addebito 10, 20 e 30 giorni',
  '3X15': 'Addebito 15, 30 e 45 giorni',
  '3X3': 'Addebito 45, 75 e 105 giorni',
  '3X30': 'Addebito 30, 60 e 90 giorni',
  '3X60': 'Addebito 60, 90 e 120 giorni',
  '4X30': 'Addebito 30, 60, 90 e 120 giorni',
  '4X3': 'Addebito 15, 60 e 90 giorni',
  '4X4': 'Addebito 15, 30, 60 e 90 giorni',
  '1X120': 'Addebito 120 giorni',
  MAINTENANCE_MODE: 'Il server é in manutenzione, vi preghiamo di pazientare, a breve sarà nuovamente operativo',
  '503': 'MANUTENZIONE',
  NO_PREVIOUS_BILLS: 'Non sono stati trovate fatture precedenti',
  NO_PREVIOUS_BILLS_REFUNDS: 'Non sono stati trovate note di credito precedenti',
  SEARCH_MENU: 'Cosa vuoi fare?',
  PENDING: 'Pendente',
  PENDING_TO_SEND: 'Invio Pendente',
  PENDING_APPROVAL: 'Conf. Pendente',
  PENDING_APPROVAL_DIS: 'Conf. Dis. Pendente',
  PENDING_APPROVAL_DIST: 'Conf. Dis. Pendente',
  SENDED: 'Inviato',
  IMPORT_ERROR: 'Errore Import.',
  RECEIVED: 'Ricevuto',
  ACCEPTED: 'Accettato',
  CANCELED: 'Cancellato',
  UNKNOWN: 'Sconosciuto',
  STATUS: 'Stato',
  'ENERO': 'GENNAIO',
  'FEBRERO': 'FEBBRAIO',
  'MARZO': 'MARZO',
  'ABRIL': 'APRILE',
  'MAYO': 'MAGGIO',
  'JUNIO': 'GIUGNO',
  'JULIO': 'LUGLIO',
  'AGOSTO': 'AGOSTO',
  'SETTEMBRE': 'SETTEMBRE',
  'OTTOBRE': 'OTTOBRE',
  'NOVEMBRE': 'NOVEMBRE',
  'DICEMBRE': 'DICEMBRE',
  'Parrucchiere': 'Parrucchiere',
  'Estetica': 'Estetica',
  'Estetica+Parrucchiere': 'Estetica+Parrucchiere',
  'Barbiere': 'Barbiere',
  'Altro': 'Altro',
  NOT_EMAIL: 'Il cliente non ha una E-mail. Per emettere fattura é necessario aggiungere una E-mail del cliente e fargliela convalidare, avanti!',
  NOT_VALIDATED_EMAIL: 'Il cliente ha una E-mail, ma non é ancora stata confermata. Si prega di inviare una E-mail di conferma al cliente.',
  MUST_ADD_OBJECTIVE: 'Ricorda che devi inserire l’obiettivo annuo per questo cliente',
  VALIDATE_EMAIL: 'Convalidare E-mail',
  UPDATE_EMAIL: 'Aggiornare E-mail',
  EMAIL_MORE_THAN_WEEK: 'L’E-mail è in attesa di conferma da oltre 7 giorni',
  WAITING_FOR_CONFIRM: 'In attesa di conferma dell’E-mail',
  SEND_ORDER_CONFIRMATION: 'Conferma di Invio',
  COOPERATIVA: 'COOPERATIVA',
  ASSOCIATED_CLIENT: 'Cliente Associato',
  SELECT_ASSOCIATED_CLIENT: 'Seleziona il cliente associato',
  NO_BILLS_FOUND: 'Non sono stati trovate fatture',
  ADD_BILL_MANUALLY: 'Aggiungere fattura manualmente',
  BUY_AGAIN: 'Ripetere l’Ordine',
  REBUY: 'Ricomprare',
  REFUND: 'Rendere',
  TYPE_CLIENT_EMPTY: 'Compilare il campo Tipo Cliente',
  CIF_EMPTY: 'Compilare il campo C.F./P.I.',
  CIF_NOT_VALID: 'La P.I. non è valida, verificare il campo C.F./P.I.',
  NAME_EMPTY: 'Compilare il campo Nome',
  NAME_TOO_LONG: 'Il nome e il cognome insieme sono troppo lunghi, prova ad ebbreviarli, o considera di separarli nel Nome 2.',
  NAME2_TOO_LONG: 'Il campo Nome 2 è troppo lungo',
  SOCIAL_REASON_TOO_LONG: 'Il campo ragione sociale è troppo lungo',
  ADDRESS_EMPTY: 'Compilare il campo Indirizzi',
  ADDRESS_TOO_LONG: 'Il campo Direzione è troppo lungo, considera di separarlo nel campo Indirizzo 2',
  ADDRESS2_TOO_LONG: 'Il campo Direzione 2 è troppo lungo, prova ad abbreviarlo',
  CITY_EMPTY: 'Compilare il campo Città',
  CITY_TOO_LONG: 'Il campo Città è troppo lungo, prova ad abbreviarlo',
  STATE_EMPTY: 'Compilare il campo Provincia',
  STATE_TOO_LONG: 'Il campo Provincia é tropppo lungo, prova ad abbreviarlo',
  COUNTRY_EMPTY: 'Selezionare uno Stato',
  POSTAL_CODE_EMPTY: 'Compilare il campo Codice Postale',
  POSTAL_CODE_WRONG: 'Il Codice Postale non è inserito correttamente, verificare',
  CONTACT_EMPTY: 'Compilare il campo Pers.Contatto',
  CONTACT_TOO_LONG: 'Il campo Contatto é troppo lungo, prova ad abbreviarlo',
  CLOSE_DAYS_EMPTY: 'Compilare il campo Giorni di Chiusura',
  CLOSE_DAYS_TOO_LONG: 'Il campo Giorni di Chiusura è troppo lungo, prova ad abbreviarlo',
  EMAIL_EMPTY: 'Compilare il campo E-mail',
  EMAIL_NOT_VALID: 'l’E-mail non ha un formato valido.',
  EMAIL_TOO_LONG: 'l’E-mail è troppo lunga',
  MOBILE_OR_PHONE_E: 'Compilare il campo Telefono o il campo Cellulare',
  FAX_TOO_LONG: 'Il campo Fax è troppo lungo',
  NOT_VALID_ACCOUNT: 'il campo nº di conto deve avere questo formato SSXX XXXX XXXX XX XXXXXXXXXX',
  FILL_BANK_ACCOUNT: 'Per emettere addebiti a un cliente nuovo, é necessario inserire anche il numero di conto.',
  BANKACCOUNT: 'Conto Bancario',
  CHECK_NO: 'Nº Assegno',
  CHECK_NO_PLACEHOLDER: 'Inserire il numero di assegno',
  NO_ORDER_FOUND: 'Non è stato trovato l’ordine',
  NO_REPORT_DETAILS: 'Non sono stati trovati dati del rapporto',
  NO_PENDING_TO_SHIP_FOR_NEW_CLIENT: 'Non è stato selezionato alcun cliente esistente, selezionare un cliente per vedere il suo pendente di evasione',
  NO_EXPEDITION_FOR_NEW_CLIENT: 'Non è stato selezionato alcun cliente esistente, selezionare un cliente per vedere le sue spedizioni',
  LINE_COPIED: 'Riga copiata nell’ordine',
  COMPANY_NOT_MATCH: 'Le aziende delle righe che si vogliono aggiungere all’addebito non coincidono',
  DESCON_0: 'Non è il momento di guardare lo schermo, è il tuo momento. Rilassati!',
DESCON_1: 'Sole tramontato, riposo meritato.',
  DESCON_2: 'Disconnettersi da tutto e riposare è necessario per condurre una vita sana',
  DESCON_3: 'Momento di: Clienti ... Spostamenti ... Ordini ... Disconnettersi! <icon class="icon f7-icons text-color-green" style="font-size: 16px">checkmark_alt</icon>',
  DESCON_4: 'Ora é tempo di stare con la tua famiglia: approfitta e goditela!',
  DESCON_5: 'Adesso inizia la tua giornata in famiglia!! Goditela!!',
  DESCON_6: 'Per poter rendere al 100% nella tua giornata lavorativa hai bisogno di disconnetterti al 100%',
  DESCON_7: 'Per essere produttivi bisogna riposare!! Buona giornata di riposo!!',
  DESCON_8: 'Dopo il lavoro, goditi la tua famiglia, una vita sana e una buona lettura. Ti aiuterà a sentirti meglio',
  DESCON_9: 'Fermati! Questo tempo è per te, dedicalo a te stesso!',
  SOLD_PRODUCTS: 'Prodotti Venduti',
  NO_SOLD_PRODUCTS_FOR_NEW_CLIENT: 'Non è stato selezionato alcun cliente esistente, selezionare un cliente esistente per vedere i prodotti venduti',
  DATE_RANGE: 'Intervallo Date',
  SELECT_DATES: 'Selezionare Date',
  GET_SOLD_PRODUCTS: 'Ottenere Prodotti Venduti',
  NO_SOLD_PRODUCTS_FOUND: 'Non sono stati trovati prodotti venduti per questo cliente o questo intervallo date',
  SEARCH_FAQ: 'Cercare in Aiuto',
  MY_CODES: 'I miei Codici',
  VENDORS: 'Venditori',
  SEARCH_REPORTS: 'Cercare nei rapporti',
  REPORTS_RESULTS: 'Resultati per la ricerca {0}',
  NOT_FOUND_CLIENT: 'Non trovato',
  NOT_FOUND_CLIENT_INFO: 'Se il cliente è stato inserito oggi, è normale che non esca, ma tranquillo, se conosci il codice cliente puoi aggiungerlo tu stesso da qui' +
'ricorda che le informazioni come note del contratto, indirizzi aggiuntivi, ecc saranno visibili domani. Comunquea, se vuoi inserire un ordine, reso' +
'o addebito prova ad aggiungerlo tu stesso ora, senza attendere fino a domani',
  FIND_CLIENT: 'Trova il cliente',
  CLIENT_IMPORT_FAILED: 'Spiacente, il codice inserito non esiste, o quel cliente non è associato con il tuo codice venditore, contatta l’amministrazione e' +
'riprova :)',
  CLIENT_IMPORT_SUCESFULL: 'Bene! Il cliente è già nella tua selezione',
  NO_PREVIOUS_PROPOSALS: 'Non sono stati trovate proposte precedenti.',
  PRODUCT_ERROR: 'Errore in un prodotto',
  PRODUCT_ERROR_INFO: 'C’è un problema con un prodotto? Se c’è un errore in un prodotto esistente o non riesci a trovare un prodotto questo è il posto giusto, dimmi cosa succede' +
'e ti aiuterò a risolvere',
  FIND_PRODUCT_CODE: 'Trovare il codice',
  WHAT_PROBLEM: 'Qual è il problema?',
  APPLIES_TO_DOC: 'Regolato con Reso',
  SENDED_ERROR_MESSAGE: 'È stato inviato un messaggio con l’errore rilevato, grazie mille!',
  COSMET_NOT_PRODUCT_FOUND: 'Non è stato trovato il prodotto nella base dati, verifica che il codice sia corretto e se lo è contatta l’amministratore.',
  CONTRACTS: 'Contratti',
  NO_EXPEDITIONS_CLIENT: 'Il cliente non ha spedizioni recenti',
  NO_PENDING_BILLS_CLIENT: 'Il cliente non ha fatture pendenti',
  NO_LAST_BILLS: 'Non sono stati trovate fatture precedenti',
  UPDATE_PRODUCT: 'Aggiung./Aggior. prodotto',
  UPDATE_CATEGORY: 'Aggiung./Aggior. categoria',
  NO_LAST_BILLS_VENDOR: 'Non ci sono informazioni sulle ultime fatture',
  NO_LATE_BILLS_VENDOR: 'Non ci sono saldi arretrati di alcun cliente',
  NO_PENDING_BILLS_VENDOR: 'Non ci sono fatture pendenti di alcun cliente',
  SELL_CHANNEL_SELECTION: 'Selezione del Canale di Vendita',
  TAHE_OUTLET: 'Tahe Outlet',
  TH_OUTLET: 'Th Outlet',
  PROPOSAL_ADDED_TO_ORDER: 'Proposta aggiunta correttamente all’ordine',
  NUM_CHECK: 'Nº Assegno',
  NOT_EXIST_ONE_OR_MORE_PRODUCTS: 'Sono stati aggiunti i prodotti al reso, ma non è stato possibile inserirne alcuni perchè non sono nella base dati',
  PRODUCT_BILL_ALREADY_ADDED: 'Sembra che siano già stati resi alcuni dei prodotti selezionati corrispondenti a questa fattura',
  DISTRIBUTORS: 'Distributori',
  NO_DISTRIBUTORS: 'Non ci sono distributori',
  ADD_DISTRIBUTOR: 'Aggiungere Distributore',
  NEW_DISTRIBUTOR: 'Nuovo Distributore',
  PRODUCTS_SOLD_INFO: 'Il calcolo dei prodotti venduti si effettua prendendo i prodotti fatturati e sottraendo i prodotti resi',
  ADD_DIST_CLIENT: 'Aggiungere Cliente',
  SEE_MORE: 'Vedere di più',
  cif: 'P.I.',
  socialReason: 'Ragione Sociale',
  name: 'Nome',
  name2: 'Nome 2',
  surname: 'Cognome',
  address: 'Indirizzo',
  address2: 'Indirizzo 2',
  city: 'Città',
  state: 'Provincia',
  postalCode: 'Cod. Postale',
  contact: 'Contatto',
  closeDays: 'Giorni di chiusura',
  email: 'E-mail',
  phone: 'Telefono',
  mobile: 'Cellulare',
  fax: 'Fax',
  bankAccount: 'Conto Bancario',
  esxx: 'ITXX',
  bank: 'Banca',
  office: 'Agenzia',
  digit: 'Cifra',
  numAccount: 'Nº Conto',
  'cosmet': 'Cosmet',
  'red': 'Rosso',
  'green': 'Verde',
  'blue': 'Blu',
  'pink': 'Rosa',
  'yellow': 'Giallo',
  'orange': 'Arancione',
  'purple': 'Lilla',
  'deeppurple': 'Indaco',
  'lightblue': 'Azzurro',
  'teal': 'Turchese',
  'lime': 'Lime',
  'deeporange': 'Arancione Scuro',
};
