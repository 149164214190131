import { mapGetters, mapState, mapMutations, mapActions, useStore } from 'vuex';
import { computed } from 'vue';

const createActionHook = (mapFunction) =>
  ((...args) => {
    const $store = useStore();

    return Object.fromEntries(
      Object.entries(mapFunction(...args)).map(
        ([key, value]) => [
          key,
          value.bind({
            $store,
          }),
        ],
      ),
    )
  });

export const useMutation = createActionHook(mapMutations);
export const useActions = createActionHook(mapActions);

const createComputedHook = (mapFunction) =>
  ((...args) => {
    const $store = useStore();

    return Object.fromEntries(
      Object.entries(mapFunction(...args)).map(
        ([key, value]) => [
          key,
          computed(
            value.bind({
              $store,
            }),
          ),
        ],
      ),
    )
  });

export const useGetters = createComputedHook(mapGetters);
export const useState = createComputedHook(mapState);
