<template>
  <div class="margin-right" v-if="[SELL_CHANNEL.TAHE_ESPANA.code, SELL_CHANNEL.TAHE_PORTUGAL.code, SELL_CHANNEL.TAHE_CANARIAS.code].includes(sessionInfo.sellChannel)">
    <f7-chip><strong class="font-size-10px">PVP: <span class="font-size-12px">{{numFormat(price)}}€</span></strong></f7-chip>
  </div>
  <div v-else></div>
</template>
<script>
import { useState } from '../../../../js/stores/hooks';
import { SELL_CHANNEL } from '../../../../js/config/enums.config';
import { numFormat } from '../../../../js/helpers/filters.helpers';

export default {
  name: 'product-pvp',
  props: {
    price: { type: Number, default: 0 },
  },
  setup: () => {
    const { sessionInfo } = useState('session', ['sessionInfo']);
    return { sessionInfo, SELL_CHANNEL, numFormat };
  },
};
</script>