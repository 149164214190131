import { shoppingCartService } from '../../api/graphql/services/shopping-carts.service';

export const StoreShoppingCart = {
  module: 'shoppingCart',
  actions: {
    getPendingCart: 'getPendingCart',
    editPendingCart: 'editPendingCart',
    clearPendingCart: 'clearPendingCart',
    setDiscount: 'setDiscount',
  },
  mutations: {
    setPendingCart: 'SET_PENDING_CART',
    editItemFromPendingCart: 'EDIT_ITEM_FROM_PENDING_CART',
    addItemToPendingCart: 'ADD_ITEM_TO_PENDING_CART',
    removeItemFromPendingCart: 'REMOVE_ITEM_FROM_PENDING_CART',
    removePendingCart: 'REMOVE_PENDING_CART',
    setDiscount: 'SET_DISCOUNT',
    setId: 'SET_ID',
  }
};

const state = {
  id: '',
  username: '',
  vendorCode: '',
  discount: 0,
  lines: [],
};

const getQuantity = (state, optionSku) => {
  const item = state.lines.find(l => l.optionSku === optionSku);
  return item ? item.quantity : 0;
};

const total = (state) => state.lines.reduce((sum, i) => sum = sum + i.unitPrice * i.quantity, 0);
const totalQuantities = (state) => state.lines.reduce((sum, i) => sum = sum + i.quantity, 0);

const getters = {
  getQuantity: (state) => (optionSku) => getQuantity(state, optionSku),
  total: (state) => total(state),
  totalQuantities: (state) => totalQuantities(state),
};

const actions = {
  [StoreShoppingCart.actions.getPendingCart]: async ({ commit, rootState }) => {
    const username = rootState.session.user.username;
    const vendorCode = rootState.session.sessionInfo.selectedCode;
    const language = rootState.userSettings.language;
    const { data: { pendingCart } } = await shoppingCartService.getPendingCart(username, vendorCode, language);
    commit(StoreShoppingCart.mutations.setPendingCart, pendingCart);
  },
  [StoreShoppingCart.actions.editPendingCart]: async ({ commit, state, rootState }, item) => {
    const index = state.lines.findIndex(i => i.optionSku === item.optionSku);
    const username = rootState.session.user.username;
    const vendorCode = rootState.session.sessionInfo.selectedCode;
    const { quantity } = item;
    // console.log(index);
    if (index !== -1) {
      if (quantity > 0) {
        await shoppingCartService.editPendingCartLine({ username, vendorCode, index, quantity });
        commit(StoreShoppingCart.mutations.editItemFromPendingCart, { index, quantity });
      } else {
        await shoppingCartService.removePendingCartLine({username, vendorCode, index, quantity });
        commit(StoreShoppingCart.mutations.removeItemFromPendingCart, index);
      }
    } else if (quantity > 0) {
      // console.log(item);
      // console.log('add....')
      const { optionSku, sku, unitPrice } = item;
      const { data: { pendingCart: { _id: id }}} = await shoppingCartService.addPendingCartLine({ username, vendorCode, quantity, optionSku, sku, unitPrice });
      commit(StoreShoppingCart.mutations.addItemToPendingCart, item);
      commit(StoreShoppingCart.mutations.setId, id);
    }
  },
  [StoreShoppingCart.actions.clearPendingCart]: async ({ commit }) => {
    await shoppingCartService.clearPendingCart(state.id);
    commit(StoreShoppingCart.mutations.removePendingCart);
  },
  [StoreShoppingCart.actions.setDiscount]: async ({ commit, state, rootState }, discount) => {
    const username = rootState.session.user.username;
    const vendorCode = rootState.session.sessionInfo.selectedCode;
    await shoppingCartService.editPendingCart({ username, vendorCode, discount });
    commit(StoreShoppingCart.mutations.setDiscount, discount);
  }
};

const mutations = {
  [StoreShoppingCart.mutations.setPendingCart]: (state, pendingCart) => {
    state.id = pendingCart._id;
    state.username = pendingCart.username;
    state.vendorCode = pendingCart.vendorCode;
    state.discount = pendingCart.discount;
    state.lines = pendingCart.lines.map(l => ({...l}));
  },
  [StoreShoppingCart.mutations.editItemFromPendingCart]: (state, { index, quantity }) => {
    state.lines[index].quantity = quantity;
  },
  [StoreShoppingCart.mutations.addItemToPendingCart]: (state, item) => {
    state.lines.push(item);
  },
  [StoreShoppingCart.mutations.removeItemFromPendingCart]: (state, index) => {
    // delete state.lines[index];
    state.lines.splice(index, 1)
  },
  [StoreShoppingCart.mutations.removePendingCart]: (state) => {
    state.id = '';
    state.username = '';
    state.vendorCode = '';
    state.discount = 0;
    state.lines = [];
  },
  [StoreShoppingCart.mutations.setDiscount]: (state, discount) => state.discount = discount,
  [StoreShoppingCart.mutations.setId]: (state, id) => state.id = id,
};

export const shoppingCartStore = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
