<template>
  <f7-block class="no-margin-top">
    <div class="figure-image custom-elevation-0">
      <figure><img :src="image" alt="" :width="width"></figure>
    </div>
    {{img}}
  </f7-block>
</template>
<script>
export default {
  name: 'figure-image',
  props: {
    image: { type: String, default: 'static/images/test-image.png'},
    width: { type: Number, default: 200 },
  },
  setup: () => {}
};
</script>
<style lang="scss" scoped>
.figure-image {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  padding: 5px;
  display: block;
  margin: 20px auto 20px auto;
  background-color: white;
  figure {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 50%;
    margin: 0;
    img {
      width: 100%;
    }
  }
}
</style>
