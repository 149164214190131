import { computed, reactive } from 'vue';
import { IMAGE_SIZES } from '../../../../../js/config/app.config';
import { resizeImage } from '../../../../../js/helpers/catalog.helper';
import { f7 } from 'framework7-vue';
import { numFormat } from '../../../../../js/helpers/filters.helpers';

export const ProductCatalog = (props, t) => {
  const noImage = 'static/images/test-image.png';
  const state = reactive({
    productBlocked: computed(() => !props.product || !props.product.info || props.product.blocked || props.product.info.blocked),
    fullName: computed(() => {
      const optionName = props.product.info.count === 1 ? ` ${props.product.info.optionName}` : '';
      console.log(optionName);
      return props.product ? `${props.product.translation.name}${optionName} ${props.product.translation.extraName}` : '???'
    }),
  });
  const openPhotoBrowser = () => {
    const product = props.product;
    let photos = [];
    if (product.image === '') return;
    const size = IMAGE_SIZES.PRODUCT.medium;
    if (product.info.promo) {
      const images = [];
      const results = product.translation.description.matchAll(/\<img.+?(?=src\=)src\=\"(?<image>[^\"]*)\"/g);
      for (const result of results) {
        const { image } = result.groups;
        images.push(image);
      }
      photos = [...images, {url: resizeImage(product.image, size)}];
    } else {
      photos = [{url: resizeImage(product.image, size)}, ...product.extraImages.map(i => ({url: resizeImage(i, size)}))];
    }
    f7.photoBrowser.create({ photos, type: 'standalone', navbarOfText: t('text.of'), popupCloseLinkText: t('buttons.close'), swipeToClose: false }).open();
  };
  const goToProductDetails = () => {
    if ((state.productBlocked && (!props.product || !props.product.info)) || props.product.sku === '') return;
    f7.views.main.router.navigate(`/product-details/`, {props: { product: props.product }});
  };

  const abrirPromoInfo = () => f7.popup.get('.info-promo-popup').open();
  return { noImage, state, openPhotoBrowser, goToProductDetails, abrirPromoInfo, resizeImage, numFormat };
}
