import gql from 'graphql-tag';

export const GET_ANNOUNCES = gql`
  query GetAnnounces($type: String!, $group: String!) {
    announces(filter: { type: { eq: $type }, group: { eq: $group } }) {
      edges {
        node {
          text
        }
      }
    }
  }`;

export const CREATE_ONE_ANNOUNCE = gql`
  mutation CreateOneAnnounce($text: String!, $type: String!, $group: String!) {
    createOneAnnounce(input: {
      announce: {
        text: $text,
        type: $type,
        group: $group
      }
    }) {
      text
      type
      group
    }
  }`;