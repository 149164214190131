<template>
  <f7-block inset strong class="product-card custom-elevation-1 margin-half padding-vertical-half">
    <template v-if="product.__typename === 'ProductWeb'">
      <div class="display-flex flex-direction-row justify-content-flex-end">
        <div v-if="state.productBlocked" class="ribbon-box-card ribbon-primary bg-color-red">{{ $t('text.soldOut') }}</div>
        <div v-else-if="product.new" class="ribbon-box-card ribbon-primary bg-color-green">{{ $t('text.new') }}</div>
        <div class="display-flex flex-direction-row flex-1 justify-content-space-between align-items-center font-size-12px" v-if="product.info.count === 1">
          <div class="display-flex flex-direction-column">
            <strong>{{ $t('text.cod') }}: <span class="text-color-blue">{{ product.info.optionSku }}</span></strong>
            <strong>{{ $t('text.price') }}: <span class="text-color-purple">{{numFormat(product.info.price + (product.info.promo ? product.info.promoPriceLines : 0))}}€</span></strong>
          </div>
          <product-pvp :price="product.info.pvp"></product-pvp>
        </div>
        <div class="display-flex flex-direction-row justify-content-flex-end">
          <template v-if="!state.productBlocked && product.info.count > 1">
            <f7-button @click="goToProductDetails()" class="text-capitalize">{{ $t('text.seeOptions') }}</f7-button>
          </template>
          <template v-if="!state.productBlocked && product.info.count === 1">
            <new-product-stepper-button :type="'catalog'" :product="{...product, ...product.info}"></new-product-stepper-button>
            <!--          <product-stepper :type="'catalog'" :product="product"></product-stepper>-->
          </template>
          <div class="margin-left"><f7-link icon-only :icon-f7="isFavorite ? 'heart_fill' : 'heart'"
                                            :icon-color="isFavorite ? 'red' : 'primary'" @click="toggleFavProduct(product)"></f7-link></div>
        </div>
      </div>
      <div class="display-flex flex-direction-row">
        <div style="min-height: 70px; min-width: 70px" @click="openPhotoBrowser(item)">
          <div v-if="product.info?.count === 1 && product.mostrarImagenEnDivisible">
            <img class="lazy lazy-fade-in" :src="resizeImage(product.image, 200) || COMPANY[sessionInfo.company].image" width="60" style="margin-top: 5px;"/>
            <img class="lazy lazy-fade-in" :src="resizeImage(product.optionImage, 40) || COMPANY[sessionInfo.company].image"
                 style="position: absolute; margin-top: 35px; left: 50px; width: 36px;"/>
          </div>
          <img v-else class="lazy lazy-fade-in" :src="
            (product.info?.count === 1 && product.optionImage !== '' ? resizeImage(product.optionImage, /KELAYA|BOCARE/.test(sessionInfo.company) ? 500: 200) :
            resizeImage(product.image, /KELAYA|BOCARE/.test(sessionInfo.company) ? 500: 200)) ||
            COMPANY[sessionInfo.company].image" width="60" style="margin-top: 5px;"/>
          <f7-icon v-if="product.image !== ''" class="search-icon-card" icon="las la-search-plus"></f7-icon>
          <f7-icon v-if="product.info?.count === 1" :color="product.info?.disponibilidad"  f7="circle_fill" size="14" style="position: absolute; bottom: 4px; left: 4px;"></f7-icon>
        </div>
        <div class="display-flex flex-direction-column flex-1 margin-left" @click="goToProductDetails()" style="padding: 4px; line-height: 1.1;">
          <div><strong class="text-capitalize font-size-12px">{{ state.fullName.toLowerCase() }}</strong></div>
          <div><small class="text-color-gray" v-html="product.translation.mainLine"></small></div>
          <div><small class="text-capitalize" v-html="product.translation.metaKeyword.toLowerCase()"></small></div>
          <div class="display-flex flex-direction-row flex-1 text-color-purple" v-if="/ALT/.test(product.code)">
            <small><em>{{ product.info?.description }}</em></small>
          </div>
<!--          && product.info?.disponibilidad === 'red'-->
          <div v-if="product.info?.discontinued" class="display-flex flex-direction-row flex-1 text-color-orange">
            <em>{{ $t('descatalogado_hasta_fin') }}</em>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="display-flex flex-direction-row align-items-center justify-content-space-between">
        <div class="display-flex flex-1">
          <div class="margin-right" @click="abrirPromo()"><f7-icon color="blue" f7="info_circle" v-if="product.promo"></f7-icon></div>
          <div>
            <strong>Precio:
              <span class="text-color-purple font-size-14px">{{numFormat(product.precio + product.promoPriceLines)}}€</span></strong>
          </div>
        </div>
        <div class="margin-left display-flex flex-direction-row">
          <nav-product-stepper-button :type="'catalog'" :product="{ ...product }"></nav-product-stepper-button>
          <div class="margin-left">
            <f7-link icon-only :icon-f7="isFavorite ? 'heart_fill' : 'heart'"
                     :icon-color="isFavorite ? 'red' : 'primary'" @click="toggleFavProduct(product)"></f7-link>
          </div>
        </div>
      </div>
      <div class="display-flex flex-direction-row align-items-center flex-1">
        <img class="lazy lazy-fade-in margin-right" :src="COMPANY[sessionInfo.company].image" width="50" style="margin-top: 5px;"/>
        <div class="display-flex flex-direction-column margin-right">
          <div><strong>Cod: <span class="text-color-blue">{{ product.optionSku }}</span></strong></div>
          <div><strong class="font-size-12px">{{ product.description }}</strong></div>
        </div>
      </div>
    </template>
  </f7-block>
</template>
<script>
import ProductStepper from './product-stepper.vue';
import { ProductCatalog } from './compositions/product-catalog.composition';
import { useI18n } from 'vue-i18n';
import { useActions, useState } from '../../../../js/stores/hooks';
import { computed } from 'vue';
import NewProductStepperButton from '../../../../components/sheets/new-product-stepper/new-product-stepper-button';
import { COMPANY } from '../../../../js/config/enums.config';
import ProductPvp from './product-pvp';
import NavProductStepperButton from '../../../../components/sheets/nav-product-stepper/nav-product-stepper-button';

export default {
  name: 'product-card',
  props: {
    product: { type: Object, default: null },
  },
  components: {
    NavProductStepperButton,
    ProductPvp,
    NewProductStepperButton,
    'product-stepper': ProductStepper,
  },
  setup: (props) => {
    const { t } = useI18n();
    const { favProducts: favP } = useState('userSettings', ['favProducts']);
    const { toggleFavProduct } = useActions('userSettings', ['toggleFavProduct']);
    const { sessionInfo } = useState('session', ['sessionInfo']);
    const isFavorite = computed(() => favP.value ? favP.value
        .findIndex(f => f === props.product.code || f === props.product.optionSku) > -1 : false);
    const { setPromoProduct } = useActions('catalog', ['setPromoProduct']);
    const productCatalog = ProductCatalog(props, t);
    const abrirPromo = () => {
      setPromoProduct(props.product.optionSku);
      productCatalog.abrirPromoInfo();
    };
    return {...productCatalog, toggleFavProduct, isFavorite, sessionInfo, COMPANY, abrirPromo };
  }
};
</script>
<style>
.search-icon-card {
  position: absolute !important;
  left: 70px;
  margin-top: 54px;
}
.product-card {
  min-width: 340px !important;
  max-width: 340px !important;
  margin: 2px !important;
  padding: 12px !important;
}
</style>
