<template>
  <f7-page>
    Right Navbar
  </f7-page>
</template>
<script>
export default {
  setup: (props, context) => {
  }
}
</script>
