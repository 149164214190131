import gql from 'graphql-tag';

export const GET_PROFILE = gql`
  query {
    profile {
      username
      email
      isActive
      roles
      sessionInfo {
        company
        groupPrice
        selectedClient
        selectedCode
        sellChannel
        status
        codigosExtra
        catalogos
      }
      userSettings {
        username
        favoritesMenu {
          title
          link
          icon
        }
        favProducts 
        language
        tourVisited
        hasKeyboard
        color
        typeListProduct
      }
    }
  }
`;
