import { instance as axios } from '../../plugins/axios';

const findCharge = (vendorId, clientId, sellChannel, status) =>
  axios.post('/charges/find_charge', {vendorId, clientId, sellChannel, status});

const findChargeById = (id) =>
  axios.post('/charges/find_charge_by_id', {id});

const findCharges = (skip, take, searchParams, orderParams) =>
  axios.post('/charges/find_charges', {skip, take, searchParams, orderParams});

const pendingBillsCharge = (clientId, company) =>
  axios.post('/charges/pending_bills_charge', {clientId, company});

const pendingRefundsOrder = (clientId, company) =>
  axios.post('/charges/pending_refunds_order', {clientId, company});

const addLine = (line, vendorId, clientId, sellChannel, company, status) =>
  axios.post('/charges/add_line', {line, vendorId, clientId, sellChannel, company, status});

const updateCharge = (charge, newParams) =>
  axios.post('/charges/update_charge', {charge, newParams});

const processCharge = (charge, newParams) =>
  axios.post('/charges/process', {charge, newParams});

const updateLine = (charge, id, newParam) =>
  axios.post('/charges/update_line', {charge, id, newParam});

const deleteLine = (charge, id) =>
  axios.post('/charges/delete_line', {charge, id});

const selectedAll = (charge, selected) =>
  axios.post('/charges/selected_all', {charge, selected});

const deleteSelected = (charge, ids) =>
  axios.post('/charges/delete_selected', {charge, ids});

const incomesCharges = (vendorId, date) =>
  axios.post(`/charges/incomes_charges`, {vendorId, date});

export const chargesService = {
  findCharge,
  findChargeById,
  findCharges,
  pendingBillsCharge,
  pendingRefundsOrder,
  addLine,
  updateCharge,
  processCharge,
  updateLine,
  deleteLine,
  selectedAll,
  deleteSelected,
  incomesCharges,
};
