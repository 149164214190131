import { f7 } from 'framework7-vue';

export const StoreInput = {
  module: 'input',
  actions: {
    setInput: 'setInput',
    setType: 'setType',
    openInput: 'openInput',
    clearFilters: 'clearFilters',
    setFilter: 'setFilter',
    setExtraFilters: 'setExtraFilters',
    toggleStatus: 'toggleStatus',
    setClientPopup: 'setClientPopup',
  },
  mutations: {
    setInput: 'SET_INPUT',
    setType: 'SET_TYPE',
    clearFilters: 'CLEAR_FILTERS',
    setFilter: 'SET_FILTER',
    setExtraFilters: 'SET_EXTRA_FILTERS',
    addStatus: 'ADD_STATUS',
    removeStatus: 'REMOVE_STATUS',
    setClientPopup: 'SET_CLIENT_POPUP',
  }
};

const state = {
  value: 0,
  units: '€',
  type: 'dialog',
  filters: {
    statuses: [],
    clientSearch: '',
    reportSearch: '',
    documentSearch: '',
    fromDate: [],
    toDate: [],
    companyIndex: '0',
  },
  extraFilters: {},
  clientPopup: {
    type: '',
    id: '',
  },
};

const getters = {
  isStatusChecked: (state) => (status) => state.filters.statuses.includes(status),
};

const actions = {
  [StoreInput.actions.setInput]: ({ commit }, { value, units }) => {
    commit(StoreInput.mutations.setInput, { value, units });
  },
  [StoreInput.actions.setType]: ({ commit }, type) => {
    commit(StoreInput.mutations.setType, type);
  },
  [StoreInput.actions.openInput]: ({ state }) => {
    // console.log(state.type);
    f7.sheet.get(`#input-${state.type}-sheet`).open();
  },
  [StoreInput.actions.clearFilters]: ({ commit }) => {
    commit(StoreInput.mutations.clearFilters);
  },
  [StoreInput.actions.setFilter]: ({ commit }, { key, value }) => {
    commit(StoreInput.mutations.setFilter, { key, value });
  },
  [StoreInput.actions.toggleStatus]: ({ commit, state }, status) => {
    const s = state.filters.statuses.findIndex(s => s === status);
    if (s > -1) {
      commit(StoreInput.mutations.removeStatus, s);
    } else commit(StoreInput.mutations.addStatus, status);
  },
  [StoreInput.actions.setExtraFilters]: ({ commit }, extraFilters) => {
    commit(StoreInput.mutations.setExtraFilters, extraFilters);
  },
  [StoreInput.actions.setClientPopup]: async ({commit, state}, newClientPopup) => {
    commit(StoreInput.mutations.setClientPopup, newClientPopup);
  },
};

const mutations = {
  [StoreInput.mutations.setInput]: (state, { value, units }) => {
    state.value = value;
    state.units = units;
  },
  [StoreInput.mutations.setType]: (state, type) => {
    state.type = type;
  },
  [StoreInput.mutations.clearFilters]: (state) => {
    state.filters = {
      statuses: [],
      clientSearch: '',
      reportSearch: '',
      documentSearch: '',
      fromDate: [],
      toDate: [],
      companyIndex: !!state.filters.companyIndex ? state.filters.companyIndex : '0',
    };
  },
  [StoreInput.mutations.setFilter]: (state, { key, value }) => {
    state.filters[key] = value;
  },
  [StoreInput.mutations.addStatus]: (state, status) => {
    state.filters.statuses.push(status);
  },
  [StoreInput.mutations.removeStatus]: (state, index) => {
    state.filters.statuses.splice(index, 1);
  },
  [StoreInput.mutations.setExtraFilters]: (state, extraFilters) => {
    // console.log(extraFilters);
    state.extraFilters = extraFilters;
  },
  [StoreInput.mutations.setClientPopup]: (state, newClientPopup) => {
    state.clientPopup = newClientPopup;
  },
};

export const inputStore = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
