import { StoreSession } from './session.types';
import { usersService } from '../../api/graphql/services/users.service';
import { usersService as httpUsersService } from '../../api/http/users.service';
import { Plugins } from '@capacitor/core';
import { StoreUserSettings } from '../user-settings/user-settings.index';
import { StoreClients } from '../clients/clients.index';
import { StoreCatalog } from '../catalog/catalog.index';
import { vendorsService } from '../../api/graphql/services/vendors.service';
import { ROLE } from '../../config/enums.config';
import { revisersService } from '../../api/graphql/services/revisers.service';
import { StoreShoppingCart } from '../shopping-cart/shopping-cart.index';
import { StoreApp } from '../app/app.index';
import { distributorsService } from '../../api/http/distributors.service';

const { Storage } = Plugins;

export const actions = {
  [StoreSession.actions.getProfile]: async ({ commit, dispatch }) => {
    try {
      const { data } = await usersService.getProfile();
      const { profile: {sessionInfo, userSettings, ...user} } = data;
      if (user.roles.includes(ROLE.VENDOR.code) || user.roles.includes(ROLE.CHIEFTEAM.code)) {
        const { data: { vendor, vendorTeams }} = await vendorsService.getChiefTeam(user.username);
        user.vendor = vendor;
        user.vendorTeams = vendorTeams;
      }
      if (user.roles.includes(ROLE.REVISER.code) || user.roles.includes(ROLE.OFFICE.code) || user.roles.includes(ROLE.ADMIN.code)) {
        const { data: { reviser }} = await revisersService.getReviser(user.username);
        user.reviser = reviser;
      }
      if (user.roles.includes(ROLE.DISTRIBUTOR.code) || user.roles.includes(ROLE.DISTRIBUTOR_CHIEFTEAM.code) || user.roles.includes(ROLE.DISTRIBUTOR_VENDOR.code)) {
        const { data } = await distributorsService.findDistributorByUser(user.username);
        // console.log(data);
        user.distributor = data;
      }
      commit(StoreSession.mutations.setUser, { user, sessionInfo });
      commit(`${StoreUserSettings.module}/${StoreUserSettings.mutations.setUserSettings}`, userSettings, {root: true});
      await dispatch('input/setType', userSettings.hasKeyboard ? 'dialog' : 'calculator', { root: true });
      dispatch(StoreSession.actions.getSessionInfo, sessionInfo);
    } catch (error) {
      // console.log(error);
      commit(StoreSession.mutations.authFailure);
    }
  },
  [StoreSession.actions.login]: async ({commit, dispatch}, { username, password }) => {
    try {
      const { data } = await usersService.login({ username, password });
      const { token: accessToken } = data.login;
      await Storage.set({key: 'accessToken', value: accessToken});
      commit(StoreSession.mutations.authSuccess, { accessToken });
      await dispatch(StoreSession.actions.getProfile);
    } catch (error) {
      commit(StoreSession.mutations.authFailure);
    }
  },
  [StoreSession.actions.logout]: async ({commit}) => {
    await Storage.remove({key: 'accessToken'});
    commit(StoreSession.mutations.logout);
  },
  [StoreSession.actions.setAccessToken]: ({ commit }, accessToken) => {
    commit(StoreSession.mutations.setAccessToken, accessToken);
  },
  [StoreSession.actions.getSessionInfo]: ({ commit, dispatch }, newSessionInfo) => {
    dispatch(`${StoreApp.module}/${StoreApp.actions.getCompany}`, newSessionInfo.company, { root: true });
    dispatch(`${StoreApp.module}/${StoreApp.actions.getPayments}`, null, { root: true });
    dispatch(`${StoreCatalog.module}/${StoreCatalog.actions.getFavorites}`, null, {root: true});
    dispatch(`${StoreCatalog.module}/${StoreCatalog.actions.getPathNames}`, null, {root: true});
    dispatch(`${StoreClients.module}/${StoreClients.actions.getCurrentClient}`, newSessionInfo.selectedClient, {root: true});
    dispatch(`${StoreShoppingCart.module}/${StoreShoppingCart.actions.getPendingCart}`, null, { root: true });
    dispatch(`${StoreClients.module}/${StoreClients.actions.getCooperativas}`, null, {root: true});
  },
  [StoreSession.actions.setSessionInfo]: async ({ commit, state, dispatch, rootState }, partialSessionInfo) => {
    try {
      const oldSessionInfo = state.sessionInfo;
      if (state.sessionInfo && partialSessionInfo.selectedCode && state.sessionInfo.selectedCode !== partialSessionInfo.selectedCode
        && (!partialSessionInfo.selectedClient)) {
        partialSessionInfo.selectedClient = '1';
      }
      commit(StoreSession.mutations.setSessionInfo, partialSessionInfo);
      if (oldSessionInfo.selectedCode !== state.sessionInfo.selectedCode) {
        await dispatch(`${StoreCatalog.module}/${StoreCatalog.actions.clearPath}`, null, {root: true});
        dispatch(`${StoreCatalog.module}/${StoreCatalog.actions.getPathNames}`, null, {root: true});
        // await dispatch('catalog/getFavorites', {skus: rootState.userSettings.userSettings.favProducts}, {root: true});
        // await dispatch('shoppingCart/getPendingCart', null, { root: true });
        // await dispatch('newClient/resetNewClient', null, {root: true});
        // await dispatch('newClient/setFormFilled', false, {root: true});
        // commit(SET_CLIENTS, null);
      }
      if (oldSessionInfo.selectedClient !== state.sessionInfo.selectedClient || oldSessionInfo.selectedCode !== state.sessionInfo.selectedCode) {
        await dispatch(`${StoreClients.module}/${StoreClients.actions.getCurrentClient}`, partialSessionInfo.selectedClient, { root: true });
        await dispatch('pendingOrder/getPendingOrder', { updateTranslations: true }, {root: true});
        // await dispatch('pendingCharge/getPendingCharge', null, {root: true});
        // await dispatch('pendingRefund/getPendingRefund', { updateTranslations: true }, {root: true});
      }
      if (oldSessionInfo.company !== state.sessionInfo.company) {
        await dispatch(`${StoreApp.module}/${StoreApp.actions.getCompany}`, state.sessionInfo.company, { root: true });
      }
    //   if (oldSessionInfo.status !== state.sessionInfo.status) {
    //     await dispatch('pendingOrder/getPendingOrder', { updateTranslations: true }, {root: true});
    //     await dispatch('pendingRefund/getPendingRefund', { updateTranslations: true }, {root: true});
    //   }
      if (oldSessionInfo.sellChannel !== state.sessionInfo.sellChannel) {
        // await dispatch('getPayments');
        dispatch(`${StoreClients.module}/${StoreClients.actions.getCooperativas}`, null, {root: true});
      }
      const res = await httpUsersService.updateSessionInfo(partialSessionInfo);
      commit(StoreSession.mutations.setSessionInfo, { catalogos: res?.data?.catalogos ?? [], codigosExtra: res?.data?.codigosExtra ?? []});
    } catch (e) {
      // console.log(e);
      // commit(SESSION_INFO_FAILURE, e);
    }
  },
};
