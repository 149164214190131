<template>
  <f7-block>
    <f7-button href="/" view=".view-main" icon-f7="house_fill" :icon-size="20" :text="$t('pages.home')"></f7-button>
  </f7-block>
</template>
<script>
export default {
  name: 'main-menu-button',
};
</script>
