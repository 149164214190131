import { apolloClient } from '@/js/plugins/apollo-client';
import {
  ADD_SHOPPING_CART_LINE_GQL, EDIT_SHOPPING_CART_GQL,
  EDIT_SHOPPING_CART_LINE_GQL,
  GET_SHOPPING_CART_GQL, REMOVE_SHOPPING_CART_GQL, REMOVE_SHOPPING_CART_LINE_GQL
} from '../queries/shopping-carts/shopping-carts.queries';
import { ADD_PRODUCT_GQL, ADD_SHOPPING_CART_GQL } from '../queries/shopping-carts/shopping-carts.mutations';

const getPendingCart = (username, vendorCode, language) => apolloClient.query({ query: GET_SHOPPING_CART_GQL, variables: { username, vendorCode, language }});
const addPendingCartLine = (line) => apolloClient.mutate({mutation: ADD_SHOPPING_CART_LINE_GQL, variables: { line }});
const editPendingCartLine = (line) => apolloClient.mutate({mutation: EDIT_SHOPPING_CART_LINE_GQL, variables: { line }});
const removePendingCartLine = (line) => apolloClient.mutate({mutation: REMOVE_SHOPPING_CART_LINE_GQL, variables: { line }});
const editPendingCart = (shoppingCart) => apolloClient.mutate({mutation: EDIT_SHOPPING_CART_GQL, variables: { shoppingCart }});
const clearPendingCart = (id) => apolloClient.mutate({mutation: REMOVE_SHOPPING_CART_GQL, variables: { id }});
const addPendingCartToOrder = ({ id, clientId, sellChannel, status, groupPrice }, force = false) => {
  const params = {mutation: ADD_SHOPPING_CART_GQL, variables: { id, clientId, sellChannel, status, groupPrice }};
  if (force) params.fetchPolicy = 'no-cache';
  return apolloClient.mutate(params);
};
const addProductToOrder = ({ optionSku, clientId, sellChannel, status, groupPrice }, force = false) => {
  const params = {mutation: ADD_PRODUCT_GQL, variables: { optionSku, clientId, sellChannel, status, groupPrice }};
  if (force) params.fetchPolicy = 'no-cache';
  return apolloClient.mutate(params);
}

export const shoppingCartService = {
  getPendingCart,
  addPendingCartLine,
  editPendingCartLine,
  removePendingCartLine,
  editPendingCart,
  clearPendingCart,
  addPendingCartToOrder,
  addProductToOrder,
};
