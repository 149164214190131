import { apolloClient } from '@/js/plugins/apollo-client';
import { DELETE_LINE_GQL, DELETE_SELECTED_GQL, FIND_REFUND_GQL } from '../queries/refunds/refunds.queries';

const findRefund = async ({ clientId, vendorId, sellChannel, status, language }) =>
  apolloClient.query({query: FIND_REFUND_GQL, variables: { clientId, vendorId, sellChannel, status, language }, fetchPolicy: 'network-only'});
const deleteSelected = async ({ refund, ids, language }) =>
  apolloClient.mutate({ mutation: DELETE_SELECTED_GQL, variables: { refund, ids, language }});
const deleteLine = async ({ refund, id, language }) =>
  apolloClient.mutate({ mutation: DELETE_LINE_GQL, variables: { refund, id, language }});

export const refundsService = {
  findRefund,
  deleteLine,
  deleteSelected,
};
