import { reactive, computed } from 'vue';
import { mainMenu } from '../../../js/config/menu.config';
import { i18n } from '../../../js/plugins/vue-i18n';
import { store } from '../../../js/stores/vuex';

const { t } = i18n.global;

const state = reactive({
  query: '',
  menu: computed(() => {
    console.log(store.state.session.sessionInfo?.sellChannel);
    return mainMenu.filter(l => store.getters['session/hasAnyRole'](l.roles) &&
      !l.blockedChannels.includes(store.state.session.sessionInfo?.sellChannel) && (state.query === '' ||
        t(l.title).toLowerCase().indexOf(state.query.toLowerCase()) > -1))
  }),
});

export const MainMenuComposition = () => {
  return { state };
}
