import { ROLE, SELL_CHANNEL, COMPANY } from './enums.config';

export const mainMenu = [
  {
    title: 'pages.dashboard',
    link: '/dashboard/',
    icon: 'las la-tachometer-alt',
    roles: [ROLE.ADMIN.code, ROLE.REVISER.code, ROLE.CHIEFTEAM.code, ROLE.VENDOR.code, ROLE.CATALOG_REVISER.code,
      ROLE.DISTRIBUTOR_VENDOR.code, ROLE.DISTRIBUTOR_CHIEFTEAM.code, ROLE.DISTRIBUTOR.code],
    blockedChannels: [],
  },
  {
    title: 'pages.users',
    link: '/users/',
    icon: 'las la-suitcase',
    roles: [ROLE.ADMIN.code],
    blockedChannels: [],
  },
  {
    title: 'pages.employees',
    link: '/employees/',
    icon: 'las la-suitcase',
    roles: [ROLE.ADMIN.code],
    blockedChannels: [],
  },
  {
    title: 'pages.distributors',
    link: '/distributors/',
    icon: 'las la-suitcase',
    roles: [ROLE.ADMIN.code],
    blockedChannels: [],
  },
  {
    title: 'pages.catalog',
    link: '/catalog/',
    icon: 'las la-shopping-cart',
    roles: [ROLE.ADMIN.code, ROLE.CATALOG_REVISER.code, ROLE.OFFICE.code],
    blockedChannels: [],
  },
  {
    title: 'pages.reports',
    link: '/reports/',
    icon: 'las la-file-alt',
    roles: [ROLE.ADMIN.code, ROLE.VENDOR.code],
    blockedChannels: [],
  },
  {
    title: 'pages.statistics',
    link: '/statistics/',
    icon: 'las la-chart-pie',
    roles: [ROLE.ADMIN.code, ROLE.DISTRIBUTOR.code, ROLE.REVISER.code],
    blockedChannels: [],
  },
  {
    title: 'pages.addPreClient',
    link: '/new-client/',
    icon: 'las la-user-plus',
    roles: [ROLE.ADMIN.code, ROLE.VENDOR.code, ROLE.REVISER.code],
    blockedChannels: [],
  },
  {
    title: 'pages.addDistributorClient',
    link: '/new-client-distributor/',
    icon: 'las la-user-plus',
    roles: [ROLE.ADMIN.code, ROLE.DISTRIBUTOR_VENDOR.code, ROLE.DISTRIBUTOR_CHIEFTEAM.code, ROLE.DISTRIBUTOR.code],
    blockedChannels: [],
  },
  {
    title: 'pages.approvalOrders',
    link: '/approval-orders/',
    icon: 'las la-store-alt',
    roles: [ROLE.ADMIN.code, ROLE.REVISER.code, ROLE.DISTRIBUTOR_CHIEFTEAM.code, ROLE.DISTRIBUTOR.code],
    blockedChannels: [],
  },
  {
    title: 'pages.approvalRefunds',
    link: '/approval-refunds/',
    icon: 'las la-balance-scale',
    roles: [ROLE.ADMIN.code, ROLE.REVISER.code],
    blockedChannels: [],
  },
  {
    title: 'pages.approvalCharges',
    link: '/approval-charges/',
    icon: 'las la-file-invoice-dollar',
    roles: [ROLE.ADMIN.code, ROLE.REVISER.code],
    blockedChannels: [],
  },
  {
    title: 'pages.pendingOrder',
    link: '/pending-order/',
    icon: 'las la-shopping-cart',
    roles: [ROLE.ADMIN.code, ROLE.REVISER.code, ROLE.VENDOR.code, ROLE.CLIENT.code, ROLE.DISTRIBUTOR_VENDOR.code, ROLE.DISTRIBUTOR_CHIEFTEAM, ROLE.DISTRIBUTOR.code],
    blockedChannels: [],
  },
  {
    title: 'pages.previousOrders',
    link: '/previous-orders/',
    icon: 'las la-box',
    roles: [ROLE.ADMIN.code, ROLE.REVISER.code, ROLE.VENDOR.code, ROLE.CLIENT.code,
      ROLE.DISTRIBUTOR_VENDOR.code, ROLE.DISTRIBUTOR_CHIEFTEAM, ROLE.DISTRIBUTOR.code, ROLE.OFFICE.code],
    blockedChannels: [],
  },
  {
    title: 'pages.pendingRefund',
    link: '/pending-refund/',
    icon: 'las la-tag',
    roles: [ROLE.ADMIN.code, ROLE.REVISER.code, ROLE.VENDOR.code],
    blockedChannels: [SELL_CHANNEL.TAHE_FRANCIA.code, SELL_CHANNEL.TAHE_ALEMANIA.code],
  },
  {
    title: 'pages.previousRefunds',
    link: '/previous-refunds/',
    icon: 'las la-tags',
    roles: [ROLE.ADMIN.code, ROLE.REVISER.code, ROLE.VENDOR.code, ROLE.OFFICE.code],
    blockedChannels: [SELL_CHANNEL.TAHE_FRANCIA.code, SELL_CHANNEL.TAHE_ALEMANIA.code],
  },
  {
    title: 'pages.pendingCharge',
    link: '/pending-charge/',
    icon: 'las la-euro-sign',
    roles: [ROLE.ADMIN.code, ROLE.VENDOR.code],
    blockedChannels: [...COMPANY.TH_PHARMA.sellChannels, ...COMPANY.KELAYA_2016.sellChannels, ...COMPANY.BOCARE_2016.sellChannels].concat([
      SELL_CHANNEL.TAHE_FRANCIA.code, SELL_CHANNEL.TAHE_ALEMANIA.code
    ]),
  },
  {
    title: 'pages.previousCharges',
    link: '/previous-charges/',
    icon: 'las la-file-invoice-dollar',
    roles: [ROLE.ADMIN.code, ROLE.VENDOR.code, ROLE.OFFICE.code],
    blockedChannels: [...COMPANY.TH_PHARMA.sellChannels, ...COMPANY.KELAYA_2016.sellChannels, ...COMPANY.BOCARE_2016.sellChannels].concat([
      SELL_CHANNEL.TAHE_FRANCIA.code, SELL_CHANNEL.TAHE_ALEMANIA.code
    ]),
  },
  {
    title: 'clientes_sin_validar',
    link: '/clientes-sin-validar/',
    icon: 'las la-user-circle',
    roles: [ROLE.ADMIN.code, ROLE.REVISER.code, ROLE.VENDOR.code],
    blockedChannels: [],
  },
  {
    title: 'pages.clientManagement',
    link: '/client-management/',
    icon: 'las la-user-circle',
    roles: [ROLE.ADMIN.code, ROLE.REVISER.code, ROLE.VENDOR.code],
    blockedChannels: [],
  },
  {
    title: 'pages.vendorManagement',
    link: '/vendor-management/',
    icon: 'las la-user-tie',
    roles: [ROLE.ADMIN.code, ROLE.REVISER.code, ROLE.VENDOR.code],
    blockedChannels: [],
  },
  {
    title: 'pages.extracts',
    link: '/extracts/',
    icon: 'las la-list',
    roles: [ROLE.ADMIN.code, ROLE.REVISER.code, ROLE.VENDOR.code],
    blockedChannels: [],
  },
  {
    title: 'pages.bills',
    link: '/bills/',
    icon: 'las la-file-invoice-dollar',
    roles: [ROLE.ADMIN.code, ROLE.REVISER.code, ROLE.VENDOR.code, ROLE.CLIENT.code],
    blockedChannels: [],
  },
  {
    title: 'pages.billsRefunds',
    link: '/bills-refunds/',
    icon: 'las la-file-upload',
    roles: [ROLE.ADMIN.code, ROLE.REVISER.code, ROLE.VENDOR.code, ROLE.CLIENT.code],
    blockedChannels: [],
  },
  {
    title: 'pages.soldProducts',
    link: '/sold-products/',
    icon: 'las la-fire-alt',
    roles: [ROLE.ADMIN.code, ROLE.REVISER.code, ROLE.VENDOR.code],
    blockedChannels: [SELL_CHANNEL.TAHE_FRANCIA.code, SELL_CHANNEL.KELAYA_FRANCIA.code, SELL_CHANNEL.BOCARE_FRANCIA.code, SELL_CHANNEL.TAHE_ALEMANIA.code, SELL_CHANNEL.KELAYA_ALEMANIA.code, SELL_CHANNEL.BOCARE_ALEMANIA.code],
  },
  {
    title: 'pages.expeditions',
    link: '/expeditions/',
    icon: 'las la-shipping-fast', // las la-people-carry
    roles: [ROLE.ADMIN.code, ROLE.REVISER.code, ROLE.VENDOR.code, ROLE.CLIENT.code],
    blockedChannels: [],
  },
  {
    title: 'pages.expenses',
    link: '/expenses/',
    icon: 'las la-credit-card',
    roles: [ROLE.ADMIN.code, ROLE.VENDOR.code],
    blockedChannels: [SELL_CHANNEL.TH_ITALIA.code].concat([
      SELL_CHANNEL.TAHE_ITALIA.code, SELL_CHANNEL.TAHE_FRANCIA.code, SELL_CHANNEL.KELAYA_FRANCIA.code, SELL_CHANNEL.BOCARE_FRANCIA.code, SELL_CHANNEL.TAHE_ALEMANIA.code, SELL_CHANNEL.KELAYA_ALEMANIA.code, SELL_CHANNEL.BOCARE_ALEMANIA.code]),
  },
  {
    title: 'pages.incomes',
    link: '/incomes/',
    icon: 'las la-dollar-sign',
    roles: [ROLE.ADMIN.code, ROLE.VENDOR.code],
    blockedChannels: [...COMPANY.TH_PHARMA.sellChannels, ...COMPANY.KELAYA_2016.sellChannels, ...COMPANY.BOCARE_2016.sellChannels].concat([
      SELL_CHANNEL.TAHE_PORTUGAL.code,
      SELL_CHANNEL.TAHE_ITALIA.code,
      SELL_CHANNEL.TAHE_FRANCIA.code,
      SELL_CHANNEL.TAHE_ALEMANIA.code
    ]),
  },
  {
    title: 'pages.pendingToShip',
    link: '/pendiente-servir/',
    icon: 'las la-boxes',
    roles: [ROLE.ADMIN.code, ROLE.REVISER.code, ROLE.VENDOR.code, ROLE.CLIENT.code],
    blockedChannels: [],
  },
  // {
  //   title: 'pages.productosPendientes',
  //   link: '/productos-pendientes/',
  //   icon: 'las la-boxes',
  //   roles: [ROLE.ADMIN.code, ROLE.REVISER.code, ROLE.VENDOR.code, ROLE.CLIENT.code],
  //   blockedChannels: [],
  // },
  {
    title: 'pages.clientsContracts',
    link: '/clients-contracts/',
    icon: 'las la-pen-fancy',
    roles: [ROLE.ADMIN.code, ROLE.VENDOR.code],
    blockedChannels: [SELL_CHANNEL.TAHE_FRANCIA.code, SELL_CHANNEL.KELAYA_FRANCIA.code, SELL_CHANNEL.BOCARE_FRANCIA.code, SELL_CHANNEL.TAHE_ALEMANIA.code, SELL_CHANNEL.KELAYA_ALEMANIA.code, SELL_CHANNEL.BOCARE_ALEMANIA.code],
  },
  {
    title: 'pages.refundsStatus',
    link: '/refunds-status/',
    icon: 'las la-archive',
    roles: [ROLE.ADMIN.code, ROLE.REVISER.code, ROLE.VENDOR.code],
    blockedChannels: [SELL_CHANNEL.TAHE_FRANCIA.code, SELL_CHANNEL.KELAYA_FRANCIA.code, SELL_CHANNEL.BOCARE_FRANCIA.code, SELL_CHANNEL.TAHE_ALEMANIA.code, SELL_CHANNEL.KELAYA_ALEMANIA.code, SELL_CHANNEL.BOCARE_ALEMANIA.code],
  },
  {
    title: 'pages.cursos',
    link: '/cursos/',
    icon: 'las la-users',
    roles: [ROLE.ADMIN.code, ROLE.REVISER.code, ROLE.VENDOR.code],
    blockedChannels: [...COMPANY.TH_PHARMA.sellChannels, ...COMPANY.KELAYA_2016.sellChannels, ...COMPANY.BOCARE_2016.sellChannels].concat([
      SELL_CHANNEL.TAHE_FRANCIA.code,
        SELL_CHANNEL.TAHE_ALEMANIA.code
    ]),
  },
  {
    title: 'pages.topClientes',
    link: '/top-clientes/',
    icon: 'las la-suitcase',
    roles: [ROLE.ADMIN.code, ROLE.REVISER.code, ROLE.VENDOR.code],
    blockedChannels: [...COMPANY.KELAYA_2016.sellChannels, ...COMPANY.BOCARE_2016.sellChannels].concat([
      SELL_CHANNEL.TAHE_FRANCIA.code,
        SELL_CHANNEL.TAHE_ALEMANIA.code
    ]),
  },
  {
    title: 'pages.commissions',
    link: '/commissions/',
    icon: 'las la-percent',
    roles: [ROLE.ADMIN.code, ROLE.VENDOR.code],
    blockedChannels: [...COMPANY.TAHE_2016.sellChannels, ...COMPANY.KELAYA_2016.sellChannels, ...COMPANY.BOCARE_2016.sellChannels]
      .concat([
        SELL_CHANNEL.TH_PORTUGAL.code,
        SELL_CHANNEL.TH_ITALIA.code,
        SELL_CHANNEL.TH_INTERNACIONAL.code,
        SELL_CHANNEL.TH_OUTLET.code,
      ]),
  },
  {
    title: 'pages.settings',
    link: '/settings/',
    icon: 'las la-cog',
    roles: [ROLE.ADMIN.code, ROLE.REVISER.code, ROLE.CHIEFTEAM.code, ROLE.VENDOR.code, ROLE.CLIENT.code, ROLE.CATALOG_REVISER.code,
      ROLE.DISTRIBUTOR_VENDOR.code, ROLE.DISTRIBUTOR_CHIEFTEAM.code, ROLE.DISTRIBUTOR.code, ROLE.OFFICE.code],
    blockedChannels: [],
  },
  {
    title: 'pages.changelog',
    link: '/changelog/',
    icon: 'las la-magic',
    roles: [ROLE.ADMIN.code, ROLE.REVISER.code, ROLE.VENDOR.code],
    blockedChannels: [...COMPANY.KELAYA_2016.sellChannels, ...COMPANY.BOCARE_2016.sellChannels].concat([
      SELL_CHANNEL.TAHE_FRANCIA.code,
        SELL_CHANNEL.TAHE_ALEMANIA.code
    ]),
  },
  {
    title: 'pages.faqStores',
    link: '/faq-stores/',
    icon: 'las la-cut',
    roles: [ROLE.ADMIN.code, ROLE.VENDOR.code],
    blockedChannels: [...COMPANY.TH_PHARMA.sellChannels, ...COMPANY.KELAYA_2016.sellChannels, ...COMPANY.BOCARE_2016.sellChannels].concat([
      SELL_CHANNEL.TAHE_PORTUGAL.code,
      SELL_CHANNEL.TAHE_ITALIA.code,
      SELL_CHANNEL.TAHE_FRANCIA.code,
        SELL_CHANNEL.TAHE_ALEMANIA.code
    ]),
  },
  {
    title: 'pages.faq',
    link: '/faq/',
    icon: 'las la-question',
    roles: [ROLE.ADMIN.code, ROLE.REVISER.code, ROLE.VENDOR.code],
    blockedChannels: [...COMPANY.KELAYA_2016.sellChannels, ...COMPANY.BOCARE_2016.sellChannels].concat([
      SELL_CHANNEL.TAHE_FRANCIA.code,
        SELL_CHANNEL.TAHE_ALEMANIA.code
    ]),
  },
];

export const clientManagementMenu = [
  {
    title: 'text.info',
    link: '/client-management/',
    icon: 'las la-info-circle',
    roles: Object.keys(ROLE).map(k => ROLE[k].code),
    blockedChannels: [],
    blockedForNewClient: false,
  },
  {
    title: 'pages.clientPendingOrder',
    link: '/pending-order/',
    icon: 'las la-shopping-cart',
    roles: Object.keys(ROLE).map(k => ROLE[k].code),
    blockedChannels: [],
    blockedForNewClient: false,
  },
  {
    title: 'pages.clientPreviousOrders',
    link: '/client-previous-orders/',
    icon: 'las la-box',
    roles: Object.keys(ROLE).map(k => ROLE[k].code),
    blockedChannels: [],
    blockedForNewClient: false,
  },
  {
    title: 'pages.clientPendingRefund',
    link: '/pending-refund/',
    icon: 'las la-tag',
    roles: Object.keys(ROLE).map(k => ROLE[k].code),
    blockedChannels: [SELL_CHANNEL.TAHE_FRANCIA.code, SELL_CHANNEL.KELAYA_FRANCIA.code, SELL_CHANNEL.BOCARE_FRANCIA.code, SELL_CHANNEL.TAHE_ALEMANIA.code, SELL_CHANNEL.KELAYA_ALEMANIA.code, SELL_CHANNEL.BOCARE_ALEMANIA.code],
    blockedForNewClient: true,
  },
  {
    title: 'pages.clientPreviousRefunds',
    link: '/client-previous-refunds/',
    icon: 'las la-tags',
    roles: Object.keys(ROLE).map(k => ROLE[k].code),
    blockedChannels: [SELL_CHANNEL.TAHE_FRANCIA.code, SELL_CHANNEL.KELAYA_FRANCIA.code, SELL_CHANNEL.BOCARE_FRANCIA.code, SELL_CHANNEL.TAHE_ALEMANIA.code, SELL_CHANNEL.KELAYA_ALEMANIA.code, SELL_CHANNEL.BOCARE_ALEMANIA.code],
    blockedForNewClient: true,
  },
  {
    title: 'pages.clientPendingCharge',
    link: '/pending-charge/',
    icon: 'las la-euro-sign',
    roles: Object.keys(ROLE).map(k => ROLE[k].code),
    blockedChannels: [SELL_CHANNEL.TAHE_FRANCIA.code, SELL_CHANNEL.KELAYA_FRANCIA.code, SELL_CHANNEL.BOCARE_FRANCIA.code, SELL_CHANNEL.TAHE_ALEMANIA.code, SELL_CHANNEL.KELAYA_ALEMANIA.code, SELL_CHANNEL.BOCARE_ALEMANIA.code],
    blockedForNewClient: true,
  },
  {
    title: 'pages.clientPreviousCharges',
    link: '/client-previous-charges/',
    icon: 'las la-file-invoice-dollar',
    roles: Object.keys(ROLE).map(k => ROLE[k].code),
    blockedChannels: [SELL_CHANNEL.TAHE_FRANCIA.code, SELL_CHANNEL.KELAYA_FRANCIA.code, SELL_CHANNEL.BOCARE_FRANCIA.code, SELL_CHANNEL.TAHE_ALEMANIA.code, SELL_CHANNEL.KELAYA_ALEMANIA.code, SELL_CHANNEL.BOCARE_ALEMANIA.code],
    blockedForNewClient: true,
  },
  {
    title: 'pages.clientPendingToShip',
    link: '/client-pending-to-ship/',
    icon: 'las la-boxes',
    roles: Object.keys(ROLE).map(k => ROLE[k].code),
    blockedChannels: [],
    blockedForNewClient: true,
  },
  {
    title: 'pages.clientExpeditions',
    link: '/client-expeditions/',
    icon: 'las la-shipping-fast',
    roles: Object.keys(ROLE).map(k => ROLE[k].code),
    blockedChannels: [],
    blockedForNewClient: true,
  },
  {
    title: 'pages.clientGiros',
    link: '/client-giros/',
    icon: 'las la-landmark',
    roles: Object.keys(ROLE).map(k => ROLE[k].code),
    blockedChannels: [],
    blockedForNewClient: true,
  },
  {
    title: 'pages.clientPendingBills',
    link: '/client-pending-bills/',
    icon: 'las la-file-invoice',
    roles: Object.keys(ROLE).map(k => ROLE[k].code),
    blockedChannels: [],
    blockedForNewClient: true,
  },
  {
    title: 'pages.clientObjectives',
    link: '/client-objectives/',
    icon: 'las la-chart-area',
    roles: Object.keys(ROLE).map(k => ROLE[k].code),
    blockedChannels: [SELL_CHANNEL.TAHE_FRANCIA.code, SELL_CHANNEL.KELAYA_FRANCIA.code, SELL_CHANNEL.BOCARE_FRANCIA.code, SELL_CHANNEL.TAHE_ALEMANIA.code, SELL_CHANNEL.KELAYA_ALEMANIA.code, SELL_CHANNEL.BOCARE_ALEMANIA.code],
    blockedForNewClient: true,
  },
  {
    title: 'pages.clientContracts',
    link: '/client-contracts/',
    icon: 'las la-pen-fancy',
    roles: Object.keys(ROLE).map(k => ROLE[k].code),
    blockedChannels: [SELL_CHANNEL.TAHE_FRANCIA.code, SELL_CHANNEL.KELAYA_FRANCIA.code, SELL_CHANNEL.BOCARE_FRANCIA.code, SELL_CHANNEL.TAHE_ALEMANIA.code, SELL_CHANNEL.KELAYA_ALEMANIA.code, SELL_CHANNEL.BOCARE_ALEMANIA.code],
    blockedForNewClient: true,
  },
  {
    title: 'pages.clientExtracts',
    link: '/client-extracts/',
    icon: 'las la-list',
    roles: Object.keys(ROLE).map(k => ROLE[k].code),
    blockedChannels: [],
    blockedForNewClient: true,
  },
  {
    title: 'pages.clientBills',
    link: '/client-bills/',
    icon: 'las la-file-invoice-dollar',
    roles: Object.keys(ROLE).map(k => ROLE[k].code),
    blockedChannels: [],
    blockedForNewClient: true,
  },
  {
    title: 'pages.clientBillsRefunds',
    link: '/client-bills-refunds/',
    icon: 'las la-file-upload',
    roles: Object.keys(ROLE).map(k => ROLE[k].code),
    blockedChannels: [],
    blockedForNewClient: true,
  },
];
