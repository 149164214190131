import { COMPANY } from '../config/enums.config';

export const billURL = (company, document, clientId, type, withFormat = true) => {
  // console.log(type);
  console.log(company);
  const c = Object.keys(COMPANY).find(k => COMPANY[k].database === company);
  const numDocument = document.match(/\d+/g)[0];
  const code = (parseInt(numDocument) + parseInt(clientId)) * 3;
  return `https://${COMPANY[c].billUrl}/${type === 'invoice' ? 'factura' : 'abono'}/${document}.${code}${withFormat ? '' : ''}${type === 'invoice' ? '' : '?formato=pdf'}`;
};

export const loadImage = (url) => {
  // console.log(url);
  return new Promise((resolve) => {
    let img = new Image();
    img.onload = () => resolve(img);
    img.src = url;
  })
};

export const switchDb = (database) => {
  switch(database) {
    case 'MAGAÑA': { return { company: 'Tahe Productos Cosméticos S_L_', type: 'NAV2016' }; }
    case 'TH PHARMA': { return { company: 'TH Pharma S_L_', type: 'NAV2016' }; }
    case 'Tahe Productos Cosméticos S_L_': { return { company: 'MAGAÑA', type: 'NAV360' }; }
    case 'TH Pharma S_L_': { return { company: 'TH PHARMA', type: 'NAV360' }; }
    case 'KELAYA': { return { company: 'KELAYA', type: 'NAV2016' }; }
    case 'BOCARE': { return { company: 'BOCARE', type: 'NAV2016'}; }
    default: { return { company: 'MAGAÑA', type: 'NAV360' }; }
  }
};

export const selectConnection = (company) => {
  switch(company) {
    case 'TAHE_2016': { return [
      { title: 'NAV2016', company: 'Tahe Productos Cosméticos S_L_', type: 'NAV2016' },
      { title: 'NAV360', company: 'MAGAÑA', type: 'NAV360' },
    ]; }
    case 'TH_PHARMA_2016': { return [
      { title: 'NAV2016', company: 'TH Pharma S_L_', type: 'NAV2016' },
      { title: 'NAV360', company: 'TH PHARMA', type: 'NAV360' },
    ]; }
    default: return null;
  }
}
