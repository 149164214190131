<template>
  <f7-sheet id="ocean-blocked-sheet" :close-by-backdrop-click="false" :close-on-escape="false" :swipe-to-close="false"
            style="height: 100%; --f7-sheet-bg-color: rgba(0,0,0,0.2);" backdrop :animate="false">
    <f7-page-content>
      <div class="display-flex flex-direction-column align-items-center justify-content-center" style="flex: 1; height: 100%">
        <f7-block inset class="text-align-center">
          <div class="display-flex flex-direction-column">
            <div class="margin-bottom"><strong class="text-color-white font-size-20px">{{ message }}</strong></div>
            <div class="text-color-white font-size-14px margin-top">({{$t('info.contactWithRRHH')}})</div>
          </div>
        </f7-block>
        <div><f7-button fill large color="green" @click="checkInOcean()">{{ $t('buttons.checkInOcean') }}</f7-button></div>
      </div>
    </f7-page-content>
  </f7-sheet>
</template>
<script>
import { useState } from '../../js/stores/hooks';
import { f7 } from 'framework7-vue';

export default {
  name: 'ocean-blocked-sheet',
  setup: () => {
    const { loading, message } = useState('app', ['loading', 'message']);
    const { user } = useState('session', ['user']);
    const checkInOcean = () => {
      if (user.value.vendor) {
        if (user.value.dni !== '') {
          // console.log('ticar en ocean automáticamente');
        } else {
          window.open('https://ocean.tahe.com');
        }
      }
      f7.sheet.get('#ocean-blocked-sheet').close();
    };
    return { loading, message, checkInOcean };
  }
};
</script>
