import * as _ from 'lodash';
import * as moment from 'moment';
import { TYPE_ORDER_LINE, PAYMENT_TERMS, PAYMENT_FORMS, STATUS } from '../../config/enums.config';
import { ordersService } from '../../api/graphql/services/orders.service';
import { ordersService as httpOrdersService } from '../../api/http/orders.service';
import { round } from '../../helpers/math.helpers';
import { createNotification } from '../../helpers/f7.helpers';

export const StorePendingOrder = {
  module: 'pendingOrder',
  actions: {
    getPendingOrder: 'getPendingOrder',
    setPendingOrder: 'setPendingOrder',
    setProperty: 'setProperty',
    updateOrder: 'updateOrder',
    selectAll: 'selectAll',
    selectIndex: 'selectIndex',
    selectFromTo: 'selectFromTo',
    toggleSortable: 'toggleSortable',
    reorderLines: 'reorderLines',
    setGirosInmediatos: 'setGirosInmediatos',
    addDate: 'addDate',
    deleteDate: 'deleteDate',
    setNewParams: 'setNewParams',
    recalcularPorcentajes: 'recalcularPorcentajes',
  },
  mutations: {
    setLoading: 'SET_LOADING',
    pendingOrderError: 'PENDING_ORDER_ERROR',
    setPendingOrder: 'SET_PENDING_ORDER',
    setProperty: 'SET_PROPERTY',
    setOrderParams: 'SET_ORDER_PARAMS',
    selectAll: 'SELECT_ALL',
    selectIndex: 'SELECT_INDEX',
    selectFromTo: 'SELECT_FROM_TO',
    toggleSortable: 'TOGGLE_SORTABLE',
    setCopiedLineId: 'SET_COPIED_LINE_ID',
    setGirosInmediatos: 'SET_GIROS_INMEDIATOS',
    addDate: 'ADD_DATE',
    deleteDate: 'DELETE_DATE',
    setNewParams: 'SET_NEW_PARAMS',
    recalcularPorcentajes: 'RECALCULAR_PORCENTAJES'
  }
};

const state = {
  loading: false,
  pendingOrder: null,
  sortableEnabled: false,
  copiedLineId: null,
  girosInmediatos: [],
  newParams: {},
};

const orderLines = (state) => state.pendingOrder !== null ? state.pendingOrder.lines.filter(l => l.type === TYPE_ORDER_LINE.LINE.code) : [];
const sampleLines = (state) => state.pendingOrder !== null ? state.pendingOrder.lines.filter(l => l.type === TYPE_ORDER_LINE.SAMPLE.code) : [];
const incidentLines = (state) => state.pendingOrder !== null ? state.pendingOrder.lines.filter(l => l.type === TYPE_ORDER_LINE.INCIDENT.code) : [];
const selectedLines = (state) => state.pendingOrder !== null ? state.pendingOrder.lines.filter(l => l.selected) : [];
const selectedImport = (state) => selectedLines(state).reduce((acc, l) => acc += (l.selected && !l.free ? l.import : 0), 0);
const selectedQuantity = (state) => selectedLines(state).reduce((acc, v) => acc += v.quantity, 0);
const isAllSelected = (state) => state.pendingOrder !== null ? state.pendingOrder.lines.findIndex(l => !l.selected || l.selected === false) === -1 : false;
const totalPromos = (state) => state.pendingOrder !== null ? state.pendingOrder.lines.filter(l => l.promo === true).reduce((acc, v) => acc += v.quantity, 0) : 0;
const totalFree = (state) => state.pendingOrder !== null ? state.pendingOrder.lines.filter(l => l.free === true).reduce((acc, v) => acc += v.quantity, 0) : 0;

const totalAmount = (state) => {
  const reem = state.pendingOrder ? state.pendingOrder.dates.find(d => d.paymentForm === PAYMENT_FORMS.CONTRAREEM.code) : null;
  const reemImp = reem ? reem.import : 0;
  return state.pendingOrder ? (state.pendingOrder.importWithIVA - _.sumBy(state.pendingOrder.dates, 'import')) : 0;
};
const remainingPercentage = (state) => state.pendingOrder ? (100 - _.sumBy(state.pendingOrder.dates, 'percentage')) : 0;

const getters = {
  orderLines: (state) => orderLines(state),
  sampleLines: (state) => sampleLines(state),
  incidentLines: (state) => incidentLines(state),
  selectedLines: (state) => selectedLines(state),
  selectedImport: (state) => selectedImport(state),
  selectedQuantity: (state) => selectedQuantity(state),
  isAllSelected: (state) => isAllSelected(state),
  totalPromos: (state) => totalPromos(state),
  totalFree: (state) => totalFree(state),
};

const actions = {
  [StorePendingOrder.actions.setNewParams]: ({ commit }, newParams) => commit(StorePendingOrder.mutations.setNewParams, newParams),
  [StorePendingOrder.actions.getPendingOrder]: async ({ commit, rootState }) => {
    commit(StorePendingOrder.mutations.setLoading, true);
    try {
      const { selectedClient: clientId, selectedCode: vendorId, sellChannel, status } = rootState.session.sessionInfo;
      const language = rootState.userSettings.language;
      const { data: { pendingOrder }} = await ordersService.findOrder({ clientId, vendorId, status, sellChannel, language });
      commit(StorePendingOrder.mutations.setPendingOrder, pendingOrder);
    } catch (error) {
      createNotification(error.message, error.statusCode);
      commit(StorePendingOrder.mutations.pendingOrderError);
    }
    commit(StorePendingOrder.mutations.setLoading, false);
  },
  [StorePendingOrder.actions.setPendingOrder]: async ({commit, state, dispatch}, pendingOrder) => {
    if (pendingOrder && pendingOrder.status === STATUS.PENDING_APPROVAL.code && pendingOrder.clientId === '1') {
      // const newClient = pendingOrder.client;
      // dispatch('newClient/setNewClient', newClient, {root: true});
      // dispatch('newClient/setFormFilled', true, {root: true});
    }
    commit(StorePendingOrder.mutations.setPendingOrder, pendingOrder);
  },
  [StorePendingOrder.actions.setProperty]: async ({commit, state}, {parameter, value, lineId}) => {
    const oldState = _.cloneDeep(state.pendingOrder);
    try {
      const newParam = { [parameter]: value };
      commit(StorePendingOrder.mutations.setProperty, {parameter, value, lineId});
      await httpOrdersService.updateLine(state.pendingOrder._id, lineId, newParam);
    } catch (e) {
      // console.log(e);
      commit(StorePendingOrder.mutations.setPendingOrder, oldState);
      createNotification(e.message, e.statusCode);
    }
  },
  [StorePendingOrder.actions.updateOrder]: async ({commit, state, rootState}, {parameter, value}) => {
    const oldState = _.cloneDeep(state.pendingOrder);
    try {
      const newParam = { [parameter]: value };
      const extraParams = {};
      if (parameter === 'paymentForm') {
        extraParams.paymentDate = new Date();
        if (!PAYMENT_FORMS[value].hasPaymentTerms) {
          extraParams.paymentTerm = '';
        }
      }
      if (parameter === 'paymentTerm') {
        extraParams.paymentDate = moment().add(PAYMENT_TERMS[value].dateCount, PAYMENT_TERMS[value].dateType).toDate();
      }
      commit(StorePendingOrder.mutations.setOrderParams, {...newParam, ...extraParams});
      await httpOrdersService.updateOrder(state.pendingOrder._id, {...newParam, ...extraParams});
    } catch (e) {
      commit(StorePendingOrder.mutations.setPendingOrder, oldState);
      createNotification(e.message, e.statusCode);
    }
  },
  [StorePendingOrder.actions.selectAll]: async ({ commit, dispatch, state, rootState }, { selected }) => {
    await httpOrdersService.selectedAll(state.pendingOrder._id, selected);
    commit(StorePendingOrder.mutations.selectAll, { selected });
  },
  [StorePendingOrder.actions.selectIndex]: async ({ commit, dispatch, state, rootState }, { selected, lineId }) => {
    await httpOrdersService.updateLine(state.pendingOrder._id, lineId, {selected});
    commit(StorePendingOrder.mutations.selectIndex, {selected, lineId});
  },
  [StorePendingOrder.actions.selectFromTo]: ({commit, state}, {lineId}) => {
    // console.log(lineId);
    let indexTo = state.pendingOrder.lines.findIndex(l => l.lineId === lineId);
    // console.log(indexTo);
    if (state.pendingOrder.lines[indexTo].promo) {
      for(let i = indexTo + 1; i < state.pendingOrder.lines.length; i++) {
        if (state.pendingOrder.lines[i].parentId === lineId) {
          indexTo = i;
        }
      }
    }
    let indexFrom = 0;
    const selected = !state.pendingOrder.lines[indexTo].selected;
    for(let i = indexTo - 1; i >= 0; i--) {
      if (state.pendingOrder.lines[i].selected === selected) {
        indexFrom = i;
        break;
      }
    }
    httpOrdersService.selectFromTo(state.pendingOrder._id, indexFrom, indexTo, selected);
    commit(StorePendingOrder.mutations.selectFromTo, {indexFrom, indexTo, selected});
  },
  [StorePendingOrder.actions.toggleSortable]: ({ commit }) => {
    commit(StorePendingOrder.mutations.toggleSortable);
  },
  [StorePendingOrder.actions.reorderLines]: async ({commit, state, rootState}, lineId) => {
    try {
      if (state.copiedLineId) {
        const language = rootState.userSettings.language;
        const { data: { lines } } = (await ordersService.reorder({ order: state.pendingOrder._id, copiedId: state.copiedLineId, to: lineId, language  }));
        commit(StorePendingOrder.mutations.setCopiedLineId, null);
        commit(StorePendingOrder.mutations.setPendingOrder, {...state.pendingOrder, lines});
      } else {
        commit(StorePendingOrder.mutations.setCopiedLineId, lineId);
      }
    } catch (e) {
      commit(StorePendingOrder.mutations.pendingOrderError);
    }
  },
  [StorePendingOrder.actions.setGirosInmediatos]: ({ commit }, girosInmediatos) => {
    commit(StorePendingOrder.mutations.setGirosInmediatos, girosInmediatos);
  },
  [StorePendingOrder.actions.addDate]: ({ commit }, date) => {
    commit(StorePendingOrder.mutations.addDate, date);
  },
  [StorePendingOrder.actions.deleteDate]: ({ commit }, index) => {
    commit(StorePendingOrder.mutations.deleteDate, index);
  },
  [StorePendingOrder.actions.recalcularPorcentajes]: ({ commit, state }) => {
    commit(StorePendingOrder.mutations.recalcularPorcentajes);
  }
};

const mutations = {
  [StorePendingOrder.mutations.setNewParams]: (state, newParams) => state.newParams = newParams,
  [StorePendingOrder.mutations.setLoading]: (state, loading) => state.loading = loading,
  [StorePendingOrder.mutations.pendingOrderError]: (state) => state.pendingOrder = null,
  [StorePendingOrder.mutations.setPendingOrder]: (state, pendingOrder) => {
    if (!pendingOrder) state.pendingOrder = null;
    else state.pendingOrder = { ...pendingOrder, lines: pendingOrder.lines.map(l => ({...l})), dates: [] };
  },
  [StorePendingOrder.mutations.setProperty]: (state, {parameter, value, lineId}) => {
    const index = state.pendingOrder.lines.findIndex(l => l.lineId === lineId);
    const line = state.pendingOrder.lines[index];
    let oldImport = line.free ? 0 : line.import;
    let oldImportWithIVA = line.free ? 0 : line.importWithIVA;
    const oldQuantity = line.quantity;
    state.pendingOrder.lines[index][parameter] = value;
    if (parameter === 'closed') {
      const order = _.cloneDeep(state.pendingOrder);
      order.lines.filter(o => o.parentId === line.lineId).forEach(l => { l.closed = value });
      state.pendingOrder = {...state.pendingOrder, ...order};
      return;
    }
    let importWD = line.quantity * line.unitsPer * line.unitPrice;
    state.pendingOrder.lines[index].import = round(importWD - round(importWD * (line.discount / 100)));
    // console.log(importWD, line.discount / 100);
    state.pendingOrder.lines[index].importWithIVA = round((importWD - round(importWD * (line.discount / 100))) * line.iva);
    state.pendingOrder.import = round(state.pendingOrder.import + ((line.free ? 0 : line.import) - oldImport));
    state.pendingOrder.importWithIVA = round(state.pendingOrder.importWithIVA + ((line.free ? 0 : line.importWithIVA) - oldImportWithIVA));
    if (line.promo) {
      const order = _.cloneDeep(state.pendingOrder);
      order.lines.filter(o => o.parentId === line.lineId).forEach(l => {
        l.free = line.free;
        l.discount = line.discount;
        oldImport = l.free ? 0 : l.import;
        oldImportWithIVA = l.free ? 0 : l.importWithIVA;
        l.quantity = Math.max(Math.trunc(line.quantity * l.quantity / oldQuantity), 1);
        const importWD = l.quantity * l.unitPrice;
        l.import = round(importWD - round(importWD * (l.discount / 100)));
        l.importWithIVA = round((importWD - round(importWD * (l.discount / 100))) * l.iva),
        order.import = round(order.import + (l.free ? 0 : l.import) - oldImport);
        order.importWithIVA = round(order.importWithIVA + ((l.free ? 0 : l.importWithIVA) - oldImportWithIVA));
      });
      state.pendingOrder = {...state.pendingOrder, ...order};
    }
    // console.log('new pending order');
    // console.log(state.pendingOrder);
  },
  [StorePendingOrder.mutations.setOrderParams]: (state, newOrderParams) => {
    state.pendingOrder = {...state.pendingOrder, ...newOrderParams};
  },
  [StorePendingOrder.mutations.selectAll]: (state, { selected }) => {
    const order = _.cloneDeep(state.pendingOrder);
    order.lines.forEach(l => l.selected = selected);
    state.pendingOrder = {...state.pendingOrder, ...order};
  },
  [StorePendingOrder.mutations.selectIndex]: (state, { selected, lineId }) => {
    const index = state.pendingOrder.lines.findIndex(l => l.lineId === lineId);
    const line = state.pendingOrder.lines[index];
    state.pendingOrder.lines[index].selected = selected;
    if (line.promo) {
      const order = _.cloneDeep(state.pendingOrder);
      order.lines.forEach(l => {
        if (l.parentId === line.lineId) {
          l.selected = line.selected;
        }
      });
      state.pendingOrder = {...state.pendingOrder, ...order};
    }
  },
  [StorePendingOrder.mutations.selectFromTo]: (state, {indexFrom, indexTo, selected}) => {
    const order = _.cloneDeep(state.pendingOrder);
    for (let j = indexFrom; j <= indexTo; j++) {
      order.lines[j].selected = selected;
    }
    state.pendingOrder = {...state.pendingOrder, ...order};
  },
  [StorePendingOrder.mutations.toggleSortable]: (state) => state.sortableEnabled = !state.sortableEnabled,
  [StorePendingOrder.mutations.setCopiedLineId]: (state, lineId) => {
    state.copiedLineId = lineId;
  },
  [StorePendingOrder.mutations.setGirosInmediatos]: (state, girosInmediatos) => state.girosInmediatos = girosInmediatos,
  [StorePendingOrder.mutations.addDate]: (state, date) => {
    state.pendingOrder.dates.push(date);
    state.pendingOrder.dates = _.orderBy(state.pendingOrder.dates, 'dueDate');
  },
  [StorePendingOrder.mutations.deleteDate]: (state, index) => {
    state.pendingOrder.dates.splice(index, 1);
  },
  [StorePendingOrder.mutations.recalcularPorcentajes]: (state) => {
    for (const fecha of state.pendingOrder.dates) {
      fecha.percent = (((fecha.import) * 100 / (totalAmount(state))) * remainingPercentage(state)) / 100;
    }
  },
};

export const pendingOrderStore = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
