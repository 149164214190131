
import * as axios from 'axios';
import { store } from '../stores/vuex';
import { f7 } from 'framework7-vue';
import { createNotification } from '../helpers/f7.helpers';

export const BASE_URL = process.env.NODE_ENV === 'production' ?
  `https://ofertahe.tahe.es/api` : `http://${window.location.hostname}:4000/api`;

export const instance = axios.create({
  baseURL: BASE_URL,
  timeout: 30000,
});

// Add a request interceptor
instance.interceptors.request.use(function (config) {
  // Do something before request is sent
  config.headers.Authorization = `Bearer ${store.state.session.accessToken}`;
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
instance.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  // console.log(error);
  if (error.response) {
    if (error.response.status === 401) {
      store.dispatch('session/logout');
      f7.views.main.router.navigate('/');
      return Promise.reject(error.response.data);
    } if (error.response.status === 503) {
      // goToMaintenance();
      store.dispatch('session/logout');
      return Promise.reject({message: error.response.data.message, statusCode: error.response.data.statusCode});
    } else {
      createNotification(`serverErrors.${error.response.data.message}`, error.response.data.statusCode);
      return Promise.reject({message: error.response.data.message, statusCode: error.response.data.statusCode});
    }
  } else {
    if (error.message.match(/timeout/)) {
      return Promise.reject({message: 'SERVER_TOO_BUSY', statusCode: 'TIMEOUT'});
    } else if (error.response.status === 502) {
      createNotification('serverErrors.SERVER_NOT_RESPONDING', 999);
      // store.dispatch('session/logout');
    }
  }
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  // createNotification('SERVER_NOT_RESPONDING', 'UNKNOWN');
  return Promise.reject({message: 'SERVER_NOT_RESPONDING', statusCode: 'UNKNOWN'});
});

export const checkVendor = (vendorCode) => axios.get(`https://gestahe.tahe.es/ticaje_ocean.php?comercial=${vendorCode}`);
