<template>
  <f7-link :icon="icon" panel-open="right" icon-size="24"></f7-link>
</template>
<script>
export default {
  name: 'right-menu-button',
  props: {
    icon: { type: String, default: 'las la-bars' },
  },
}
</script>
