const isSelected = (state, billDocument) => state.bills.findIndex((b) => b.document === billDocument) > -1;

export const StoreBills = {
  module: 'bills',
  actions: {
    toggleBill: 'toggleBill',
    clearAll: 'clearAll'
  },
  mutations: {
    toggleBill: 'TOGGLE_BILL',
    clearAll: 'CLEAR_ALL'
  }
};

const state = {
  bills: [], //
};

const getters = {
  isSelected: (state) => (billDocument) => isSelected(state, billDocument),
};

const actions = {
  [StoreBills.actions.toggleBill]: ({commit}, bill) => {
    commit(StoreBills.mutations.toggleBill, bill);
  },
  [StoreBills.actions.clearAll]: ({commit}) => {
    commit(StoreBills.mutations.clearAll);
  },
};

const mutations = {
  [StoreBills.mutations.toggleBill]: (state, bill) => {
    const index = state.bills.findIndex((b) => b.document === bill.document);
    if (index === -1) {
      console.log(index);
      state.bills.push(bill);
    } else {
      state.bills.splice(index, 1);
    }
  },
  [StoreBills.mutations.clearAll]: (state) => state.bills = [],
};

export const billsStore = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
