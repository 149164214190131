import gql from 'graphql-tag';

export const ADD_SHOPPING_CART_GQL = gql`
  mutation AddPendingCartToOrder(
    $id: String!,
    $clientId: String!,
    $sellChannel: String!,
    $status: String!,
    $groupPrice: String!,
  ) {
    order: addPendingCartToOrder(
      data: {
        _id: $id,
        clientId: $clientId,
        sellChannel: $sellChannel,
        status: $status,
        groupPrice: $groupPrice
      }
    ) {
      _id
    }
  }
`;

export const ADD_PRODUCT_GQL = gql`
  mutation AddProductToOrder(
    $optionSku: String!,
    $clientId: String!,
    $sellChannel: String!,
    $status: String!,
    $groupPrice: String!,
  ) {
    producto: addProductToOrder(
      data: {
        optionSku: $optionSku,
        clientId: $clientId,
        sellChannel: $sellChannel,
        status: $status,
        groupPrice: $groupPrice
      }
    )
  }
`;

