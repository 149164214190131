import { accionesService } from '../api/seguimiento/acciones.service';

export const trackearClick = async (usuarios, usuario, accion, datosExtra = {}) => {
  console.log(usuario, accion);
  if (usuarios.includes(usuario)) {
    Promise.all([
      accionesService.getIp(),
      // accionesService.getUserAgent(),
    ]).then(res => {
      accionesService.insertarAccion({
        usuario: usuario,
        ip: JSON.stringify(res[0].data.ip),
        headers: JSON.stringify(res[0].data.headers),
        localizacion: '',
        accionApp: accion,
        app: 'COSMET',
        datosExtra: JSON.stringify(datosExtra),
      })
    });
  }
};
