import HomePage from '../../pages/home/home.vue';
import LeftPanelPage from '../../pages/left-panel.vue';
import RightPanelPage from '../../pages/right-panel.vue';
import NotFoundPage from '../../pages/not-found.vue';
import { APP_PANEL_TRANSITION, APP_TRANSITION } from '../config/app.config';
import { leftPanelRoutes } from './left-panel-routes';
import { rightPanelRoutes } from './right-panel-routes';
import { mainRoutes } from './main-routes';

const mountRoute = (resolve, reject, routePath) => {
  const isAuthenticated = true;
  if (isAuthenticated) {
    const vueComponent = () => import (`../../pages/${routePath}.vue`);
    vueComponent().then((vc) => {
      resolve({ component: vc.default });
    });
  } else {
    reject();
  }
};

const mapRoutes = (a, transition = '') => {
  return a.map(r => {
    return {
      path: r.path,
      async: function ({to, from, resolve, reject}) {
        mountRoute(resolve, reject, r.componentPath);
      },
      ...(transition !== '' ? {options: {transition}} : {}),
    };
  });
};

export const routes = [
  { path: '/'               , component: HomePage       , ...(APP_TRANSITION !== '' ? {options: {transition: APP_TRANSITION}} : {}) },
  { path: '/left-panel/'    , component: LeftPanelPage  , ...(APP_PANEL_TRANSITION !== '' ? {options: {transition: APP_PANEL_TRANSITION}} : {}) },
  { path: '/right-panel/'   , component: RightPanelPage , ...(APP_PANEL_TRANSITION !== '' ? {options: {transition: APP_PANEL_TRANSITION}} : {}) },
  ...mapRoutes(leftPanelRoutes  , APP_PANEL_TRANSITION),
  ...mapRoutes(rightPanelRoutes , APP_PANEL_TRANSITION),
  ...mapRoutes(mainRoutes       , APP_TRANSITION),
  { path: '/index.html'     , redirect: '/'             , ...(APP_TRANSITION !== '' ? {options: {transition: APP_TRANSITION}} : {}) },
  { path: '(.*)'            , component: NotFoundPage   , ...(APP_TRANSITION !== '' ? {options: {transition: APP_TRANSITION}} : {}) },
];
