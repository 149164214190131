import * as moment from 'moment';
import * as numeral from 'numeral';
import 'moment/locale/es';

numeral.register('locale', 'es', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  currency: {
    symbol: '€',
  }
});

numeral.locale('es');
numeral.defaultFormat('0,00€');
moment.locale('es');

export const dateFormat = (format, date = new Date()) => moment(date).format(format);
export const numFormat = (n) => numeral(n).format('0.[00]');
export const formatDate = (date = new Date(), format = 'DD/MM/YYYY') => date === null ? '' : moment(date).locale('es').format(format);
export const formatHora = (date = '') => date === null ? '' : date.substring(11, 16);

export const splitInColumns = (array, number) => {
  const result = [];
  for (let i = 0; i < array.length; i++) {
    for (let j = 0; j < number; j++) {
      if (i % number === j) {
        if (!result[j]) result[j] = [];
        result[j].push(array[i]);
      }
    }
  }
  return result;
};

export const beforeToday = (date) => {
  return moment(date).isBefore(moment());
};

export const beforeNextMonth = (date) => {
  return moment(date).isAfter(moment().add(1, 'month'));
};

export const mountExpeditionUrl = (data, language = 'es') => {
  if (/MRW/.test(data.agency)) {
    return `https://www.mrw.es/seguimiento_envios/MRW_resultados_consultas.asp?modo=nacional&envio=${data.expedition}`;
  } else if (/TNT|GÓMEZ/.test(data.agency)) {
    return `https://www.tnt.com/express/es_es/site/herramientas-envio/seguimiento.html?searchType=con&cons=${data.expedition}`;
  } else if (/CORREOS/.test(data.agency)) {
    return `https://track.aftership.com/correosexpress/${data.expedition}`;
  } else if (/GLS|CAREX/.test(data.agency)) {
    return `https://m.gls-spain.es/e/${data.expedition}/${data.postalCode}/${language}`;
  } else if (/DHL/.test(data.agency)) {
    return `https://clientesparcel.dhl.es/LiveTracking/ModificarEnvio/${`${data.expedition.length <= 7 ? '30' : ''}${data.expedition}`.padEnd(10, '0')}`;
  } else if (/TOURLINE/.test(data.agency)) {
    return `https://www.cttexpress.com/home/seguimiento-de-envios/index?objects=${data.expedition}`;
  } else if (/DACHSER/.test(data.agency)) {
    return `https://elogistics.dachser.com/shp2s/?javalocale=es_ES&search=${data.expedition.substring(6)}`;
  } else if (/UPS/.test(data.agency) && data.expedition.indexOf('1Z') === 0) {
    return `https://www.ups.com/track?loc=es_ES&tracknum=${data.expedition}`;
  } else if (/SEUR/.test(data.agency)) {
    return `https://www.seur.com/miseur/mis-envios?code=${data.expedition}`;
  } else return '';
};

export const getAgency = (agency) => {
  const a = agency.match(/^[^_]+(?=_)/);
  // console.log(a);
  return a ? a[0] : agency;
};

export const percSymbol = (con) => {
  return con === 'NAV360' ? '%' : '_';
};

export const isAbonable = (date) => {
  const today = moment().subtract(3, 'month');
  const billDate = moment(date);
  return billDate.isAfter(today);
};
