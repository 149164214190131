import { apolloSeguimiento, SEGUIMIENTO_API_URL } from '../../plugins/apollo-seguimiento';
import gql from 'graphql-tag';
import * as axios from 'axios';

export const INSERTAR_ACCION_GQL = gql`
  mutation InsertarAccion(
    $usuario: String!
    $ip: String!
    $headers: String!
    $localizacion: String!
    $accionApp: String!
    $app: String!
    $datosExtra: String!
  ) {
    createAccion(createAccionInput: {
      usuario: $usuario
      ip: $ip
      headers: $headers
      localizacion: $localizacion
      accionApp: $accionApp
      app: $app
      datosExtra: $datosExtra
    }) {
      id
      usuario
      ip
      headers
      localizacion
      accionApp
      app
      datosExtra
      creado
    }
  }
`;

const insertarAccion = ({ usuario, ip, headers, localizacion, accionApp, app, datosExtra }) =>
  apolloSeguimiento.mutate({ mutation: INSERTAR_ACCION_GQL, variables: { usuario, ip, headers, localizacion, accionApp, app, datosExtra }});

const getIp = () => axios.get(`${SEGUIMIENTO_API_URL}/api/ip`, { headers: { 'x-api-key': 'TEST' }});

// const getIp = () =>
//   axios.get('https://api.ipgeolocation.io/ipgeo?apiKey=065ed3f27c224b2cba3bec39d0d611a6');
//
// const getUserAgent = () =>
//   axios.get('https://api.ipgeolocation.io/user-agent?apiKey=065ed3f27c224b2cba3bec39d0d611a6');;

export const accionesService = {
  insertarAccion,
  getIp,
  // getUserAgent,
};
