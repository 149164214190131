import { apolloClient } from '@/js/plugins/apollo-client';
import { GET_PROFILE } from '../queries/users/users.queries';
import { LOGIN, TOGGLE_FAV_PRODUCT, TOGGLE_FAVORITE_MENU } from '../queries/users/users.mutations';

const getProfile = async () => apolloClient.query({query: GET_PROFILE, fetchPolicy: 'no-cache'});
const login = async (data) => apolloClient.mutate({mutation: LOGIN, variables: { data }, fetchPolicy: 'no-cache'});
const toggleFavoriteMenu = async (favItem) => apolloClient.mutate({mutation: TOGGLE_FAVORITE_MENU, variables: { favItem }});
const toggleFavProduct = async (favProduct) => apolloClient.mutate({mutation: TOGGLE_FAV_PRODUCT, variables: { favProduct }});

export const usersService = {
  getProfile,
  login,
  toggleFavoriteMenu,
  toggleFavProduct,
};
