<template>
  <div class="display-flex flex-direction-column align-items-center justify-content-center flex-1 height-100">
    <f7-preloader color="primary" :size="56"></f7-preloader>
  </div>
</template>
<script>
export default {
  name: 'PagePreloader',
};
</script>
