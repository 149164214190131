import { instance as axios } from '../../plugins/axios';

const getPaymentForms = (sellChannel) => axios.post('/companies/get_payment_forms', {sellChannel});
const getPaymentTerms = (sellChannel) => axios.post('/companies/get_payment_terms', {sellChannel});
const getPaymentFormsGlobal = () => axios.post('/companies/get_payment_forms_global');
const getPaymentTermsGlobal = () => axios.post('/companies/get_payment_terms_global');
const getGroupPrices = () => axios.post('/companies/get_group_prices');

export const companiesService = {
  getPaymentForms,
  getPaymentTerms,
  getPaymentFormsGlobal,
  getPaymentTermsGlobal,
  getGroupPrices,
};
