import { instance as axios } from '../../plugins/axios';

const findRefund = (vendorId, clientId, sellChannel, status) =>
  axios.post('/refunds/find_refund', {vendorId, clientId, sellChannel, status});

const findRefundById = (id) =>
  axios.post('/refunds/find_refund_by_id', {id});

const findRefunds = (skip, take, searchParams, orderParams) =>
  axios.post('/refunds/find_refunds', {skip, take, searchParams, orderParams});

const searchProductBills = (optionSku, clientId, company, fromDate) =>
  axios.post('/refunds/search_product_bills', {optionSku, clientId, company, fromDate});

const addLines = (lines, vendorId, clientId, sellChannel, status) =>
  axios.post('/refunds/add_lines', {lines, vendorId, clientId, sellChannel, status});

const updateRefund = (refund, newParams) =>
  axios.post('/refunds/update_refund', {refund, newParams});

const process = (refund) =>
  axios.post('/refunds/process', {refund});

const updateLine = (refund, id, newParam) =>
  axios.post('/refunds/update_line', {refund, id, newParam});

const deleteLine = (refund, id) =>
  axios.post('/refunds/delete_line', {refund, id});

const deleteSelected = (refund, ids) =>
  axios.post('/refunds/delete_selected', {refund, ids});

const selectedAll = (refund, selected) =>
  axios.post('/refunds/selected_all', {refund, selected});

const selectFromTo = (refund, indexFrom, indexTo, selected) =>
  axios.post('/refunds/select_from_to', {refund, indexFrom, indexTo, selected});

export const refundsService = {
  findRefund,
  findRefundById,
  findRefunds,
  searchProductBills,
  addLines,
  updateRefund,
  process,
  updateLine,
  deleteLine,
  deleteSelected,
  selectedAll,
  selectFromTo,
};
