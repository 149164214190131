import gql from 'graphql-tag';

export const MINI_SC = gql`
  fragment MiniSC on ShoppingCart {
    _id
  }
`;

export const FRAGMENT_SHOPPING_CART = gql`
  fragment PendingCart on ShoppingCart {
    _id
    username
    vendorCode
    discount
    lines {
      optionSku
      sku
      unitType
      unitPrice
      unitsPer
      quantity
      product(language: $language) {
        optionName
        name
        extraName
        optionImage
        image
        blocked
        mainLine
      }
    }
  }
`;

export const GET_SHOPPING_CART = `getPendingCart(username: $username, vendorCode: $vendorCode) { ...PendingCart }`;
export const ADD_SHOPPING_CART_LINE = `addPendingCartLine(line: $line) { ...MiniSC }`;
export const EDIT_SHOPPING_CART_LINE = `editPendingCartLine(line: $line) { ...MiniSC }`;
export const REMOVE_SHOPPING_CART_LINE = `removePendingCartLine(line: $line) { ...MiniSC }`;
export const EDIT_SHOPPING_CART = `editPendingCart(shoppingCart: $shoppingCart) { ...MiniSC }`;
export const REMOVE_SHOPPING_CART = `removePendingCart(id: $id) { ...MiniSC }`;

export const GET_SHOPPING_CART_GQL = gql`
  query GetPendingCart($username: String!, $vendorCode: String!, $language: String!) {
    pendingCart: ${GET_SHOPPING_CART},
  }
  ${FRAGMENT_SHOPPING_CART}
`;

export const ADD_SHOPPING_CART_LINE_GQL = gql`
  mutation AddPendingCartLine($line: NewShoppingLineInput!) {
    pendingCart: ${ADD_SHOPPING_CART_LINE},
  }
  ${MINI_SC}
`;

export const EDIT_SHOPPING_CART_LINE_GQL = gql`
  mutation EditPendingCartLine($line: EditShoppingLineInput!) {
    pendingCart: ${EDIT_SHOPPING_CART_LINE},
  }
  ${MINI_SC}
`;

export const REMOVE_SHOPPING_CART_LINE_GQL = gql`
  mutation EditPendingCartLine($line: EditShoppingLineInput!) {
    pendingCart: ${REMOVE_SHOPPING_CART_LINE},
  }
  ${MINI_SC}
`;

export const EDIT_SHOPPING_CART_GQL = gql`
  mutation EditPendingCart($shoppingCart: EditShoppingCartInput!) {
    pendingCart: ${EDIT_SHOPPING_CART},
  }
  ${MINI_SC}
`;

export const REMOVE_SHOPPING_CART_GQL = gql`
  mutation RemovePendingCart($id: String!) {
    pendingCart: ${REMOVE_SHOPPING_CART},
  }
  ${MINI_SC}
`;
