import { state } from './session.state';
import { getters } from './session.getters';
import { actions } from './session.actions';
import { mutations } from './session.mutations';

export const sessionStore = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export * from './session.types';
