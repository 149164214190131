<template>
  <left-page name="left-page" :has-right-panel="true">
    <template v-slot:right>
      <f7-link @click="showChecks" v-if="user && user.vendor && user.vendor.sellChannelToCodes.findIndex(sc => /(VES|VCA)/.test(sc.code)) > -1">
        <img src="static/images/favicon-ocean.png" alt="" width="24" height="24">
      </f7-link>
      <div v-else></div>
    </template>
    <template v-slot:default>
      <figure-image :image="state.image"></figure-image>
      <main-menu-button></main-menu-button>
      <profile-button></profile-button>
      <favorites-menu></favorites-menu>
      <logout-button></logout-button>
      <f7-block>
        <div class="display-flex justify-content-center">
          <f7-link icon="las la-retweet" icon-size="28" :text="$t('pages.updatePage')" @click="updateWindow()"></f7-link>
        </div>
      </f7-block>
    </template>
  </left-page>
</template>
<script>
import LeftPage from '@/components/layouts/left-page.vue';
import LogoutButton from '@/components/buttons/logout-button.vue';
import FigureImage from '@/components/utils/figure-image.vue';
import FavoritesMenu from '@/components/menus/favorites-menu.vue';
import MainMenuButton from '@/components/buttons/main-menu-button.vue';
import ProfileButton from '@/components/buttons/profile-button.vue';
import { useState } from '../js/stores/hooks';
import { COMPANY, SELL_CHANNEL } from '../js/config/enums.config';
import { reactive, computed } from 'vue';
import { f7 } from 'framework7-vue';
import { checkVendor } from '../js/plugins/axios';
import { vendorsService } from '../js/api/http/vendors.service';

export default {
  components: {
    'left-page': LeftPage,
    'logout-button': LogoutButton,
    'figure-image': FigureImage,
    'favorites-menu': FavoritesMenu,
    'main-menu-button': MainMenuButton,
    'profile-button': ProfileButton,
  },
  setup: (props, context) => {
    const { sessionInfo, user } = useState('session', ['sessionInfo', 'user']);
    const state = reactive({
      image: computed(() => sessionInfo.value ?
          COMPANY[/TH/.test(sessionInfo.value.company) ? 'TH_PHARMA' : (
              /KELAYA/.test(sessionInfo.value.company) ? 'KELAYA_2016' :
              /BOCARE/.test(sessionInfo.value.company) ? 'BOCARE_2016' : 'TAHE'
          )].image : 'static/images/test-image.png'),
    });
    const showChecks = async () => {
      const { data } = await vendorsService.ticajeOcean(sessionInfo.value.selectedCode);
      // console.log(data);
      let message = `
        <div class="display-flex flex-direction-column">
          <div>Datos registrados en Ocean</div>
          <div class="margin-top display-flex flex-direction-row justify-content-space-between text-color-gray">
            <div>Entrada</div><div>Salida</div>
          </div>`;
      for (const c of data.marcajes) {
        message += `
          <div class="display-flex flex-direction-row justify-content-space-between">
            <div class="display-flex flex-direction-row align-items-center margin-vertical-half">
              <div class="margin-right"><i class="icon f7-icons" style="color: #ADC32D">arrowshape_turn_up_right</i></div>
              <strong>${c.entrada}</strong>
            </div>`;
        if (c.salida !== '') {
          message += `<div class="display-flex flex-direction-row align-items-center margin-vertical-half">
              <strong class="margin-right">${c.salida}</strong>
              <div><i class="icon f7-icons" style="color: #AD3A32">arrowshape_turn_up_left</i></div>
            </div>`;
        }
        message +=  `</div>`;
      }
      message += `
          <div class="margin-top display-flex flex-direction-row justify-content-space-between text-color-gray"><div>H. Computadas</div><div>H. Teóricas</div></div>
          <div class="display-flex flex-direction-row justify-content-space-between"><div class="font-weight-bold">${ data.horas_computadas }</div>
          <div class="font-weight-bold">${ data.horas_teoricas }</div></div>
        </div>`;
      f7.dialog.alert(message, 'Ocean', () => {});
    };
    const updateWindow = () => location.reload();
    return { state, showChecks, SELL_CHANNEL, sessionInfo, user, updateWindow };
  }
}
</script>
