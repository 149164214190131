export const rightPanelRoutes = [
  { path: '/products-filters/'              , componentPath: 'catalogv2/right-panel/filters' },
  { path: '/orders-filters/'                , componentPath: 'orders/right-panel/orders-filters' },
  { path: '/pending-order-right-panel/'     , componentPath: 'orders/right-panel/pending-order-right-panel' },
  { path: '/refunds-filters/'               , componentPath: 'refunds/right-panel/refunds-filters' },
  { path: '/charges-filters/'               , componentPath: 'charges/right-panel/charges-filters' },
  { path: '/bills-filters/'                 , componentPath: 'informs/bills/right-panel/bills-filters' },
  { path: '/extracts-filters/'              , componentPath: 'informs/extracts/right-panel/extracts-filters' },
  { path: '/product-refund-filters/'        , componentPath: 'refunds/right-panel/product-refund-filters' },
];
