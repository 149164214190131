export const fr = {
  contratoAparatologia: 'contrat d\'équipement laser',
  WARNING_CONTRAREEM: 'Il n\'est pas possible d\'effectuer des remboursements supérieurs à 1000€.',

  alert: 'Alerta',
  abono_contrareembolso_no_permitido: 'No puedes casar un abono con el pedido si la forma de pago no es giro o contado.',
  lunes: 'Lundi',
  martes: 'Mardi',
  miercoles: 'Mercredi',
  jueves: 'Jeudi',
  viernes: 'Vendredi',
  sabado: 'Samedi',
  domingo: 'Dimanche',
  no_cierra: 'Ne ferme pas',

  nuevo_partner_hcc: 'Nouveau Partner HCC',
  inscribir_como_partner_hcc: 'Inscrit comme Partner HCC',

  otro: 'autre',
  telefono: 'téléphone',
  atencion_rgpd: 'ATTENTION, RGPD TOUJOURS MANQUANT',
  atencion_rgpd_info: 'N\'oubliez pas que le client sera bloqué dans 3 mois à compter de la date de la première vente pour\n' +
    'clients après le [date indéfinie] et pour les anciens clients 3 mois à compter du [date indéfinie]',

  descatalogado_hasta_fin: 'Déstockage jusqu’à la fin des stocks',

  enviar_rgpd_a_email: 'Le consentement de données sera envoyé à l’email',
  rgpd_necesita_validar_email: 'Pour pouvoir envoyer le RGPD par email, le client a d’abord dû valider son email',
  clientes_sin_validar: 'Clients non validés',
  rgpd_validado: 'RGPD Validé',
  mail_validado: 'Email Validé',
  formato_factura: 'Format de facture',
  sin_confirmar: 'Non confirmé',
  factura_electronica: 'Fact. Électronique',
  factura_papel: 'Fact. Papier',
  enviar_rgpd: 'Envoyer RGPD',
  enviar_rgpd_info: 'Envoyer RGPD au client ',
  confirmar_envio_rgpd_SMS: 'Un message sera envoyé pour valider le RGPD au téléphone',
  rgpd_verificacion_enviado_SMS: 'Un message a été envoyé au téléphone',
  confirmar_envio_rgpd_EMAIL: 'Un e-mail sera envoyé pour valider le RGPD à la poste',
  rgpd_verificacion_enviado_EMAIL: 'Un email a été envoyé à l’email',
  no_cliente_seleccionado: 'Aucun client sélectionné',
  telefonos_disponibles: 'Téléphones disponibles',
  confirmar_envio_email: 'Vous allez envoyer une validation d’email à l’email',
  confirmacion: 'Confirmation',
  productos_similares: 'Produits similaires',
  cambiar_email: 'Changer de email',

  cif_vacio_oferta: 'Veuillez introduire le Nº TVA ou SIRET, autrement vous devez obligatoirement écrire un commentaire pour passer le bon commande.',
  revisado_y_sin_info: 'Révisée et pas d\'infos',
  ps_nuevo: '¡NOUVEAU!',
  ps_pend_servir_texto_card: 'Cliquez ici pour vérifier votre commande en attente',
  real: 'Réel',
  aprox: 'Aprox',
  llegada: 'Arrivée',
  hace_x_minutos: 'A {x} minutes',
  ver_por_producto: 'Voir par produits',
  ps_info: 'Les dates de disponibilité sont indicatives. Si vous avez besoin de plus d\'informations sur les produits qui ne sont pas servis et qui n\'ont pas de date de disponibilité, consultez l\'administration.',
  ps_productos_envasandose: 'Produits de ta commande en attente en cours d’emballage',
  ps_no_productos_envasandose: 'Il n’ y a pas de produits  en cours d’ emballage pour votre commande en attente',
  productos_pendientes_servir: 'Produits en attente',
  sirviendose: 'En cours',
  enRevision: 'En cours de révision',
  ps_clientes_envasando: 'Les clients suivants ont un produit en cours d’ emballage',
  disponible: 'Disponible',
  pedidos: 'Commandes',

  nuevo_email: 'Nouvelle adresse e-mail',
  email_actual_es: 'L’adresse e-mail actuelle du client est {email}. Est-ce correct ?',
  factura_a_email: 'ATTENTION: Le client a une adresse e-mail valide et la facture électronique sera envoyée à {email}',
  BUY_AGAIN: 'Refaire la même commande',
  importe: 'Montant',
  NO_PENDING_BILLS_VENDOR: 'Aucune facture impayée trouvée',

  statusCode: {
    '201': 'Succès',
  },

  code: 'Code',
  mainAddress: 'Adresse principale',
  navisionId: 'Code',
  cif: 'SIRET/TVA',
  socialReason: 'Dénomination sociale',
  name: 'Prénom',
  name2: 'Nom 2',
  closeDays: 'Jours de fermeture',
  email: 'Email',
  phone: 'Portable',
  mobile: 'Téléphone portable',
  fax: 'Fax',
  address: 'Adresse',
  address2: 'Adresse 2',
  city: 'Ville',
  state: 'Région',
  postalCode: 'Code postal',
  clientComments: 'Commentaires du client',
  contractComments: 'Commentaires du contrat',
  addresses: 'Adresses',
  contact: 'Contact',
  newClient: 'Nouveau client',
  surnameName: 'Nom',
  bankAccount: 'RIB',

  datos_correctos: 'Les données sont-elles correctes?',
  cliente_no_encontrado: 'Client non trouvé',
  cliente_click_cambiar: 'Le client sélectionné est introuvable, veuillez cliquer ici pour changer de client',
  email_validado: 'L’e-mail du client est validé',
  email_no_validado: 'L’e-mail du client n’est pas validé, il ne pourra donc pas recevoir de factures électroniques',
  validar_email: 'Valider l’e-mail',

  leyenda: 'Légende',
  oferta_pendiente: 'Commande en cours',
  exito: 'Succès',
  contrasena_cambiada: 'Le mot de passe a été mis à jour correctement',
  hace_x_horas: 'Il y a {x} heures',
  hace_x_dias: 'Il y a {x} jours',
  no_pendiente_servir: 'Aucune commande en cours',
  codigo_cliente: 'Code client',
  nombre_cliente: 'Nom client',
  ascendente: 'Croissant',
  descendente: 'Décroissant',
  fecha: 'Date',
  num_expedicion: 'Nº envoi',
  num_documento: 'Nº document',
  no_expediciones_2_semanas: 'Aucun envoi de moins de 2 semaines',
  no_facturas_anteriores: 'Aucune facture antérieure trouvée',
  no_abonos_anteriores: 'Aucun avoir précédent trouvé',
  num_factura: 'Nº Facture',
  num_vendedor: 'Nº Vendeur',
  fecha_factura: 'Date facture',
  num_cliente: 'Nº Client',
  forma_pago: 'Moyen de paiement',
  facturar_a: 'Facturer à',
  fecha_pedido: 'Date commande',
  direccion_envio: 'Adresse de livraison',
  num_pedido: 'Nº Commande',
  base: 'Base',
  importe_iva: 'Montant TVA',
  total_iva_inc: 'Total TVA incluse',
  importe_base: 'Montant de base',
  porc_iva: '%TVA',
  porc_re: '% RE', // % Taxe d’égalisation
  importe_re: 'Montant RE', // Montant Taxe d’égalisation
  dto_factura_pagos: 'Remise Fact et Paiements',
  no_facturas_mes: 'Aucune facture trouvée pour ce mois',
  no_facturas_pendientes: 'Aucune facture impayée trouvée',
  seleccionar_mes: 'Sélectionner mois',
  seleccionar_ano: 'Sélectionner année',
  pago: 'Paiement',
  factura: 'Facture',
  impago: 'Impayé',
  total_pendiente: 'Total restant',
  cobros_pendientes: 'Créances impayées',
  sin_explicar_comentario: 'Sans expliquer, veuillez envoyer un commentaire',
  importe_pendiente: 'Somme restante',
  saldo_acumulado: 'Solde cumulé',
  documentos_pendientes: 'Documents en cours',
  tipo_doc: 'Type Doc.',
  importe_original: 'Montant initial',
  num_contrato: 'Nº Contrat',
  tipo_contrato: 'Type de contrat',
  importe_contrareembolso: 'Montant Contre-remboursement',
  anadir_comentario: 'Ajouter un commentaire',
  cerrar: 'Fermer',
  introduce_comentario: 'Inclure un commentaire',
  aun_no_comentarios: 'Pas encore de commentaires',
  client_no_cuenta: 'Le client n’a pas de numéro de compte',
  actualizar_email: 'Mettre à jour l’e-mail',
  atencion_factura_electronica: 'ATTENTION: A partir de maintenant une facture électronique sera envoyée au client, vous devez vérifier que l’adresse e-mail est correcte, ou bien la corriger',
  cliente_no_email_asociado: 'Le client n’a pas d’adresse e-mail associée. Est-ce correct?',
  email_cliente_incorrecto: 'L’adresse e-mail du client EST INCORRECTE',
  email_cliente_correcto: 'L’adresse e-mail du client est correcte',
  introduce_nuevo_email: 'Veuillez introduir la nouvelle adresse e-mail',
  email_no_valido: 'L’adresse e-mail est invalide',
  email_verificacion_enviado: 'Un message de vérification a été envoyé à l’adresse e-mail spécifiée.',
  cancelar: 'Annuler',
  GIRO_DISABLED: 'Le virement bancaire n’est pas possible sans spécifier le numéro de compte. Veuillez contacter l’administration pour rajouter un numéro de compte bancaire et réessayer. Les modifications seront disponibles dans votre application le jour suivant.',
  informacion: 'Information',
  eliminar_promocion: 'Eliminer la promotion',
  que_quieres_hacer: 'Que souhaitez-vous faire?',
  eliminar_todo: 'Tout éliminer',
  eliminar_solo_cabecera: 'Eliminer seulement l’en-tête',
  eliminar_cabecera_poner_precios: 'Eliminer l’en-tête et fixer les prix de base',
  buscar_direccion: 'Chercher une adresse',
  volver_hacer_pedido: 'Refaire la même commande',
  no_giros_mostrar: 'Auncun virement à montrer',
  son_correctos_datos: 'Les données sont-elles correctes?',
  puedes_cambiar_mes_ano: 'Vous pouvez changer le mois et l’année',
  obtener_facturas: 'Obtenir les factures',
  mes: 'Mois',
  ano: 'Année',
  desde_el: 'Depuis le',
  hasta_el: 'Jusqu’au',
  total_ventas: 'Total Vente',
  total_impagos: 'Total Impayés',
  total_cobros_impagos: 'Total Encaissements Impayés',
  buscar_en_documentos: 'Chercher dans les documents',
  ventas_abonos_anulaciones: 'Ventes-Crédits-Retours',
  impagos: 'Impayés',
  cobros_impagos: 'Encaissements d’impayés',
  DELETE_PROMO: 'Eliminer la promotion',
  DELETE_PROMO_TEXT: 'Que souhaitez-vous faire?',
  DELETE_ALL: 'Tout éliminer',
  DELETE_ONLY_HEADER: 'Eliminer seulement l’en-tête',
  DELETE_HEADER_AND_PUT_PRICES: 'Eliminer l’en-tête et fixer les prix de base',

  CONFIRM_SEND_ORDER: 'Êtes-vous sûr de vouloir envoyer la proforma?',
  SEND_ORDER: 'Envoyer proforma',
  SELECT_MODIFIERS: 'Sélectionnez les champs que vous voulez éditer',
  DELETE_SELECTED_TEXT: 'Êtes-vous sûr de vouloir supprimer les articles suivants?',
  CONFIRM: 'Confirmer',
  ACT_CANTIDAD: 'Changer la quantité',
  ACT_PRECIO_UNIDAD: 'Changer prix unitaire',
  ACT_DESC: 'Mettre à jour la réduction',
  ACT_GRATIS: 'Mettre à jour gratuitement',
  LINEAS_BONIFICADAS: 'Lignes offertes',
  CERRAR: 'Fermer',
  GUARDAR: 'Enregistrer',

  TYPE_CLIENT_EMPTY: 'Vous devez remplir le champ Type Client',
  CIF_EMPTY: 'Vous devez remplis le champ SIRET/TVA',
  CIF_NOT_VALID: 'Le SIRET/TVA n\'est pas correct, vérifiez le champ SIRET/TVA',
  NAME_EMPTY: 'Vous devez remplir le champ Prénom',
  NAME_TOO_LONG: 'Prénom et nom ensemble ils sont trop longs, essaiez de les raccourcir ou envisagez de les séparer dans le nom 2',
  NAME2_TOO_LONG: 'Le champ Nom 2 est trop long',
  SOCIAL_REASON_TOO_LONG: 'Le champ raison sociale est trop long',
  ADDRESS_EMPTY: 'Vous devez remplir le champ Adresse',
  ADDRESS_TOO_LONG: 'Le champ Adresse est trop long, essayiez de la séparer avec le champ Adresse 2',
  ADDRESS2_TOO_LONG: 'Le champ adresse 2 est trop long, essayiez de le raccourcir',
  CITY_EMPTY: 'Vous devez remplir le champ Ville',
  CITY_TOO_LONG: 'Le champ Ville est trop long, esayiez de le raccourcir',
  STATE_EMPTY: 'Vous devez remplir le champ Région',
  STATE_TOO_LONG: 'Le champ Région est trop long, essayiez de le raccourcir',
  COUNTRY_EMPTY: 'Vous devez sélectionner un pays',
  POSTAL_CODE_EMPTY: 'Vous devez remplir le code postal',
  POSTAL_CODE_WRONG: 'Vous avez mal entré le champ Code Postal',
  CONTACT_EMPTY: 'Vous devez remplir le champ Contact',
  CONTACT_TOO_LONG: 'Le champ Contact est trop long',
  CLOSE_DAYS_EMPTY: 'Vous devez remplir le champ jours fériés',
  CLOSE_DAYS_TOO_LONG: 'Le champ Jours fériés est trop long, essayiez de le raccourcir',
  EMAIL_EMPTY: 'Vous devez remplir le champ E-mail',
  EMAIL_NOT_VALID: 'L\'E-mail n\'a pas un format valide',
  EMAIL_TOO_LONG: 'L\'E-mail est trop long',
  MOBILE_OR_PHONE_E: 'Vous devez remplir le champ Téléphone ou le champ Portable',
  FAX_TOO_LONG: 'Le champ Fax est trop long',
  NOT_VALID_ACCOUNT: 'Le champ Nº de compte doit avoir ce format SSXX XXXX XXXX XX XXXXXXXXXX',
  FILL_BANK_ACCOUNT: 'Si vous voulez faire un mandat à un nouveau client vous devez envoyer aussi le RIB, s\'il vous plaît remplissez-le',
  FILL_NEW_CLIENT_DATA: 'Si vous allez passer une commande à un nouveau client, vous devez remplir les données. Cliquez sur le suivant bouton et remplissez les champs de données',
  FILL_PRECLIENT: 'Remplir les données du client',
  UPDATE_PRECLIENT: 'Mise à jour les données du client',
  INFO: 'Information',
  CLIENT: 'Client',
  ORDER_DATE: 'Date de la proforma',
  IMPORT: 'Montant',
  COMPANY_SELL_CHANNEL: 'Entreprise et canal de vente',
  SEND_ADDRESS: 'Adresse de livraison',
  INFO_METHODS: 'Informatiosn et modes de paiement',
  RESUME_ORDER: 'Récapitulatif de la commande',
  NEW_CLIENT: 'NOUVEAU CLIENT',

  errores: 'Erreurs',
  aceptar: 'Accepter',
  enviar: 'Envoyer',
  actualizarPagina: 'Actualiser la page',
  topClientes: 'Top Clients',
  actualizacion: 'Mise à jour',
  vamosAActualizar: 'Nous mettrons à jour',
  relevancia: 'Importance',
  masVisitados: 'Plus visités',
  novedades: 'Nouveautés',
  TAHE_FRANCIA: 'Tahe EU',
  TAHE_ALEMANIA: 'Tahe EU',
  informacionYMetodos: 'Informatiosn et modes de paiement',
  resumenPedido: 'Récapitulatif de la commande',
  cliente: 'Client',
  fechaDeOferta: 'Date de la proforma',
  empresaYCanal: 'Entreprise et canal de vente',
  direccionEnvio: 'Adresse de livraison',

  proximosCursos: 'Próximos Cursos',
  seleccionCursos: 'Selección de Cursos',
  'Relevancia': 'Importance',
  'Más visitados': 'Plus visités',
  'Novedades': 'Nouveautés',
  pages: {
    cursos: 'Cursos',
    updatePage: 'Actualiser la page',
    home: 'Accueil',
    dashboard: 'Panneau de contrôle',
    distributors: 'Distributeurs',
    catalog: 'Catalogue',
    reports: 'Rapports',
    statistics: 'Stadistiques',
    addPreClient: 'Ajouter un prospect',
    addDistributorClient: 'Ajouter un client',
    approvalOrders: 'Approuver les commandes',
    approvalRefunds: 'Approuver les notes de crédits',
    approvalCharges: 'Approuver les charges',
    pendingOrder: 'Commande en cours',
    previousOrders: 'Commandes précédentes',
    pendingCharge: 'Recouvrement en cours',
    previousCharges: 'Recouvrements précédents',
    pendingRefund: 'Note de crédit en cours',
    previousRefunds: 'Note des crédits précédentes',
    clientManagement: 'Gestion du client',
    vendorManagement: 'Renseignement sur le vendeur',
    extracts: 'Mouvements du client',
    bills: 'Factures',
    billsRefunds: 'Notes de crédit facturées',
    billsInvoices: 'Factures',
    soldProducts: 'Produits vendus',
    expeditions: 'Expéditions',
    expenses: 'Dépenses',
    incomes: 'Revenus',
    pendingToShip: 'Commandes en attente',
    clientsContracts: 'Contrats',
    refundsStatus: 'Statut de la note de crédit',
    commissions: 'Comissions',
    settings: 'Réglages',
    changelog: 'Nouveautés',
    faqStores: 'Aide aux salons de coiffeurs',
    faq: 'Aide',
    notFound: 'Pas trouvé',
    vendorSelection: 'Sélection du vendeur',
    clientSelection: 'Sélection du client',
    sellChannelSelection: 'Chaîne des ventes',
    clientPendingOrder: 'Commandes ouvertes',
    clientPreviousOrders: 'Commandes précédentes',
    clientPendingCharge: 'Recouvrement en cours',
    clientPreviousCharges: 'Recouvrements précédents',
    clientPendingRefund: 'Note de crédit en cours',
    clientPreviousRefunds: 'Note de crédits précédentes',
    clientPendingToShip: 'Commandes en attente',
    clientExpeditions: 'Expéditions',
    clientGiros: 'Virements',
    clientPendingBills: 'Factures en attente',
    clientObjectives: 'Objectifs',
    clientContracts: 'Contrats',
    clientExtracts: 'Mouvements',
    clientBills: 'Factures',
    clientBillsRefunds: 'Note de crédit facturées',
    productDetails: 'Produit',
    notFoundClient: 'Client',
    refundStatusDetails: 'État de la note de crédit',
    searchRefundProduct: 'Chercher produit à créditer',
    orderDetails: 'Détails de la commande',
    productBills: 'Factures du produit',
    checkoutCharge: 'Faire les démarches pour le recouvrement',
    newClientDetails: 'Détails du nouvel client',
    newIncome: 'Nouvel revenu',
    newExpense: 'Nouvelle dépense',
    checkoutOrder: 'Commande en cours',
    newDistributorClients: 'Nouveau client',
    employees: 'Employés',
    users: 'Utilisateurs',
    topClientes: 'Top Clients',
  },
  menus: {
    menu: 'Menu',
    mainMenu: 'Menu Principal',
    favoritesMenu: 'Favoris',
    clientManagementMenu: 'Gestion du client',
  },
  buttons: {
    add: 'Ajouter',
    changePassword: 'Changer le mot de passe',
    copyToOtherClient: 'Copier à un autre client',
    moveToOtherClient: 'Passer à un autre client',
    duplicate: 'Dupliquer',
    copy: 'Copier',
    checkInOcean: 'Enregistrer en Ocean',
    addBillManual: 'Ajouter une facture manuellement',
    addRemaining: 'Ajouter le reste',
    addChargeDate: 'Ajouter la date d’encaissement',
    save: 'Enregistrer',
    ok: 'OK',
    update: 'Actualiser',
    search: 'Chercheur',
    close: 'Fermer',
    logout: 'Sortir',
    searchClient: 'Chercher Client',
    searchProductInBills: 'Chercher facture',
    back: 'Retour',
    cancel: 'Annuler',
    login: 'Accepter',
    clean: 'Supprimer',
    confirm: 'Confirmer',
    findClient: 'Trouver client',
    updateEmail: 'Actualiser E-mail',
    addToOrder: 'Ajouter au panier',
    delete: 'Effacer',
    addIncome: 'Ajouter un revenu',
    addExpense: 'Ajouter une dépense',
    checkoutOrder: 'Valider la commande',
    selectAll: 'Sélectionner tout',
    activateSort: 'Activer le classement',
    deactivateSort: 'Désactiver le classement',
    addCharge: 'Ajouter encaissement',
    createAdvice: 'Générer un acompte',
    addPendingBill: 'Ajouter encaissement',
    accept: 'Accepter', done: 'Fait',
    getObjective: 'Obtenir la cible',
    getSoldProducts: 'Obtenir les produits vendus',
    updateWebProduct: 'Actualiser le produit',
    updateWebCategory: 'Actualiser la catégorie',
    addProduct: 'Ajouter produit',
    searchInBills: 'Chercher dans les factures',
    sendRefund: 'Envoyer avoir',
    showAccount: 'Consulter le numéro de compte ',
    addObjective: 'Ajouter la cible',
    addPreclient: 'Ajouter Pré-client',
    checkoutCharge: 'Traiter l’encaissement',
    deleteSelected: 'Supprimer sélectionnés',
    send: 'Envoyer',
    refund: 'Régler',
    refundAll: 'Régler tout',
    reshopAll: 'Tout racheter',
    reshop: 'Racheter',
    newUser: 'Nouveau utilisateur'
  },
  popups: {
    globalSearch: 'Chercheur',
  },
  inputs: {
    password: { label: 'Mot de passe', placeholder: 'Votre mot de passe actuel' },
    newPassword: { label: 'Nouveau mot de passe', placeholder: 'Le nouveau mot de passe' },
    newPasswordConfirmation: { label: 'Répète le nouveau mot de passe', placeholder: 'Répète le nouveau mot de passe' },
    searchClient : { label: 'Client' , placeholder: 'Chercher un client par le prénom, l’identifiant du client ou le code d’identification fiscale ' },
    searchProductInBills : { label: 'Produit' , placeholder: 'Chercher un produit par code ou description' },
    searchMainMenu : { label: 'Chercher produit' , placeholder: '¿Qu’est-ce que vous voulez faire?' },
    searchProduct : { label: 'Chercher un produit' , placeholder: 'Cherchez un produit par code, description...' },
    searchFaq : { label: 'Chercher dans l’option Aide' , placeholder: 'Chercher dans l’option Aide' },
    searchOption : { label: 'Chercher option' , placeholder: 'Options de filtre par code, prénom...' },
    searchVendorCode : { label: 'Chercher code vendeur' , placeholder: 'Chercher vendeur par code, prénom...' },
    searchReports : { label: 'Rapport de recherche' , placeholder: 'Rechercher un rapport par prénom ou code du client...'},
    motive : { label: 'Motif' , placeholder: 'Introduit une petite description' },
    advice : { label: 'Cantidad' , placeholder: 'Introduit le montant de l’acompte' },
    comment : { label: 'Commentaire' , placeholder: 'Écrivez votre commentaire ici si vous avez besoin de fournir des informations supplémentaires' },
  },
  login: {
    title: 'Cosmet',
    username: { label: 'Utilisateur', placeholder: 'Ton utilisateur' },
    password: { label: 'Mot de passe', placeholder: 'Ton mot de passe' },
    information: {
      line1: `Connectez-vous avec votre nom d'utilisateur et votre mot de passe pour entrer dans le cosmet <br><strong>(Antiguo OferTAHE y OferTH)</strong>`,
      line2: `Cosmet &copy; {year} {version}`, }
  },
  info: {
    selectAddressFill: 'Sélectionnez une adresse de livraison',
    noLastBillsVendor: `Aucune facture trouvée pour ce mois`,
    noExpeditions: `Aucun envoi de moins de 2 semaines`,
    passwordSuccessfullChanged: 'Le mot de passe a été mis à jour correctement',
    noFavProducts: `Vous n'avez pas encore ajouté de favoris`,
    cardProduct: 'Les produits du catalogue seront affichés dans un style carte',
    listProduct: 'Les produits du catalogue seront affichés sous forme de liste',
    contactWithRRHH: 'Si vous avez des questions ou des problèmes à ce sujet, veuillez contacter avec ' +
      'le département des ressources humaines et pas avec l’administration de l’appli',
    noBillsFound: 'On n’a pas trouvé des factures',
    searchRefundProducts: 'D’abord cherche le produit qu’on veut régler',
    addChargeDates: 'Sélectionnez le montant à encaisser et la date souhaitée, puis cliquez sur ajouter un encaissement',
    selectPaymentFormFill: 'Vous devez choisir un mode de paiement',
    selectPaymentTermFill: 'Vous devez choisir un délai de paiement',
    noFavoritesMenu: "Vous n'avez pas encore ajouté de liens aux favoris. Essayez de cliquer sur l'une des étoiles" +
      '<icon class="icon f7-icons" style="font-size: 20px">star</icon> des liens du menu.',
    searchResults: 'Résultats de la recherche',
    isSelectedCode: 'Vous avez sélectionnez ce nouveau code et ce circuit de vente',
    newClientUse: ' Utilisez ce client pour saisir une commande pour un nouveau client ou un pré-client',
    noClientsFound: 'Ops! Aucun client trouvé avec cette recherche',
    noCodesFound: 'On n’a pas trouvé des codes avec votre recherche',
    clientConfirm: 'Vous êtes sûr que vous souhaitez changer à ce client?',
    clientConfirmNew: 'Vous voulez changer au NOUVEAU CLIENT(Code: 1)?',
    notFoundClient: 'Si vous avez ajouté le client aujourd’hui, il est normal qu’il n’apparaisse pas, mais ne vous inquiétez pas ! ' +
      'Si vous connaissez le code du client, vous pouvez l’ajouter vous-même ici ' +
      'Mais n’oubliez pas que vos informations, telles que les commentaires sur le contrat, ' +
      'les adresses supplémentaires, etc,... apparaîtront demain. Malgré tout, si vous avez besoin de passer une commande, un avoir' +
      'ou encaissement essayez-vous de l’ajouter vous-même, pour ne pas attendre jusqu’à demain',
    noPromoProducts: 'Il n’a pas une liste de matériaux fixe',
    orderLines: 'Produits',
    sampleLines: 'Échantillons de commande',
    incidentLines: 'Produits avec des incidences de commande',
    noOrderLines: 'Vous n’avez pas encore ajouté des lignes à la commande',
    noRefundLines: ' Vous n’avez pas encore ajouté des lignes à l’avoir',
    noSampleLines: 'Vous n’avez pas encore ajouté des échantillons à votre commande',
    noIncidentLines: 'On n’a pas encore aucune ligne d’incidence dans la commande',
    chargeFor: 'Encaissement pour',
    noPendingCharge: 'Vous n’avez pas encore ajouté de lignes à l’encaissement. Cliquez sur le bouton pour voir les frais en attente du client.',
    refundFor: 'Avoir pour',
    noPendingRefund: 'Vous n’avez pas encore ajouté de lignes à votre avoir. Cliquez sur le bouton pour rechercher un produit.',
    noPendingBills: 'Le client n’a pas aucune facture non réglée',
    noPreviousOrders: 'On n’a pas trouvés des devis précédents',
    noPendingOrders: 'On n’a pas trouvés des devis en cours',
    noPreviousRefunds: 'On n’a pas trouvé des avoirs précédents',
    noPendingRefunds: 'On n’a pas trouvé des avoirs en cours',
    noPreviousCharges: 'On n’a pas trouvé des encaissement précédents',
    noPendingCharges: 'On n’a pas trouvé des encaissements en cours',
    selectVendorCode: 'Sélectionnez un code de vendeur',
    productsSoldInfo: 'Le calcul des produits vendus se fait en retirant les produits facturés et en soustrayant les produits payés',
    hasKeyboard: 'Si vous ne travaillez pas avec un clavier désactivez cette option pour avoir une calculette.',
    pleaseFixErrors: 'Corrigez les erreurs suivantes',
    noProductsFound: `Aucun produit n'a été trouvé avec la recherche <em>{0}</em>`,
    noPendingToShip: `Aucune commande en cours`,
    noPreviousBills: 'Aucune facture antérieure trouvée',
  },
  text: {
    underConstruction: 'En développement',
    suggestions: 'Peut être vous vouliez dire',
    lastUpdate: 'Dernière version',
    added: 'rajout',
    addressSelection: 'Sélectionner l’adresse',
    send: 'Envoyer',
    type: 'Type',
    importWithImp: 'Montant (mont.compris)',
    changePassword: 'Changer le mot de passe',
    accesibility: 'Accesibilité',
    hasKeyboard: 'Travail au clavier',
    tour: 'Tour fait',
    languages: 'Langues',
    catalog: 'Catalogue',
    card: 'Carte',
    list: 'Liste',
    bonifyItems: 'Articles offerts',
    promoCount: 'Nombre de promos',
    editSelected: 'Modifier la sélection',
    copySelected: 'Copier la sélection',
    duplicateSelected: 'Doubler la sélection',
    deleteSelected: 'Supprimer la sélection',
    seeBills: 'Montrer les factures',
    dates: 'Dates',
    remainingIVAInc: 'Solde (TVA inclus)',
    companyAndSellChannel: 'Société et réseau de commercialisation',
    numCheck: 'numéro de chèque',
    paymentDates: 'Dates de repglèment',
    addChargeDates: 'Ajouter la date de reglèment',
    paymentFormSelection: 'Moyent de paiement',
    paymentTermSelection: 'Modalité de paiement',
    orderDate: 'Date de la commande',
    abonable: 'À régler',
    sellChannel: 'Réseau de commercialisation',
    news: 'Nouveautés',
    info: 'Information',
    infoShort: 'Info',
    importantInfo: 'Information importante',
    new: 'Nouveau',
    soldOut: 'Épuisé',
    products: 'Produits',
    navigation: 'Navigation',
    favorites: 'Favoris',
    lines: 'Lignes',
    categories: 'Catégories',
    first: 'Première',
    last: 'Dernière',
    seeOptions: 'Voir les options',
    price: 'Prix',
    of: 'de',
    user: 'Utilisateur',
    codes: 'Codes',
    newClient: 'NOUVEAU CLIENT',
    cif: 'SIRET/TVA',
    confirm: 'Confirmer',
    notFoundClient: 'Ne pas trouvé',
    comments: 'Commentaires',
    cod: 'Code',
    orderBy: 'Classer par',
    code: 'Code',
    description: 'Description',
    quantity: 'Quantité',
    unitsPer: 'Unités par',
    total: 'Total',
    totalQuantities: 'Unités',
    discount: 'Remise',
    actions: 'Actions',
    addWithDiscount: 'Ajouter une remise',
    productItems: 'Articles',
    import: 'Montant',
    orders: 'Commandes',
    refunds: 'Versement',
    charges: 'Reglèments',
    expenses: 'Dépenses',
    newClients: 'Nouveaux clients',
    income: 'Versement',
    yes: 'Oui',
    no: 'Non',
    numb: 'Nº',
    date: 'Date',
    reportId: 'Numéro de suivi',
    addProduct: 'Ajouter un produit',
    addByCode: 'Ajouter par code',
    searchProduct: 'Chercher un produit',
    order: 'Commande',
    samples: 'Échantillons',
    incidents: 'Incidences',
    ivaInc: '+ (TVA inclus)',
    freeItems: 'Articles offerts',
    numPromos: 'Numéro de promos',
    selec: 'Sélect.',
    impSelect: 'Mont. Sélect.',
    pendingBills: 'Factures non réglées',
    company: 'Société',
    postingDate: 'Date d’enregistrement',
    dueDate: 'Date d’expiration',
    documentNo: 'Numéro de document',
    paymentForm: 'Mode de paiement',
    paymentMethod: 'Méthode de paiement',
    paymentTerm: 'Modalité de paiement',
    amount: 'Montant',
    remainingAmount: 'Montant restant',
    totalContrareembolso: 'Contre-remboursement',
    unfinished: 'Inachevé',
    paymentDate: 'Date de paiement',
    status: 'État',
    statuses: 'États',
    client: 'Client',
    clients: 'Clients',
    from: 'Despuis',
    to: 'Jusqu’à',
    fromDate: 'Date depuis',
    toDate: 'Date jusqu’à',
    item: 'Article',
    items: 'Articles',
    update: 'Mettre à jour',
    statistics: 'Statistiques',
    pendingOrders: 'Commandes en cours',
    previousOrders: 'Commandes précedentes',
    pendingRefunds: 'Abonnements restants',
    previousRefunds: 'Abbonnements précedents',
    pendingCharges: 'Montants à recevoir',
    previousCharges: 'Prélèvements précedents',
    vendorCodeSelection: 'Cod. Vendeur',
    selectForStatistics: 'Vous pouvez changer le mois et l’année',
    month: 'Mois',
    year: 'Année',
    objective: 'But',
    billed: 'Enregistré',
    ideal: 'Idéal',
    cumplimentObj: 'mission accomplie',
    numPacks: 'Cartons',
    appliesToDoc: 'Liquidé par',
    dateRange: 'Plage de dates',
    selectDate: 'Sélectionnez la date',
    pendingItems: 'Unités en attente',
    units: 'Unités',
    reviser: 'Réviseur',
    quant: 'Quantité',
    unitPrice: 'Prix',
    totalImport: 'Montant total',
    totalPromo: 'Total promo',
    totalIVA: '+ (TVA compr.)',
    free: 'Offert',
    sample: 'Échantillon',
    incident: 'Incidence',
    bill: 'Facture',
    bills: 'Factures',
    motive: 'Raison',
    information: 'Information',
    select: 'Sélectionné',
    comment: 'Commentaire',
    account: 'Numéro de compte',
    warnings: 'Avertissements',
    disc: 'Remises %',
    discImp: 'Montant actualisé',
    promo: 'Promo',
    lastBills: 'Dernières factures',
    lateBills: 'Soldes non réglés',
    report: 'Rapport',
    vendor: 'Vendeur',
    share: 'Partager par',
    shareTelegram: 'Telegram',
    shareWhatsapp: 'Whatsapp',
    shareEmail: 'Envoyer à l’email',
    download: 'Téléchargez',
    searchResult: 'Résultats pour',
    totalPending: 'Total de l’encours',
    createAdvice: 'Créer une avance',
    advance: 'Avance',
    moveNo: 'Mouvement',
    toLiquid: 'À liquider',
    pendingImport: 'Montant à régler',
    selectedImport: 'Montant sélectionné',
  },
  fnText: {
    showResults: 'Montrant ({0} - {1}) de {2} résultats',
    total: 'Montant total {0}€',
    totalCash: 'Espèces {0}€',
  },
  filters: {
    lines: 'Lignes',
  },
  sellChannel: {
    TAHE_ESPANA: 'Tahe Espagne',
    TAHE_DISTRIBUTOR: 'Tahe distributeur',
    TAHE_DISTRIBUIDOR: 'Tahe distributeur',
    TAHE_CANARIAS: 'Tahe Îles Canaries',
    TAHE_PORTUGAL: 'Tahe Portugal',
    TAHE_ITALIA: 'Tahe Italie',
    TAHE_INTERNACIONAL: 'Tahe International',
    TAHE_OUTLET: 'Tahe Sortie',
    TAHE_FRANCIA: 'Tahe EU',
    TAHE_ALEMANIA: 'Tahe EU',
    TH_ESPANA: 'Th Pharma Espagne',
    TH_PORTUGAL: 'Th Pharma Portugal',
    TH_ITALIA: 'Th Pharma Italie',
    TH_INTERNATIONAL: 'Th Pharma International',
    TH_INTERNACIONAL: 'Th Pharma International',
    TH_OUTLET: 'Th Pharma Sortie',
    MER_ESPANA: 'Mercacosmética Espagne',
    DVF_ESPANA: 'Dv Farmaceutic Espagne',
    KELAYA_ESPANA:      'KELAYA España',
    KELAYA_CANARIAS:    'KELAYA Canarias',
    KELAYA_PORTUGAL:    'KELAYA Portugal',
    KELAYA_FRANCIA:    'KELAYA EU',
    KELAYA_ALEMANIA:    'KELAYA EU',
    BOCARE_ESPANA:      'BOCARE España',
    BOCARE_CANARIAS:    'BOCARE Canarias',
    BOCARE_PORTUGAL:    'BOCARE Portugal',
    BOCARE_FRANCIA:    'BOCARE EU',
    BOCARE_ALEMANIA:    'BOCARE EU',
  },
  status: {
    PENDING: 'En attente',
    PENDING_TO_SEND: 'Pend. envoyé',
    PENDING_APPROVAL: 'Pend. approuvé',
    PENDING_APPROVAL_DIST: 'Pend. Appr. Dis.',
    SENDED: 'Envoyé',
    IMPORT_ERROR: 'Erreur d’importation',
    RECEIVED: 'Reçu',
    ACCEPTED: 'Accepté',
    CANCELED: 'Annulé',
    UNKNOWN: 'Inconnu',
    OMNI_RECEIVED: 'Envoyé',
    ERROR_IMPORTACION: 'Pend. Rec.',
  },
  company: {
    TAHE: 'Tahe',
    TH_PHARMA: 'Th Pharma',
    TAHE_2016: 'Tahe',
    TH_PHARMA_2016: 'Th Pharma',
    MERCACOSMETICA: 'Mercacosmética',
    DV_FARMACEUTIC: 'DV Farmaceutic',
    'NEO-DEPIL LASER': 'Neo Depil Láser',
    'MAGAÑA': 'Tahe Productos Cosméticos S.L.',
    'Tahe Productos Cosméticos S_L_': 'Tahe Productos Cosméticos S.L.',
    'TH Pharma S_L_': 'Th Pharma',
  },
  packing: {
    BOX: 'Boîte',
    PACK: 'Palette',
    CAJA: 'Carton',
    PALET: 'Palette',
    UNIT: 'u',
    UNIDAD: 'u',
  },
  client: {
    code: 'Code',
    mainAddress: 'Adresse principale',
    navisionId: 'Code',
    cif: 'SIRET/TVA',
    socialReason: 'Dénomination sociale',
    name: 'Prénom',
    name2: 'Nom 2',
    closeDays: 'Jours de fermeture',
    email: 'Email',
    phone: 'Portable',
    mobile: 'Téléphone portable',
    fax: 'Fax',
    address: 'Adresse',
    address2: 'Adresse 2',
    city: 'Ville',
    state: 'Région',
    postalCode: 'Code postal',
    country: 'Pays',
    clientComments: 'Commentaires du client',
    contractComments: 'Commentaires du contrat',
    info: 'Information du client',
    addresses: 'Adresses',
    contact: 'Contact',
    typeClient: 'Type de client',
    newClient: 'Nouveau client',
    surnameName: 'Nom',
    bankAccount: 'RIB',
  },
  country: {
    ES: 'Espagne',
    PT: 'Portugal',
    IT: 'Italie',
    FR: 'France',
    BE: 'Belgique',
    DE: 'Allemagne',
  },
  paymentForm: {
    CONTADO: 'Contado',
    GIRO: 'Virement',
    CONTRAREEM: 'Contrareembolso',
    GIROPT: 'Giro',
    GIROFR: 'Prélèvement',
    CONTRARPOR: 'Contrareembolso',
    CHEQUEPT: 'Cheque',
    TARJETAPT: 'Tarjeta Clientes',
    CONTADOPT: 'Contrato Cheque (Contado)',
    'TARJ OUTLE': 'Tarjeta Outlet',
    TRANSFPORT: 'Transferencia',
    TRANSFERIT: 'Transferencia',
    TRANSFRANC: 'Virement',
    EFECTIVO: 'Efectivo',
    TARJETA: 'Tarjeta',
    GIROCOBRO: 'Giro',
    CHEQUE: 'Cheque',
    DESCUENTO: 'Descuento',
  },
  paymentTerm: {
    PERSONALIZADO: 'Personalizado',
    '1X7': 'Prélèvement 7 jours',
    '1X15': 'Prélèvement 15 jours',
    '1X20': 'Prélèvement 20 jours',
    '1X21': 'Prélèvement 21 jours',
    '1X30': 'Prélèvement 30 jours',
    '1X40': 'Prélèvement 40 jours',
    '1X45': 'Prélèvement 45 jours',
    '1X60': 'Prélèvement 60 jours',
    '1X75': 'Prélèvement 75 jours',
    '1X90': 'Prélèvement 90 jours',
    '2X7': 'Prélèvement 7 / 30 jours',
    '2X15': 'Prélèvement 15 / 30 jours',
    '2X20': 'Prélèvement 20 / 40 jours',
    '2X30': 'Prélèvement 30 / 60 jours',
    '2X45': 'Prélèvement 45 / 90 jours',
    '2X45B': 'Prélèvement 45 / 60 jours',
    '2X60': 'Prélèvement 60 / 90 jours',
    '2X60X120': 'Prélèvement 60 / 120 jours',
    '2X90': 'Prélèvement 90 / 120 jours',
    '3X10': 'Prélèvement 10 / 20 / 30 jours',
    '3X15': 'Prélèvement 15 / 30 / 45 jours',
    '3X3': 'Prélèvement 45 / 75 / 105 jours',
    '3X30': 'Prélèvement 30 / 60 / 90 jours',
    '3X60': 'Prélèvement 60 / 90 / 120 jours',
    '4X30': 'Prélèvement 30 / 60 / 90 / 120 jours',
    '4X3': 'Prélèvement 15 / 60 / 90 jours',
    '4X4': 'Prélèvement 15 / 30 / 60 / 90 jours',
    '1X120': 'Prélèvement 120 jours',
  },
  languages: {
    es: 'Espagnol',
    it: 'Italien',
    pt: 'Portugais',
    fr: 'Français',
  },
  typeClient: {
    'Peluquería': 'Salon de coiffure',
    'Estética': 'Salon de beauté',
    'Estética+Peluquería': 'Salon de beauté et coiffure',
    'Barbería': 'Barbière',
    'Otros': 'Otros',
  },
  'Peluquería': 'Salon de coiffure',
  'Estética': 'Salon de beauté',
  'Estética+Peluquería': 'Salon de beauté et coiffure',
  'Barbería': 'Barbière',
  'Otros': 'Otros',
};
