export const validateEmail = (email) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const navisionLength = (string) => {
  return string.length;
};

export const validateInputFloat = (input) => {
  return `${input}`.replace(',', '.').replace(/[^\-0-9.\-\*\/\+]+/g, '');
};
