import gql from 'graphql-tag';

export const GET_COMPANY = gql`
query GetCompany ($code: String){
  companies(filter: {code: {eq: $code}}) {
    edges {
      node {
        code
        isActive
        invoiceTo
        billsDatabases
        database
        type
      }
    }
  }
}`;

export const GET_SELL_CHANNELS = gql`
query {
  sellChannels(paging: {first: 50}) {
    edges {
      node {
        code
        company
        groupPrice
      }
    }
  }
}`;