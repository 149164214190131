<template>
  <f7-stepper :manual-input-mode="true" :id="`stepper-${type}-${product.optionSku}`"
    :value="getQuantity(product.optionSku)" :min="0" :max="9999"
    @stepper:change="changePendingCart($event, product)"
  ></f7-stepper>
</template>
<script>
import { useActions, useGetters } from '../../../../js/stores/hooks';
import { reactive } from 'vue';

export default {
  props: {
    type: { type: String, default: 'catalog' }, // catalog, cart
    product: { type: Object, default: null },
  },
  setup: (props) => {
    const { getQuantity } = useGetters('shoppingCart', ['getQuantity']);
    const { editPendingCart } = useActions('shoppingCart', ['editPendingCart']);
    const state = reactive({
      adding: false,
    });
    const changePendingCart = async (quantity, product) => {
      if (state.adding) return;
      state.adding = true;
      quantity = Math.max(0, quantity);
      if (props.type === 'card') {
        await editPendingCart({ optionSku: props.product.optionSku, quantity });
      } else {
        await editPendingCart({
          optionSku: props.product.optionSku,
          sku: props.product.sku,
          quantity: quantity,
          unitPrice: props.product.info.price + props.product.info.promoPriceLines,
          product: {
            blocked: props.product.blocked,
            extraName: props.product.translation.extraName,
            image: props.product.image,
            mainLine: props.product.translation.mainLine,
            name: props.product.translation.name,
            optionImage: props.product.optionImage,
            optionName: props.product.translation.optionName,
          }
        });
      }
      state.adding = false;
    };
    return { getQuantity, editPendingCart, changePendingCart };
  },
};
</script>
