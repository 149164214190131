<template>
  <f7-page :name="name" :page-content="false" :with-subnavbar="false">
    <navbar :nav-large="false" :transparent="true" :back-button="false" :has-right-panel="hasRightPanel">
      <template v-slot:left>
        <f7-link icon-only icon-f7="house_fill" href="/" view=".view-main" :disabled="isLoadingRoute" icon-size="20" panel-close></f7-link>
        <slot name="left"></slot>
      </template>
      <template v-slot:right>
        <slot name="right"></slot>
      </template>
    </navbar>
    <f7-page-content :with-subnavbar="false">
      <slot></slot>
    </f7-page-content>
  </f7-page>
</template>
<script>
import Navbar from './navbar/navbar.vue';
import { useState } from '@/js/stores/hooks';

export default {
  name: 'left-page',
  props: {
    name: { type: String, default: '' },
    hasRightPanel: { type: Boolean, default: false },
  },
  components: {
    'navbar': Navbar,
  },
  setup: () => {
    const { isLoadingRoute } = useState('app', ['isLoadingRoute']);
    return { isLoadingRoute };
  },
}
</script>
