<template>
  <f7-link v-if="isInBreakpoint" :icon="icon" panel-open="left" icon-size="24"></f7-link>
</template>
<script>
import { isInBreakpoint } from '@/js/helpers/f7.helpers';

export default {
  name: 'left-menu-button',
  props: {
    icon: { type: String, default: 'las la-bars' },
  },
  setup: () => {
    return { isInBreakpoint };
  }
}
</script>
