<template>
  <f7-sheet id="nav-product-stepper-sheet" backdrop :close-by-backdrop-click="false"
            @sheet:close="resetValues" @sheet:opened="setNewValue"
            style="height: 200px; width: 328px; position: absolute; left: 0; right: 0;
               top: 0; bottom: 0; margin: auto; --f7-sheet-bg-color: #fafafa; --f7-panel-backdrop-bg-color: transparent;
                border-radius: 4px;" class="shadow">
    <f7-page-content class="padding">
      <div class="display-flex flex-direction-row align-items-center justify-content-center">
        <f7-input class="padding padding-vertical-half value-input-dialog add-product-input" style="background: #d8d8d8; border-radius: 30px" type="text" :value="state.newValue"
                  @input="state.newValue = changeInput($event.target.value)"
                  @focus="selectInput()"
                  @keyup.enter.native="updateValue"></f7-input>
        <div class="margin-left-half"><strong>u</strong></div>
      </div>
      <div class="display-flex flex-direction-row justify-content-space-between" style="margin-top: 30px; margin-bottom: 30px;">
        <f7-button fill style="width: 30px;" @click="setQuantity('1')">1</f7-button>
        <f7-button fill style="width: 30px;" @click="setQuantity('3')">3</f7-button>
        <f7-button fill style="width: 30px;" @click="setQuantity('6')">6</f7-button>
        <f7-button fill style="width: 30px;" @click="setQuantity('9')">9</f7-button>
        <f7-button fill style="width: 30px;" @click="setQuantity('12')">12</f7-button>
      </div>
      <f7-row class="margin-top">
        <f7-col><f7-button @click="closeWithoutSave">{{$t('buttons.cancel')}}</f7-button></f7-col>
        <f7-col><f7-button @click="updateValue">{{$t('buttons.accept')}}</f7-button></f7-col>
      </f7-row>
    </f7-page-content>
  </f7-sheet>
</template>
<script>
import { reactive } from 'vue';
import { f7 } from 'framework7-vue';
import $$ from 'dom7';
import { validateInputFloat } from '../../../js/helpers/validation.helpers';
import { InputComposition } from '../input/input.composition';
import { useGetters } from '../../../js/stores/hooks';
import { store } from '../../../js/stores/vuex';

export default {
  name: 'nav-product-stepper-sheet',
  emits: ['input-updated'],
  setup: (props, { emit }) => {
    const { value, units, type, setInput } = InputComposition();
    const setNewValue = () => {
      state.newValue = value.value;
      $$('.add-product-input input').focus();
      setTimeout(() => selectInput(), 100);
    };
    const resetValues = () => {
      state.newValue = '';
      state.startTyping = false;
    };
    const clearNewValue = () => {
      state.newValue = state.newValue.substring(0, state.newValue.length - 1);
    };
    const updateValue = () => {
      // console.log(state.newValue);
      setInput({value: state.newValue, units: units.value});
      f7.sheet.get('#nav-product-stepper-sheet').close();
      emit('input-updated');
    };
    const touchHandler = (name) => {
      return function() {
        editNewValue(name);
      }
    };
    const editNewValue = (string) => {
      if (!state.startTyping) {
        state.startTyping = true;
        state.newValue = string;
      } else {
        state.newValue = state.newValue.concat(string);
      }
    };
    const state = reactive({
      newValue: '',
      startTyping: false,
    });
    const changeInput = (value) => {
      return validateInputFloat(value);
    };
    const setQuantity = (value) => {
      state.newValue = value;
      updateValue();
    };
    const closeWithoutSave = () => {
      setInput({value: store.getters['shoppingCart/getQuantity'](units.value), units: units.value});
      f7.sheet.get('#nav-product-stepper-sheet').close();
      emit('input-updated');
    };
    const selectInput = () => {
      document.querySelector('.add-product-input input').select();
    };
    return { value, units, type, setInput, state, editNewValue, touchHandler, updateValue, clearNewValue,
      resetValues, setNewValue, changeInput, setQuantity, closeWithoutSave, selectInput };
  },
};
</script>
