import { computed, reactive } from 'vue';
import { useStore } from 'vuex';
import { APP_VERSION, PANEL_BREAKPOINT } from '../config/app.config';
import { Dom7 } from 'framework7';
import { f7 } from 'framework7-vue';
import { Plugins } from '@capacitor/core';
import { i18n } from '../plugins/vue-i18n';
import {Workbox, messageSW} from 'workbox-window';
import { announcesService } from '../api/graphql/services/announces.service';

const { Storage } = Plugins;

const state = reactive({
  registration: null,
  localVersion: APP_VERSION,
  remoteVersion: '?',
});

export const F7 = () => {
  return { state };
};

export const isInBreakpoint = computed(() => {
  // const store = useStore();
  // console.log(store);
  return useStore().state.app.width < PANEL_BREAKPOINT;
});

export const isInPopup = (route) => {
  // console.log(route, useStore().state.app.currentRoute);
  return useStore().state.app.currentRoute !== route;
};

export const initTransitionGuard = ({setCurrentRoute, setLoadingRoute}) => {
  Dom7('.view-main').on('page:beforein', () => {
    setCurrentRoute(f7.views.main.router.currentRoute.url);
  });
  Dom7('.view-main').on('page:afterin', () => {
    Dom7('.link.back').removeClass('disable-back-button');
    setLoadingRoute(false);
  });
  Dom7('.view-main').on('page:beforeout', () => {
    Dom7('.link.back').addClass('disable-back-button');
    setLoadingRoute(true);
    setCurrentRoute('');
  });

};

export const hideSplashScreen = () => {
  Dom7('.splash-screen').hide();
};

export const checkAuthentication = async ({ getProfile, setAccessToken }) => {
  const accessToken = await Storage.get({ key: 'accessToken' });
  if (accessToken.value) {
    await setAccessToken(accessToken.value);
    await getProfile();
  }
};

export const initCurrentPath = async ({ setPath }) => {
  const path = await Storage.get({ key: 'path' });
  if (path.value) {
    await setPath(JSON.parse(path.value));
  } else {
    await Storage.set({ key: 'path' , value: JSON.stringify([0])});
  }
};

export const makeClientInfo = (t, clientInfo) => {
  let text = `${t('info.clientConfirm')}: <br>`;
  if (clientInfo.navisionId === '1') {
    text = `${t('info.clientConfirmNew')}`;
  } else {
    const filledKeys = Object.keys(clientInfo).filter(k =>
      !['__typename', 'address', 'city', 'state', 'postalCode', 'country',
        'emailValidado', 'permitePublicidad', 'rgpdValidado', 'formatoFactura',
      ].includes(k)
      && clientInfo[k] && clientInfo[k].trim() !== '');
    for (const k of filledKeys) {
      text += `<strong>${t(`client.${k}`)}:&nbsp;</strong>${clientInfo[k]}<br>`;
    }
    text += `<br>`;
    text += `<strong>${t('client.mainAddress')}</strong><br />
             <span>${clientInfo.address} ${clientInfo.address2}</span><br />
             <span>${clientInfo.city}, ${clientInfo.postalCode}</span><br />
             <span>${clientInfo.state} ${clientInfo.country}</span><br /></p>`;
    // if (!clientInfo.rgpdValidado || !clientInfo.emailValidado) {
    //   text += `
    //   <div class="display-flex justify-content-center">`;
    //   if (!clientInfo.rgpdValidado) {
    //     text += `
    //     <div style="width: 130px"><button class="button button-fill bg-color-blue button-rgpd" data-popup=".enviar-rpgd-popup">${t('enviar_rgpd')}</button></div>`;
    //   }
    //   if (!clientInfo.emailValidado) {
    //     text += `
    //     <div style="width: 160px"><button
    //         class="margin-left button button-fill bg-color-green button-email"
    //         data-popup=".enviar-email-popup">${t('validar_email')}</button></div>
    //   `;
    //   }
    //   text += `</div>`;
    // }
    // if (clientInfo.rgpdValidado) {
    //   text += `<div class="text-color-green">${t('rgpd_validado')}</div>`
    // }
    // if (clientInfo.emailValidado) {
    //   text += `<div class="text-color-green">${t('email_validado')}</div>`
    // }
  }
  return text;
};

export const createNotification = (message, status) => {
  const { t } = i18n.global;
  f7.notification.create({
    icon: `<i class="icon f7-icons ${status >= 300 ? 'text-color-red' : 'text-color-blue'}"
             >${status >= 300 ? 'exclamationmark_shield_fill' : 'paperplane_fill'}</i>`,
    title: t(`statusCode.${status}`),
    titleRightText: status === 999 ? 'Error de conexión' : status,
    text: t(message),
    closeOnClick: true,
    closeTimeout: 3000,
  }).open();
};

export const selectInput = (event) => {
  event.target.select();
};

export const checkAppVersion = async () => {
  const { t } = i18n.global;
  // console.log('CHECK APP VERSION');
  let appVersion = await Storage.get({ key: 'appVersion' });
  if (appVersion.value) {
    state.localVersion = appVersion.value;
  }
  let remoteVersion;
  try {
    const { data: { announces: { edges }}} = (await announcesService.getAnnounces('VERSION', 'ALL'));
    remoteVersion = edges[0].node.text;
  } catch (e) {
    remoteVersion = appVersion.value;
  }
  state.remoteVersion = remoteVersion;
  if (!state.registration) {
    if ('serviceWorker' in navigator) {
      // console.log('me cago en la hostia puta ya')
      const wb = new Workbox('/service-worker.js');
      const showSkipWaitingPrompt = (event) => {
        f7.dialog.alert(t('vamosAActualizar'), t('actualizacion'), () => {
          wb.addEventListener('controlling', (event) => {
            location.reload();
            // if (window.innerWidth > 1000) {
            //   document.getElementsByClassName('view view-main')[0].style.marginLeft = `260px`;
            //   document.getElementsByClassName('view view-main')[0].style.width = `calc(100% - 260px)`;
            // } else {
            //   document.getElementsByClassName('view view-main')[0].style.width = `100%`;
            // }
          });
          if (state.registration && state.registration.waiting) {
            // console.log('holi');
            messageSW(state.registration.waiting, {type: 'SKIP_WAITING'});
          }
        });
      }
      wb.addEventListener('waiting', showSkipWaitingPrompt);
      wb.addEventListener('externalWaiting', showSkipWaitingPrompt);
      state.registration = await wb.register();
    }
  }
  if (!appVersion.value || remoteVersion !== appVersion.value) {
    // console.log('no tengo appversion o es distinga');
    state.localVersion = remoteVersion;
    await Storage.set({ key: 'appVersion' , value: remoteVersion });
    f7.dialog.alert('Vamos a actualizar', 'Actualización', () => {
      window.location.reload();
      // if (state.registration) state.registration.update();
      // else
      // if (window.innerWidth > 1000) {
      //   document.getElementsByClassName('view view-main')[0].style.marginLeft = `260px`;
      //   document.getElementsByClassName('view view-main')[0].style.width = `calc(100% - 260px)`;
      // } else {
      //   document.getElementsByClassName('view view-main')[0].style.width = `100%`;
      // }
    });
  }
};
