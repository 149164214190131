import gql from 'graphql-tag';

const FULL_CLIENT = gql`
  fragment FullClient on Client {
    navisionId
    empresa
    cif
    name
    name2
    address
    address2
    city
    state
    postalCode
    country
    paymentForm
    vendorCodes
    validatedEmail
    email
    forzarGrupoPrecio
    forzarGrupoPrecio
    emailValidado
    rgpdValidado
    permitePublicidad
    formatoFactura
    comments {
      comment
      typeComment
    }
    contracts {
      startingDate
      endingDate
      status
      totalImport
      type
      clientId
      contractId
      paymentForm
    }
    addresses {
      navisionId
      name
      name2
      address
      address2
      postalCode
      city
      state
      country
      phone
      contact
    }
  }
`;

export const GET_CLIENT = `
  client(navisionId: $navisionId, empresa: $empresa) {
    ...FullClient
    phone
    mobile
    fax
    contact
    email
    closeDays
    socialReason
    bankAccount
  }
`;

export const GET_CLIENTS = `
  findClients(code: $code, empresa: $empresa) {
    navisionId
    empresa
    name
    cif
    pendingOrder
    pendingRefund
    pendingCharge
    pendingBill
    address
    address2
    city
    state
    postalCode
    country
    forzarGrupoPrecio
    emailValidado
    rgpdValidado
    permitePublicidad
    formatoFactura
    email
    phone
    mobile
  }
`;

export const GET_CLIENT_INFO = `
  client(navisionId: $navisionId, empresa: $empresa) {
    navisionId
    cif
    name
    name2
    address
    address2
    city
    state
    postalCode
    country
    phone
    mobile
    closeDays
    email
    forzarGrupoPrecio
    forzarGrupoPrecio
    emailValidado
    rgpdValidado
    permitePublicidad
    formatoFactura
  }
`;

export const IMPORT_CLIENT = `
  importClient(data: {
    navisionId: $navisionId,
    empresa: $empresa,
    vendorCode: $vendorCode,
  }) {
    ...FullClient
  }
`;

export const GET_CLIENT_GQL = gql`query GetClient ($navisionId: String!, $empresa: String!){ ${ GET_CLIENT } } ${FULL_CLIENT}`;
export const GET_CLIENT_INFO_GQL = gql`query GetClientInfo ($navisionId: String!, $empresa: String!){ ${GET_CLIENT_INFO} }`;
export const GET_CLIENTS_GQL = gql`query FindClients($code: String!, $empresa: String!) { ${GET_CLIENTS} }`;
export const IMPORT_CLIENT_GQL = gql`mutation ImportClient($navisionId: String!, $vendorCode: String!, $empresa: String!) { ${IMPORT_CLIENT} } ${FULL_CLIENT}`;
