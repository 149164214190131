<template>
  <f7-popup class="info-promo-popup" @popup:open="cargarProducto()">
    <f7-page>
      <f7-navbar>
        <f7-nav-title>{{ $t('informacion') }}</f7-nav-title>
        <f7-nav-right>
          <f7-link popup-close=".info-promo-popup">{{$t('cerrar')}}</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <template v-if="state.loading">
        <f7-preloader></f7-preloader>
      </template>
      <template v-else-if="state.producto">
        <f7-block inset>
          <f7-block-title>
            <div class="display-flex flex-direction-row justify-content-space-between align-items-center">
              <div class="display-flex flex-direction-row align-items-center">
                <div class="font-size-14px display-flex flex-direction-column" style="line-height: 1.2;">
                  <div>{{ $t('text.cod') }}: <span class="text-color-blue">{{state.producto.optionSku}}</span></div>
                  <div>{{ $t('text.price') }}: <span class="text-color-purple">{{numFormat(state.producto.precio + state.producto.promoPriceLines)}}€</span></div>
                </div>
                <div class="margin-left-half font-size-12px">{{state.producto.description}}</div>
              </div>
            </div>
          </f7-block-title>
          <f7-block strong inset>
            <div v-if="state.producto.promoProducts.length > 0">
              <div class="display-flex flex-direction-row justify-content-space-between text-color-gray font-weight-bold font-size-12px">
                <div class="display-flex flex-direction-row flex-1">
                  <div style="width: 80px">{{ $t('text.code') }}</div>
                  <div>{{ $t('text.description') }}</div>
                </div>
                <div class="display-flex flex-direction-row">
                  <div style="width: 80px; text-align: right;">{{ $t('text.quantity') }}</div>
                  <div style="width: 80px; text-align: right;">{{ $t('text.price') }}</div>
                </div>
              </div>
              <hr>
              <div v-for="(mat, index) in state.producto.promoProducts" :key="index" class="display-flex flex-direction-row justify-content-space-between">
                <div class="display-flex flex-direction-row flex-1">
                  <div style="width: 80px"><strong class="text-color-blue">{{mat.optionSku}}</strong></div>
                  <div><span class="font-size-12px">{{mat.description}}</span></div>
                </div>
                <div class="display-flex flex-direction-row">
                  <div style="width: 80px; text-align: right;"><strong class="margin-right-half">{{mat.unitsPer}}</strong></div>
                  <div style="width: 80px; text-align: right;"><strong class="text-color-purple">{{numFormat(mat.price)}}€</strong></div>
                </div>
              </div>
            </div>
            <div v-else>
              {{ $t('info.noPromoProducts') }}
            </div>
          </f7-block>
        </f7-block>
      </template>
      <template v-else>
        <f7-block inset>
          No se encuentra la promoción seleccionada.
        </f7-block>
      </template>
    </f7-page>
  </f7-popup>
</template>
<script>
import { reactive } from 'vue';
import { useState } from '../../../../js/stores/hooks';
import { productsService } from '../../../../js/api/graphql/services/products.service';
import { numFormat } from '../../../../js/helpers/filters.helpers';

export default {
  name: 'InfoProductPopup',
  setup: () => {
    const state = reactive({
      loading: false,
      producto: null,
    });
    const { sessionInfo } = useState('session', ['sessionInfo']);
    const { promoProduct } = useState('catalog', ['promoProduct']);
    const cargarProducto = async () => {
      state.loading = true;
      try {
        // console.log(promoProduct.value)
        const { data: { producto }} = await productsService.getProducto({
          optionSku: promoProduct.value ?? '',
          company: sessionInfo.value?.company,
          groupPrice: sessionInfo.value?.groupPrice,
        });
        state.producto = producto;
      } catch (e) {
        // console.log(e);
      }
      state.loading = false;
    };
    return { state, sessionInfo, cargarProducto, numFormat };
  },
};
</script>
