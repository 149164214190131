export const pt = {
  contratoAparatologia: 'CONTRATO APARATOLOGÍA',
  WARNING_CONTRAREEM: 'Não é possível realizar reembolsos superiores a 1000€.',

  alert: 'Alerta',
  abono_contrareembolso_no_permitido: 'No puedes casar un abono con el pedido si la forma de pago no es giro o contado.',
  lunes: 'Segunda-feira',
  martes: 'Terça-feira',
  miercoles: 'Quarta-feira',
  jueves: 'Quinta-feira',
  viernes: 'Sexta-feira',
  sabado: 'Sábado',
  domingo: 'Domingo',
  no_cierra: 'Ñão fecha',

  nuevo_partner_hcc: 'Novo Partner HCC',
  inscribir_como_partner_hcc: 'Inscreber como Partner HCC',

  otro: 'outro',
  telefono: 'telefone',
  atencion_rgpd: 'ATENÇÃO, AINDA FALTA RGPD',
  atencion_rgpd_info: 'Lembre-se que o cliente será bloqueado em 3 meses a partir da data da primeira venda para\n' +
    ' clientes após [data indefinida] e para clientes antigos 3 meses a partir de [data indefinida]',

  descatalogado_hasta_fin: 'Descontinuado enquanto durarem os estoques',

  enviar_rgpd_a_email: 'O consentimento de dados será enviado para o e-mail',
  rgpd_necesita_validar_email: 'Para enviar o GDPR por e-mail, o cliente deve primeiro validar seu e-mail.',
  clientes_sin_validar: 'Clientes não validados',
  rgpd_validado: 'RGPD Validado',
  mail_validado: 'Email Validado',
  formato_factura: 'Formato de fatura',
  sin_confirmar: 'Não confirmado',
  factura_electronica: 'Facto. eletrônicos',
  factura_papel: 'Facto. papel',
  enviar_rgpd: 'Enviar GDPR',
  enviar_rgpd_info: 'Enviar GDPR ao cliente ',
  confirmar_envio_rgpd_SMS: 'Uma mensagem será enviada para validar o GDPR para o telefone',
  rgpd_verificacion_enviado_SMS: 'Se va a enviar un email para validar el RGPD al correo',
  confirmar_envio_rgpd_EMAIL: 'Será enviado um email para validar o RGPD para o email',
  rgpd_verificacion_enviado_EMAIL: 'Um e-mail foi enviado para o correio',
  no_cliente_seleccionado: 'Nenhum cliente selecionado',
  telefonos_disponibles: 'Telefones disponíveis',
  confirmar_envio_email: 'Você vai enviar uma validação de e-mail para o e-mail',
  confirmacion: 'Confirmação',
  productos_similares: 'Produtos Similares',
  cambiar_email: 'Mude o e-mail',

  cif_vacio_oferta: 'Como el cliente no tiene CIF tienes que introducir un comentario obligatoriamente, si te lo sabes ponlo en los comentarios y si no escribe algo',
  revisado_y_sin_info: 'Orden rev. y sin info',
  ps_nuevo: '¡NUEVO!',
  ps_pend_servir_texto_card: 'Haz click aqui para revisar tu pendiente de servir',
  real: 'Real',
  aprox: 'Aprox',
  llegada: 'Llegada',
  hace_x_minutos: 'Hace {x} minutos',
  ver_por_producto: 'Ver por producto',
  ps_info: 'Las fechas de disponibilidad son orientativas. Si necesitáis más información de los productos que no están sirviendose y no tienen fecha de disponibilidad consultar con administración.',
  ps_productos_envasandose: 'Productos envasándose',
  ps_no_productos_envasandose: 'No hay productos envasandose de tu pendiente de servir',
  productos_pendientes_servir: 'Productos pendientes de servir',
  sirviendose: 'Sirviendose',
  enRevision: 'En revisión',
  ps_clientes_envasando: 'Los siguientes clientes tienen producto que se está envasando.',
  disponible: 'Disponible',
  pedidos: 'Pedidos',

  nuevo_email: 'Nuevo email',
  email_actual_es: 'El email actual del cliente es {email} ¿es correcto?:',
  factura_a_email: 'ATENCIÓN: El cliente tiene un correo validado, y su factura electrónica se enviará a {email}:',
  datos_correctos: '¿Son correctos estos datos?',
  cliente_no_encontrado: 'No encontrado',
  cliente_click_cambiar: 'Parece que el cliente que tenías seleccionado ha desaparecido, haz click aqui para cambiar a otro cliente',
  email_validado: 'El email del cliente está validado',
  email_no_validado: 'El email del cliente no está validado, no podrá recibir facturas electrónicas',
  validar_email: 'Validar email',
  leyenda: 'Leyenda',
  oferta_pendiente: 'Oferta pendiente',
  exito: 'Éxito',
  contrasena_cambiada: 'La contraseña se ha cambiado correctamente',
  hace_x_horas: 'Hace {x} horas',
  hace_x_dias: 'Hace {x} dias',
  no_pendiente_servir: 'No hay pendiente de servir',
  codigo_cliente: 'Código cliente',
  nombre_cliente: 'Nombre cliente',
  ascendente: 'Ascendente',
  descendente: 'Descendente',
  fecha: 'Fecha',
  num_expedicion: 'Nº expedición',
  num_documento: 'Nº documento',
  no_expediciones_2_semanas: 'No hay expediciones de menos de 2 semanas',
  no_facturas_anteriores: 'No se han encontrado facturas anteriores',
  no_abonos_anteriores: 'No se han encontrado abonos anteriores',
  num_facturas: 'Nº factura',
  num_vendedor: 'Nº vendedor',
  fecha_factura: 'Fecha factura',
  num_cliente: 'Nº cliente',
  forma_pago: 'Forma pago',
  facturar_a: 'Facturar a',
  fecha_pedido: 'Fecha pedido',
  direccion_envio: 'Dirección envío',
  num_pedido: 'Nº pedido',
  base: 'Base',
  importe_iva: 'Importe IVA',
  total_iva_inc: 'Total IVA incluido',
  importe_base: 'Importe base',
  porc_iva: '% IVA',
  porc_re: '% RE',
  importe_re: 'Importe RE',
  dto_factura_pagos: 'Dto. Factura y pagos',
  no_facturas_mes: 'No se han encontrado últimas facturas de este mes',
  no_facturas_pendientes: 'No hay facturas pendientes de ningún cliente',
  seleccionar_mes: 'Seleccionar mes',
  seleccionar_ano: 'Seleccionar año',
  pago: 'Pago',
  factura: 'Factura',
  impago: 'Impago',
  total_pendiente: 'Total pendiente',
  cobros_pendientes: 'Cobros pendientes',
  sin_explicar_comentario: 'Sin explicar, por favor manda un comentario',
  importe_pendiente: 'Importe pendiente',
  saldo_acumulado: 'Saldo acumulado',
  documentos_pendientes: 'Docs. pendientes',
  tipo_doc: 'Tipo doc',
  importe_original: 'Importe original',
  num_contrato: 'Nº contrato',
  tipo_contrato: 'Tipo contrato',
  importe_contrareembolso: 'Importe contrareembolso',
  anadir_comentario: 'Añadir comentario',
  cerrar: 'Cerrar',
  introduce_comentario: 'Introduce un comentario',
  aun_no_comentarios: 'Aún no hay comentarios',
  client_no_cuenta: 'El cliente no tiene ningún nº de cuenta',
  actualizar_email: 'Actualizar email',
  atencion_factura_electronica: 'ATENCIÓN: A partir de ahora se enviará una factura electrónica, tienes que comprobar que el email del cliente es correcto, y si no, enviar el nuevo email',
  cliente_no_email_asociado: 'El cliente no tiene ningún email asociado ¿Es correcto?',
  email_cliente_incorrecto: 'El email del cliente NO ES CORRECTO',
  email_cliente_correcto: 'El email del cliente es correcto',
  introduce_nuevo_email: 'Introduce el nuevo email',
  email_no_valido: 'El email no es un email válido',
  email_verificacion_enviado: 'Se ha mandado un mensaje de verificación al correo proporcionado',
  cancelar: 'Cancelar',
  no_puedes_girar: 'No puedes girarle a este cliente porque no tiene una cuenta bancaria asociada. Por favor, contacta con administración para añadirle una cuenta bancaria y vuélvelo a intentar. Recuerda que la aplicación se actualiza por las noches',
  informacion: 'Información',
  eliminar_promocion: 'Eliminar promoción',
  que_quieres_hacer: '¿Qué quieres hacer?',
  eliminar_todo: 'Eliminar todo',
  eliminar_solo_cabecera: 'Eliminar solo cabecera',
  eliminar_cabecera_poner_precios: 'Eliminar cabecera y poner precios base',
  buscar_direccion: 'Buscar dirección',
  volver_hacer_pedido: 'Volver a hacer pedido',
  no_giros_mostrar: 'No hay giros que mostrar',
  son_correctos_datos: '¿Son correctos estos datos?',
  puedes_cambiar_mes_ano: 'Puedes cambiar mes y año',
  obtener_facturas: 'Obtener facturas',
  mes: 'Mes',
  ano: 'Año',
  desde_el: 'Desde el',
  hasta_el: 'Hasta el',
  total_ventas: 'Total ventas',
  total_impagos: 'Total impagos',
  total_cobros_impagos: 'Total cobros impagos',
  buscar_en_documentos: 'Buscar en los documentos',
  ventas_abonos_anulaciones: 'Ventas-Abonos-Anulaciones',
  impagos: 'Impagos',
  cobros_impagos: 'Cobros impagos',

  ACT_CANTIDAD: 'Actualizar cantidad',
  ACT_PRECIO_UNIDAD: 'Actualizar precio unidad',
  ACT_DESC: 'Actualizar descuento',
  ACT_GRATIS: 'Actualizar gratis',
  LINEAS_BONIFICADAS: 'Líneas bonificadas',
  CERRAR: 'Cerrar',
  GUARDAR: 'Guardar',

  newClient: {
    cifError: 'Tienes que rellenar el campo NIF/CIF',
    nameError: 'Tienes que rellenar el campo Nombre',
    addressError: 'Tienes que rellenar el campo Dirección',
    stateError: 'Tienes que rellenar el campo Población',
    cityError: 'Tienes que rellenar el campo Provincia',
    cpError: 'Tienes que rellenar el campo Código Postal',
    contactError: 'Tienes que rellenar el campo Pers. Contacto',
    closeDaysError: 'Tienes que rellenar el campo Días cierre',
    emailError: 'Tienes que rellenar el campo Email',
    phoneError: 'Tienes que rellenar el campo Teléfono o el campo Móvil',
    infoFill: 'Vas a pasar los datos un pedido para un Cliente Nuevo, ahora tienes que rellenar los datos.' +
      'Pulsa en el siguiente botón para rellenarlos.',
    fillData: 'Rellenar datos cliente',
  },

  errores: 'Fautes',
  aceptar: 'Aceptar',
  enviar: 'Enviar',
  actualizarPagina: 'Actualizar Página',
  topClientes: 'Top Clientes',
  actualizacion: 'Actualizacion',
  vamosAActualizar: 'Vamos a actualizar',
  relevancia: 'Relevancia',
  masVisitados: 'Más visitados',
  novedades: 'Novedades',
  TAHE_FRANCIA: 'Tahe EU',
  TAHE_ALEMANIA: 'Tahe EU',
  informacionYMetodos: 'Información y métodos de pago',
  resumenPedido: 'Resumen del pedido',
  cliente: 'Cliente',
  fechaDeOferta: 'Fecha de la oferta',
  empresaYCanal: 'Empresa y canal de venta',
  direccionEnvio: 'Dirección de envío',

  'Relevancia': 'Relevancia',
  'Más visitados': 'Más visitados',
  'Novedades': 'Novedades',
  reporteDescendente: 'Rapporto (Discendente)',
  reporteAscendente: 'Rapporto (Crescente)',
  fechaDescendente: 'Data (Discendente)',
  fechaAscendente: 'Data (Crescente)',
  codigoClienteDescendente: 'Codice Cliente (Discendente)',
  codigoClienteAscendente: 'Codice Cliente (Crescente)',
  importeAscendente: 'Importo (Crescente)',
  importeDescendente: 'Importo (Discendente)',
  noDocumentoAscendente: 'N. documento (Crescente)',
  noDocumentoDescendente: 'N. documento (Discendente)',
  totalEquipos: 'Totale per squadre',
  estadoPedidos: 'Lo stato dell`ordine',
  total: 'Totale',
  pendientes: 'In attesa di',
  porAprobar: 'Essere approvato',
  pendientesAprobar: 'In attesa di approvazione',
  enviadas: 'Inviato',
  impTotal: 'Totale',
  impPendiente: 'Imp. in sospeso',
  impAprob: 'Imp. da approvare',
  impEnv: 'Imp. Inviato',
  equipo: 'Squadra',
  jefeEquipo: 'Guida del gruppo',
  distribuidor: 'Distributore',
  ofertaPendiente: 'Oferta pendiente',
  pendienteAprobar: 'Pendiente de aprobar',
  pendienteAprobarEquipos: 'Pendiente de aprobar en los equipos',
  editarCliente: 'Editar cliente',
  pages: {
    updatePage: 'Actualizar página',
    home: 'Menu Principal',
    dashboard: 'Painel de Controle',
    distributors: 'Distribuidores',
    catalog: 'Catálogo',
    reports: 'Relatórios diários',
    statistics: 'Estatísticas',
    addPreClient: 'Adicionar pré-cliente',
    addDistributorClient: 'Adicionar cliente',
    approvalOrders: 'Aprovar pedidos',
    approvalRefunds: 'Aprovar devoluções',
    approvalCharges: 'Aprovar cobranças',
    pendingOrder: 'Pedido',
    previousOrders: 'Pedidos anteriores',
    pendingCharge: 'Cobrança',
    previousCharges: 'Cobranças anteriores',
    pendingRefund: 'Devolução',
    previousRefunds: 'Devoluções anteriores',
    clientManagement: 'Gestão de cliente',
    vendorManagement: 'Informação do vendedor',
    extracts: 'Movimentos do cliente',
    bills: 'Faturas',
    billsRefunds: 'Créditos faturados',
    billsInvoices: 'Faturas',
    soldProducts: 'Produtos vendidos',
    expeditions: 'Expedições',
    expenses: 'Gastos',
    incomes: 'Pagamentos',
    pendingToShip: 'Pendente de servir',
    clientsContracts: 'Contratos',
    refundsStatus: 'Estado devoluções',
    commissions: 'Comissões',
    settings: 'Definições',
    changelog: 'Novidades',
    faqStores: 'Ajuda loja cabeleireiros',
    faq: 'Ajuda',
    notFound: 'Não encontrado',
    vendorSelection: 'Seleção de Vendedor',
    clientSelection: 'Seleção de Cliente',
    sellChannelSelection: 'Canal de Venda',
    clientPendingOrder: 'Pedido',
    clientPreviousOrders: 'Pedidos anteriores',
    clientPendingCharge: 'Cobrança',
    clientPreviousCharges: 'Cobranças anteriores',
    clientPendingRefund: 'Devolução ',
    clientPreviousRefunds: 'Devoluções anteriores',
    clientPendingToShip: 'Pendente de Servir',
    clientExpeditions: 'Expedições',
    clientGiros: 'Débitos Diretos',
    clientPendingBills: 'Faturas',
    clientObjectives: 'Objetivos',
    clientContracts: 'Contratos',
    clientExtracts: 'Movimentos',
    clientBills: 'Faturas',
    clientBillsRefunds: 'Créditos faturados',
    productDetails: 'Produto',
    notFoundClient: 'Cliente não encontrado',
    refundStatusDetails: 'Estado da Devolução',
    searchRefundProduct: 'Procurar produto a devolver',
    orderDetails: 'Detalhes do pedido',
    productBills: 'Faturas do produto',
    checkoutCharge: 'Enviar Cobrança',
    newClientDetails: 'Detalhes do novo cliente',
    newIncome: 'Novo Pagamento',
    newExpense: 'Nova Despesa',
    checkoutOrder: 'Enviar pedido',
    newDistributorClients: 'Novo cliente',
    employees: 'Empregados',
    users: 'Usuários',
  },
  menus: {
    menu: 'Menu',
    mainMenu: 'Menu Principal',
    favoritesMenu: 'Favoritos',
    clientManagementMenu: 'Gestão de cliente',
  },
  buttons: {
    add: 'Adicionar',
    changePassword: 'Mudar senha',
    copyToOtherClient: 'Copiar para outro cliente',
    moveToOtherClient: 'Mover para outro cliente',
    duplicate: 'Duplicar',
    copy: 'Copiar',
    checkInOcean: 'Ticar en Ocean',
    addBillManual: 'Adicionar fatura manualmente',
    addRemaining: 'Adicionar restante',
    addChargeDate: 'Adicionar data de cobrança',
    save: 'Guardar',
    ok: 'OK',
    update: 'Atualizar',
    search: 'Procurar',
    close: 'Fechar',
    logout: 'Sair',
    searchClient: 'Procurar Cliente',
    searchProductInBills: 'Procurar na Fatura',
    back: 'Atrás',
    cancel: 'Cancelar',
    login: 'Aceder',
    clean: 'Apagar',
    confirm: 'Confirmar',
    findClient: 'Encontrar cliente',
    updateEmail: 'Atualizar Email',
    addToOrder: 'Adicionar ao pedido',
    delete: 'Apagar',
    addIncome: 'Adicionar Pagamento',
    addExpense: 'Adicionar Despesas',
    checkoutOrder: 'Enviar Pedido',
    selectAll: 'Selecionar Tudo',
    activateSort: 'Ativar ordenação',
    deactivateSort: 'Desativar ordenação',
    addCharge: 'Adicionar Cobrança',
    createAdvice: 'Gerar Pagto Antecipado',
    addPendingBill: 'Adicionar Cobrança',
    accept: 'Aceitar',
    done: 'Feito',
    getObjective: 'Obter Objetivo',
    getSoldProducts: 'Obter produtos vendidos',
    updateWebProduct: 'Atualizar Produto',
    updateWebCategory: 'Atualizar Categoria',
    addProduct: 'Adicionar produto',
    searchInBills: 'Procurar na fatura',
    sendRefund: 'Enviar devolução',
    showAccount: 'Ver Nº Conta',
    addObjective: 'Adicionar objetivo',
    addPreclient: 'Adicionar Pré-cliente',
    checkoutCharge: 'Enviar Cobrança',
    deleteSelected: 'Apagar selecionados',
    send: 'Enviar',
    refund: 'Devolver',
    refundAll: 'Devolver tudo',
    reshopAll: 'Voltar a comprar tudo',
    reshop: 'Voltar a comprar',
    newUser: 'Novo usuário',
  },
  popups: {
    globalSearch: 'Procurar',
  },
  inputs: {
    password: { label: 'Senha', placeholder: 'A tua senha atual' },
    newPassword: { label: 'Nova senha', placeholder: 'A nova senha' },
    newPasswordConfirmation: { label: 'Repete a nova senha', placeholder: 'Repete a nova senha' },
    searchClient          : { label: 'Cliente'                , placeholder: 'Procurar cliente pelo nome, NIF do cliente' },
    searchProductInBills  : { label: 'Produto'               , placeholder: 'Procurar um produto pelo código ou nome' },
    searchMainMenu        : { label: 'Procurar'                 , placeholder: 'O que queres fazer?' },
    searchProduct         : { label: 'Procurar Produto'        , placeholder: 'Procura um produto por código, nome...' },
    searchFaq             : { label: 'Procurar em Ajuda'        , placeholder: 'Procurar em ajuda' },
    searchOption          : { label: 'Procurar Opção'          , placeholder: 'Filtra opções pelo nome ou código' },
    searchVendorCode      : { label: 'Procurar Código Vendedor' , placeholder: 'Procurar vendedor por código, nome...' },
    searchReports         : { label: 'Procurar relatório'         , placeholder: 'Procurar um relatório pelo nome ou código de cliente...'},
    motive                : { label: 'Motivo'                 , placeholder: 'Introduz uma pequena descrição' },
    advice                : { label: 'Quantidade'               , placeholder: 'Introduz o valor do adiantamento' },
    comment               : { label: 'Comentário'             , placeholder: 'Escreve aqui o teu comentário caso necessites de passar uma informação adicional' },
  },
  login: {
    title: 'Cosmet',
    username: { label: 'Usuário', placeholder: 'O teu usuário' },
    password: { label: 'Senha', placeholder: 'A tua senha' },
    information: {
      line1: `Acede com o teu usuário e senha para entrar no Cosmet<br><strong>(Antigo OferTAHE e OferTH)</strong>`,
      line2: `Cosmet &copy; {year} {version}`,
    }
  },
  info: {
    selectAddressFill: 'Selecciona una dirección de envío',
    cardProduct: 'Os produtos do catálogo serão exibidos em formato de cartões',
    listProduct: 'Os produtos do catálogo serão exibidos em formato de listagem',
    contactWithRRHH: 'Se tiveres alguma dúvida ou algum problema, por favor contactar os RRHH e NÃO com a administração da aplicação',
    noBillsFound: 'Não foram encontradas faturas',
    searchRefundProducts: 'Primeiro, procura o produto que queres devolver',
    addChargeDates: 'Introduz o valor a cobrar e a data desejada e  seleciona adicionar cobrança',
    selectPaymentFormFill: 'Deves escolher uma forma de pagamento',
    selectPaymentTermFill: 'Deves escolher um prazo de pagamento',
    noFavoritesMenu: 'Ainda não adicionaste nenhum link aos favoritos. Tenta clicar numa das estrelas ' +
      '<icon class="icon f7-icons" style="font-size: 20px">star</icon> dos ícones do Menu.',
    searchResults: 'Resultados da procura',
    isSelectedCode: 'Está selecionado este código e este canal de venda',
    newClientUse: 'Utiliza este cliente para introduzir um pedido para cliente novo ou um pré-cliente',
    noClientsFound: 'Ups! Não foram encontrados clientes com esta pesquisa',
    noCodesFound: 'Não foram encontrados códigos com a tua pesquisa',
    clientConfirm: 'Tens a certeza que queres mudar para este cliente?',
    clientConfirmNew: 'Queres trocar para CLIENTE NOVO (Código: 1)?',
    notFoundClient: 'Se o cliente foi adicionado hoje, é normal que não o encontres! Calma! Se sabes o código do cliente podes procura-lo tu mesmo a partir daqui' +
      'embora toda a informação como os comentários de contrato, direções adicionais, etc,... aparecerá no dia seguinte. Ainda assim, caso necessites fazer um pedido, devolução' +
      'ou cobrança tenta adicionar agora e assim não aguardar pelo dia seguinte',
    noPromoProducts: 'Não tem uma lista de materiais fixa',
    orderLines: 'Linhas do Pedido',
    sampleLines: 'Amostras do Pedido',
    incidentLines: 'Produtos com incidência do Pedido',
    noOrderLines: 'Ainda não adicionaste linhas ao pedido',
    noRefundLines: 'Ainda não adicionaste linhas à devolução',
    noSampleLines: 'Ainda não adicionaste amostras ao pedido',
    noIncidentLines: 'Ainda não há nenhuma linha de incidência no pedido',
    chargeFor: 'Cobrança para',
    noPendingCharge: 'Ainda não adicionaste nenhuma linha à cobrança. Clica no ícone para ver as cobranças pendentes do cliente.',
    refundFor: 'Devolução para',
    noPendingRefund: 'Ainda não adicionaste nenhuma linha à devolução. Clica no ícone para procurar o produto.',
    noPendingBills: 'O cliente não tem faturas',
    noPreviousOrders: 'Não foram encontrados pedidos anteriores',
    noPendingOrders: 'Não foram encontrados pedidos',
    noPreviousRefunds: 'Não foram encontradas devoluções anteriores',
    noPendingRefunds: 'Não foram encontradas devoluções',
    noPreviousCharges: 'Não foram encontradas cobranças anteriores',
    noPendingCharges: 'Não foram encontradas cobranças',
    selectVendorCode: 'Seleciona um código de vendedor',
    productsSoldInfo: 'O cálculo dos produtos vendidos é feito retirando os produtos faturados e subtraindo os produtos devolvidos',
    noSoldProductsFound:  'Não foram encontrados produtos vendidos para este cliente ou nestas datas',
    noApprovalOrders: 'Não há pedidos pendentes de aprovar',
    noApprovalRefunds: 'Não há devoluções pendentes de aprovar',
    noApprovalCharges: 'Não há cobranças pendentes de aprovar',
    noExpeditions: 'Não há expedições de à 2 semanas',
    refundLineManual: 'Este produto está associado manualmente à fatura, deverás indicar porquê',
    refundLineQuantityExceeded: 'Foi introduzido uma quantidade superior à da fatura, deverás indicar o porquê',
    refundLineNoAbonable: 'O produto excedeu o tempo permitido para devolução, deverás indicar porquê',
    isRefundLinesFilled: 'Preencheste todos os motivos de devolução de CADA produto?',
    noRefundForNewClient: 'Não selecionaste nenhum cliente existente, seleciona o cliente para enviar a devolução',
    noChargeForNewClient: 'Não selecionaste nenhum cliente existente, seleciona o cliente para enviar uma cobrança',
    noAccountFound: 'Não foi encontrado o numero de conta do cliente',
    noComments: 'O cliente não tem comentários',
    noPendingToShip: 'Não existem pendentes de servir',
    noGiros: 'Não existem débitos para mostrar',
    noReports: 'Não foram encontrados relatórios',
    fillFieldsAndSave: 'Preencher os campos da parte inferior e guardar',
    passwordSuccessfullChanged: 'A senha foi alterada com sucesso',
    revisitTour: 'Desmarca esta opção se quiseres voltar a ver o índice.',
    hasKeyboard: 'Se não trabalhas com o teclado desmarca esta opção para teres uma calculadora táctil.',
    noIncomes: 'Não foram encontrados pagamentos anteriores, clica no botão superior para adicionar um novo pagamento',
    noExpenses: 'Não foram encontradas despesas anteriores, clica no botão superior para adicionar uma nova despesa',
    noPreviousBills: 'Não foram encontradas faturas anteriores',
    noProductsFound: `Não foram encontrados produtos com a pesquisa <em>{0}</em>`,
  },
  text: {
    underConstruction: 'Em construção',
    suggestions: 'Sugestões',
    lastUpdate: 'Última versão',
    added: 'adicionado',
    addressSelection: 'Selecionar direção',
    send: 'Enviar',
    type: 'Tipo',
    importWithImp: 'Valor (valor. incl.)',
    changePassword: 'Mudar senha',
    accesibility: 'Acessibilidade',
    hasKeyboard: 'Trabalho com teclado',
    tour: 'Tour Realizado',
    languages: 'Idiomas',
    catalog: 'Catálogo',
    card: 'Cartão',
    list: 'Lista',
    bonifyItems: 'Art. bonificados',
    promoCount: 'Nº Promoções',
    editSelected: 'Editar seleção',
    copySelected: 'Copiar seleção',
    duplicateSelected: 'Duplicar seleção',
    deleteSelected: 'Eliminar seleção',
    seeBills: 'Ver Faturas',
    dates: 'Datas',
    remainingIVAInc: 'Restante (IVA incl)',
    companyAndSellChannel: 'Empresa e Canal de Venta',
    numCheck: 'Nº Cheque',
    paymentDates: 'Datas de cobrança',
    addChargeDates: 'Adicionar data de cobrança',
    paymentFormSelection: 'Forma de Pagamento',
    paymentTermSelection: 'Prazo de Pagamento',
    orderDate: 'Data do Pedido',
    abonable: 'Devolução possível',
    sellChannel: 'Canal de Venda',
    news: 'Novidades',
    info: 'Informação',
    infoShort: 'Info',
    importantInfo: 'Informação importante',
    new: 'Novo',
    soldOut: 'Esgotado',
    products: 'Produtos',
    navigation: 'Navegação',
    favorites: 'Favoritos',
    lines: 'Linhas',
    categories: 'Categorias',
    first: 'Primeira',
    last: 'Última',
    seeOptions: 'Ver Opções',
    price: 'Preço',
    of: 'de',
    user: 'Usuário',
    codes: 'Códigos',
    newClient: 'NOVO CLIENTE',
    cif: 'NIF',
    confirm: 'Confirmar',
    notFoundClient: 'Não encontrado',
    comments: 'Comentários',
    cod: 'Cod',
    orderBy: 'Ordenar por',
    code: 'Código',
    description: 'Descrição',
    quantity: 'Quantidade',
    unitsPer: 'unidades por',
    total: 'Total',
    totalQuantities: 'Unidades',
    discount: 'Desconto',
    actions: 'Ações',
    addWithDiscount: 'Adicionar com desconto',
    productItems: 'Artigos',
    import: 'Valor',
    orders: 'Pedidos',
    refunds: 'Devoluções',
    charges: 'Cobranças',
    expenses: 'Gastos',
    newClients: 'Clientes novos',
    income: 'Pagamentos',
    yes: 'Sim',
    no: 'Não',
    numb: 'Nº',
    date: 'Data',
    reportId: 'Nº de relatório',
    addProduct: 'Adicionar Produto',
    addByCode: 'Adicionar por código',
    searchProduct: 'Pesquisar Produto',
    order: 'Pedido',
    samples: 'Amostras',
    incidents: 'Incidências',
    ivaInc: '+ (IVA inc.)',
    freeItems: 'Art. Bonificados',
    numPromos: 'Nº Promoções',
    selec: 'Selecionar',
    impSelect: 'Selecionar valor',
    pendingBills: 'Faturas Pendentes',
    company: 'Empresa',
    postingDate: 'Data Registo',
    dueDate: 'Data Vencimento',
    documentNo: 'Nº Documento',
    paymentForm: 'Forma de Pagamento',
    paymentMethod: 'Método de Pagamento',
    paymentTerm: 'Prazo de Pago',
    amount: 'Valor',
    remainingAmount: 'Valor Pendente',
    totalContrareembolso: 'Contrarreembolso',
    unfinished: 'Sem terminar',
    paymentDate: 'Data de Pagamento',
    status: 'Estado',
    statuses: 'Estados',
    client: 'Cliente',
    clients: 'Clientes',
    from: 'Desde',
    to: 'Até',
    fromDate: 'Da data',
    toDate: 'Até à data',
    item: 'artigo',
    items: 'artigos',
    update: 'Atualizar',
    statistics: 'Estatísticas',
    pendingOrders: 'Pedidos',
    previousOrders: 'Pedidos Anteriores',
    pendingRefunds: 'Devoluções',
    previousRefunds: 'Devoluções Anteriores',
    pendingCharges: 'Cobranças',
    previousCharges: 'Cobranças Anteriores',
    vendorCodeSelection: 'Cód. Vendedor',
    selectForStatistics: 'Podes mudar o mês e o ano',
    month: 'Mês',
    year: 'Ano',
    objective: 'Objetivo',
    billed: 'Faturado',
    ideal: 'Ideal ao dia',
    cumplimentObj: 'de objetivo cumprido',
    numPacks: 'Volumes',
    appliesToDoc: 'Liquidado por.',
    dateRange: 'Intervalo de datas',
    selectDate: 'Seleciona a data',
    pendingItems: 'Unidades pendentes',
    units: 'Unidades',
    reviser: 'Revisor',
    quant: 'Quant.',
    unitPrice: 'Preço',
    totalImport: 'Total',
    totalPromo: 'Total Promo',
    totalIVA: '+ (IVA inc.)',
    free: 'Grátis',
    sample: 'Amostra',
    incident: 'Incidência',
    bill: 'Fatura',
    bills: 'Faturas',
    motive: 'Motivo',
    information: 'Informação',
    select: 'Selecionado',
    comment: 'Comentário',
    account: 'Nº Conta',
    warnings: 'Avisos',
    disc: 'Desc. %',
    discImp: 'Valor. Desc.',
    promo: 'Promo',
    lastBills: 'Últimas Faturas',
    lateBills: 'Saldos Atrasados',
    report: 'Relatório',
    vendor: 'Vendedor',
    share: 'Compartilhar',
    shareTelegram: 'Telegrama',
    shareWhatsapp: 'WhatsApp',
    shareEmail: 'Enviar por email',
    download: 'Descarregar',
    searchResult: 'Resultados para',
    totalPending: 'Total Pendente',
    createAdvice: 'Criar pagto antecipado',
    advance: 'Pagamento antecipado',
    moveNo: 'Movimento',
    toLiquid: 'A liquidar',
    pendingImport: 'Valor Pendente',
    selectedImport: 'Valor Selecionado',
  },
  fnText: {
    showResults: 'Mostrando ({0} - {1}) de {2} resultados',
    total: 'Total {0}€',
    totalCash: 'Numerário {0}€',
  },
  filters: {
    lines: 'Linhas',
  },
  sellChannel: {
    TAHE_ESPANA:        'Tahe Espanha',
    TAHE_DISTRIBUTOR:   'Tahe Distribuidor',
    TAHE_DISTRIBUIDOR:   'Tahe Distribuidor',
    TAHE_CANARIAS:      'Tahe Canarias',
    TAHE_PORTUGAL:      'Tahe Portugal',
    TAHE_ITALIA:        'Tahe Itália',
    TAHE_INTERNACIONAL: 'Tahe Internacional',
    TAHE_OUTLET:        'Tahe Outlet',
    TAHE_FRANCIA:        'Tahe EU',
    TAHE_ALEMANIA:        'Tahe EU',
    TH_ESPANA:          'Th Pharma Espanha',
    TH_PORTUGAL:        'Th Pharma Portugal',
    TH_ITALIA:          'Th Pharma Itália',
    TH_INTERNATIONAL:   'Th Pharma Internacional',
    TH_INTERNACIONAL:   'Th Pharma Internacional',
    TH_OUTLET:          'Th Pharma Outlet',
    MER_ESPANA:         'Mercacosmética Espanha',
    DVF_ESPANA:         'DV Farmaceutic Espanha',
    KELAYA_ESPANA:      'KELAYA España',
    KELAYA_CANARIAS:    'KELAYA Canarias',
    KELAYA_PORTUGAL:    'KELAYA Portugal',
    KELAYA_FRANCIA:    'KELAYA EU',
    KELAYA_ALEMANIA:    'KELAYA EU',
    BOCARE_ESPANA:      'BOCARE España',
    BOCARE_CANARIAS:    'BOCARE Canarias',
    BOCARE_PORTUGAL:    'BOCARE Portugal',
    BOCARE_FRANCIA:    'BOCARE EU',
    BOCARE_ALEMANIA:    'BOCARE EU',
    TH_FRANCIA: 'Th Pharma EU',
    TH_ALEMANIA: 'Th Pharma EU',
  },
  status: {
    PENDING:              'Pendente',
    PENDING_TO_SEND:      'Pend. Env.',
    PENDING_APPROVAL:     'Pend. Aprov.',
    PENDING_APPROVAL_DIST: 'Pend. Aprov. Dis.',
    SENDED:               'Enviado',
    IMPORT_ERROR:         'Erro de Importação',
    RECEIVED:             'Recebido',
    ACCEPTED:             'Aceite',
    CANCELED:             'Cancelado',
    UNKNOWN:              'Desconhecido',
    OMNI_RECEIVED:        'Enviado',
    ERROR_IMPORTACION:    'Pend. Rec.',
  },
  company: {
    TAHE: 'Tahe',
    TH_PHARMA: 'Th Pharma',
    TAHE_2016: 'Tahe',
    TH_PHARMA_2016: 'Th Pharma',
    MERCACOSMETICA: 'Mercacosmética',
    DV_FARMACEUTIC: 'DV Farmaceutic',
    'NEO-DEPIL LASER': 'Neo Depil Láser',
    'MAGAÑA': 'Tahe Produtos Cosméticos S.L.',
    'Tahe Produtos Cosméticos S_L_': 'Tahe Produtos Cosméticos S.L.',
    'TH Pharma S_L_': 'Th Pharma',
  },
  packing: {
    BOX: 'caixa',
    PACK: 'palete',
  },
  paymentForm: {
    CONTADO: 'Pagamento prévio',
    GIRO: 'Débito bancário',
    CONTRAREEM: 'Contrarreembolso',
    GIROPT: 'Débito bancário',
    CONTRARPOR: 'Contrarreembolso',
    CHEQUEPT: 'Cheque',
    TARJETAPT: 'Cartão Crédito Clientes',
    CONTADOPT: 'Contrato Cheque',
    'TARJ OUTLE': 'Cartão Outlet',
    TRANSFPORT: 'Transferência',
    TRANSFERIT: 'Transferência',
    EFECTIVO: 'Numerário',
    TARJETA: 'Cartão de Crédito',
    GIROCOBRO: 'Débito Bancário',
    CHEQUE: 'Cheque',
    DESCUENTO: 'Desconto',
  },
  paymentTerm: {
    PERSONALIZADO: 'Personalizado',
    '1X7': 'Débito Bancário 7 dias',
    '1X15': 'Débito Bancário 15 dias',
    '1X20': 'Débito Bancário 20 dias',
    '1X21': 'Débito Bancário 21 dias',
    '1X30': 'Débito Bancário 30 dias',
    '1X40': 'Débito Bancário 40 dias',
    '1X45': 'Débito Bancário 45 dias',
    '1X60': 'Débito Bancário 60 dias',
    '1X75': 'Débito Bancário 75 dias',
    '1X90': 'Débito Bancário 90 dias',
    '2X7': 'Débito Bancário 7 e 30 dias',
    '2X15': 'Débito Bancário 15 e 30 dias',
    '2X20': 'Débito Bancário 20 e 40 dias',
    '2X30': 'Débito Bancário 30 e 60 dias',
    '2X45': 'Débito Bancário 45 e 90 dias',
    '2X45B': 'Débito Bancário 45 e 60 dias',
    '2X60': 'Débito Bancário 60 e 90 dias',
    '2X60X120': 'Débito Bancário 60 e 120 dias',
    '2X90': 'Débito Bancário 90 e 120 dias',
    '3X10': 'Débito Bancário 10, 20 e 30 dias',
    '3X15': 'Débito Bancário 15, 30 e 45 dias',
    '3X3': 'Débito Bancário 45, 75 e 105 dias',
    '3X30': 'Débito Bancário 30, 60 e 90 dias',
    '3X60': 'Débito Bancário 60, 90 e 120 dias',
    '4X30': 'Débito Bancário 30, 60, 90 e 120 dias',
    '4X3': 'Débito Bancário 15, 60 e 90 dias',
    '4X4': 'Débito Bancário 15, 30, 60 e 90 dias',
    '1X120': 'Débito Bancário 120 dias',
  },
  desconTime: {
    0: 'Não olhes para o ecrã, aproveita o teu momento! Relaxa!',
    1: 'Sol escondido, descanso merecido.',
    2: 'Desligar de tudo e descansar é necessário para levar uma vida saudável.',
    3: 'Momento de: Clientes ... Deslocamentos ... Pedidos ... ¡Desligar! <icon class="icon f7-icons text-color-green" style="font-size: 16px">checkmark_alt</icon>',
    4: 'Agora é o momento para estar com a tua família: aproveita e disfruta!',
    5: 'Agora começa o teu momento familiar!! Aproveita!!',
    6: 'Para conseguir um rendimento de 100% no teu dia-a-dia profissional, necessitas desligar a 100%',
    7: 'Para ser produtivo temos que descansar!! Feliz dia de descanso!!',
    8: 'Depois do trabalho, disfrutar da família, de uma vida saudável e uma boa leitura, irá ajudar a sentires-te melhor.',
    9: 'Alto! Este tempo é para ti, aproveita-o!',
  },
  client: {
    code: 'Relatório',
      mainAddress: 'Direção Principal',
      navisionId: 'Código',
      cif: 'CIF',
      socialReason: 'Razão Social',
      name: 'Nome',
      name2: 'Nome 2',
      closeDays: 'Dias de Fecho',
      email: 'Email',
      phone: 'Telefone',
      mobile: 'Telemóvel',
      fax: 'Fax',
      address: 'Direção',
      address2: 'Direção 2',
      city: 'Cidade',
      state: 'Distrito',
      postalCode: 'Código Postal',
      country: 'País',
      clientComments: 'Comentários Cliente',
      contractComments: 'Comentários Contrato',
      info: 'Informação de Cliente',
      addresses: 'Direções',
      contact: 'Contacto',
      typeClient: 'Tipo de Cliente',
      newClient: 'Novo Cliente',
      surnameName: 'Apelidos, Nome',
      bankAccount: 'Conta Banc.',
  },
  typeClient: {
    'Cabeleireiro': 'Cabeleireiro',
      'Estética': 'Estética',
      'Estética+Cabeleireiro': 'Estética+Cabeleireiro',
      'Barbearia': 'Barbearia',
      'Outros': 'Outros',
  },
  country: {
    ES: 'Espanha',
    PT: 'Portugal',
    IT: 'Itália',
    FR: 'Francia',
    BE: 'Bélgica',
    DE: 'Alemanha',
  },
  errors: {
    networkError: 'Não foi possível estabelecer ligação com o servidor. Por favor contactar o administrador da aplicação',
      notVendorInTeam: 'Este código de vendedor não está nas tuas equipas',
      dateExistError: 'Já adicionaste a data {0} às datas de vencimento',
      navisionError: 'Ocorreu um erro ao ligar o Navision, é possível que não haja ligação, tenta de novo, mais tarde',
  },
  serverErrors: {
    NAV_INACCESSIBLE: 'Cosmet não pode aceder ao servidor do Navision',
      NAV_UNKNOWN_ERROR: 'Ocorreu um erro desconhecido ao tentar ligar com o Navision',
      SERVER_NOT_RESPONDING: 'O servidor está muito ocupado neste momento. Tentar de novo, mais tarde',
      CHARGE_ALREADY_PROCESSED: 'Esta linha já foi processada',
      AUTH_USER_BLOCKED: 'O usuário está bloqueado, contacta com o teu superior para resolver o problema',
      AUTH_PASSWORD_INCORRECT: 'A senha está incorreta',
      LINE_ALREADY_ADDED: 'Esta linha já foi enviada ou já foi adicionada à cobrança pendente',
      'Internal server error': 'O servidor está com um problema, por favor contacta o administrador da aplicação ou tenta mais tarde',
  },
  statusCode: {
    999: 'Erro de conexão',
      200: 'Êxito',
      201: 'Êxito',
      404: 'Não Encontrado',
      401: 'Erro de autenticação',
      422: 'Erro no servidor',
      499: 'Já existe',
      403: 'Já existe',
      500: 'Erro de ligação',
      503: 'Manutenção',
  },
  success: {
    chargeLineAdded: 'Linha a cobrar adicionada corretamente',
      categoryAdded: 'Categoria adicionada corretamente',
      productAdded: 'Produto adicionado corretamente',
  },
  prompt: {
    updateWebProduct: {
      text: 'Introduz o ID do Produto em OpenCart',
        title: 'Produto Web',
    },
    updateWebCategory: {
      text: 'Introduz o ID da Categoria em OpenCart',
        title: 'Categoria Web',
    },
    deleteCharges: {
      text: 'Tens a certeza que desejas eliminar as cobranças selecionadas?',
        title: 'Confirmar',
    }
  },
  warning: {
    mustAddObjective: 'Lembra-te que tens que adicionar neste cliente, o objetivo do próximo ano',
      emailMoreThanAWeek: 'Email com mais de 7 dias sem estar confirmado',
      waitingForConfirm: 'Esperando confirmação do email',
      notValidatedEmail: 'O cliente tem um email, mas ainda não está confirmado. Por favor enviar um email de confirmação',
      notEmail: 'O cliente não tem email. Para podermos faturar tens que adicionar que um email e que o cliente o valide, Vamos a isso!',
  },
  languages: {
    es: 'Espanhol',
      it: 'Italiano',
      pt: 'Português',
  },
  ES: 'Espanha',
    PT: 'Portugal',
    'MAGAÑA': 'Tahe',
    'TH PHARMA': 'Th Pharma',
    'NEO-DEPIL LASER': 'Neo Depil',
    TAHE: 'Tahe Produtos Cosméticos S.L.',
    TAHE_2016: 'Tahe Produtos Cosméticos S.L.',
    TH_PHARMA: 'Th Pharma',
    TH_PHARMA_2016: 'Th Pharma',
    MIXPROFESSIONAL: 'Mix Professional',
    DVFARMACEUTIC: 'DV Farmaceutic',
    TAHE_ESPANA: 'Tahe Espanha',
    TAHE_CANARIAS: 'Tahe Canarias',
    TAHE_PORTUGAL: 'Tahe Portugal',
    TAHE_ITALIA: 'Tahe Itália',
    TH_ESPANA: 'Th Espanha',
    TH_PORTUGAL: 'Th Portugal',
    TH_ITALIA: 'Th Itália',
    TAHE_INTERNACIONAL: 'Tahe Internacional',
    TH_INTERNACIONAL: 'Th Internacional',
    MIXP_ESPANA: 'Mix Professional Espanha',
    DVF_ESPANA: 'DV Farmaceutic Espanha',
    NEW_CLIENT: 'NOVO CLIENTE',
    COMPANY: 'Empresa',
    SELL_CHANNEL: 'Canal de Venda',
    USER: 'Usuário',
    COMPANY_SELECTION: 'Seleção de Código',
    CLIENT_SELECTION: 'Seleção de Cliente',
    DASHBOARD: 'Painel de Controle',
    REPORTS: 'Relatórios diários',
    PENDING_PROPOSAL: 'Proposta para Cliente',
    PREVIOUS_PROPOSALS: 'Estado das propostas',
    PENDING_ORDER: 'Pedido',
    PENDING_APPROVAL_ORDERS: 'Pedidos Pendentes de aprovar',
    PENDING_APPROVAL_REFUNDS: 'Devoluções Pendentes de aprovar',
    PREVIOUS_ORDERS: 'Pedidos Anteriores',
    PENDING_CHARGE: 'Cobranças',
    PREVIOUS_CHARGES: 'Cobranças Anteriores',
    PENDING_REFUND: 'Devolução',
    PREVIOUS_REFUNDS: 'Devoluções',
    CLIENT_MANAGEMENT: 'Informação do Cliente',
    VENDOR_INFO: 'Informação do Vendedor',
    EXTRACTS: 'Movimentos do Vendedor',
    BILLS: 'Faturas',
    BILLS_REFUNDS: 'Notas de Crédito',
    EXPEDITIONS: 'Expedições',
    EXPENSES: 'Gastos',
    INCOMES: 'Pagamentos',
    PENDING_TO_SHIP: 'Pendente de Servir',
    REFUNDS_STATUS: 'Estado das Devoluções',
    COMMISSIONS: 'Comissões',
    SETTINGS: 'Ajustes',
    CHANGELOG: 'Novidades',
    FAQ_STORES: 'Ajuda Lojas de Cabeleireiro',
    FAQ: 'Ajuda',
    PROFILE: 'Perfil',
    BACK_LINK: 'Atrás',
    MENU: 'Menu',
    LOGOUT: 'Sair',
    LOGIN: 'Aceder',
    CANCEL: 'Cancelar',
    NO_FAVORITES_MENU: 'Ainda não adicionaste nenhum ícone aos favoritos. Experimenta clicar numa das estrelas <icon class="icon f7-icons" style="font-size: 20px">star</icon> dos links do Menu.',
    NO_RESULTS_FOUND: 'Não foram encontrados resultados',
    COD: 'Cód',
    CIF: 'NIF',
    PROPOSAL_FOR: 'Proposta para ',
    ORDER_FOR: 'Pedido para ',
    CHARGE_FOR: 'Cobrança para ',
    REFUND_FOR: 'Devolução para ',
    PENDING_PROPOSAL_EMPTY: 'Ainda não adicionaste nenhuma linha à proposta. Clica no ícone para pesquisar no catalogo.',
    PENDING_ORDER_EMPTY: 'Ainda não adicionaste nenhuma linha ao pedido. Clica no ícone para pesquisar no catalogo.',
    PENDING_CHARGE_EMPTY: 'Ainda não adicionaste nenhuma linha à cobrança. Clica no ícone para ver as cobranças pendentes do cliente.',
    PENDING_REFUND_EMPTY: 'Ainda não adicionaste nenhuma linha à devolução. Clica no ícone para procurar um produto.',
    SEARCH_CLIENT: 'Pesquisar Cliente por Código, Nome, NIF...',
    SEARCH_VENDOR: 'Pesquisar Vendedor por Código, Nome...',
    SEARCH_PRODUCT: 'Pesquisar produto por Código, Nome...',
    ADD_PRODUCT: 'Adicionar Produto',
    ADD_CHARGE: 'Adicionar cobrança',
    SEARCH_IN_BILLS: 'Pesquisar nas faturas',
    GENERATE_ADVICE: 'Gerar Pagto Antecipado',
    CATALOG: 'Catálogo',
    PRODUCTS: 'Produtos',
    LINES: 'Linhas',
    CATEGORIES: 'Categorias',
    IF_OPTION: 'Segunda Opção',
    PRICE: 'Preço',
    MAIN_MENU: 'Menu Principal',
    ORDERS: 'Pedidos',
    REFUNDS: 'Devoluções',
    CHARGES: 'Cobranças',
    NEW_CLIENTS: 'Clientes',
    INCOME: 'Pagamentos',
    YES: 'Sim',
    NO: 'Não',
    REPORT_DETAILS: 'Relatório {0}',
    REPORT_DETAILS_NOTFOUND: 'Não foram encontrados dados do relatório {0}',
    INFO: 'Informação',
    REPORT_NO: 'Relatório Nº',
    DATE: 'Data',
    VENDOR: 'Vendedor',
    IMPORT: 'Valor',
    TOTAL: 'Total {0}€',
    TOTAL_CASH: 'Numerário {0}€',
    ADD_PRECLIENT_INFO: 'Não tens nenhum cliente existente selecionado. Se pretendes adicionar um cliente sem realizar um pedido, clica no ícone Adicionar Pré-cliente',
    ADD_PRECLIENT: 'Adicionar Pré-cliente',
    NEW_CLIENT_FORM: 'Formulário de Novo Cliente',
    TYPE_CLIENT: 'Tipo de Cliente',
    NIF_CIF: 'NIF',
    NAME: 'Nome',
    NAME_2: 'Nome 2',
    NAME2: 'Nome 2',
    SOCIAL_REASON: 'Razão Social',
    ADDRESS: 'Direção',
    ADDRESS_2: 'Direção 2',
    CITY: 'Cidade',
    STATE: 'Distrito',
    COUNTRY: 'País',
    POSTAL_CODE: 'Código Postal',
    CONTACT: 'Contacto',
    CLOSE_DAYS: 'Dias de Fecho',
    CLOSEDAYS: 'Dias de Fecho',
    EMAIL: 'Email',
    PHONE: 'Telefone',
    MOBILE: 'Telemóvel',
    FAX: 'Fax',
    NAVISIONID: 'Código',
    SOCIALREASON: 'Razão Social',
    ADDRESS2: 'Direção 2',
    POSTALCODE: 'Código Postal',
    BANK_ACC: 'Conta Banc.',
    PRECLIENT: 'Pré-cliente',
    TYPECLIENT: 'Tipo de Cliente',
    UNFINISHED: 'Sem Terminar',
    STATISTICS: 'Estatísticas',
    NO_PENDING_APPROVAL_ORDERS: 'Não existem pedidos por aprovar',
    NO_PENDING_APPROVAL_REFUNDS: 'Não existem devoluções por aprovar',
    PENDING_ORDERS: 'Pedidos',
    PENDING_REFUNDS: 'Devoluções',
    PENDING_CHARGES: 'Cobranças',
    NO_PENDING_ORDERS: 'Não tens pedidos',
    NO_PREVIOUS_ORDERS: 'Ainda não existem pedidos anteriores',
    NO_PENDING_REFUNDS: 'Não tens devoluções',
    NO_PREVIOUS_REFUNDS: 'Ainda não existem devoluções anteriores',
    NO_PENDING_CHARGES: 'Não tens cobranças',
    NO_PREVIOUS_CHARGES: 'Ainda não existem cobranças anteriores',
    OBJECTIVE: 'Objetivo',
    BILLED: 'Faturado',
    IDEAL: 'Ideal ao dia',
    CUMPLIMENT_OBJ: 'de objetivo cumprido',
    SELECT_FOR_STATISTICS: 'Podes mudar o mês e o ano',
    MONTH: 'Mês',
    YEAR: 'Ano',
    BACK: 'Atrás',
    ACTIONS: 'Ações',
    SELECT_ALL: 'Selecionar Tudo',
    DISELECT_ALL: 'Desmarcar Tudo',
    DEACTIVATE_SORT: 'Desativar Ordenação',
    ACTIVATE_SORT: 'Ativar Ordenação',
    CHECKOUT_ORDER: 'Enviar Pedido',
    TOTAL_IMPORT: 'Total',
    TOTAL_IVA: '+ (IVA inc.)',
    BONIFY_ITEMS: 'Art. Bonificados',
    PROMO_COUNT: 'Nº Promos',
    SELECT_QUANTITY: 'Seleciona',
    SELECT_IMPORT: 'Seleciona valor',
    CLIENT: 'Cliente',
    ITEMS: 'Artigos',
    ORDER_DETAILS: 'Detalhes do Pedido',
    CHARGE_DETAILS: 'Detalhes da Cobrança',
    REFUND_DETAILS: 'Detalhes da Devolução',
    FAVORITES: 'Favoritos',
    ADD_WITH_DISCOUNT: 'Adicionar com desconto',
    UNITS: 'Unidades',
    ADD_TO_ORDER: 'Adicionar ao pedido',
    ADD_TO_PROPOSAL: 'Adicionar à proposta',
    WEB_STATUS: 'Estado Web',
    NAV_STATUS: 'Estado Navision',
    BLOCKED: 'Bloqueado',
    ACTIVE: 'Ativo',
    PENDING_ORDER_BACK: 'Pedido',
    DESCRIPTION: 'Descrição',
    QUANTITY: 'Quantidade',
    CODE: 'Código',
    NO_PROMO_PRODUCTS: 'Esta promoção não tem uma lista fixa de produtos',
    CLOSE: 'Fechar',
    UNITS_PER_BOX: 'Unidades por caixa',
    UNITS_PER_PACK: 'Unidades por palete',
    NO_PRODUCTS_FOUND: `Não foram encontrados produtos com a pesquisa <em>{0}</em>`,
    SELL_CHANNEL_SELECTED: 'Tens selecionada esta empresa e este canal de venda',
    NOT_CLIENTS_FOUND: `Não foram encontrados clientes com a pesquisa <em>{0}</em>`,
    CONFIRM: 'Confirmar',
    PERSONALIZADO: 'Personalizado',
    DELETE: 'Eliminar',
    DISCOUNT: 'Desconto',
    DUPLICATE: 'Duplicar',
    COPY: 'Copiar',
    QUANT: 'Quant.',
    UNIT_PRICE: 'Preço unit.',
    TOTAL_PROMO: 'Total Promo',
    FREE: 'Grátis',
    EDIT_SELECTED: 'Editar Selecionados',
    COPY_SELECTED: 'Copiar Selecionados',
    DUPLICATE_SELECTED: 'Duplicar Selecionados',
    DELETE_SELECTED: 'Eliminar Selecionados',
    NO_REPORTS: 'Ainda não tens nenhum relatório diário, estes relatórios são gerados automaticamente.',
    NEW_CLIENT_ADDRESS: 'Utiliza este cliente para introduzir um pedido num cliente novo ou num pré-cliente',
    es: 'Espanhol',
    it: 'Italiano',
    pt: 'Português',
    fr: 'Francês',
    CHANGE_PASSWORD: 'Mudar Senha',
    LANGUAGES: 'Idiomas',
    ACCEPT: 'Aceitar',
    DELETE_SELECTED_TEXT: 'Tens a certeza que pretendes eliminar os seguintes artigos?',
    DELETE_PROMO: 'Eliminar Promoção',
    DELETE_PROMO_TEXT: ' O que queres fazer?',
    DELETE_ALL: 'Eliminar tudo',
    DELETE_ONLY_HEADER: 'Eliminar só o cabeçalho',
    DELETE_HEADER_AND_PUT_PRICES: 'Eliminar cabeçalho e definir preços de tabela',
    SEARCH_BY_CODE: 'Adicionar por código',
    NOT_EXIST_PRODUCT: 'Lamento, mas o produto não existe na base de dados, tens a certeza que está correto?',
    PRODUCT_BLOCKED: 'Lamento, este produto está bloqueado no Navision, não podes adiciona-lo',
    SELECT_MODIFIERS: 'Seleciona os campos que queres editar, modifica-os e clica em guardar',
    SAVE: 'Guardar',
    OK: 'Ok',
    TIMEOUT: 'Pausa',
    UNAUTHORIZED: 'Erro de autenticação',
    USER_BLOCKED: 'O usuário está bloqueado, contacta um superior para solucionar o problema',
    PASSWORD_INCORRECT: 'A senha está incorreta',
    NOT_USER_FOUND: 'Não foi encontrado nenhum usuário com esses dados, <span class="text-color-red">lembra-te que o usuário <strong>NO</strong> é o teu código de vendedor e não o teu ID</span>',
    ALERT: '¡Atenção!',
    MOVE_TO_OTHER_CLIENT_INFO: 'Vais MOVER este pedido para outro cliente. Se moves o pedido <strong>SERÁ APAGADO</strong> deste cliente, e as linhas' +
  'passarão para outro cliente. Se o pedido já existir, serão adicionadas ao pedido existente, se não existir, um novo será criado. Tens a certeza' +
  'que pretendes continuar?',
    COPY_TO_OTHER_CLIENT_INFO: 'Vais COPIAR este pedido para outro cliente. Se copias o pedido <strong>PERMANECERÁ</strong> neste cliente, e as linhas' +
  'serão criadas noutro cliente. Se o pedido já existir, serão adicionadas ao pedido existente, se não existir, um novo será criado. Tens a certeza' +
  'que pretendes continuar?',
    MOVE_ORDER_TO_CLIENT_SUCCESS: `O pedido foi movido para o cliente Nº {0}, Queres mudar para esse cliente?`,
    COPY_ORDER_TO_CLIENT_SUCCESS: `O pedido foi copiado para o cliente Nº {0}, Queres mudar para esse cliente?`,
    SERVER_TOO_BUSY: 'De momento, o servidor está a processar muitos pedidos, por favor, tenta de novo',
    SERVER_NOT_RESPONDING: 'Parece que de momento, não se pode aceder ao servidor, verifica a tua ligação à internet' +
  'e que outras aplicações funcionam, se assim for, contacta com um administrador',
    UPDATE: 'Atualizar',
    ORDER_MOVED: 'Pedido Movido',
    ORDER_COPIED: 'Pedido Copiado',
    INFO_METHODS: 'Informação e Métodos de Pagamento',
    RESUME_ORDER: 'Resumo do Pedido',
    SEND: 'Enviar',
    NOT_FOUND: 'Não encontrado',
    SORRY: 'Lamento',
    CONTENT_NOT_FOUND: 'Não foi encontrado o que estavas a pesquisar, volta atrás ou clica no menu principal para voltar ao painel de controle',
    ADDRESS_SELECTION: 'Seleciona uma direção',
    PAYMENT_FORM_SELECTION: 'Forma de Pagamento',
    PAYMENT_TERM_SELECTION: 'Prazo de Pagamento',
    SELECT_ADDRESS_FILL: 'O cliente tem mais que uma direção, clica aqui e seleciona uma.',
    SEARCH_ADDRESS: 'Filtrar direções...',
    NO_ADDRESSES_FOUND: 'Não foram encontradas direções',
    SELECT_PAYMENT_FORM_FILL: 'Deves escolher uma forma de pagamento',
    SELECT_PAYMENT_TERM_FILL: 'Deves escolher um prazo de pagamento',
    ADD_ORDER_DATES: 'Adicionar datas de Cobrança',
    REMAINING_IVA_INC: 'Restante (IVA incl)',
    ADD_CHARGE_DATE: 'Adicionar data de Cobrança',
    ADD_CHARGE_DATES: 'Adicionar data de Cobranças',
    ADD_REMAINING: 'Adicionar restante',
    ADD_ORDER_DATES_INFO: 'Estes valores são orientativos, lembra-te que o preço final do pedido poderá variar',
    ADD_CHARGE_DATES_INFO: 'Seleciona o valor a cobrar, a data desejada e clica em adicionar cobrança',
    COMMENT: 'Comentário',
    COMMENT_FOR_CLIENT: 'Comentário para o Cliente',
    SAVE_COMMENT: 'Guardar Comentário',
    PENDING_BILLS: 'Faturas Pendentes',
    NO_PREVIOUS_MOVEMENTS: 'Não foram encontrados movimentos anteriores, do cliente selecionado.',
    NOT_MOVEMENTS_NEW_CLIENT: 'Tens selecionado o cliente novo, seleciona um cliente existente para ver os seus movimentos',
    NOT_BILLS_REFUNDS_NEW_CLIENT: 'Tens selecionado o cliente novo, seleciona um cliente existente para ver as suas notas de crédito',
    NO_BILLS_NEW_CLIENT: 'Tens selecionado o cliente novo, seleciona um cliente existente para ver as suas faturas',
    BILL_DETAILS: 'Detalhes da Fatura',
    SEARCH_PRODUCTS: 'Filtrar linhas da fatura',
    SEARCH_REFUND_PRODUCTS: 'Pesquisar Produto',
    SEARCH_REFUND_BILLS: 'Pesquisar Fatura',
    NO_CHARGES_FOR_NEW_CLIENT: 'Não selecionaste nenhum cliente existente, seleciona um cliente para enviar uma cobrança',
    NO_REFUNDS_FOR_NEW_CLIENT: 'Não selecionaste nenhum cliente existente, seleciona um cliente para enviar uma devolução',
    NO_PENDING_BILLS: 'O cliente não tem faturas pendentes',
    NO_MOV: 'Nº Mov',
    BILL: 'Fatura',
    TO_LIQUID: 'A Liquidar',
    PENDING_IMPORT: 'Valor Pendente',
    ADVICE: 'Valor Antecipado',
    CHECKOUT_CHARGE: 'Enviar Cobrança',
    CHARGE_ALREADY_PROCESSED: 'Esta linha já foi processada',
    CHARGE_LINE_ADDED: 'Linha a cobrar, adicionada corretamente',
    ORDER: 'Pedido',
    UNITS_PENDING: 'Unidades Pendentes',
    NUM_PACKS: 'Volumes',
    WRITE_COMMENT: 'Escreve aqui o teu comentário',
    CONTENT: 'Conteúdo',
    NEW_CLIENT_DETAILS: 'Detalhes do Novo Cliente',
    NO_NEW_CLIENT_DATA: 'Não foram encontrados dados deste Cliente Novo, tenta atualizar',
    COMMENTS: 'Comentários',
    OBJECTIVES: 'Objetivos',
    EXP_PEND: 'Expedições',
    PEND_BILLS: 'Fat. Pendentes',
    DROP_PLACEHOLDER: 'Arraste arquivos aqui',
    SELECTED_FILE: 'Imagem Selecionada',
    ADD_IMAGE: 'Adicionar Doc.',
    NEW_INCOME: 'Novo Pagamento',
    ADD_INCOME: 'Adicionar Pagamento',
    NO_INCOMES: 'Não foram encontrados pagamentos anteriores, clica no ícone superior para adicionar um novo pagamento',
    CHOOSE_FILE_INCOME: 'Escolhe um arquivo (recebimento do pagamento)',
    NEW_EXPENSE: 'Nova Despesa',
    ADD_EXPENSE: 'Adicionar Despesa',
    NO_EXPENSES: 'Não foram encontradas despesas anteriores, clica no ícone superior para adicionar uma nova despesa',
    CHOOSE_FILE_EXPENSE: 'Escolhe um arquivo (ticket da despesa)',
    TAP_HERE_IMG: 'Clica aqui para selecionar uma foto <br>ou tirar uma',
    DATE_INCOME: 'Data do Pagamento',
    DATE_EXPENSE: 'Data da Despesa',
    EXPENSE_ADDED: 'Cobrança adicionada corretamente',
    INCOME_ADDED: 'Pagamento adicionado corretamente',
    IMPORT_ERROR_MESSAGE: 'Erro de Importação. Por favor, consulte o administrador da aplicação',
    ERRORS: 'Erros',
    PLEASE_FIX_ERRORS: 'Por favor, corrige os seguintes erros',
    INCOME_DETAILS: 'Detalhes Pagamento',
    NO_INCOME_FOUND: 'Não foi encontrado o pagamento que pesquisavas.',
    PAYMENT_FORM: 'Forma de Pagamento',
    GROUP: 'Grupo',
    EXPENSE_DETAILS: 'Detalhes Despesa',
    PREVIEW: 'Previsão',
    PROPOSAL_DETAILS: 'Detalhes da Proposta',
    COMMERCIAL_COMMENT: 'Notas do Comercial',
    NO_PROPOSAL: 'Não foi encontrada esta proposta',
    RESPONSE: 'Resposta para o Comercial',
    SURNAME_NAME: 'Apelidos, Nome',
    UNDER_CONSTRUCTION: 'Em Construção',
    ORDER_DATE: 'Data do pedido',
    REFUND_DATE: 'Data da devolução',
    COMPANY_SELL_CHANNEL: 'Empresa e Canal de venda',
    SEND_ADDRESS: 'Direção de Envio',
    SEND_DATE: 'Entregar a partir do dia',
    PROPOSALS: 'Propostas',
    PROPOSAL: 'Proposta',
    PROPOSAL_CONFIRMATION: 'Será gerado um link para enviar a proposta ao cliente, que irá aceitar ou rejeitar,' +
  'além de ver toda a informação dos produtos na proposta, estás de acordo?',
    PAYMENT_TERM: 'Prazo de Pagamento',
    DATES: 'Datas',
    PAYMENT_DATE: 'Data de Pagamento',
    PASSWORDS_NOT_MATCH: 'A senha que introduziste não é a tua senha atual',
    PASSWORD_SUCCESSFULL_CHANGED: 'Mudaste a tua senha',
    ADDRESSES: 'Direções',
    CLIENT_COMMENTS: 'Comentários Cliente',
    CONTRACT_COMMENTS: 'Comentários Contrato',
    NO_COMMENTS: 'Não há comentários',
    FILL_NEW_CLIENT_DATA: 'Vais fazer um pedido para um Cliente Novo, agora tens que preencher os dados. Clica no seguinte ' +
  'ícone e preenche os dados.',
    FILL_PRECLIENT: 'Preencher os dados do cliente',
    UPDATE_PRECLIENT: 'Atualizar dados do cliente',
    FIXED_DUE_DATE: 'Vencimento Fixo',
    PRODUCT_BILLS: 'Faturas do Produto',
    REFUND_SELECTED: 'Devolver Selecionados',
    APPROVAL_ORDERS: 'Aprovar Pedidos',
    APPROVAL_REFUNDS: 'Aprovar Devoluções',
    VENDOR_SELECTION: 'Seleção de Vendedor',
    VENDOR_SELECTED: 'Tens este vendedor selecionado',
    GIRO_DISABLED: 'Não podes aplicar o Debito a este cliente porque não tem uma conta bancária associada. Por favor contacta com a administração' +
  ' para adicionar uma conta bancária e voltar a tentar. Lembra-te que a aplicação é atualizada todas as noites.',
    TALON_C_DISABLED: 'Não podes utilizar esta forma de pagamento porque o cliente não tem um contrato com cheques antecipados',
    TRANSFER_DISABLED: 'Não podes utilizar esta forma de pagamento porque o cliente não é uma cooperativa',
    CONFIRM_SEND_ORDER: 'Tens a certeza que pretendes enviar este pedido?',
    SEND_ORDER: 'Enviar Pedido',
    CHARGE_DATE: 'Data de Cobrança',
    DATE_EXISTS_ERROR: 'Já foi adicionada a data{0} às datas de vencimento',
    ZERO_IMPORT: 'Para enviar uma cobrança a 0 tens que selecionar numerário',
    CONFIRM_SEND_CHARGE: ' Tens a certeza que pretendes enviar a cobrança?',
    SEND_CHARGE: 'Enviar Cobrança',
    SEARCH_REFUND_PRODUCT: 'Pesquisa um produto na barra de cima',
    ADD: 'Adicionar',
    REFUND_MOTIVE: 'Motivo da Devolução',
    MOTIVES: 'Motivos',
    REFUND_LINES_ADDED: 'A/s linha/s selecionada/s foram adicionada/s à devolução',
    MOTIVE: 'Motivo',
    SET_LITTLE_DESCRIPTION: 'Introduz uma pequena descrição',
    SEND_REFUND: 'Enviar Devolução',
    CONFIRM_SEND_REFUND: 'Tens a certeza que pretendes enviar a devolução? Confirma antes de enviar',
    REFUND_MOTIVE_SUGGEST: 'Preencheste todos os motivos de devolução para CADA produto?',
    NO_REFUND_FOUND: 'A devolução não foi encontrada',
    OBJECTIVES_FROM_COSMET: 'Objetivos adicionados desde o Ofertahe',
    ADD_OBJECTIVE: 'Adicionar Objetivo',
    PREVIOUS_OBJECTIVES: 'Objetivos Anteriores',
    OBJECTIVE_ADDED: 'Objetivo adicionado corretamente',
    '200': 'Êxito',
    '201': 'Êxito',
    '404': 'Não encontrado',
    '400': 'Erro',
    '401': 'Erro de autenticação',
    '422': 'Erro no servidor',
    '499': 'Já existe',
    'CONTADO': 'Contrato com cheques',
    'GIRO': 'Debito Bancário',
    'CONTRAREEM': 'Contrarreembolso',
    'GIROPT': 'Debito Bancário Portugal',
    'CONTRARPOR': 'Contrarreembolso Portugal',
    'CHEQUEPT': 'Cheque Portugal',
    'TARJETAPT': 'Cartão Crédito Clientes Portugal',
    'TRANSFPORT': 'Transferência',
    'TRANSFERIT': 'Transferência',
    'TARJ OUTLE': 'Cartão Crédito',
    'CONTADOPT': 'Contrato Cheque',
    'EFECTIVO': 'Numerário',
    'TARJETA': 'Cartão Crédito',
    'GIROCOBRO': 'Débito Bancário',
    'CHEQUE': 'Cheque',
    'DESCUENTO': 'Desconto',
    '1X7': 'Débito Bancário 7 dias',
    '1X15': 'Débito Bancário 15 dias',
    '1X20': 'Débito Bancário dias',
    '1X21': 'Débito Bancário dias',
    '1X30': 'Débito Bancário 30 dias',
    '1X40': 'Débito Bancário dias',
    '1X45': 'Débito Bancário 45 dias',
    '1X60': 'Débito Bancário 60 dias',
    '1X75': 'Débito Bancário 75 dias',
    '1X90': 'Débito Bancário 90 dias',
    '2X7': 'Débito Bancário 7 e 30 dias',
    '2X15': 'Débito Bancário 15 e 30 dias',
    '2X20': 'Débito Bancário 20 e 40 dias',
    '2X30': 'Débito Bancário 30 e 60 dias',
    '2X45': 'Débito Bancário 45 e 90 dias',
    '2X45B': 'Débito Bancário 45 e 60 dias',
    '2X60': 'Débito Bancário 60 e 90 dias',
    '2X60X120': 'Débito Bancário 60 e 120 dias',
    '2X90': 'Débito Bancário 90 e 120 dias',
    '3X10': 'Débito Bancário 10, 20 e 30 dias',
    '3X15': 'Débito Bancário 15, 30 e 45 dias',
    '3X3': 'Débito Bancário 45, 75 e 105 dias',
    '3X30': 'Débito Bancário 30, 60 e 90 dias',
    '3X60': 'Débito Bancário 60, 90 e 120 dias',
    '4X30': 'Débito Bancário 30, 60, 90 e 120 dias',
    '4X3': 'Débito Bancário 15, 60 e 90 dias',
    '4X4': 'Débito Bancário 15, 30, 60 e 90 dias',
    '1X120': 'Débito Bancário 120 dias',
  MAINTENANCE_MODE: 'O servidor está em manutenção, por favor seja paciente, brevemente estará operativo',
  '503': 'MANUTENÇÃO',
  NO_PREVIOUS_BILLS: 'Não foram encontradas faturas anteriores',
  NO_PREVIOUS_BILLS_REFUNDS: 'Não foram encontradas notas de crédito anteriores',
  SEARCH_MENU: 'O que queres fazer?',
  PENDING: 'Pendente',
  PENDING_TO_SEND: 'Pendente E.',
  PENDING_APPROVAL: 'Pend. Aprov.',
  PENDING_APPROVAL_DIS: 'Pend. Dis.',
  SENDED: 'Enviado',
  IMPORT_ERROR: 'Erro Import.',
  RECEIVED: 'Recebido',
  ACCEPTED: 'Aceite',
  CANCELED: 'Cancelado',
  UNKNOWN: 'Desconhecido',
  STATUS: 'Estado',
  'ENERO': 'JANEIRO',
  'FEBRERO': 'FEVEREIRO',
  'MARZO': 'MARÇO',
  'ABRIL': 'ABRIL',
  'MAYO': 'MAIO',
  'JUNIO': 'JUNHO',
  'JULIO': 'JULHO',
  'AGOSTO': 'AGOSTO',
  'SEPTIEMBRE': 'SETEMBRO',
  'OCTUBRE': 'OUTUBRO',
  'NOVIEMBRE': 'NOVEMBRO',
  'DICIEMBRE': 'DEZEMBRO',
  'Peluquería': 'Cabeleireiro',
  'Estética': 'Estética',
  'Estética+Peluquería': 'Estética+Cabeleireiro',
  'Barbería': 'Barbearia',
  'Otros': 'Outros',
  NOT_EMAIL: 'O cliente não tem email. Para poder faturar tens que adicionar um email e o cliente tem de o validar, Vamos a isso!',
  NOT_VALIDATED_EMAIL: 'O cliente tem um email, mas ainda não está confirmado. Por favor envia-lhe um email de confirmação',
  MUST_ADD_OBJECTIVE: 'Lembra-te que tens que adicionar neste cliente, o objetivo do próximo ano',
  VALIDATE_EMAIL: 'Validar Email',
  UPDATE_EMAIL: 'Atualizar Email',
  EMAIL_MORE_THAN_WEEK: 'Email com mais de 7 dias sem estar confirmado',
  WAITING_FOR_CONFIRM: 'Esperando confirmação do email',
  SEND_ORDER_CONFIRMATION: 'Confirmação de Envio',
  COOPERATIVA: 'COOPERATIVA',
  ASSOCIATED_CLIENT: 'Cliente Associado',
  SELECT_ASSOCIATED_CLIENT: 'Seleciona o cliente associado',
  NO_BILLS_FOUND: 'Não foram encontradas faturas',
  ADD_BILL_MANUALLY: 'Adicionar fatura manualmente',
  BUY_AGAIN: 'Voltar a fazer Pedido',
  REBUY: 'Voltar a comprar',
  REFUND: 'Devolver',
  TYPE_CLIENT_EMPTY: 'Tens que preencher o campo Tipo Cliente',
  CIF_EMPTY: 'Tens que preencher o campo NIF',
  CIF_NOT_VALID: 'O NIF não é válido, confirma o campo NIF',
  NAME_EMPTY: 'Tens que preencher o campo Nome',
  NAME_TOO_LONG: 'O nome e os apelidos são demasiadamente extensos, tenta abrevia-los ou considera separa-los no Nome 2.',
  NAME2_TOO_LONG: 'O campo Nome 2 é demasiado extenso',
  SOCIAL_REASON_TOO_LONG: 'O campo razão social é demasiado extenso',
  ADDRESS_EMPTY: 'Tens que preencher o campo Direção',
  ADDRESS_TOO_LONG: 'O campo Direção é demasiado extenso, considera separa-lo no campo Direção 2',
  ADDRESS2_TOO_LONG: 'O campo Direção 2 é demasiado extenso, tenta abrevia-lo.',
  CITY_EMPTY: 'Tens que preencher o campo Cidade',
  CITY_TOO_LONG: 'O campo cidade é demasiado extenso, tenta abrevia-lo',
  STATE_EMPTY: 'Tens que preencher o campo Distrito',
  STATE_TOO_LONG: 'O campo Distrito é demasiado extenso, tenta abrevia-lo',
  COUNTRY_EMPTY: 'Tens que selecionar um País',
  POSTAL_CODE_EMPTY: 'Tens que preencher o campo Código Postal',
  POSTAL_CODE_WRONG: 'O campo Código Postal foi mal introduzido, confirma',
  CONTACT_EMPTY: 'Tens que preencher o campo Pess.Contacto',
  CONTACT_TOO_LONG: 'O campo Contacto é demasiado extenso, tenta abrevia-lo',
  CLOSE_DAYS_EMPTY: 'Tens que preencher o campo Dias de Fecho',
  CLOSE_DAYS_TOO_LONG: 'O campo Dias de Fecho é demasiado extenso, tenta abrevia-lo',
  EMAIL_EMPTY: 'Tens que preencher o campo Email',
  EMAIL_NOT_VALID: 'O email não tem um formato válido.',
  EMAIL_TOO_LONG: 'O email é demasiado extenso',
  MOBILE_OR_PHONE_E: 'Tens que preencher o campo Telefone ou o campo Telemóvel',
  FAX_TOO_LONG: 'O campo Fax é demasiado extenso',
  NOT_VALID_ACCOUNT: 'O campo nº de conta tem que ter este formato SSXX XXXX XXXX XX XXXXXXXXXX',
  FILL_BANK_ACCOUNT: 'Se queres fazer Debito Direto a um cliente novo tens que enviar também o número de conta, por favor, preenche',
  BANKACCOUNT: 'Conta Bancaria',
  CHECK_NO: 'Nº Cheque',
  CHECK_NO_PLACEHOLDER: 'Introduz o número de cheque',
  NO_ORDER_FOUND: 'O pedido não foi encontrado',
  NO_REPORT_DETAILS: 'Não foram encontrados dados do relatório',
  NO_PENDING_TO_SHIP_FOR_NEW_CLIENT: 'Não tens selecionado nenhum cliente existente, seleciona um cliente para ver o seu pendente de servir',
  NO_EXPEDITION_FOR_NEW_CLIENT: 'Não tens selecionado nenhum cliente existente, seleciona um cliente para ver as suas expedições',
  LINE_COPIED: 'Linha copiada no pedido',
  COMPANY_NOT_MATCH: 'As empresas das linhas que queres adicionar à cobrança não coincidem',
  DESCON_0: 'Não olhes para o ecrã, aproveita o teu momento. Relaxa!',
  DESCON_1: 'Sol escondido, descanso merecido.',
  DESCON_2: 'Desligar de tudo e descansar é necessário para levar uma vida saudável.',
  DESCON_3: 'Momento de: Clientes ... Deslocamentos ... Pedidos ... ¡Desligar! <icon class="icon f7-icons text-color-green" style="font-size: 16px">checkmark_alt</icon>',
  DESCON_4: 'Agora é tempo de estar com a tua família: aproveita e disfruta!',
  DESCON_5: 'Agora começa o teu momento familiar!! Aproveita!!',
  DESCON_6: 'Para conseguir um rendimento de 100% no teu dia-a-dia profissional, necessitas desligar a 100%',
  DESCON_7: 'Para ser produtivo tens que descansar!! Feliz dia de descanso!!',
  DESCON_8: 'Depois do trabalho, disfrutar da família, de uma vida saudável e uma boa leitura irá ajudar a sentires-te melhor',
  DESCON_9: 'Alto! Este tempo é para ti, aproveita-o!',
  SOLD_PRODUCTS: 'Produtos Vendidos',
  NO_SOLD_PRODUCTS_FOR_NEW_CLIENT: 'Não selecionaste nenhum cliente existente, seleciona um cliente para ver os seus produtos vendidos',
  DATE_RANGE: 'Intervalo de Datas',
  SELECT_DATES: 'Selecionar Datas',
  GET_SOLD_PRODUCTS: 'Obter Produtos Vendidos',
  NO_SOLD_PRODUCTS_FOUND: 'Não foram encontrados produtos vendidos para este cliente ou neste intervalo de datas.',
  SEARCH_FAQ: 'Pesquisar em ajuda',
  MY_CODES: 'Os meus Códigos',
  VENDORS: 'Vendedores',
  SEARCH_REPORTS: 'Pesquisar nos relatórios',
  REPORTS_RESULTS: 'Resultados para a pesquisa {0}',
  NOT_FOUND_CLIENT: 'Não o encontro',
  NOT_FOUND_CLIENT_INFO: 'Se o cliente foi adicionado hoje, é normal que não o encontres! Calma! Se sabes o código do cliente podes adiciona-lo tu mesmo a partir daqui' +
'embora toda a informação como os comentários de contrato, direções adicionais, etc,... aparecerá no dia seguinte. Ainda assim, caso necessites fazer um pedido, devolução' +
'ou cobrança, tenta introduzir agora mesmo e assim não tens de esperar pelo dia seguinte',
  FIND_CLIENT: 'Encontra o cliente',
  CLIENT_IMPORT_FAILED: 'Lamento, mas o código que introduziste, não existe, ou esse cliente não está associado com o teu código de vendedor, consulta com a administração e' +
'volta a tentar :)',
  CLIENT_IMPORT_SUCESFULL: 'Muito bem! Já tens o cliente na tua seleção',
  NO_PREVIOUS_PROPOSALS: 'Não foram encontradas propostas anteriores.',
  PRODUCT_ERROR: 'Erro num produto',
  PRODUCT_ERROR_INFO: 'Existe problemas com algum produto? Se houver algum erro com um produto existente ou caso não o consigas encontrar, este é o local indicado, diz-me o que se passa' +
'e vou-te ajudar a resolver o problema',
  FIND_PRODUCT_CODE: 'Encontrar o código',
  WHAT_PROBLEM: 'Qual é o problema?',
  APPLIES_TO_DOC: 'Acerto com a nota de crédito',
  SENDED_ERROR_MESSAGE: 'Foi enviada uma mensagem com o erro detetado, Muito obrigada!',
  COSMET_NOT_PRODUCT_FOUND: 'Não foi encontrado o produto na base de dados, confirma que o código está correto e neste caso contacta com o administrador.',
  CONTRACTS: 'Contratos',
  NO_EXPEDITIONS_CLIENT: 'O cliente não tem expedições recentes',
  NO_PENDING_BILLS_CLIENT: 'O cliente não tem faturas pendentes',
  NO_LAST_BILLS: 'Não foram encontradas faturas anteriores',
  UPDATE_PRODUCT: 'Adicionar/Act. produto',
  UPDATE_CATEGORY: 'Adicionar/Act. categoria',
  NO_LAST_BILLS_VENDOR: 'Não há informação de últimas faturas',
  NO_LATE_BILLS_VENDOR: 'Não tens saldos atrasados de nenhum cliente',
  NO_PENDING_BILLS_VENDOR: 'Não há Faturas pendentes de nenhum cliente',
  SELL_CHANNEL_SELECTION: 'Seleção do Canal de Venda',
  TAHE_OUTLET: 'Tahe Outlet',
  TH_OUTLET: 'Th Outlet',
  PROPOSAL_ADDED_TO_ORDER: 'Proposta adicionada corretamente ao pedido',
  NUM_CHECK: 'Nº Cheque',
  NOT_EXIST_ONE_OR_MORE_PRODUCTS: 'Foram adicionados os produtos à devolução, mas alguns deles não foram adicionados, porque não existem na base de dados',
  PRODUCT_BILL_ALREADY_ADDED: 'Parece que já devolveste algum dos produtos selecionados correspondentes a esta fatura',
  DISTRIBUTORS: 'Distribuidores',
  NO_DISTRIBUTORS: 'Não há distribuidores',
  ADD_DISTRIBUTOR: 'Adicionar Distribuidor',
  NEW_DISTRIBUTOR: 'Novo Distribuidor',
  PRODUCTS_SOLD_INFO: 'O cálculo dos produtos vendidos é feito retirando os produtos faturados e subtraindo os produtos devolvidos',
  ADD_DIST_CLIENT: 'Adicionar Cliente',
  SEE_MORE: 'Ver mais',
  cif: 'NIF',
  socialReason: 'Razão Social',
  name: 'Nome',
  name2: 'Nome 2',
  surname: 'Apelidos',
  address: 'Direção',
  address2: 'Direção 2',
  city: 'Cidade',
  state: 'Distrito',
  postalCode: 'Cód. Postal',
  contact: 'Contacto',
  closeDays: 'Dias de Fecho',
  email: 'Email',
  phone: 'Telefone',
  mobile: 'Telemóvel',
  fax: 'Fax',
  bankAccount: 'Conta Bancária',
  esxx: 'ESXX',
  bank: 'Banco',
  office: 'Oficina',
  digit: 'Digito',
  numAccount: 'Nº Conta',
  'cosmet': 'Cosmet',
  'red': 'Vermelho',
  'green': 'Verde',
  'blue': 'Azul',
  'pink': 'Rosa',
  'yellow': 'Amarelo',
  'orange': 'Laranja',
  'purple': 'Lilás',
  'deeppurple': 'Índigo',
  'lightblue': 'Azul Claro',
  'teal': 'Turquesa',
  'lime': 'Lima',
  'deeporange': 'Laranja Escuro',
};

