<template>
  <f7-popup class="info-product-popup" :opened="infoPopupOpen" @popup:open="enableDisplay" @popup:closed="setPopup" tablet-fullscreen>
    <f7-page>
      <f7-navbar>
        <f7-nav-right>
          <f7-link popup-close=".info-product-popup">{{$t('CLOSE')}}</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <template v-if="productInfo">
        <f7-block-title class="no-margin-top size-13" style="white-space: normal;">{{productInfo.webProduct.translation.name}}</f7-block-title>
        <f7-block strong inset>
          <div class="display-flex justify-content-space-between" :class="isInBreakpoint ? 'flex-direction-column' : 'flex-direction-row'">
            <div style="min-width: 20%;">
              <f7-swiper pagination style="min-width: 20%; max-width: 200px;">
                <f7-swiper-slide>
                  <div style="width: 100%; height: 100%">
                    <img :src="getImage(productInfo.webProduct.image, productInfo.webProduct.optionImage, IMAGE_SIZES.PRODUCT.medium, IMAGE_SIZES.OPTION.medium)"
                         @onerror="$event.target.src = COMPANY[sessionInfo.company].image" alt="" style="width: inherit">
                  </div>
                </f7-swiper-slide>
                <template v-if="productInfo.webProduct.extraImages">
                  <f7-swiper-slide v-for="(extraImage, index) in productInfo.webProduct.extraImages" :key="index">
                    <div style="width: 100%; height: 100%">
                      <img :src="extraImage" alt="" style="width: inherit">
                    </div>
                  </f7-swiper-slide>
                </template>
              </f7-swiper>
            </div>
            <template v-if="productInfo.promo">
              <div @click="openPromoPhoto($event)">
                <f7-icon f7="search" color="blue" style="position: absolute; top: 10px; right: 10px" size="40"></f7-icon>
                <div class="promo-photo" v-html="productInfo.webProduct.translation.description"></div>
              </div>
            </template>
            <template v-else>
              <!--              <div class="display-flex flex-direction-column margin-top">-->
              <!--                <h4 class="font-weight-bold no-margin-vertical text-capitalize size-13"-->
              <!--                    v-html="`${product.name.toLowerCase()} ${option.optionName.toLowerCase()} ${product.extraName.toLowerCase()}`"></h4>-->
              <!--                <div class="subtitle size-12 text-capitalize" v-html="`${product.mainLine.toLowerCase()}`"></div>-->
              <!--                <div class="subtitle text-color-gray size-12 text-capitalize margin-bottom-half" v-html="`${product.metaKeyword.toLowerCase()}`"></div>-->
              <!--                <div class="size-12" v-html="product.description"></div>-->
              <!--              </div>-->
              <div class="size-12" v-html="productInfo.webProduct.translation.description"></div>
            </template>
          </div>
        </f7-block>
        <template v-if="productInfo && productInfo.productTabs.length">
          <f7-block-title>{{$t('INFO')}}</f7-block-title>
          <f7-list accordion-list inset>
            <f7-list-item accordion-item v-for="(infoTab, index) in productInfo.productTabs" :key="index">
              <template #title>
                <div class="size-13"><strong v-html="infoTab.translation.name"></strong></div>
              </template>
              <f7-accordion-content>
                <div v-html="infoTab.translation.description" style="padding: 16px" class="size-12"></div>
              </f7-accordion-content>
            </f7-list-item>
          </f7-list>
        </template>
        <!--        <template v-if="productInfo && productInfo.productTabs.length">-->
        <!--          <f7-block-title>{{$t('INFO')}}</f7-block-title>-->
        <!--          <f7-block strong inset class="shadow size-13 no-padding">-->
        <!--            <f7-toolbar tabbar bottom scrollable>-->
        <!--              <f7-link class="size-13" v-for="(infoTab, index) in productInfo.productTabs" :tab-link-active="state.selectedTab === index"-->
        <!--                       :key="index" :tab-link="`#info-tab-${index}`" @click="state.selectedTab = index"><strong v-html="infoTab.name"></strong></f7-link>-->
        <!--            </f7-toolbar>-->
        <!--            <f7-tabs>-->
        <!--              <f7-tab v-for="(infoTab, index) in productInfo.productTabs" :key="index" :id="`info-tab-${index}`"-->
        <!--                      :tab-active="state.selectedTab === index" class="page-content no-padding">-->
        <!--                <f7-block>-->
        <!--                  <div v-html="infoTab.description"></div>-->
        <!--                </f7-block>-->
        <!--              </f7-tab>-->
        <!--            </f7-tabs>-->
        <!--          </f7-block>-->
        <!--          &lt;!&ndash;      <f7-list accordion-list inset>&ndash;&gt;-->
        <!--          &lt;!&ndash;        <f7-list-item accordion-item v-for="(infoTab, index) in productInfo.productTabs" :key="index">&ndash;&gt;-->
        <!--          &lt;!&ndash;          <div slot="title" class="size-13"><strong v-html="infoTab.name"></strong></div>&ndash;&gt;-->
        <!--          &lt;!&ndash;          <f7-accordion-content>&ndash;&gt;-->
        <!--          &lt;!&ndash;            <div v-html="infoTab.description" style="padding: 16px" class="size-12"></div>&ndash;&gt;-->
        <!--          &lt;!&ndash;          </f7-accordion-content>&ndash;&gt;-->
        <!--          &lt;!&ndash;        </f7-list-item>&ndash;&gt;-->
        <!--          &lt;!&ndash;      </f7-list>&ndash;&gt;-->
        <!--        </template>-->
<!--        <template v-if="productInfo && productInfo.relatedProducts.length && state.canDisplay">-->
<!--          <f7-block-title>Productos Relacionados</f7-block-title>-->
<!--          <div class="size-13 margin-bottom">-->
<!--            <f7-swiper pagination navigation :params="{ speed:500, slidesPerView: 3, spaceBetween: 20, centeredSlides: true }">-->
<!--              <f7-swiper-slide v-for="(pi, index) in productInfo.relatedProducts" style="height: 240px;">-->
<!--                <div class="bg-color-white shadow display-flex flex-direction-column justify-content-center" style="height: 200px;">-->
<!--                  <div class="display-flex flex-direction-column justify-content-center align-items-center margin" @click="goToProduct(pi.sku, pi.optionSku, pi)">-->
<!--                    <div><img :src="getImage(pi.image, pi.image, IMAGE_SIZES.PRODUCT.medium, IMAGE_SIZES.OPTION.medium)"-->
<!--                              @onerror="$event.target.src = COMPANY[sessionInfo.company].image" alt="" width="100" height="100"></div>-->
<!--                    <div class="text-align-center"><small class="font-weight-bold size-12">{{pi.translation.name}}</small></div>-->
<!--                    <div class="text-align-center"><small class="text-color-gray size-11">{{pi.translation.metaKeyword}}</small></div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </f7-swiper-slide>-->
<!--            </f7-swiper>-->
<!--          </div>-->
<!--        </template>-->
            <template v-if="state.similares.length && state.canDisplay">
              <f7-block-title>{{ $t('productos_similares') }}</f7-block-title>
              <div class="display-flex flex-direction-row justify-content-center no-margin margin-bottom" style="flex-wrap: wrap;">
                <product-card v-for="(product, index) in state.similares" :key="index" :product="product"></product-card>
              </div>
            </template>
<!--        <template v-if="productInfo && productInfo.relatedProducts.length && state.canDisplay">-->
<!--          <f7-block-title>{{ $t('productos_similares') }}</f7-block-title>-->
<!--          <div class="size-13 margin-bottom">-->
<!--            <f7-swiper pagination navigation :params="{ speed: 500, slidesPerView: 3, spaceBetween: 20, centeredSlides: true }">-->
<!--              <f7-swiper-slide v-for="(pi, index) in productInfo.relatedProducts" style="height: 240px;">-->
<!--                <div class="bg-color-white shadow display-flex flex-direction-column justify-content-center" style="height: 200px;">-->
<!--                  <div class="display-flex flex-direction-column justify-content-center align-items-center margin" @click="goToProduct(pi.sku, pi.optionSku, pi)">-->
<!--                    <div><img :src="getImage(pi.image, pi.image, IMAGE_SIZES.PRODUCT.medium, IMAGE_SIZES.OPTION.medium)"-->
<!--                              @onerror="$event.target.src = COMPANY[sessionInfo.company].image" alt="" width="100" height="100"></div>-->
<!--                    <div class="text-align-center"><small class="font-weight-bold size-12">{{pi.translation.name}}</small></div>-->
<!--                    <div class="text-align-center"><small class="text-color-gray size-11">{{pi.translation.metaKeyword}}</small></div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </f7-swiper-slide>-->
<!--            </f7-swiper>-->
<!--          </div>-->
<!--        </template>-->
        <template v-else><div></div></template>
      </template>
      <template v-else><div></div></template>
    </f7-page>
  </f7-popup>
</template>
<script>
import { f7 } from 'framework7-vue';
import { useActions, useState } from '../../../../js/stores/hooks';
import { reactive } from 'vue';
import { isInBreakpoint } from '../../../../js/helpers/f7.helpers';
import { COMPANY } from '../../../../js/config/enums.config';
import { getImage } from '../../../../js/helpers/catalog.helper';
import { IMAGE_SIZES } from '../../../../js/config/app.config';
import ProductCard from './product-card';
import { productsService } from '../../../../js/api/graphql/services/products.service';

export default {
  props: {
    popupOpen: Boolean,
  },
  components: {
    'product-card': ProductCard
  },
  setup: (props) => {
    const { product, infoPopupOpen, productInfo } = useState('catalog', ['product', 'infoPopupOpen', 'productInfo']);
    const { setInfoPopupOpen, getProduct } = useActions('catalog', ['setInfoPopupOpen', 'getProduct']);
    const { language } = useState('userSettings', ['language']);
    const { sessionInfo } = useState('session', ['sessionInfo']);
    const state = reactive({
      selectedTab: 0,
      canDisplay: false,
      similares: [],
    });
    const openPromoPhoto = (event) => {
      f7.photoBrowser.create({
        photos: [event.target.currentSrc],
      }).open();
    };
    const goToProduct = (sku, optionSku, relatedProduct) => {
      // f7.views.main.router.navigate('/product-details/', {props: { product: relatedProduct } });
      // f7.popup.get('.info-product-popup').close();
      // getProduct({sku, optionSku, company: sessionInfo.value.company, sellChannel: sessionInfo.value.sellChannel, language: language.value, groupPrice: sessionInfo.value.groupPrice });
    };
    const enableDisplay = () => {
      // setTimeout(() => state.canDisplay = true, 500);
      obtenerProductosSimilares();
    };
    const setPopup = () => {
      setInfoPopupOpen(false);
      state.canDisplay = false;
    };
    const obtenerProductosSimilares = async () => {
      console.log(product);
      const { data: { similares }} = await productsService.productosSimilares({
        productoId: product.value.sku,
        sellChannel: sessionInfo.value.sellChannel,
        language: language.value,
        groupPrice: sessionInfo.value.groupPrice,
      });
      console.log(similares);
      state.similares = similares;
      state.canDisplay = true;
    };
    return { product, infoPopupOpen, setInfoPopupOpen, isInBreakpoint, openPromoPhoto, state, goToProduct,
      enableDisplay, setPopup, sessionInfo, COMPANY, productInfo, getImage, IMAGE_SIZES, obtenerProductosSimilares };
  },
};
</script>