<template>
  <f7-button outline color="primary" class="text-capitalize" @click="openSheet()">{{state.quantity > 0 ? `(${state.quantity}) ${$t('text.added')}` : $t('buttons.add') }}</f7-button>
</template>
<script>
  import { f7 } from 'framework7-vue';
  import { useActions, useGetters, useState } from '../../../js/stores/hooks';
  import { computed, reactive } from 'vue';
  import $$ from 'dom7';
  import { store } from '../../../js/stores/vuex';

  export default {
    props: {
      type: { type: String, default: 'catalog' }, // catalog, cart
      product: { type: Object, default: null },
    },
    setup: (props) => {
      const { value } = useState('input', ['value']);
      const { setInput } = useActions('input', ['setInput']);
      const { editPendingCart } = useActions('shoppingCart', ['editPendingCart']);
      const state = reactive({
        adding: false,
        quantity: computed(() => store.getters['shoppingCart/getQuantity'](props.product.optionSku)),
      })
      const openSheet = () => {
        setInput({ value: Math.max(1, state.quantity), units: props.product.optionSku})
        $$('#new-product-stepper-sheet').on('sheet:closed', async e => {
          let newVal = 0;
          try {
            newVal = eval(value.value);
          } catch (e) { newVal = 0; }
          newVal = !isNaN(newVal) ? parseInt(newVal) : 0;
          await changePendingCart(newVal);
          $$('#new-product-stepper-sheet').off('sheet:closed');
        });
        f7.sheet.get('#new-product-stepper-sheet').open();
      };
      const changePendingCart = async (quantity) => {
        // console.log(quantity);
        if (state.adding) return;
        state.adding = true;
        quantity = Math.max(0, quantity);
        // console.log(props.product);
        if (props.type === 'card') {
          await editPendingCart({ optionSku: props.product.optionSku, quantity });
        } else {
          await editPendingCart({
            optionSku: props.product.optionSku,
            sku: props.product.sku,
            quantity: quantity,
            unitPrice: props.product.info.price + props.product.info.promoPriceLines,
            product: {
              blocked: props.product.blocked,
              extraName: props.product.translation.extraName,
              image: props.product.image,
              mainLine: props.product.translation.mainLine,
              name: props.product.translation.name,
              optionImage: props.product.optionImage,
              optionName: props.product.translation.optionName,
            }
          });
        }
        state.adding = false;
      };
      return { openSheet, state };
    }
  }
</script>