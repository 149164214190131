<template>
  <page name="home" :title="`${$t('pages.home')} v.${f7State.localVersion}`" :nav-large="false" :transparent="false" :back-button="false"
        :with-subnavbar="true" @page-after-in="checkVersion()">
    <!-- :show-search-button="true" -->
    <template v-slot:subnavbar>
      <f7-subnavbar>
        <f7-searchbar :disable-button-text="$t('buttons.cancel')" :placeholder="$t('inputs.searchMainMenu.placeholder')"
                      custom-search :value="mmState.query" @searchbar:search="filterMenu"></f7-searchbar>
      </f7-subnavbar>
    </template>
    <template v-slot:default>
      <component :is="state.component" v-if="state.component"></component>
    </template>
  </page>
</template>
<script>
import Page from '@/components/layouts/page.vue';
import { watch, reactive, defineAsyncComponent, onMounted } from 'vue';
import { useActions, useState } from '../../js/stores/hooks';
import { ROLE } from '../../js/config/enums.config';
import { useStore } from 'vuex';
import { MainMenuComposition } from '../../components/menus/compositions/main-menu.composition';
import { LEFT_PANEL_WIDTH, PANEL_BREAKPOINT } from '../../js/config/app.config';
import { checkAppVersion, F7 } from '../../js/helpers/f7.helpers';
import { f7 } from 'framework7-vue';
import { store } from '../../js/stores/vuex';
import { configuracionService } from '../../js/api/seguimiento/configuracion.service';

export default {
  components: {
    'page': Page,
  },
  setup: (props, context) => {
    const { username } = useState('session', ['username']);
    const sessionState = useStore().state.session;
    const { setPersonas } = useActions('app', ['setPersonas']);
    const mounted = () => defineAsyncComponent({
      loader: () => {
        if (store.getters['session/hasAnyRole']([ROLE.VENDOR.code, ROLE.REVISER.code])) return import (`./templates/vendor-home.vue`);
        if (store.getters['session/hasRole'](ROLE.CATALOG_REVISER.code)) return import (`./templates/catalog-reviser-home.vue`);
        if (store.getters['session/hasAnyRole']([ROLE.DISTRIBUTOR_VENDOR.code, ROLE.DISTRIBUTOR.code, ROLE.DISTRIBUTOR_CHIEFTEAM.code])) return import (`./templates/distributor-home.vue`);
        if (store.getters['session/hasAnyRole']([ROLE.ADMIN.code, ROLE.OFFICE.code])) return import (`./templates/admin-home.vue`);
        return import (`./templates/default-home.vue`);
      },
      delay: 200,
      timeout: 3000,
    });
    const state = reactive({ component: mounted() });
    const { state: mmState } = MainMenuComposition();
    const filterMenu = (searchbar, query, previousQuery) => {
      mmState.query = query;
    };
    watch(username, (username, prevUsername) => state.component = mounted());
    const checkVersion = async () => {
      await checkAppVersion();
      try {
        const { data: { configuracion } } = await configuracionService.getConfiguracion('PERSONAS');
        setPersonas(JSON.parse(configuracion.valor));
      } catch (e) {
        console.log(e);
      }
    };
    onMounted(() => {
      setTimeout(function () {
        const panel = f7.panel.get('left');
        panel.visibleBreakpoint = PANEL_BREAKPOINT;
        panel.enableVisibleBreakpoint();
        document.getElementsByClassName('view view-main')[0].style.marginLeft = window.innerWidth < PANEL_BREAKPOINT ? 0 : `${LEFT_PANEL_WIDTH}`;
      }, 250);
    });
    const { state: f7State } = F7();
    return { state, mmState, filterMenu, sessionState, f7State, checkVersion };
  }
}
</script>
