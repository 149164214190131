import { createStore } from 'vuex';
import { StoreApp, appStore } from './app/app.index';
import { StoreCatalog, catalogStore } from './catalog/catalog.index';
import { StoreClients, clientsStore } from './clients/clients.index';
import { StoreInput, inputStore } from './input/input.index';
import { pendingChargeStore, StorePendingCharge } from './pending-charge/pending-charge.index';
import { pendingOrderStore, StorePendingOrder } from './pending-order/pending-order.index';
import { pendingRefundStore, StorePendingRefund } from './pending-refund/pending-refund.index';
import { sessionStore, StoreSession } from './session/session.index';
import { shoppingCartStore, StoreShoppingCart } from './shopping-cart/shopping-cart.index';
import { StoreUserSettings, userSettingsStore } from './user-settings/user-settings.index';
import { billsStore, StoreBills } from './bills/bills.index';

export const store = createStore({
  modules: {
    [StoreApp.module]: appStore,
    [StoreCatalog.module]: catalogStore,
    [StoreClients.module]: clientsStore,
    [StoreInput.module]: inputStore,
    [StorePendingCharge.module]: pendingChargeStore,
    [StorePendingOrder.module]: pendingOrderStore,
    [StorePendingRefund.module]: pendingRefundStore,
    [StoreSession.module]: sessionStore,
    [StoreShoppingCart.module]: shoppingCartStore,
    [StoreUserSettings.module]: userSettingsStore,
    [StoreBills.module]: billsStore,
  },
  strict: true,
});
