<template>
  <div v-if="user">
    <f7-block-title>{{ $t('text.user') }}</f7-block-title>
    <f7-list>
      <f7-list-item :link="getLink()"
                    view=".view-main"
                    :disabled="isLoadingRoute"
                    :class="{'menu-active': currentRoute === '/profile/'}"
                    panel-close>
        <div slot="inner-start" class="display-flex flex-direction-row flex-1 align-items-center">
          <div class="margin-right-half"><f7-icon icon="las la-user" size="24"></f7-icon></div>
          <strong>{{ user.username }} (<span class="text-color-blue">{{ sessionInfo.selectedCode }}</span>)</strong>
        </div>
      </f7-list-item>
    </f7-list>
  </div>
</template>
<script>
import { useState } from '../../js/stores/hooks';
import { useStore } from 'vuex';
import { ROLE } from '../../js/config/enums.config';
import { hasRole } from '../../js/stores/session/session.getters';

export default {
  name: 'profile-button',
  setup: () => {
    const { user, sessionInfo } = useState('session', ['user', 'sessionInfo']);
    const { isLoadingRoute, currentRoute } = useState('app', ['isLoadingRoute', 'currentRoute']);
    const getLink = () => {
      if (hasRole(useStore().state.session, ROLE.CATALOG_REVISER.code)) return '/sell-channel-selection/';
      return '/vendor-selection/';
    };
    return { user, isLoadingRoute, currentRoute, sessionInfo, getLink };
  }
};
</script>
