import { apolloClient } from '@/js/plugins/apollo-client';
import {
  COPY_LINE_GQL, COPY_SELECTED_GQL,
  DELETE_LINE_GQL, DELETE_SELECTED_GQL,
  DUPLICATE_LINE_GQL, DUPLICATE_SELECTED_GQL,
  FIND_ORDER_GQL, BLOQUEADO_POR_RGPD_GQL, REORDER_LINES_GQL,
  UPDATE_SELECTED_GQL
} from '../queries/orders/orders.queries';

const findOrder = async ({ clientId, vendorId, sellChannel, status, language }) =>
  apolloClient.query({query: FIND_ORDER_GQL, variables: { clientId, vendorId, sellChannel, status, language }, fetchPolicy: 'network-only'});
const copyLine = async ({ order, id, language }) => apolloClient.mutate({ mutation: COPY_LINE_GQL, variables: { order, id, language}});
const duplicateLine = async ({ order, id, language }) => apolloClient.mutate({ mutation: DUPLICATE_LINE_GQL, variables: { order, id, language }});
const deleteLine = async ({ order, id, onlyHeader, prices, groupPrice, language }) =>
  apolloClient.mutate({ mutation: DELETE_LINE_GQL, variables: { order, id, onlyHeader, prices, groupPrice, language }});
const updateSelected = async ({ order, ids, newParams, language}) =>
  apolloClient.mutate({ mutation: UPDATE_SELECTED_GQL, variables: { order, ids, newParams, language}});
const copySelected = async ({ order, ids, language }) => apolloClient.mutate({ mutation: COPY_SELECTED_GQL, variables: { order, ids, language}});
const duplicateSelected = async ({ order, ids, language }) => apolloClient.mutate({ mutation: DUPLICATE_SELECTED_GQL, variables: { order, ids, language }});
const deleteSelected = async ({ order, ids, language }) =>
  apolloClient.mutate({ mutation: DELETE_SELECTED_GQL, variables: { order, ids, language }});
const reorder = async ({ order, copiedId, to, language }) =>
  apolloClient.mutate({ mutation: REORDER_LINES_GQL, variables: { order, copiedId, to, language }});
const bloqueadoPorRGPD = async ({ clientId, vendorId, company }) =>
  apolloClient.query({ query: BLOQUEADO_POR_RGPD_GQL, variables: { clientId, vendorId, company }, fetchPolicy: 'network-only'});

export const ordersService = {
  findOrder,
  copyLine,
  duplicateLine,
  deleteLine,
  updateSelected,
  copySelected,
  duplicateSelected,
  deleteSelected,
  reorder,
  bloqueadoPorRGPD,
};
