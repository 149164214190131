import { instance as axios } from '../../plugins/axios';

const findExtracts = (company, skip, take, searchParams, orderParams, tipo) =>
  axios.post('/extracts/find_extracts_v2', {company, skip, take, searchParams, orderParams, tipo });

const findBills = (type, company, skip, take, searchParams, orderParams, tipo) =>
  axios.post('/extracts/find_bills_v2', {type, company, skip, take, searchParams, orderParams, tipo });

const findRefunds = (client, company, fromDate, page, perPage, searchParams, orderParams, tipo ) =>
  axios.post('/extracts/find_refunds', {client, company, fromDate, page, perPage, searchParams, orderParams, tipo});

const findInvoice = (document, company, tipo) =>
  axios.post('/extracts/invoice', {document, company, tipo });

const findRefund = (document, company, tipo) =>
  axios.post('/extracts/refund', {document, company, tipo });

const dueDates = (document, company, tipo) => axios.post('/extracts/due_dates', {document, company, tipo });

const totalsInvoice = (document, company, tipo) => axios.post('/extracts/totals_invoice', {document, company, tipo });

const totalsRefund = (document, company, tipo) => axios.post('/extracts/totals_refund', {document, company, tipo });

const findGroupedBills = (document, company, tipo ) =>
  axios.post('/extracts/find_grouped_bills', {document, company, tipo });

const facturasOferta = (noOferta, company, tipo) => axios.post('/extracts/facturas_oferta', {noOferta, company, tipo });

export const extractsService = {
  findExtracts,
  findBills,
  findRefunds,
  findInvoice,
  findRefund,
  dueDates,
  totalsInvoice,
  totalsRefund,
  findGroupedBills,
  facturasOferta,
};
