import { companiesService as httpCompaniesService } from '../../api/http/companies.service';
import { companiesService } from '../../api/graphql/services/companies.service';

export const StoreApp = {
  module: 'app',
  actions: {
    setWidth: 'setWidth',
    setLoadingRoute: 'setLoadingRoute',
    setCurrentRoute: 'setCurrentRoute',
    setLoading: 'setLoading',
    getPayments: 'getPayments',
    setMessage: 'setMessage',
    getCompany: 'getCompany',
    setPersonas: 'setPersonas'
  },
  mutations: {
    setWidth: 'SET_WIDTH',
    setLoadingRoute: 'SET_LOADING_ROUTE',
    setCurrentRoute: 'SET_CURRENT_ROUTE',
    setLoading: 'SET_LOADING',
    setPayments: 'SET_PAYMENTS',
    setMessage: 'SET_MESSAGE',
    setCompany: 'SET_COMPANY',
    setSellChannels: 'SET_SELL_CHANNELS',
    setPersonas: 'SET_PERSONAS'
  }
};

const state = {
  width: window.innerWidth,
  isLoadingRoute: false,
  currentRoute: '',
  loading: false,
  paymentForms: [],
  paymentTerms: [],
  sellChannels: [],
  message: '',
  company: null,
  personas: [],
};

const getters = {
};

const actions = {
  [StoreApp.actions.setPersonas]: ({commit}, personas) => {
    commit(StoreApp.mutations.setPersonas, personas);
  },
  [StoreApp.actions.setWidth]: ({commit}, newWidth) => {
    commit(StoreApp.mutations.setWidth, newWidth);
  },
  [StoreApp.actions.setLoadingRoute]: ({commit}, isLoading) => {
    commit(StoreApp.mutations.setLoadingRoute, isLoading);
  },
  [StoreApp.actions.setCurrentRoute]: ({commit}, path) => {
    commit(StoreApp.mutations.setCurrentRoute, path);
  },
  [StoreApp.actions.setLoading]: ({commit}, isLoading) => {
    commit(StoreApp.mutations.setLoading, isLoading);
  },
  [StoreApp.actions.getPayments]: async ({commit, state}) => {
    const paymentForms = (await httpCompaniesService.getPaymentFormsGlobal()).data;
    const paymentTerms = (await httpCompaniesService.getPaymentTermsGlobal()).data;
    const { data: { sellChannels }} = await companiesService.getSellChannels();
    commit(StoreApp.mutations.setPayments, {paymentForms, paymentTerms});
    commit(StoreApp.mutations.setSellChannels, { sellChannels });
  },
  [StoreApp.actions.setMessage]: ({ commit }, message) => {
    commit(StoreApp.mutations.setMessage, message);
  },
  [StoreApp.actions.getCompany]: async ({ commit }, code) => {
    const { data: { companies }} = await companiesService.getCompany({ code });
    const company = companies.edges[0].node || null;
    // console.log(company);
    commit(StoreApp.mutations.setCompany, company);
  },
};

const mutations = {
  [StoreApp.mutations.setPersonas]: (state, personas) => state.personas = personas,
  [StoreApp.mutations.setWidth]: (state, newWidth) => state.width = newWidth,
  [StoreApp.mutations.setLoadingRoute]: (state, isLoading) => state.isLoadingRoute = isLoading,
  [StoreApp.mutations.setCurrentRoute]: (state, path) => state.currentRoute = path,
  [StoreApp.mutations.setLoading]: (state, isLoading) => state.loading = isLoading,
  [StoreApp.mutations.setPayments]: (state, { paymentForms, paymentTerms }) => {
    state.paymentForms = paymentForms;
    state.paymentTerms = paymentTerms;
  },
  [StoreApp.mutations.setSellChannels]: (state, { sellChannels }) => state.sellChannels = sellChannels,
  [StoreApp.mutations.setMessage]: (state, message) => state.message = message,
  [StoreApp.mutations.setCompany]: (state, company) => state.company = company,
};

export const appStore = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
