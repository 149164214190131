<template>
  <f7-popup class="global-search-popup">
    <f7-page>
      <f7-navbar :title="$t('popups.globalSearch')">
        <f7-nav-right>
          <f7-link popup-close>{{ $t('buttons.close') }}</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <f7-list inset form>
        <f7-list-input
          outline class="custom-input"
          :label="$t('inputs.searchClient.label')"
          type="text"
          :placeholder="$t('inputs.searchClient.placeholder')"
          :value="state.searchClient"
          @input="state.searchClient = $event.target.value"
        ></f7-list-input>
      </f7-list>
      <f7-block>
        <f7-button fill :disabled="state.searchClient === ''">{{$t('buttons.searchClient')}}</f7-button>
      </f7-block>
      <f7-list inset form>
        <f7-list-input
          outline class="custom-input"
          :label="$t('inputs.searchProductInBills.label')"
          type="text"
          :placeholder="$t('inputs.searchProductInBills.placeholder')"
          :value="state.searchProduct"
          @input="state.searchProduct = $event.target.value"
        ></f7-list-input>
      </f7-list>
      <f7-block>
        <f7-button fill :disabled="state.searchProduct === ''">{{$t('buttons.searchProductInBills')}}</f7-button>
      </f7-block>
    </f7-page>
  </f7-popup>
</template>
<script>
import { reactive } from 'vue';

export default {
  name: 'global-search-popup',
  setup: () => {
    const state = reactive({
      searchClient: '',
      searchProduct: '',
    });
    return { state };
  },
};
</script>
