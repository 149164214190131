import * as _ from 'lodash';
import { Plugins } from '@capacitor/core';
import { categoriesService } from '../../api/graphql/services/categories.service';
import { productsService } from '../../api/graphql/services/products.service';
import { toggleArrayItem } from '../../helpers/math.helpers';
import { productsService as httpProductsService } from '../../api/http/products.service';

const { Storage } = Plugins;

export const FILTER_TYPE = {
  LINE: 'line',
  PROPERTY: 'property',
  ATTRIBUTE: 'attribute',
};

export const StoreCatalog = {
  module: 'catalog',
  actions: {
    setPath: 'setPath',
    getCurrentPath: 'getCurrentPath',
    getCustomResults: 'getCustomResults',
    pushPath: 'pushPath',
    popPath: 'popPath',
    clearPath: 'clearPath',
    getPathNames: 'getPathNames',
    changeProductsPage: 'changeProductsPage',
    changeActiveFilters: 'changeActiveFilters',
    clearActiveFilters: 'clearActiveFilters',
    setFavView: 'setFavView',
    getFavorites: 'getFavorites',
    getProduct: 'getProduct',
    setInfoPopupOpen: 'setInfoPopupOpen',
    setCurrentSort: 'setCurrentSort',
  },
  mutations: {
    setLoading: 'SET_LOADING',
    setSearchQuery: 'SET_SEARCH_QUERY',
    setCurrentPath: 'SET_CURRENT_PATH',
    setPath: 'SET_PATH',
    addPath: 'ADD_PATH',
    removePath: 'REMOVE_PATH',
    setPathNames: 'SET_PATH_NAMES',
    setNewPage: 'SET_NEW_PAGE',
    toggleActiveFilters: 'TOGGLE_ACTIVE_FILTER',
    setActiveFilters: 'SET_ACTIVE_FILTERS',
    clearActiveFilters: 'CLEAR_ACTIVE_FILTERS',
    setFavView: 'SET_FAV_VIEW',
    addFavProduct: 'ADD_FAVORITE_PRODUCT',
    removeFavProduct: 'REMOVE_FAVORITE_PRODUCT',
    setFavProducts: 'SET_FAV_PRODUCTS',
    setInfoPopupOpen: 'SET_INFO_POPUP_OPEN',
    setCurrentSort: 'SET_CURRENT_SORT',
  }
};

const state = {
  loading: false,
  searchQuery: '',
  path: [0],
  pathNames: {},
  categories: [],
  lineCategory: null,
  products: [],
  productsCount: 0,
  productsPageInfo: {
    isCursor: false,
    page: 1,
    perPage: 16,
  },
  filters: {
    lines: [],
    properties: {},
    linesCount: [],
    attributesCount: {},
  },
  activeFilters: {
    lineIds: [],
    attributeIds: {},
  },
  lastFilter: {
    filterType: '',
    filterId: null,
  },
  suggestions: [],
  favView: false,
  favProducts: [],
  product: null,
  productInfo: null,
  infoPopupOpen: false,
  currentSort: 'relevance',
  sortMenu: [
    { code: 'relevance'   , title: 'Relevancia'    , sortMongo: {}, sort: {} },
    { code: 'more-visit'  , title: 'Más visitados' , sortMongo: { viewed: -1 }, sort: { viewed: { order: 'desc' } } },
    { code: 'news'        , title: 'Novedades'     , sortMongo: { createdAt: -1 }, sort: { createdAt: { order: 'desc' } } },
  ],
  promoProduct: '',
};

const currentPath = (state) => state.path[state.path.length - 1];
const isActiveLine = (state, lineId) => state.activeFilters.lineIds.includes(lineId);
const isActiveAttribute = (state, {propertyId, attributeId}) => state.activeFilters.attributeIds[propertyId] && state.activeFilters.attributeIds[propertyId].includes(attributeId);
const getKeepFilter = (state) => {
  if (state.lastFilter.filterType === FILTER_TYPE.LINE) return [...state.filters.lines];
  else if (state.lastFilter.filterType === FILTER_TYPE.PROPERTY) return state.filters.properties[state.lastFilter.filterId];
};
const currentSortObj = (state) => state.sortMenu.find(s => s.code === state.currentSort);

const getters = {
  currentPath: (state) => currentPath(state),
  page: (state) => state.productsPageInfo.page,
  perPage: (state) => state.productsPageInfo.perPage,
  isActiveLine: (state) => (lineId) => isActiveLine(state, lineId),
  isActiveAttribute: (state) => ({propertyId, attributeId}) => isActiveAttribute(state, {propertyId, attributeId}),
  currentSortObj: (state) => currentSortObj(state),
};

const actions = {
  setPromoProduct: ({ commit }, optionSku) => commit('SET_PROMO_PRODUCT', optionSku),
  [StoreCatalog.actions.clearPath]: async ({ commit, state }) => {
    const path = [0];
    commit(StoreCatalog.mutations.setPath, path);
    await Storage.set({ key: 'path', value: JSON.stringify(state.path)});
  },
  [StoreCatalog.actions.setPath]: ({ commit, state }, path) => {
    commit(StoreCatalog.mutations.setPath, path);
  },
  [StoreCatalog.actions.getCurrentPath]: async ({ commit, dispatch, state, rootState }, { searchQuery, clearFilters = true }) => {
    commit(StoreCatalog.mutations.setLoading, true);
    if (searchQuery !== '') {
      await dispatch(StoreCatalog.actions.getCustomResults, { searchQuery, clearFilters });
    } else {
      commit(StoreCatalog.mutations.setSearchQuery, searchQuery);
      // try {
        const parentId = currentPath(state);
        const company = rootState.session.sessionInfo.company;
        const sellChannel = rootState.session.sessionInfo.sellChannel;
        const groupPrice = rootState.session.sessionInfo.groupPrice;
        const language = rootState.userSettings.language;
        let sellChannelLastPart = sellChannel.split('_');
        sellChannelLastPart = sellChannelLastPart[sellChannelLastPart.length - 1];
        commit(StoreCatalog.mutations.setNewPage, 1);
        commit(StoreCatalog.mutations.clearActiveFilters);
        const { data } = await categoriesService.getCurrentPath({
          parentId,
          company: rootState.session.sessionInfo.catalogos?.length > 0 ? rootState.session.sessionInfo.catalogos.map((c) => `${c}_2016`) : [company],
          sellChannels: rootState.session.sessionInfo.catalogos?.length > 0 ? rootState.session.sessionInfo.catalogos.map((c) => `${c}_${sellChannelLastPart}`) : [sellChannel],
          sellChannel,
          language,
          groupPrice, depth: state.path.length, lineIds: [], attributeIds: []});
        const { result, count: productsCount, lines, attributes, properties } = data.products;
        let lineCategory = null;
        let categories = [...data.categories];
        let products = [...result];
        // console.log('LINEAS');
        // console.log(lines);
        const filters = {
          lines: lines.map(l => ({...l})),
          linesCount: lines.reduce((o, v) => ({...o, [v.lineIds.join('-')]: v.count }), {}),
          properties: properties.reduce((o, p) => {
            const property = {...p};
            property.attributes = attributes.filter(a => a.propertyId === p.propertyId);
            o[property.propertyId] = property;
            return o;
          }, {}),
          attributesCount: properties.reduce((o, p) => {
            const attrs = attributes.filter(a => a.propertyId === p.propertyId);
            o[p.propertyId] = attrs.reduce((o2, a) => {
              o2[a.attributeId] = a.count;
              return o2;
            }, {});
            return o;
          }, {}),
        };
        const index = categories.findIndex(c => c.es.name === 'Líneas');
        if (index !== -1) {
          lineCategory = categories[index];
          categories.splice(index, 1);
        }
        commit(StoreCatalog.mutations.setCurrentPath, { categories, lineCategory, products, productsCount, filters, clearFilters: true });
      // } catch (error) {
      //   console.log(error);
      // }
    }
    commit(StoreCatalog.mutations.setLoading, false);
  },
  [StoreCatalog.actions.getCustomResults]: async ({ commit, rootState, state }, { searchQuery, newPage = 1, clearFilters = true }) => {
    commit(StoreCatalog.mutations.setLoading, true);
    commit(StoreCatalog.mutations.setSearchQuery, searchQuery);
    const company = rootState.session.sessionInfo.company;
    const sellChannel = rootState.session.sessionInfo.sellChannel;
    const groupPrice = rootState.session.sessionInfo.groupPrice;
    const language = rootState.userSettings.language;
    let sellChannelLastPart = sellChannel.split('_');
    sellChannelLastPart = sellChannelLastPart[sellChannelLastPart.length - 1];
    commit(StoreCatalog.mutations.setNewPage, newPage);
    if (clearFilters) commit(StoreCatalog.mutations.clearActiveFilters);
    // console.log(currentSortObj(state));
    const { data } = await productsService.searchProducts({
      search: searchQuery,
      company: rootState.session.sessionInfo.catalogos?.length > 0 ? rootState.session.sessionInfo.catalogos.map((c) => `${c}_2016`) : [company],
      sellChannels: rootState.session.sessionInfo.catalogos?.length > 0 ? rootState.session.sessionInfo.catalogos.map((c) => `${c}_${sellChannelLastPart}`) : [sellChannel],
      sellChannel,
      language,
      groupPrice,
      offset: ((newPage - 1) * state.productsPageInfo.perPage), limit: state.productsPageInfo.perPage,
      lineIds: state.activeFilters.lineIds, attributeIds: Object.keys(state.activeFilters.attributeIds).reduce((a, v) => {
        a.push(state.activeFilters.attributeIds[v]);
        return a;
      }, []), order: currentSortObj(state).sort});
    const { result, count: productsCount, lines, attributes, properties, suggestions } = data.products;
    const products = [...result];
    const filters = {
      lines: lines.map(l => ({...l})),
      linesCount: lines.reduce((o, v) => ({...o, [v.lineIds.join('-')]: v.count }), {}),
      properties: properties.reduce((o, p) => {
        const property = {...p};
        property.attributes = attributes.filter(a => a.propertyId === p.propertyId);
        o[property.propertyId] = property;
        return o;
      }, {}),
      attributesCount: properties.reduce((o, p) => {
        const attrs = attributes.filter(a => a.propertyId === p.propertyId);
        o[p.propertyId] = attrs.reduce((o2, a) => {
          o2[a.attributeId] = a.count;
          return o2;
        }, {});
        return o;
      }, {}),
    };
    commit(StoreCatalog.mutations.setCurrentPath, { products, productsCount, filters, clearFilters, suggestions });
    commit(StoreCatalog.mutations.setLoading, false);
  },
  [StoreCatalog.actions.getProduct]: async ({ commit, rootState }, { sku, optionSku, language, groupPrice }) => {
    const sellChannel = rootState.session.sessionInfo.sellChannel;
    const product = (await productsService.getProduct({ sku, optionSku, language, groupPrice, sellChannel })).data.product;
    const productInfo = (await httpProductsService.productInfo(sku, language, sellChannel)).data;
    commit(StoreCatalog.mutations.setCurrentPath, { product, productInfo });
  },
  [StoreCatalog.actions.pushPath]: async ({ commit, dispatch, state }, { categoryId, name }) => {
    commit(StoreCatalog.mutations.addPath, { categoryId, name });
    dispatch(StoreCatalog.actions.getCurrentPath, { searchQuery: '' });
    await Storage.set({ key: 'path', value: JSON.stringify(state.path)});
  },
  [StoreCatalog.actions.popPath]: async ({ commit, dispatch, state }, index ) => {
    commit(StoreCatalog.mutations.removePath, index);
    dispatch(StoreCatalog.actions.getCurrentPath, { searchQuery: '' });
    await Storage.set({ key: 'path', value: JSON.stringify(state.path)});
  },
  [StoreCatalog.actions.getPathNames]: async ({ commit, rootState }) => {
    const language = rootState.userSettings.language;
    const { data: { pathNames: { path } }} = await categoriesService.getPathNames({ path: state.path, language });
    commit(StoreCatalog.mutations.setPathNames, path);
  },
  [StoreCatalog.actions.changeProductsPage]: async ({ commit, rootState, state }, newPage) => {
    try {
      commit(StoreCatalog.mutations.setLoading, true);
      commit(StoreCatalog.mutations.setNewPage, newPage);
      const parentId = currentPath(state);
      const company = rootState.session.sessionInfo.company;
      const sellChannel = rootState.session.sessionInfo.sellChannel;
      const groupPrice = rootState.session.sessionInfo.groupPrice;
      const language = rootState.userSettings.language;
      let sellChannelLastPart = sellChannel.split('_');
      sellChannelLastPart = sellChannelLastPart[sellChannelLastPart.length - 1];
      const { data } = await productsService.productsByCategoryPg({
        parentId,
        company: rootState.session.sessionInfo.catalogos?.length > 0 ? rootState.session.sessionInfo.catalogos.map((c) => `${c}_2016`) : [company],
        sellChannel, language,
        sellChannels: rootState.session.sessionInfo.catalogos?.length > 0 ? rootState.session.sessionInfo.catalogos.map((c) => `${c}_${sellChannelLastPart}`) : [sellChannel],
        groupPrice, depth: state.path.length, offset: ((newPage - 1) * state.productsPageInfo.perPage), limit: state.productsPageInfo.perPage,
        lineIds: state.activeFilters.lineIds, attributeIds: Object.keys(state.activeFilters.attributeIds).reduce((a, v) => {
          a.push(state.activeFilters.attributeIds[v]);
          return a;
        }, []), order: currentSortObj(state).sortMongo });
      const { result, count: productsCount, lines, attributes, properties } = data.products;
      let products = [...result];
      const keepFilter = getKeepFilter(state);
      let filters = {
        lines: lines.map(l => ({...l})),
        linesCount: lines.reduce((o, v) => ({...o, [v.lineIds.join('-')]: v.count }), {}),
        properties: properties.reduce((o, p) => {
          const property = {...p};
          property.attributes = attributes.filter(a => a.propertyId === p.propertyId);
          o[property.propertyId] = property;
          return o;
        }, {}),
        attributesCount: properties.reduce((o, p) => {
          const attrs = attributes.filter(a => a.propertyId === p.propertyId);
          o[p.propertyId] = attrs.reduce((o2, a) => {
            o2[a.attributeId] = a.count;
            return o2;
          }, {});
          return o;
        }, {}),
      };
      if (state.lastFilter.filterType === FILTER_TYPE.LINE) {
        // console.log('KEEP FILTER');
        filters.lines = keepFilter;
      }
      else if (state.lastFilter.filterType === FILTER_TYPE.PROPERTY) filters.properties[state.lastFilter.filterId] = keepFilter;
      for (const af of state.activeFilters.lineIds) {
        const line = filters.lines.find(i => i.lineIds.includes(af));
        if (!line) filters.lines.push(state.filters.lines.find(i => i.lineIds.includes(af)));
      }
      for (const pf of Object.keys(state.activeFilters.attributeIds)) {
        // const p = filters.properties.find(i => i.attributes.)
      }
      commit(StoreCatalog.mutations.setCurrentPath, { products, productsCount, filters, clearFilters: false });
      commit(StoreCatalog.mutations.setLoading, false);
    } catch (error) {
      // console.log(error);
    }
  },
  [StoreCatalog.actions.changeActiveFilters]: async ({ commit, state, dispatch }, { filterType, filterId }) => {
    commit(StoreCatalog.mutations.toggleActiveFilters, { filterType, filterId });
    if (state.searchQuery === '')
      await dispatch(StoreCatalog.actions.changeProductsPage, 1);
    else
      await dispatch(StoreCatalog.actions.getCustomResults, { searchQuery: state.searchQuery, newPage: 1, clearFilters: false });
  },
  [StoreCatalog.actions.clearActiveFilters]: async ({ commit, dispatch }) => {
    commit(StoreCatalog.mutations.clearActiveFilters);
    if (state.searchQuery === '')
      await dispatch(StoreCatalog.actions.changeProductsPage, 1);
    else
      await dispatch(StoreCatalog.actions.getCustomResults, { searchQuery: state.searchQuery, newPage: 1 });
  },
  [StoreCatalog.actions.setFavView]: ({ commit }, favView) => {
    commit(StoreCatalog.mutations.setFavView, favView);
  },
  [StoreCatalog.actions.getFavorites]: async ({commit, rootState}) => {
    try {
      const language = rootState.userSettings.language;
      const company = rootState.session.sessionInfo.company;
      const sellChannel = rootState.session.sessionInfo.sellChannel;
      const groupPrice = rootState.session.sessionInfo.groupPrice;
      let sellChannelLastPart = sellChannel.split('_');
      sellChannelLastPart = sellChannelLastPart[sellChannelLastPart.length - 1];
      const { favProducts } = (await productsService.favProducts(
          {
            sellChannel, language, groupPrice,
            company: rootState.session.sessionInfo.catalogos?.length > 0 ? rootState.session.sessionInfo.catalogos.map((c) => `${c}_2016`) : [company],
            sellChannels: rootState.session.sessionInfo.catalogos?.length > 0 ? rootState.session.sessionInfo.catalogos.map((c) => `${c}_${sellChannelLastPart}`) : [sellChannel],
          })).data;
      commit(StoreCatalog.mutations.setFavProducts, favProducts);
    } catch (e) {}
  },
  [StoreCatalog.actions.setInfoPopupOpen]: ({ commit }, open) => commit(StoreCatalog.mutations.setInfoPopupOpen, open),
  [StoreCatalog.actions.setCurrentSort]: ({ commit }, newCode) => commit(StoreCatalog.mutations.setCurrentSort, newCode),
};

const mutations = {
  ['SET_PROMO_PRODUCT']: (state, optionSku) => state.promoProduct = optionSku,
  [StoreCatalog.mutations.setLoading]: (state, loading) => state.loading = loading,
  [StoreCatalog.mutations.setSearchQuery]: (state, searchQuery) => state.searchQuery = searchQuery,
  [StoreCatalog.mutations.setCurrentPath]: (state, { categories, lineCategory, products, productsCount, filters, clearFilters, suggestions, product, productInfo }) => {
    if (categories) state.categories = categories;
    if (typeof lineCategory !== 'undefined') state.lineCategory = lineCategory;
    if (products) {
      state.products = products;
      state.productsCount = productsCount;
    }
    if (suggestions) state.suggestions = suggestions;
    if (filters) {
      if (clearFilters) state.filters = { ...state.filters, ...filters };
      else {
        // console.log('FILTROS');
        // console.log(filters);
        const hasAttrFilters = Object.keys(state.activeFilters.attributeIds).length > 0;
        if (!hasAttrFilters || state.lastFilter.filterId !== null) {
          for (const line of state.filters.lines) {
            const l = filters.lines.find(o => o.lineIds.some(i => line.lineIds.includes(i)) );
            if (l && filters.linesCount[`${l.lineIds.join('-')}`]) {
              // console.log(l);
              state.filters.linesCount[`${l.lineIds.join('-')}`] = l.count; // (hasAttrFilters ? 0 : l.count);
            } else {
              state.filters.linesCount[`${line.lineIds.join('-')}`] = (hasAttrFilters ? 0 : line.count);
            }
          }
        }
        // console.log(state.filters);
        let savedProperties = {};
        if (state.lastFilter.filterId !== null) {
          savedProperties = {[`${state.lastFilter.filterId}`]: state.filters.attributesCount[`${state.lastFilter.filterId}`]};
        }
        state.filters.attributesCount = { ...savedProperties };
        for (const k of Object.keys(filters.properties)) {
          const p = filters.properties[k];
          if (state.lastFilter.filterType === FILTER_TYPE.PROPERTY && state.lastFilter.filterId === p.propertyId) {
          } else {
            const temp = state.filters.attributesCount[`${p.propertyId}`];
            if (!temp) state.filters.attributesCount[`${p.propertyId}`] = {};
            for (const attribute of filters.properties[k].attributes) {
              // console.log(attribute);
              // console.log(state.filters.attributesCount[`${attribute.propertyId}`]);
              state.filters.attributesCount[`${attribute.propertyId}`][`${attribute.attributeId}`] = attribute.count;
            }
            // const l = filters.lines.find(o => o.lineIds.some(i => line.lineIds.includes(i)) );
            // console.log(line);
            // if (l) {
            //   state.filters.linesCount[index] = l.count;
            // } else state.filters.linesCount[index] = 0;
          }
        }
      }
    }
    if (product) {
      state.product = { ...product };
      if (product.optionList) {
        state.product.optionList = { ...product.optionList, options: [] };
        const pdts = product.products.map(o => o.optionSku);
        state.product.optionList.options = product.optionList.options.filter(f => pdts.includes(f.optionSku));
      }
    }
    if (productInfo) state.productInfo = productInfo;
  },
  [StoreCatalog.mutations.setPath]: (state, path) => state.path = path,
  [StoreCatalog.mutations.addPath]: (state, { categoryId, name }) => {
    state.path.push(categoryId);
    state.pathNames[categoryId] = { name };
  },
  [StoreCatalog.mutations.removePath]: (state, index) => {
    const categoryIds = state.path.splice(index + 1);
    categoryIds.forEach(c => delete state.pathNames[c]);
  },
  [StoreCatalog.mutations.setPathNames]: (state, pathNames) => Object.assign(state.pathNames, pathNames),
  [StoreCatalog.mutations.setNewPage]: (state, newPage) => state.productsPageInfo.page = newPage,
  [StoreCatalog.mutations.toggleActiveFilters]: (state, { filterType, filterId }) => {
    if (filterType === FILTER_TYPE.LINE) {
      const ol = state.activeFilters.lineIds.length;
      state.activeFilters.lineIds = _.xor(state.activeFilters.lineIds, filterId);
      state.lastFilter = { filterType: (ol - state.activeFilters.lineIds.length >= 0) ? '' : FILTER_TYPE.LINE, filterId: null };
    } else if (filterType === FILTER_TYPE.ATTRIBUTE) {
      const filter = state.activeFilters.attributeIds[filterId.propertyId];
      if (!filter) {
        state.activeFilters.attributeIds[filterId.propertyId] = [filterId.attributeId];
      } else {
        toggleArrayItem(filter, filterId.attributeId);
      }
      if (!state.activeFilters.attributeIds[filterId.propertyId].length) {
        delete state.activeFilters.attributeIds[filterId.propertyId];
        state.lastFilter = { filterType: '', filterId: filterId.propertyId};
      } else {
        state.lastFilter = { filterType: FILTER_TYPE.PROPERTY, filterId: filterId.propertyId};
      }
      // state.lastFilter = { filterType: FILTER_TYPE.PROPERTY, filterId: filterId.propertyId};
    }
  },
  [StoreCatalog.mutations.clearActiveFilters]: (state) => state.activeFilters = { lineIds: [], attributeIds: {} },
  [StoreCatalog.mutations.setActiveFilters]: (state, newFilters) => state.activeFilters = {...state.activeFilters, ...newFilters},
  [StoreCatalog.mutations.setFavView]: (state, favView) => state.favView = favView,
  [StoreCatalog.mutations.addFavProduct]: (state, product) => {
    state.favProducts.push(product);
  },
  [StoreCatalog.mutations.removeFavProduct]: (state, { code, sku, optionSku }) => {
    const index = state.favProducts.findIndex(p => p.code === code);
    if (index > -1) {
      state.favProducts.splice(index, 1);
    }
  },
  [StoreCatalog.mutations.setFavProducts]: (state, favProducts) => state.favProducts = [...favProducts],
  [StoreCatalog.mutations.setInfoPopupOpen]: (state, open) => state.infoPopupOpen = open,
  [StoreCatalog.mutations.setCurrentSort]: (state, newCode) => state.currentSort = newCode,
};

export const catalogStore = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
