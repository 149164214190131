export const es = {
  contratoAparatologia: 'CONTRATO APARATOLOGÍA',
  WARNING_CONTRAREEM: 'No se pueden realizar contrareembolsos superiores a 1000€',

  alert: 'Alerta',
  abono_contrareembolso_no_permitido: 'No puedes casar un abono con el pedido si la forma de pago no es giro o contado.',
  lunes: 'Lunes',
  martes: 'Martes',
  miercoles: 'Miércoles',
  jueves: 'Jueves',
  viernes: 'Viernes',
  sabado: 'Sábado',
  domingo: 'Domingo',
  no_cierra: 'No cierra',

  nuevo_partner_hcc: 'Nuevo Partner HCC',
  inscribir_como_partner_hcc: 'Inscribir como Partner HCC',

  otro: 'otro',
  telefono: 'telefono',
  atencion_rgpd: 'ATENCIÓN, AUN FALTA RGPD',
  atencion_rgpd_info: 'Recuerda que el cliente se bloqueará en 3 meses desde la fecha de la primera venta para\n' +
    '                  clientes posteriores al [Fecha sin definir] y para clientes antiguos 3 meses a partir del [Fecha sin definir]',

  descatalogado_hasta_fin: 'Descatalogado hasta fin de existencias',

  enviar_rgpd_a_email: 'Se enviará el consetimiento de datos al email',
  rgpd_necesita_validar_email: 'Para poder mandar el RGPD por email primero el cliente ha tenido que validar su email.',
  clientes_sin_validar: 'Clientes sin validar',
  rgpd_validado: 'RGPD Validado',
  mail_validado: 'Email Validado',
  formato_factura: 'Formato factura',
  sin_confirmar: 'Sin confirmar',
  factura_electronica: 'Fact. electrónica',
  factura_papel: 'Fact. papel',
  enviar_rgpd: 'Enviar RGPD',
  enviar_rgpd_info: 'Enviar RGPD al cliente ',
  confirmar_envio_rgpd_SMS: 'Se va a enviar un mensaje para validar el RGPD al teléfono ',
  rgpd_verificacion_enviado_SMS: 'Se ha enviado un mensaje al teléfono ',
  confirmar_envio_rgpd_EMAIL: 'Se va a enviar un email para validar el RGPD al correo ',
  rgpd_verificacion_enviado_EMAIL: 'Se ha enviado un email al correo ',
  no_cliente_seleccionado: 'No hay ningún cliente seleccionado',
  telefonos_disponibles: 'Teléfonos disponibles',
  confirmar_envio_email: 'Vas a enviar una validación de email al correo ',
  confirmacion: 'Confirmación',
  productos_similares: 'Productos similares',
  cambiar_email: 'Cambiar email',

  cif_vacio_oferta: 'Como el cliente no tiene CIF tienes que introducir un comentario obligatoriamente, si te lo sabes ponlo en los comentarios y si no escribe algo',
  revisado_y_sin_info: 'Orden rev. y sin info',
  ps_nuevo: '¡NUEVO!',
  ps_pend_servir_texto_card: 'Haz click aqui para revisar tu pendiente de servir',
  real: 'Real',
  aprox: 'Aprox',
  llegada: 'Llegada',
  hace_x_minutos: 'Hace {x} minutos',
  ver_por_producto: 'Ver por producto',
  ps_info: 'Las fechas de disponibilidad son orientativas. Si necesitáis más información de los productos que no están sirviendose y no tienen fecha de disponibilidad consultar con administración.',
  ps_productos_envasandose: 'Productos de tu pendiente de servir envasándose',
  ps_no_productos_envasandose: 'No hay productos envasándose de tu pendiente de servir',
  productos_pendientes_servir: 'Productos pendientes de servir',
  sirviendose: 'Sirviendose',
  enRevision: 'En revisión',
  ps_clientes_envasando: 'Los siguientes clientes tienen producto que se está envasando.',
  disponible: 'Disponible',

  pedidos: 'Pedidos',
  nuevo_email: 'Nuevo email',
  email_actual_es: 'El email actual del cliente es {email} ¿es correcto?:',
  factura_a_email: 'ATENCIÓN: El cliente tiene un correo validado, y su factura electrónica se enviará a {email}:',
  datos_correctos: '¿Son correctos estos datos?',
  cliente_no_encontrado: 'No encontrado',
  cliente_click_cambiar: 'Parece que el cliente que tenías seleccionado ha desaparecido, haz click aqui para cambiar a otro cliente',
  email_validado: 'El email del cliente está validado',
  email_no_validado: 'El email del cliente no está validado, no podrá recibir facturas electrónicas',
  validar_email: 'Validar email',
  leyenda: 'Leyenda',
  oferta_pendiente: 'Oferta pendiente',
  exito: 'Éxito',
  contrasena_cambiada: 'La contraseña se ha cambiado correctamente',
  hace_x_horas: 'Hace {x} horas',
  hace_x_dias: 'Hace {x} dias',
  no_pendiente_servir: 'No hay pendiente de servir',
  codigo_cliente: 'Código cliente',
  nombre_cliente: 'Nombre cliente',
  ascendente: 'Ascendente',
  descendente: 'Descendente',
  fecha: 'Fecha',
  num_expedicion: 'Nº expedición',
  num_documento: 'Nº documento',
  no_expediciones_2_semanas: 'No hay expediciones de menos de 2 semanas',
  no_facturas_anteriores: 'No se han encontrado facturas anteriores',
  no_abonos_anteriores: 'No se han encontrado abonos anteriores',
  num_facturas: 'Nº factura',
  num_vendedor: 'Nº vendedor',
  fecha_factura: 'Fecha factura',
  num_cliente: 'Nº cliente',
  forma_pago: 'Forma pago',
  facturar_a: 'Facturar a',
  fecha_pedido: 'Fecha pedido',
  direccion_envio: 'Dirección envío',
  num_pedido: 'Nº pedido',
  base: 'Base',
  importe_iva: 'Importe IVA',
  total_iva_inc: 'Total IVA incluido',
  importe_base: 'Importe base',
  porc_iva: '% IVA',
  porc_re: '% RE',
  importe_re: 'Importe RE',
  dto_factura_pagos: 'Dto. Factura y pagos',
  no_facturas_mes: 'No se han encontrado últimas facturas de este mes',
  no_facturas_pendientes: 'No hay facturas pendientes de ningún cliente',
  seleccionar_mes: 'Seleccionar mes',
  seleccionar_ano: 'Seleccionar año',
  pago: 'Pago',
  factura: 'Factura',
  impago: 'Impago',
  total_pendiente: 'Total pendiente',
  cobros_pendientes: 'Cobros pendientes',
  sin_explicar_comentario: 'Sin explicar, por favor manda un comentario',
  importe_pendiente: 'Importe pendiente',
  saldo_acumulado: 'Saldo acumulado',
  documentos_pendientes: 'Docs. pendientes',
  tipo_doc: 'Tipo doc',
  importe_original: 'Importe original',
  num_contrato: 'Nº contrato',
  tipo_contrato: 'Tipo contrato',
  importe_contrareembolso: 'Importe contrareembolso',
  anadir_comentario: 'Añadir comentario',
  cerrar: 'Cerrar',
  introduce_comentario: 'Introduce un comentario',
  aun_no_comentarios: 'Aún no hay comentarios',
  client_no_cuenta: 'El cliente no tiene ningún nº de cuenta',
  actualizar_email: 'Actualizar email',
  atencion_factura_electronica: 'ATENCIÓN: A partir de ahora se enviará una factura electrónica, tienes que comprobar que el email del cliente es correcto, y si no, enviar el nuevo email',
  cliente_no_email_asociado: 'El cliente no tiene ningún email asociado ¿Es correcto?',
  email_cliente_incorrecto: 'El email del cliente NO ES CORRECTO',
  email_cliente_correcto: 'El email del cliente es correcto',
  introduce_nuevo_email: 'Introduce el nuevo email',
  email_no_valido: 'El email no es un email válido',
  email_verificacion_enviado: 'Se ha mandado un mensaje de verificación al correo proporcionado',
  cancelar: 'Cancelar',
  no_puedes_girar: 'No puedes girarle a este cliente porque no tiene una cuenta bancaria asociada. Por favor, contacta con administración para añadirle una cuenta bancaria y vuélvelo a intentar. Recuerda que la aplicación se actualiza por las noches',
  informacion: 'Información',
  eliminar_promocion: 'Eliminar promoción',
  que_quieres_hacer: '¿Qué quieres hacer?',
  eliminar_todo: 'Eliminar todo',
  eliminar_solo_cabecera: 'Eliminar solo cabecera',
  eliminar_cabecera_poner_precios: 'Eliminar cabecera y poner precios base',
  buscar_direccion: 'Buscar dirección',
  volver_hacer_pedido: 'Volver a hacer pedido',
  no_giros_mostrar: 'No hay giros que mostrar',
  son_correctos_datos: '¿Son correctos estos datos?',
  puedes_cambiar_mes_ano: 'Puedes cambiar mes y año',
  obtener_facturas: 'Obtener facturas',
  mes: 'Mes',
  ano: 'Año',
  desde_el: 'Desde el',
  hasta_el: 'Hasta el',
  total_ventas: 'Total ventas',
  total_impagos: 'Total impagos',
  total_cobros_impagos: 'Total cobros impagos',
  buscar_en_documentos: 'Buscar en los documentos',
  ventas_abonos_anulaciones: 'Ventas-Abonos-Anulaciones',
  impagos: 'Impagos',
  cobros_impagos: 'Cobros impagos',

  newClient: {
    cifError: 'Tienes que rellenar el campo NIF/CIF',
    nameError: 'Tienes que rellenar el campo Nombre',
    addressError: 'Tienes que rellenar el campo Dirección',
    stateError: 'Tienes que rellenar el campo Población',
    cityError: 'Tienes que rellenar el campo Provincia',
    cpError: 'Tienes que rellenar el campo Código Postal',
    contactError: 'Tienes que rellenar el campo Pers. Contacto',
    closeDaysError: 'Tienes que rellenar el campo Días cierre',
    emailError: 'Tienes que rellenar el campo Email',
    phoneError: 'Tienes que rellenar el campo Teléfono o el campo Móvil',
    infoFill: 'Vas a pasar los datos un pedido para un Cliente Nuevo, ahora tienes que rellenar los datos.' +
      'Pulsa en el siguiente botón para rellenarlos.',
    fillData: 'Rellenar datos cliente',
  },

  errores: 'Fautes',
  aceptar: 'Aceptar',
  enviar: 'Enviar',
  actualizarPagina: 'Actualizar Página',
  topClientes: 'Top Clientes',
  actualizacion: 'Actualizacion',
  vamosAActualizar: 'Vamos a actualizar',
  relevancia: 'Relevancia',
  masVisitados: 'Más visitados',
  novedades: 'Novedades',
  TAHE_FRANCIA: 'Tahe EU',
  TAHE_ALEMANIA: 'Tahe EU',
  informacionYMetodos: 'Información y métodos de pago',
  resumenPedido: 'Resumen del pedido',
  cliente: 'Cliente',
  fechaDeOferta: 'Fecha de la oferta',
  empresaYCanal: 'Empresa y canal de venta',
  direccionEnvio: 'Dirección de envío',

  proximosCursos: 'Próximos Cursos',
  seleccionCursos: 'Selección de Cursos',
  'Relevancia': 'Relevancia',
  'Más visitados': 'Más visitados',
  'Novedades': 'Novedades',
  reporteDescendente: 'Rapporto (Discendente)',
  reporteAscendente: 'Rapporto (Crescente)',
  fechaDescendente: 'Data (Discendente)',
  fechaAscendente: 'Data (Crescente)',
  codigoClienteDescendente: 'Codice Cliente (Discendente)',
  codigoClienteAscendente: 'Codice Cliente (Crescente)',
  importeAscendente: 'Importo (Crescente)',
  importeDescendente: 'Importo (Discendente)',
  noDocumentoAscendente: 'N. documento (Crescente)',
  noDocumentoDescendente: 'N. documento (Discendente)',
  totalEquipos: 'Totale per squadre',
  estadoPedidos: 'Lo stato dell`ordine',
  total: 'Totale',
  pendientes: 'In attesa di',
  porAprobar: 'Essere approvato',
  pendientesAprobar: 'In attesa di approvazione',
  enviadas: 'Inviato',
  impTotal: 'Totale',
  impPendiente: 'Imp. in sospeso',
  impAprob: 'Imp. da approvare',
  impEnv: 'Imp. Inviato',
  equipo: 'Squadra',
  jefeEquipo: 'Guida del gruppo',
  distribuidor: 'Distributore',
  ofertaPendiente: 'Oferta pendiente',
  pendienteAprobar: 'Pendiente de aprobar',
  pendienteAprobarEquipos: 'Pendiente de aprobar en los equipos',
  editarCliente: 'Editar cliente',
  pages: {
    productosPendientes: 'Productos pendientes',
    cursos: 'Cursos',
    updatePage: 'Actualizar página',
    home: 'Menú Principal',
    dashboard: 'Panel de Control',
    distributors: 'Distribuidores',
    catalog: 'Catálogo',
    reports: 'Partes diarios',
    statistics: 'Estadísticas',
    addPreClient: 'Añadir precliente',
    addDistributorClient: 'Añadir cliente',
    approvalOrders: 'Aprobar pedidos',
    approvalRefunds: 'Aprobar abonos',
    approvalCharges: 'Aprobar cobros',
    pendingOrder: 'Pedido pendiente',
    previousOrders: 'Pedidos anteriores',
    pendingCharge: 'Cobro pendiente',
    previousCharges: 'Cobros anteriores',
    pendingRefund: 'Abono pendiente',
    previousRefunds: 'Abonos anteriores',
    clientManagement: 'Gestión de cliente',
    vendorManagement: 'Información del vendedor',
    extracts: 'Movimientos del vendedor',
    bills: 'Facturas',
    billsRefunds: 'Abonos facturados',
    billsInvoices: 'Facturas',
    soldProducts: 'Productos vendidos',
    expeditions: 'Expediciones',
    expenses: 'Gastos',
    incomes: 'Ingresos',
    pendingToShip: 'Pendiente de servir',
    clientsContracts: 'Contratos',
    refundsStatus: 'Estado abonos',
    commissions: 'Comisiones',
    settings: 'Ajustes',
    changelog: 'Novedades',
    faqStores: 'Ayuda tienda peluquerías',
    faq: 'Ayuda',
    notFound: 'No encontrado',
    vendorSelection: 'Selección de Vendedor',
    clientSelection: 'Selección de Cliente',
    sellChannelSelection: 'Canal de Venta',
    clientPendingOrder: 'Pedido pendiente',
    clientPreviousOrders: 'Pedidos anteriores',
    clientPendingCharge: 'Cobro pendiente',
    clientPreviousCharges: 'Cobros anteriores',
    clientPendingRefund: 'Abono pendiente',
    clientPreviousRefunds: 'Abonos anteriores',
    clientPendingToShip: 'Pendiente de Servir',
    clientExpeditions: 'Expediciones',
    clientGiros: 'Giros',
    clientPendingBills: 'Facturas pendientes',
    clientObjectives: 'Objetivos',
    clientContracts: 'Contratos',
    clientExtracts: 'Movimientos',
    clientBills: 'Facturas',
    clientBillsRefunds: 'Abonos facturados',
    productDetails: 'Producto',
    notFoundClient: 'Cliente no encontrado',
    refundStatusDetails: 'Estado del Abono',
    searchRefundProduct: 'Buscar producto a abonar',
    orderDetails: 'Detalles del pedido',
    productBills: 'Facturas del producto',
    checkoutCharge: 'Tramitar cobro',
    newClientDetails: 'Detalles del nuevo cliente',
    newIncome: 'Nuevo Ingreso',
    newExpense: 'Nuevo Gasto',
    checkoutOrder: 'Tramitar pedido',
    newDistributorClients: 'Nuevo cliente',
    employees: 'Empleados',
    users: 'Usuarios',
    topClientes: 'Top Clientes',
  },
  menus: {
    menu: 'Menú',
    mainMenu: 'Menú Principal',
    favoritesMenu: 'Favoritos',
    clientManagementMenu: 'Gestión de cliente',
  },
  buttons: {
    add: 'Añadir',
    changePassword: 'Cambiar contraseña',
    copyToOtherClient: 'Copiar a otro cliente',
    moveToOtherClient: 'Mover a otro cliente',
    duplicate: 'Duplicar',
    copy: 'Copiar',
    checkInOcean: 'Ticar en Ocean',
    addBillManual: 'Añadir factura manualment',
    addRemaining: 'Añadir restante',
    addChargeDate: 'Añadir fecha de cobro',
    save: 'Guardar',
    ok: 'OK',
    update: 'Actualizar',
    search: 'Buscardor',
    close: 'Cerrar',
    logout: 'Salir',
    searchClient: 'Buscar Cliente',
    searchProductInBills: 'Buscar en Factura',
    back: 'Atrás',
    cancel: 'Cancelar',
    login: 'Acceder',
    clean: 'Limpiar',
    confirm: 'Confirmar',
    findClient: 'Encontrar cliente',
    updateEmail: 'Actualizar Email',
    addToOrder: 'Añadir al pedido',
    delete: 'Borrar',
    addIncome: 'Añadir Ingreso',
    addExpense: 'Añadir Gasto',
    checkoutOrder: 'Tramitar Pedido',
    selectAll: 'Seleccionar Todo',
    activateSort: 'Activar ordenación',
    deactivateSort: 'Desactivar ordenación',
    addCharge: 'Añadir Cobro',
    createAdvice: 'Generar Anticipo',
    addPendingBill: 'Añadir Cobro',
    accept: 'Aceptar',
    done: 'Hecho',
    getObjective: 'Obtener Objetivo',
    getSoldProducts: 'Obtener productos vendidos',
    updateWebProduct: 'Actualizar Producto',
    updateWebCategory: 'Actualizar Categoria',
    addProduct: 'Añadir producto',
    searchInBills: 'Buscar en facturas',
    sendRefund: 'Enviar abono',
    showAccount: 'Ver Nº Cuenta',
    addObjective: 'Añadir objetivo',
    addPreclient: 'Añadir Precliente',
    checkoutCharge: 'Tramitar Cobro',
    deleteSelected: 'Borrar seleccionados',
    send: 'Enviar',
    refund: 'Abonar',
    refundAll: 'Abonar todo',
    reshopAll: 'Volver a comprar todo',
    reshop: 'Recomprar',
    newUser: 'Nuevo usuario',
  },
  popups: {
    globalSearch: 'Buscador',
  },
  inputs: {
    password: { label: 'Contraseña', placeholder: 'Tu contraseña actual' },
    newPassword: { label: 'Nueva contraseña', placeholder: 'La nueva contraseña' },
    newPasswordConfirmation: { label: 'Repite nueva contraseña', placeholder: 'Repite la nueva contraseña' },
    searchClient          : { label: 'Cliente'                , placeholder: 'Buscar al cliente por nombre, ID del cliente o CIF' },
    searchProductInBills  : { label: 'Producto'               , placeholder: 'Buscar un producto por código o nombre' },
    searchMainMenu        : { label: 'Buscar'                 , placeholder: '¿Qué quieres hacer?' },
    searchProduct         : { label: 'Buscar Producto'        , placeholder: 'Busca un producto por código, nombre...' },
    searchFaq             : { label: 'Buscar en Ayuda'        , placeholder: 'Buscar en la ayuda' },
    searchOption          : { label: 'Buscar Opción'          , placeholder: 'Filtra opciones por nombre o código' },
    searchVendorCode      : { label: 'Buscar Código Vendedor' , placeholder: 'Buscar vendedor por código, nombre...' },
    searchReports         : { label: 'Buscar reporte'         , placeholder: 'Buscar un reporte por nombre o código de cliente...'},
    motive                : { label: 'Motivo'                 , placeholder: 'Introduce una pequeña descripción' },
    advice                : { label: 'Cantidad'               , placeholder: 'Introduce la cantidad del anticipo' },
    comment               : { label: 'Comentario'             , placeholder: 'Escribe aquí tu comentario si necesitas aportar información adicional' },
  },
  login: {
    title: 'Cosmet',
    username: { label: 'Usuario', placeholder: 'Tu usuario' },
    password: { label: 'Contraseña', placeholder: 'Tu contraseña' },
    information: {
      line1: `Accede con tu usuario y contraseña para entrar en Cosmet<br><strong>(Antiguo OferTAHE y OferTH)</strong>`,
      line2: `Cosmet &copy; {year} {version}`,
    }
  },
  info: {
    selectAddressFill: 'Selecciona una dirección de envío',
    cardProduct: 'Los productos del catálogo se mostrarán con un estilo de tarjetas',
    listProduct: 'Los productos del catálogo se mostrarán con un estilo de lista',
    contactWithRRHH: 'Si tienes alguna duda o algún problema con esto por favor contacta con RRHH y NO con el admin de la aplicación',
    noBillsFound: 'No se han encontrado facturas',
    searchRefundProducts: 'Primero busca el producto que quieres abonar',
    addChargeDates: 'Selecciona el importe a cobrar y la fecha deseada y pulsa en añadir cobro',
    selectPaymentFormFill: 'Debes elegir una forma de pago',
    selectPaymentTermFill: 'Debes elegir un término de pago',
    noFavoritesMenu: 'Aún no has añadido ningún enlace a favoritos. Prueba a pinchar en una de las estrellas ' +
      '<icon class="icon f7-icons" style="font-size: 20px">star</icon> de los enlaces del Menú.',
    searchResults: 'Resultados de la búsqueda',
    isSelectedCode: 'Tienes seleccionado este código y este canal de venta',
    newClientUse: 'Utiliza este cliente para introducir un pedido a un cliente nuevo o un precliente',
    noClientsFound: 'Ops! No se han encontrado clientes con esa búsqueda',
    noCodesFound: 'No se han encontrado códigos con tu búsqueda',
    clientConfirm: '¿Estás seguro de que deseas cambiar a este cliente?',
    clientConfirmNew: '¿Quieres cambiar al CLIENTE NUEVO (Código: 1)?',
    notFoundClient: 'Si te han añadido el cliente hoy, es normal que no salga ¡pero tranquilo! si sabes el código de cliente puedes añadirlo tu mismo desde aqui ' +
      'aunque recuerda que su información como los comentarios de contrato, direcciones adicionales, etc,... aparecerá mañana. Aún así, si necesitas hacerle un pedido, abono ' +
      'o cobro intenta añadirlo tu mismo ahora, y así no esperar a mañana',
    noPromoProducts: 'No tiene una lista de materiales fija',
    orderLines: 'Líneas del Pedido',
    sampleLines: 'Muestras del Pedido',
    incidentLines: 'Productos con incidencia del Pedido',
    noOrderLines: 'Aún no has añadido líneas al pedido',
    noRefundLines: 'Aún no has añadido líneas al abono',
    noSampleLines: 'Aún no has añadido muestras al pedido',
    noIncidentLines: 'Aún no hay ninguna linea de incidencia en el pedido',
    chargeFor: 'Cobro para',
    noPendingCharge: 'Aún no has añadido ninguna linea al cobro. Pulsa en el botón para ver los cobros pendientes del cliente.',
    refundFor: 'Abono para',
    noPendingRefund: 'Aún no has añadido ninguna linea al abono. Pulsa en el botón para buscar un producto.',
    noPendingBills: 'El cliente no tiene facturas pendientes',
    noPreviousOrders: 'No se han encontrado ofertas anteriores',
    noPendingOrders: 'No se han encontrado ofertas pendientes',
    noPreviousRefunds: 'No se han encontrado abonos anteriores',
    noPendingRefunds: 'No se han encontrado abonos pendientes',
    noPreviousCharges: 'No se han encontrado cobros anteriores',
    noPendingCharges: 'No se han encontrado cobros pendientes',
    selectVendorCode: 'Selecciona un código de vendedor',
    productsSoldInfo: 'El cálculo de los productos vendidos se hace sacando los productos facturados y restandole los productos abonados',
    noSoldProductsFound:  'No se han encontrado productos vendidos para ese cliente o ese rango de fechas',
    noApprovalOrders: 'No hay pedidos pendientes de aprobar',
    noApprovalRefunds: 'No hay abonos pendientes de aprobar',
    noApprovalCharges: 'No hay cobros pendientes de aprobar',
    noExpeditions: 'No hay expediciones de hace 2 semanas',
    refundLineManual: 'Este producto está asociado manualmente a la factura, deberás indicar por qué',
    refundLineQuantityExceeded: 'Has introducido una cantidad mayor a la de la factura, deberás indicar por qué',
    refundLineNoAbonable: 'El producto ha excedido el tiempo permitido para abonar, deberás indicar por qué',
    isRefundLinesFilled: '¿Has rellenado todos los motivos de abono de CADA producto?',
    noRefundForNewClient: 'No tienes seleccionado a ningún cliente existente, selecciona a un cliente para tramitarle un abono',
    noChargeForNewClient: 'No tienes seleccionado a ningún cliente existente, selecciona a un cliente para tramitarle un cobro',
    noAccountFound: 'No se ha encontrado en nº de cuenta del cliente',
    noComments: 'El cliente no tiene comentarios',
    noPendingToShip: 'No hay pendiente de servir',
    noGiros: 'No hay giros que mostrar',
    noReports: 'No se han encontrado reportes',
    fillFieldsAndSave: 'Rellena los campos de la parte inferior, y dale a guardar',
    passwordSuccessfullChanged: 'La contraseña se ha cambiado correctamente',
    revisitTour: 'Deselecciona esta opción si quieres volver a ver el tour.',
    hasKeyboard: 'Si no trabajas con teclado deselecciona esta opción para tener una calculadora táctil.',
    noIncomes: 'No se han encontrado ingresos anteriores, pulsa en el botón superior para añadir un nuevo ingreso',
    noExpenses: 'No se han encontrado gastos anteriores, pulsa en el botón superior para añadir un nuevo gasto',
    noPreviousBills: 'No se han encontrado facturas anteriores',
    noProductsFound: `No se han encontrado productos con la búsqueda <em>{0}</em>`,
    pleaseFixErrors: 'Arregla los siguientes Errores',
  },
  text: {
    underConstruction: 'En construcción',
    suggestions: 'Quizás quisiste decir',
    lastUpdate: 'Última versión',
    added: 'añadido',
    addressSelection: 'Seleccionar dirección',
    send: 'Enviar',
    type: 'Tipo',
    importWithImp: 'Importe (imp. incl.)',
    changePassword: 'Cambiar contraseña',
    accesibility: 'Accesibilidad',
    hasKeyboard: 'Trabajo con teclado',
    tour: 'Tour Realizado',
    languages: 'Idiomas',
    catalog: 'Catálogo',
    card: 'Tarjeta',
    list: 'Lista',
    bonifyItems: 'Art. bonificados',
    promoCount: 'Nº Promos',
    editSelected: 'Editar selección',
    copySelected: 'Copiar selección',
    duplicateSelected: 'Duplicar selección',
    deleteSelected: 'Eliminar selección',
    seeBills: 'Ver Facturas',
    dates: 'Fechas',
    remainingIVAInc: 'Restante (IVA incl)',
    companyAndSellChannel: 'Empresa y Canal de Venta',
    numCheck: 'Nº Cheque',
    paymentDates: 'Fechas de cobro',
    addChargeDates: 'Añadir fecha de cobro',
    paymentFormSelection: 'Forma de Pago',
    paymentTermSelection: 'Término de Pago',
    orderDate: 'Fecha Pedido',
    abonable: 'Abonable',
    sellChannel: 'Canal de Venta',
    news: 'Novedades',
    info: 'Información',
    infoShort: 'Info',
    importantInfo: 'Información importante',
    new: 'Nuevo',
    soldOut: 'Agotado',
    products: 'Productos',
    navigation: 'Navegación',
    favorites: 'Favoritos',
    lines: 'Líneas',
    categories: 'Categorías',
    first: 'Primera',
    last: 'Última',
    seeOptions: 'Ver Opciones',
    price: 'Precio',
    of: 'de',
    user: 'Usuario',
    codes: 'Códigos',
    newClient: 'NUEVO CLIENTE',
    cif: 'CIF',
    confirm: 'Confirmar',
    notFoundClient: 'No lo encuentro',
    comments: 'Comentarios',
    cod: 'Cod',
    orderBy: 'Ordenar por',
    code: 'Código',
    description: 'Descripción',
    quantity: 'Cantidad',
    unitsPer: 'unidades por',
    total: 'Total',
    totalQuantities: 'Unidades',
    discount: 'Descuento',
    actions: 'Acciones',
    addWithDiscount: 'Añadir con descuento',
    productItems: 'Artículos',
    import: 'Importe',
    orders: 'Pedidos',
    refunds: 'Abonos',
    charges: 'Cobros',
    expenses: 'Gastos',
    newClients: 'Clientes nuevos',
    income: 'Ingreso',
    yes: 'Sí',
    no: 'No',
    numb: 'Nº',
    date: 'Fecha',
    reportId: 'Nº de reporte',
    addProduct: 'Añadir Producto',
    addByCode: 'Añadir por código',
    searchProduct: 'Buscar Producto',
    order: 'Pedido',
    samples: 'Muestras',
    incidents: 'Incidencias',
    ivaInc: '+ (IVA inc.)',
    freeItems: 'Art. Bonificados',
    numPromos: 'Nº Promos',
    selec: 'Selecc',
    impSelect: 'Imp. Selecc',
    pendingBills: 'Facturas Pendientes',
    company: 'Empresa',
    postingDate: 'Fecha Registro',
    dueDate: 'FechaVencimiento',
    documentNo: 'Nº Documento',
    paymentForm: 'Forma de Pago',
    paymentMethod: 'Método de Pago',
    paymentTerm: 'Término de Pago',
    amount: 'Importe',
    remainingAmount: 'Importe Pendiente',
    totalContrareembolso: 'Contrareembolso',
    unfinished: 'Sin terminar',
    paymentDate: 'Fecha de Pago',
    status: 'Estado',
    statuses: 'Estados',
    client: 'Cliente',
    clients: 'Clientes',
    from: 'Desde',
    to: 'Hasta',
    fromDate: 'Fecha desde',
    toDate: 'Fecha Hasta',
    item: 'artículo',
    items: 'artículos',
    update: 'Actualizar',
    statistics: 'Estadísticas',
    pendingOrders: 'Ofertas Pendientes',
    previousOrders: 'Ofertas Anteriores',
    pendingRefunds: 'Abonos Pendientes',
    previousRefunds: 'Abonos Anteriores',
    pendingCharges: 'Cobros Pendientes',
    previousCharges: 'Cobros Anteriores',
    vendorCodeSelection: 'Cód. Vendedor',
    selectForStatistics: 'Puedes cambiar mes y año',
    month: 'Mes',
    year: 'Año',
    objective: 'Objetivo',
    billed: 'Facturado',
    ideal: 'Ideal a día',
    cumplimentObj: 'de objetivo cumplido',
    numPacks: 'Bultos',
    appliesToDoc: 'Liquidado por.',
    dateRange: 'Rango de fechas',
    selectDate: 'Selecciona la fecha',
    pendingItems: 'Unidades pendientes',
    units: 'Unidades',
    reviser: 'Revisor',
    quant: 'Cant.',
    unitPrice: 'Precio',
    totalImport: 'Total',
    totalPromo: 'Total Promo',
    totalIVA: '+ (IVA inc.)',
    free: 'Gratis',
    sample: 'Muestra',
    incident: 'Incidencia',
    bill: 'Factura',
    bills: 'Facturas',
    motive: 'Motivo',
    information: 'Información',
    select: 'Seleccionado',
    comment: 'Comentario',
    account: 'Nº Cuenta',
    warnings: 'Avisos',
    disc: 'Desc. %',
    discImp: 'Imp. Desc.',
    promo: 'Promo',
    lastBills: 'Últimas Facturas',
    lateBills: 'Saldos Atrasados',
    report: 'Parte',
    vendor: 'Vendedor',
    share: 'Compartir por',
    shareTelegram: 'Telegram',
    shareWhatsapp: 'Whatsapp',
    shareEmail: 'Enviar al email',
    download: 'Descargar',
    searchResult: 'Resultados para',
    totalPending: 'Total Pendiente',
    createAdvice: 'Crear anticipo',
    advance: 'Anticipo',
    moveNo: 'Movimiento',
    toLiquid: 'A liquidar',
    pendingImport: 'Imp. Pendiente',
    selectedImport: 'Imp. Seleccionado',
  },
  fnText: {
    showResults: 'Mostrando ({0} - {1}) de {2} resultados',
    total: 'Total {0}€',
    totalCash: 'Efectivo {0}€',
  },
  filters: {
    lines: 'Líneas',
  },
  sellChannel: {
    TAHE_ESPANA:        'Tahe España',
    TAHE_DISTRIBUTOR:   'Tahe Distribuidor',
    TAHE_DISTRIBUIDOR:   'Tahe Distribuidor',
    TAHE_CANARIAS:      'Tahe Canarias',
    TAHE_PORTUGAL:      'Tahe Portugal',
    TAHE_ITALIA:        'Tahe Italia',
    TAHE_INTERNACIONAL: 'Tahe Internacional',
    TAHE_OUTLET:        'Tahe Outlet',
    TAHE_FRANCIA:       'Tahe EU',
    TAHE_ALEMANIA:       'Tahe EU',
    TH_ESPANA:          'Th Pharma España',
    TH_PORTUGAL:        'Th Pharma Portugal',
    TH_ITALIA:          'Th Pharma Italia',
    TH_INTERNATIONAL:   'Th Pharma Internacional',
    TH_INTERNACIONAL:   'Th Pharma Internacional',
    TH_OUTLET:          'Th Pharma Outlet',
    MER_ESPANA:         'Mercacosmética España',
    DVF_ESPANA:         'DV Farmaceutic España',
    KELAYA_ESPANA:      'KELAYA España',
    KELAYA_CANARIAS:    'KELAYA Canarias',
    KELAYA_PORTUGAL:    'KELAYA Portugal',
    KELAYA_FRANCIA:    'KELAYA EU',
    KELAYA_ALEMANIA:    'KELAYA EU',
    BOCARE_ESPANA:      'BOCARE España',
    BOCARE_CANARIAS:    'BOCARE Canarias',
    BOCARE_PORTUGAL:    'BOCARE Portugal',
    BOCARE_FRANCIA:    'BOCARE EU',
    BOCARE_ALEMANIA:    'BOCARE EU',
  },
  status: {
    PENDING:              'Pendiente',
    PENDING_TO_SEND:      'Pend. Env.',
    PENDING_APPROVAL:     'Pend. Aprob.',
    PENDING_APPROVAL_DIST: 'Pend. Aprob. Dis.',
    SENDED:               'Enviado',
    IMPORT_ERROR:         'Error Importación',
    RECEIVED:             'Recibido',
    ACCEPTED:             'Aceptado',
    CANCELED:             'Cancelado',
    UNKNOWN:              'Desconocido',
    OMNI_RECEIVED:        'Enviado',
    ERROR_IMPORTACION:    'Pend. Rec.',
  },
  company: {
    TAHE: 'Tahe',
    TH_PHARMA: 'Th Pharma',
    TAHE_2016: 'Tahe',
    TH_PHARMA_2016: 'Th Pharma',
    MERCACOSMETICA: 'Mercacosmética',
    DV_FARMACEUTIC: 'DV Farmaceutic',
    'NEO-DEPIL LASER': 'Neo Depil Láser',
    'MAGAÑA': 'Tahe Productos Cosméticos S.L.',
    'Tahe Productos Cosméticos S_L_': 'Tahe Productos Cosméticos S.L.',
    'TH Pharma S_L_': 'Th Pharma',
    'KELAYA_2016': 'KELAYA',
    'BOCARE_2016': 'BOCARÉ',
  },
  packing: {
    BOX: 'caja',
    PACK: 'palet',
    CAJA: 'caja',
    PALET: 'palet',
  },
  paymentForm: {
    CONTADO: 'Contado',
    GIRO: 'Giro',
    CONTRAREEM: 'Contrareembolso',
    GIROPT: 'Giro',
    CONTRARPOR: 'Contrareembolso',
    CHEQUEPT: 'Cheque',
    TARJETAPT: 'Tarjeta Clientes',
    CONTADOPT: 'Contrato Cheque (Contado)',
    'TARJ OUTLE': 'Tarjeta Outlet',
    TRANSFPORT: 'Transferencia',
    TRANSFERIT: 'Transferencia',
    EFECTIVO: 'Efectivo',
    TARJETA: 'Tarjeta',
    GIROCOBRO: 'Giro',
    CHEQUE: 'Cheque',
    DESCUENTO: 'Descuento',
  },
  paymentTerm: {
    PERSONALIZADO: 'Personalizado',
    '1X7': 'Giro 7 días',
    '1X15': 'Giro 15 días',
    '1X20': 'Giro 20 días',
    '1X21': 'Giro 21 días',
    '1X30': 'Giro 30 días',
    '1X40': 'Giro 40 días',
    '1X45': 'Giro 45 días',
    '1X60': 'Giro 60 días',
    '1X75': 'Giro 75 días',
    '1X90': 'Giro 90 días',
    '2X7': 'Giro 7 y 30 días',
    '2X15': 'Giro 15 y 30 días',
    '2X20': 'Giro 20 y 40 días',
    '2X30': 'Giro 30 y 60 días',
    '2X45': 'Giro 45 y 90 días',
    '2X45B': 'Giro 45 y 60 días',
    '2X60': 'Giro 60 y 90 días',
    '2X60X120': 'Giro 60 y 120 días',
    '2X90': 'Giro 90 y 120 días',
    '3X10': 'Giro 10, 20 y 30 días',
    '3X15': 'Giro 15, 30 y 45 días',
    '3X3': 'Giro 45, 75 y 105 días',
    '3X30': 'Giro 30, 60 y 90 días',
    '3X60': 'Giro 60, 90 y 120 días',
    '4X30': 'Giro 30, 60, 90 y 120 días',
    '4X3': 'Giro 15, 60 y 90 días',
    '4X4': 'Giro 15, 30, 60 y 90 días',
    '1X120': 'Giro 120 días',
  },
  desconTime: {
    0: 'No mires la pantalla, ahora no es su momento, sino el tuyo. Relájate!',
    1: 'Sol escondido, descanso merecido.',
    2: 'Desconectar de todo y descansar es necesario para llevar una vida saludable.',
    3: 'Momento de: Clientes ... Desplazamientos ... Pedidos ... ¡Desconectar! <icon class="icon f7-icons text-color-green" style="font-size: 16px">checkmark_alt</icon>',
    4: 'Ahora es tiempo de estar con tu familia: ¡aprovecha y disfruta!',
    5: 'Ahora empieza tu jornada familiar!! Disfrútala!!',
    6: 'Para conseguir un rendimiento del 100% en tu jornada laboral necesitas una desconexión del 100%',
    7: 'Para ser productivo hay que descansar!! Feliz jornada de descanso!!',
    8: 'Tras el trabajo, disfrutar de tu familia, de una vida sana y una buena lectura te ayudará a sentirte mejor',
    9: 'Alto! Este tiempo es para ti, dedícatelo!',
  },
  client: {
    code: 'Reporte',
    mainAddress: 'Dirección Principal',
    navisionId: 'Código',
    cif: 'CIF',
    socialReason: 'Razón Social',
    name: 'Nombre',
    name2: 'Nombre 2',
    closeDays: 'Días de Cierre',
    email: 'Email',
    phone: 'Teléfono',
    mobile: 'Móvil',
    fax: 'Fax',
    address: 'Dirección',
    address2: 'Dirección 2',
    city: 'Ciudad',
    state: 'Provincia',
    postalCode: 'Código Postal',
    country: 'País',
    clientComments: 'Comentarios Cliente',
    contractComments: 'Comentarios Contrato',
    info: 'Información de Cliente',
    addresses: 'Direcciones',
    contact: 'Contacto',
    typeClient: 'Tipo de Cliente',
    newClient: 'Nuevo Cliente',
    surnameName: 'Apellidos, Nombre',
    bankAccount: 'Cuenta Banc.',
  },
  typeClient: {
    'Peluquería': 'Peluquería',
    'Estética': 'Estética',
    'Estética+Peluquería': 'Estética+Peluquería',
    'Barbería': 'Barbería',
    'Otros': 'Otros',
  },
  country: {
    ES: 'España',
    PT: 'Portugal',
    IT: 'Italia',
    FR: 'Francia',
    BE: 'Bélgica',
    DE: 'Alemania',
  },
  errors: {
    networkError: 'No se ha podido establecer conexión con el servidor. Por favor contacta con el administrador de la aplicación',
    notVendorInTeam: 'Ese código de vendedor no está en tus equipos',
    dateExistError: 'Ya has añadido la fecha {0} a las fechas de vencimiento',
    navisionError: 'Ha ocurrido un error al conectar con Navision, es posible que no haya conexión, inténtalo de nuevo más tarde',
  },
  serverErrors: {
    NAV_INACCESSIBLE: 'Cosmet no puede acceder al servidor de Navision',
    NAV_UNKNOWN_ERROR: 'Ha ocurrido un error desconocido al intentar conectar con Navision',
    SERVER_NOT_RESPONDING: 'El servidor está muy ocupado en estos momentos. Intentalo de nuevo más tarde',
    CHARGE_ALREADY_PROCESSED: 'Esta línea ya ha sido procesada',
    AUTH_USER_BLOCKED: 'El usuario está bloqueado, contacta con un superior para solucionar el problema',
    AUTH_PASSWORD_INCORRECT: 'La contraseña es incorrecta',
    LINE_ALREADY_ADDED: 'Esa línea ya ha sido tramitada o ya ha sido añadida al cobro pendiente',
    'Internal server error': 'El servidor está teniendo un problema, por favor contacta con el administrador de la aplicación o inténtalo más tarde',
  },
  statusCode: {
    999: 'Error de conexión',
    200: 'Éxito',
    201: 'Éxito',
    404: 'No Encontrado',
    401: 'Error de autenticación',
    422: 'Error en el servidor',
    499: 'Ya existe',
    403: 'Ya existe',
    500: 'Error de conexión',
    503: 'Mantenimiento',
  },
  success: {
    chargeLineAdded: 'Línea a cobrar añadida correctamente',
    categoryAdded: 'Categoría añadida correctamente',
    productAdded: 'Producto añadido correctamente',
  },
  prompt: {
    updateWebProduct: {
      text: 'Introduce el ID del Producto en OpenCart',
      title: 'Producto Web',
    },
    updateWebCategory: {
      text: 'Introduce el ID de la Categoría en OpenCart',
      title: 'Categoría Web',
    },
    deleteCharges: {
      text: '¿Estás seguro de que deseas eliminar los cobros seleccionados?',
      title: 'Confirmar',
    }
  },
  warning: {
    mustAddObjective: 'Recuerda que tienes que añadir el objetivo del año que viene para este cliente',
    emailMoreThanAWeek: 'El email lleva más de 7 días sin ser confirmado',
    waitingForConfirm: 'Esperando confirmación del email',
    notValidatedEmail: 'El cliente tiene un email, pero aún no está confirmado. Por favor envíale un email de confirmación',
    notEmail: 'El cliente no tiene email. Para poder facturarle tienes que añadirle un email y que el cliente lo valide, ¡Vamos a ello!',
  },
  languages: {
    es: 'Español',
    it: 'Italiano',
    pt: 'Portugués',
    fr: 'Francés',
    de: 'Alemán',
    en: 'Inglés',
  },
  ES: 'España',
  PT: 'Portugal',
  FR: 'Francia',
  DE: 'Alemania',
  BE: 'Bélgica',
  'MAGAÑA': 'Tahe',
  'TH PHARMA': 'Th Pharma',
  'NEO-DEPIL LASER': 'Neo Depil',
  TAHE: 'Tahe Productos Cosméticos S.L.',
  TAHE_2016: 'Tahe Productos Cosméticos S.L.',
  TH_PHARMA: 'Th Pharma',
  TH_PHARMA_2016: 'Th Pharma',
  MIXPROFESSIONAL: 'Mix Professional',
  DVFARMACEUTIC: 'DV Farmaceutic',
  TAHE_ESPANA: 'Tahe España',
  TAHE_CANARIAS: 'Tahe Canarias',
  TAHE_PORTUGAL: 'Tahe Portugal',
  TAHE_ITALIA: 'Tahe Italia',
  TH_ESPANA: 'Th España',
  TH_PORTUGAL: 'Th Portugal',
  TH_ITALIA: 'Th Italia',
  TAHE_INTERNACIONAL: 'Tahe Internacional',
  TH_INTERNACIONAL: 'Th Internacional',
  MIXP_ESPANA: 'Mix Professional España',
  DVF_ESPANA: 'DV Farmaceutic España',
  NEW_CLIENT: 'NUEVO CLIENTE',
  COMPANY: 'Empresa',
  SELL_CHANNEL: 'Canal de Venta',
  USER: 'Usuario',
  COMPANY_SELECTION: 'Selección de Código',
  CLIENT_SELECTION: 'Selección de Cliente',
  DASHBOARD: 'Panel de Control',
  REPORTS: 'Partes diarios',
  PENDING_PROPOSAL: 'Propuesta para Cliente',
  PREVIOUS_PROPOSALS: 'Estado de las propuestas',
  PENDING_ORDER: 'Pedido Pendiente',
  PENDING_APPROVAL_ORDERS: 'Pedidos Pendientes de aprobar',
  PENDING_APPROVAL_REFUNDS: 'Abonos Pendientes de aprobar',
  PREVIOUS_ORDERS: 'Pedidos Anteriores',
  PENDING_CHARGE: 'Cobro Pendiente',
  PREVIOUS_CHARGES: 'Cobros Anteriores',
  PENDING_REFUND: 'Abono Pendiente',
  PREVIOUS_REFUNDS: 'Abonos Anteriores',
  CLIENT_MANAGEMENT: 'Información del Cliente',
  VENDOR_INFO: 'Información del Vendedor',
  EXTRACTS: 'Movimientos del Vendedor',
  BILLS: 'Facturas',
  BILLS_REFUNDS: 'Abonos Facturados',
  EXPEDITIONS: 'Expediciones',
  EXPENSES: 'Gastos',
  INCOMES: 'Ingresos',
  PENDING_TO_SHIP: 'Pendiente de Servir',
  REFUNDS_STATUS: 'Estado Abonos',
  COMMISSIONS: 'Comisiones',
  SETTINGS: 'Ajustes',
  CHANGELOG: 'Novedades',
  FAQ_STORES: 'Ayuda Tiendas Peluquerias',
  FAQ: 'Ayuda',
  PROFILE: 'Perfil',
  BACK_LINK: 'Atrás',
  MENU: 'Menú',
  LOGOUT: 'Salir',
  LOGIN: 'Acceder',
  CANCEL: 'Cancelar',
  NO_FAVORITES_MENU: 'Aún no has añadido ningún enlace a favoritos. Prueba a pinchar en una de las estrellas <icon class="icon f7-icons" style="font-size: 20px">star</icon> de los enlaces del Menú.',
  NO_RESULTS_FOUND: 'No se han encontrado resultados',
  COD: 'Cód',
  CIF: 'CIF',
  PROPOSAL_FOR: 'Propuesta para ',
  ORDER_FOR: 'Oferta para ',
  CHARGE_FOR: 'Cobro para ',
  REFUND_FOR: 'Abono para ',
  PENDING_PROPOSAL_EMPTY: 'Aún no has añadido ninguna linea a la propuesta. Pulsa en el botón para ir al catálogo.',
  PENDING_ORDER_EMPTY: 'Aún no has añadido ninguna linea al pedido. Pulsa en el botón para ir al catálogo.',
  PENDING_CHARGE_EMPTY: 'Aún no has añadido ninguna linea al cobro. Pulsa en el botón para ver los cobros pendientes del cliente.',
  PENDING_REFUND_EMPTY: 'Aún no has añadido ninguna linea al abono. Pulsa en el botón para buscar un producto.',
  SEARCH_CLIENT: 'Buscar Cliente por Código, Nombre, CIF...',
  SEARCH_VENDOR: 'Buscar Vendedor por Código, Nombre...',
  SEARCH_PRODUCT: 'Buscar producto por Código, Nombre...',
  ADD_PRODUCT: 'Añadir Producto',
  ADD_CHARGE: 'Añadir cobro',
  SEARCH_IN_BILLS: 'Buscar en las facturas',
  GENERATE_ADVICE: 'Generar Anticipo',
  CATALOG: 'Catálogo',
  PRODUCTS: 'Productos',
  LINES: 'Líneas',
  CATEGORIES: 'Categorías',
  IF_OPTION: 'Según Opción',
  PRICE: 'Precio',
  MAIN_MENU: 'Menú Principal',
  ORDERS: 'Pedidos',
  REFUNDS: 'Abonos',
  CHARGES: 'Cobros',
  NEW_CLIENTS: 'Clientes',
  INCOME: 'Ingreso',
  YES: 'Sí',
  NO: 'No',
  REPORT_DETAILS: 'Reporte {0}',
  REPORT_DETAILS_NOTFOUND: 'No se han encontrado datos del reporte {0}',
  INFO: 'Información',
  REPORT_NO: 'Reporte Nº',
  DATE: 'Fecha',
  VENDOR: 'Vendedor',
  IMPORT: 'Importe',
  TOTAL: 'Total {0}€',
  TOTAL_CASH: 'Efectivo {0}€',
  ADD_PRECLIENT_INFO: 'No tienes ningún cliente existente seleccionado. Si deseas añadir un cliente sin realizar pedido pulsa en el botón Añadir Precliente',
  ADD_PRECLIENT: 'Añadir Precliente',
  NEW_CLIENT_FORM: 'Formulario de Nuevo Cliente',
  TYPE_CLIENT: 'Tipo Cliente',
  NIF_CIF: 'NIF/CIF',
  NAME: 'Nombre',
  NAME_2: 'Nombre 2',
  NAME2: 'Nombre 2',
  SOCIAL_REASON: 'Razón Social',
  ADDRESS: 'Dirección',
  ADDRESS_2: 'Dirección 2',
  CITY: 'Población',
  STATE: 'Provincia',
  COUNTRY: 'País',
  POSTAL_CODE: 'Código Postal',
  CONTACT: 'Contacto',
  CLOSE_DAYS: 'Días de Cierre',
  CLOSEDAYS: 'Días de Cierre',
  EMAIL: 'Email',
  PHONE: 'Teléfono',
  MOBILE: 'Móvil',
  FAX: 'Fax',
  NAVISIONID: 'Código',
  SOCIALREASON: 'Razón Social',
  ADDRESS2: 'Dirección 2',
  POSTALCODE: 'Código Postal',
  BANK_ACC: 'Cuenta Banc.',
  PRECLIENT: 'Precliente',
  TYPECLIENT: 'Tipo de Cliente',
  UNFINISHED: 'Sin Terminar',
  STATISTICS: 'Stats',
  NO_PENDING_APPROVAL_ORDERS: 'No hay pedidos pendientes de aprobar',
  NO_PENDING_APPROVAL_REFUNDS: 'No hay abonos pendientes de aprobar',
  PENDING_ORDERS: 'Pedidos Pendientes',
  PENDING_REFUNDS: 'Abonos Pendientes',
  PENDING_CHARGES: 'Cobros Pendientes',
  NO_PENDING_ORDERS: 'No tienes pedidos pendientes',
  NO_PREVIOUS_ORDERS: 'Aún no hay pedidos anteriores',
  NO_PENDING_REFUNDS: 'No tienes abonos pendientes',
  NO_PREVIOUS_REFUNDS: 'Aún no hay abonos anteriores',
  NO_PENDING_CHARGES: 'No tienes cobros pendientes',
  NO_PREVIOUS_CHARGES: 'Aún no hay cobros anteriores',
  OBJECTIVE: 'Objetivo',
  BILLED: 'Facturado',
  IDEAL: 'Ideal a día',
  CUMPLIMENT_OBJ: 'de objetivo cumplido',
  SELECT_FOR_STATISTICS: 'Puedes cambiar mes y año',
  MONTH: 'Mes',
  YEAR: 'Año',
  BACK: 'Atrás',
  ACTIONS: 'Acciones',
  SELECT_ALL: 'Seleccionar Todo',
  DISELECT_ALL: 'Deseleccionar Todo',
  DEACTIVATE_SORT: 'Desactivar Ordenación',
  ACTIVATE_SORT: 'Activar Ordenación',
  CHECKOUT_ORDER: 'Tramitar Pedido',
  TOTAL_IMPORT: 'Total',
  TOTAL_IVA: '+ (IVA inc.)',
  BONIFY_ITEMS: 'Art. Bonificados',
  PROMO_COUNT: 'Nº Promos',
  SELECT_QUANTITY: 'Selecc',
  SELECT_IMPORT: 'Imp. Selecc',
  CLIENT: 'Cliente',
  ITEMS: 'Artículos',
  ORDER_DETAILS: 'Detalles del Pedido',
  CHARGE_DETAILS: 'Detalles del Cobro',
  REFUND_DETAILS: 'Detalles del Abono',
  FAVORITES: 'Favoritos',
  ADD_WITH_DISCOUNT: 'Añadir con descuento',
  UNITS: 'Unidades',
  ADD_TO_ORDER: 'Añadir al pedido',
  ADD_TO_PROPOSAL: 'Añadir a la propuesta',
  WEB_STATUS: 'Estado Web',
  NAV_STATUS: 'Estado Navision',
  BLOCKED: 'Bloqueado',
  ACTIVE: 'Activo',
  PENDING_ORDER_BACK: 'Pedido',
  DESCRIPTION: 'Descripción',
  QUANTITY: 'Cantidad',
  CODE: 'Código',
  NO_PROMO_PRODUCTS: 'Esta promoción no tiene una lista de materiales fija',
  CLOSE: 'Cerrar',
  UNITS_PER_BOX: 'Unidades por caja',
  UNITS_PER_PACK: 'Unidades por palet',
  NO_PRODUCTS_FOUND: `No se han encontrado productos con la búsqueda <em>{0}</em>`,
  SELL_CHANNEL_SELECTED: 'Tienes seleccionada esta empresa y este canal de venta',
  NOT_CLIENTS_FOUND: `No se han encontrado clientes con la búsquera <em>{0}</em>`,
  CONFIRM: 'Confirmar',
  PERSONALIZADO: 'Personalizado',
  DELETE: 'Eliminar',
  DISCOUNT: 'Descuento',
  DUPLICATE: 'Duplicar',
  COPY: 'Copiar',
  QUANT: 'Cant.',
  UNIT_PRICE: 'Precio u.',
  TOTAL_PROMO: 'Total Promo',
  FREE: 'Gratis',
  EDIT_SELECTED: 'Editar Seleccionados',
  COPY_SELECTED: 'Copiar Seleccionados',
  DUPLICATE_SELECTED: 'Duplicar Seleccionados',
  DELETE_SELECTED: 'Eliminar Seleccionados',
  NO_REPORTS: 'Aún no tienes ningún parte diario, estos partes se generan automáticamente',
  NEW_CLIENT_ADDRESS: 'Utiliza este cliente para introducir un pedido a un cliente nuevo o un precliente',
  es: 'Español',
  it: 'Italiano',
  pt: 'Portugués',
  fr: 'Francés',
  CHANGE_PASSWORD: 'Cambiar Contraseña',
  LANGUAGES: 'Idiomas',
  ACCEPT: 'Aceptar',
  DELETE_SELECTED_TEXT: '¿Estás seguro de que deseas eliminar los siguientes artículos?',
  DELETE_PROMO: 'Eliminar Promoción',
  DELETE_PROMO_TEXT: '¿Qué quieres hacer?',
  DELETE_ALL: 'Eliminar todo',
  DELETE_ONLY_HEADER: 'Eliminar solo la cabecera',
  DELETE_HEADER_AND_PUT_PRICES: 'Eliminar cabecera y poner precios de tarifa',
  SEARCH_BY_CODE: 'Añadir por código',
  NOT_EXIST_PRODUCT: 'Lo siento, pero el producto no existe en la base de datos, ¿estás seguro de que es correcto?',
  PRODUCT_BLOCKED: 'Lo siento, ese producto está bloqueado en Navision, no puedes añadirlo',
  SELECT_MODIFIERS: 'Selecciona los campos que quieres editar, modificalos y dale a guardar',
  SAVE: 'Guardar',
  OK: 'Ok',
  TIMEOUT: 'Timeout',
  UNAUTHORIZED: 'Error de autenticación',
  USER_BLOCKED: 'El usuario está bloqueado, contacta con un superior para solucionar el problema',
  PASSWORD_INCORRECT: 'La contraseña es incorrecta',
  NOT_USER_FOUND: 'No se ha encontrado ningún usuario con esos datos, <span class="text-color-red">recuerda que el usuario <strong>NO</strong> es tu código de vendedor ni tu DNI</span>',
  ALERT: '¡Atención!',
  MOVE_TO_OTHER_CLIENT_INFO: 'Vas a MOVER este pedido a otro cliente. Si mueves el pedido <strong>SE BORRARÁ</strong> de este cliente, y las líneas' +
    'pasarán al otro cliente. Si ya existe el pedido se añadirán al pedido existente, y si no existe se creará uno nuevo. ¿Estás seguro de ' +
    'que deseas continuar?',
  COPY_TO_OTHER_CLIENT_INFO: 'Vas a COPIAR este pedido a otro cliente. Si copias el pedido <strong>PERMANECERÁ</strong> en este cliente, y las líneas' +
    'se crearán en el otro cliente. Si ya existe el pedido se añadirán al pedido existente, y si no existe se creará uno nuevo. ¿Estás seguro de ' +
    'que deseas continuar?',
  MOVE_ORDER_TO_CLIENT_SUCCESS: `Se ha movido el pedido al cliente Nº {0}, ¿Quieres cambiar a ese cliente?`,
  COPY_ORDER_TO_CLIENT_SUCCESS: `Se ha copiado el pedido al cliente Nº {0}, ¿Quieres cambiar a ese cliente?`,
  SERVER_TOO_BUSY: 'El servidor está procesando muchas solicitudes en este momento, inténtalo de nuevo',
  SERVER_NOT_RESPONDING: 'Parece que ahora mismo no se puede acceder al servidor, comprueba primero tu conexión a internet' +
    'y que otras aplicaciones funcionan, y si es así contacta con un administrador',
  UPDATE: 'Actualizar',
  ORDER_MOVED: 'Pedido Movido',
  ORDER_COPIED: 'Pedido Copiado',
  INFO_METHODS: 'Información y Métodos de Pago',
  RESUME_ORDER: 'Resumen del Pedido',
  SEND: 'Enviar',
  NOT_FOUND: 'No encontrado',
  SORRY: 'Lo siento',
  CONTENT_NOT_FOUND: 'No he encontrado lo que estabas buscando, ve hacia atrás o pulsa en el menú principal para volver al panel de control',
  ADDRESS_SELECTION: 'Selecciona una dirección',
  PAYMENT_FORM_SELECTION: 'Forma de Pago',
  PAYMENT_TERM_SELECTION: 'Término de Pago',
  SELECT_ADDRESS_FILL: 'El cliente tiene más de una dirección, pincha aqui y selecciona una.',
  SEARCH_ADDRESS: 'Filtrar direcciones...',
  NO_ADDRESSES_FOUND: 'No se han encontrado direcciones',
  SELECT_PAYMENT_FORM_FILL: 'Debes elegir una forma de pago',
  SELECT_PAYMENT_TERM_FILL: 'Debes elegir un término de pago',
  ADD_ORDER_DATES: 'Añadir fechas de Cobro',
  REMAINING_IVA_INC: 'Restante (IVA incl)',
  ADD_CHARGE_DATE: 'Añadir fecha de Cobro',
  ADD_CHARGE_DATES: 'Añadir fecha de Cobro',
  ADD_REMAINING: 'Añadir restante',
  ADD_ORDER_DATES_INFO: 'Estos giros son orientativos, recuerda que el precio final de la oferta podrá variar',
  ADD_CHARGE_DATES_INFO: 'Selecciona el importe a cobrar y la fecha deseada y pulsa en añadir cobro',
  COMMENT: 'Comentario',
  COMMENT_FOR_CLIENT: 'Comentario para el Cliente',
  SAVE_COMMENT: 'Guardar Comentario',
  PENDING_BILLS: 'Facturas Pendientes',
  NO_PREVIOUS_MOVEMENTS: 'No se han encontrado movimientos anteriores del cliente seleccionado.',
  NOT_MOVEMENTS_NEW_CLIENT: 'Tienes seleccionado el cliente nuevo, selecciona un cliente existente para ver sus movimientos',
  NOT_BILLS_REFUNDS_NEW_CLIENT: 'Tienes seleccionado el cliente nuevo, selecciona un cliente existente para ver sus abonos facturados',
  NO_BILLS_NEW_CLIENT: 'Tienes seleccionado el cliente nuevo, selecciona un cliente existente para ver sus facturas',
  BILL_DETAILS: 'Detalles de la Factura',
  SEARCH_PRODUCTS: 'Filtrar líneas de la factura',
  SEARCH_REFUND_PRODUCTS: 'Buscar Producto',
  SEARCH_REFUND_BILLS: 'Buscar Factura',
  NO_CHARGES_FOR_NEW_CLIENT: 'No tienes seleccionado a ningún cliente existente, selecciona a un cliente para tramitarle un cobro',
  NO_REFUNDS_FOR_NEW_CLIENT: 'No tienes seleccionado a ningún cliente existente, selecciona a un cliente para tramitarle un abono',
  NO_PENDING_BILLS: 'El cliente no tiene facturas pendientes',
  NO_MOV: 'Nº Mov',
  BILL: 'Factura',
  TO_LIQUID: 'A Liquidar',
  PENDING_IMPORT: 'Importe Pendiente',
  ADVICE: 'Anticipo',
  CHECKOUT_CHARGE: 'Tramitar Cobro',
  CHARGE_ALREADY_PROCESSED: 'Esta línea ya ha sido procesada',
  CHARGE_LINE_ADDED: 'Línea a cobrar añadida correctamente',
  ORDER: 'Pedido',
  UNITS_PENDING: 'Unidades Pendientes',
  NUM_PACKS: 'Bultos',
  WRITE_COMMENT: 'Escribe aquí tu comentario',
  CONTENT: 'Contenido',
  NEW_CLIENT_DETAILS: 'Detalles de Nuevo Cliente',
  NO_NEW_CLIENT_DATA: 'No se han encontrado datos de este Cliente Nuevo, prueba a actualizar',
  COMMENTS: 'Comentarios',
  OBJECTIVES: 'Objetivos',
  EXP_PEND: 'Expediciones',
  PEND_BILLS: 'Fact. Pendientes',
  DROP_PLACEHOLDER: 'Arrastra archivos aqui',
  SELECTED_FILE: 'Imagen Seleccionada',
  ADD_IMAGE: 'Añadir Doc.',
  NEW_INCOME: 'Nuevo Ingreso',
  ADD_INCOME: 'Añadir Ingreso',
  NO_INCOMES: 'No se han encontrado ingresos anteriores, pulsa en el botón superior para añadir un nuevo ingreso',
  CHOOSE_FILE_INCOME: 'Elige un archivo (resguardo del ingreso)',
  NEW_EXPENSE: 'Nuevo Gasto',
  ADD_EXPENSE: 'Añadir Gasto',
  NO_EXPENSES: 'No se han encontrado gastos anteriores, pulsa en el botón superior para añadir un nuevo gasto',
  CHOOSE_FILE_EXPENSE: 'Elige un archivo (ticket del gasto)',
  TAP_HERE_IMG: 'Pulsa aqui para selecionar una foto <br>o sacar una',
  DATE_INCOME: 'Fecha del Ingreso',
  DATE_EXPENSE: 'Fecha del Gasto',
  EXPENSE_ADDED: 'Cobro añadido correctamente',
  INCOME_ADDED: 'Ingreso añadido correctamente',
  IMPORT_ERROR_MESSAGE: 'Error de Importación. Por favor, consulte con el administrador de la aplicación',
  ERRORS: 'Errores',
  PLEASE_FIX_ERRORS: 'Por favor, corrija los siguientes errores',
  INCOME_DETAILS: 'Detalles Ingreso',
  NO_INCOME_FOUND: 'No se han encontrado el ingreso que buscabas.',
  PAYMENT_FORM: 'Forma de Pago',
  GROUP: 'Grupo',
  EXPENSE_DETAILS: 'Detalles Gasto',
  PREVIEW: 'Vista Previa',
  PROPOSAL_DETAILS: 'Detalles de la Propuesta',
  COMMERCIAL_COMMENT: 'Notas del Comercial',
  NO_PROPOSAL: 'No se han encontrado esta propuesta',
  RESPONSE: 'Respuesta para el Comercial',
  SURNAME_NAME: 'Apellidos, Nombre',
  UNDER_CONSTRUCTION: 'En Construcción',
  ORDER_DATE: 'Fecha de la oferta',
  REFUND_DATE: 'Fecha del abono',
  COMPANY_SELL_CHANNEL: 'Empresa y Canal de venta',
  SEND_ADDRESS: 'Dirección de Envío',
  SEND_DATE: 'Entregar a partir del día',
  PROPOSALS: 'Propuestas',
  PROPOSAL: 'Propuesta',
  PROPOSAL_CONFIRMATION: 'Se va a generar un enlace para enviarle al cliente la propuesta, y él podrá aceptarla o rechazarla,' +
    'además de ver toda la información de los productos en la propuesta, ¿estás de acuerdo?',
  PAYMENT_TERM: 'Término de Pago',
  DATES: 'Fechas',
  PAYMENT_DATE: 'Fecha Pago',
  PASSWORDS_NOT_MATCH: 'La contraseña que has introducido no es tu contraseña actual',
  PASSWORD_SUCCESSFULL_CHANGED: 'Has cambiado tu contraseña',
  ADDRESSES: 'Direcciones',
  CLIENT_COMMENTS: 'Comentarios Cliente',
  CONTRACT_COMMENTS: 'Comentarios Contrato',
  NO_COMMENTS: 'No hay comentarios',
  FILL_NEW_CLIENT_DATA: 'Vas a pasar un pedido para un Cliente Nuevo, ahora tienes que rellenar los datos. Pulsa en el siguiente ' +
    'botón y rellena los datos.',
  FILL_PRECLIENT: 'Rellenar datos cliente',
  UPDATE_PRECLIENT: 'Actualizar datos cliente',
  FIXED_DUE_DATE: 'Vencimiento Fijo',
  PRODUCT_BILLS: 'Facturas del Producto',
  REFUND_SELECTED: 'Abonar Seleccionados',
  APPROVAL_ORDERS: 'Aprobar Pedidos',
  APPROVAL_REFUNDS: 'Aprobar Abonos',
  VENDOR_SELECTION: 'Selección de Vendedor',
  VENDOR_SELECTED: 'Tienes seleccionado a este vendedor',
  CONTADO_DISABLED: 'No puedes pasar un pedido contado a un nuevo cliente',
  GIRO_DISABLED: 'No puedes girarle a este cliente porque no tiene una cuenta bancaria asociada. Por favor contacta con administración' +
    ' para añadirle una cuenta bancaria y vuélvelo a intentar. Recuerda que la aplicación se actualiza por las noches.',
  TALON_C_DISABLED: 'No puedes utilizar esta forma de pago porque el cliente no tiene un contrato de cheques anticipados',
  TRANSFER_DISABLED: 'No puedes utilizar esta forma de pago porque el cliente no es una cooperativa',
  CONFIRM_SEND_ORDER: '¿Estás seguro de que deseas enviar la oferta?',
  SEND_ORDER: 'Enviar Oferta',
  CHARGE_DATE: 'Fecha de Cobro',
  DATE_EXISTS_ERROR: 'Ya has añadido la fecha {0} a las fechas de vencimiento',
  ZERO_IMPORT: 'Para mandar un cobro a 0 tienes que seleccionar efectivo',
  CONFIRM_SEND_CHARGE: '¿Estás seguro de que deseas enviar el cobro?',
  SEND_CHARGE: 'Enviar Cobro',
  SEARCH_REFUND_PRODUCT: 'Busca un producto en la barra de arriba',
  ADD: 'Añadir',
  REFUND_MOTIVE: 'Motivo del Abono',
  MOTIVES: 'Motivos',
  REFUND_LINES_ADDED: 'La/s linea/s seleccionada/s han sido añadida/s al abono',
  MOTIVE: 'Motivo',
  SET_LITTLE_DESCRIPTION: 'Introduce una pequeña descripción',
  SEND_REFUND: 'Enviar Abono',
  CONFIRM_SEND_REFUND: '¿Estás seguro de que deseas enviar el abono? Recuerda revisarlo antes de enviarlo',
  REFUND_MOTIVE_SUGGEST: '¿Has rellenado todos los motivos de abono de CADA producto?',
  NO_REFUND_FOUND: 'No se ha encontrado el abono',
  OBJECTIVES_FROM_COSMET: 'Objetivos añadidos desde Ofertahe',
  ADD_OBJECTIVE: 'Añadir Objetivo',
  PREVIOUS_OBJECTIVES: 'Objetivos Anteriores',
  OBJECTIVE_ADDED: 'Objetivo añadido correctamente',
  '200': 'Éxito',
  '201': 'Éxito',
  '404': 'No encontrado',
  '400': 'Error',
  '401': 'Error de autenticación',
  '422': 'Error en el servidor',
  '499': 'Ya existe',
  'CONTADO': 'Contado',
  'GIRO': 'Giro',
  'CONTRAREEM': 'Contrareembolso',
  'GIROPT': 'Giro Portugal',
  'CONTRARPOR': 'Contrareembolso Portugal',
  'CHEQUEPT': 'Cheque Portugal',
  'TARJETAPT': 'Tarjeta Clientes Portugal',
  'TRANSFPORT': 'Transferencia',
  'TRANSFERIT': 'Transferencia',
  'TARJ OUTLE': 'Tarjeta',
  'CONTADOPT': 'Contrato Queque',
  'EFECTIVO': 'Efectivo',
  'TARJETA': 'Tarjeta',
  'GIROCOBRO': 'Giro',
  'CHEQUE': 'Cheque',
  'DESCUENTO': 'Descuento',
  '1X7': 'Giro 7 días',
  '1X15': 'Giro 15 días',
  '1X20': 'Giro 20 días',
  '1X21': 'Giro 21 días',
  '1X30': 'Giro 30 días',
  '1X40': 'Giro 40 días',
  '1X45': 'Giro 45 días',
  '1X60': 'Giro 60 días',
  '1X75': 'Giro 75 días',
  '1X90': 'Giro 90 días',
  '2X7': 'Giro 7 y 30 días',
  '2X15': 'Giro 15 y 30 días',
  '2X20': 'Giro 20 y 40 días',
  '2X30': 'Giro 30 y 60 días',
  '2X45': 'Giro 45 y 90 días',
  '2X45B': 'Giro 45 y 60 días',
  '2X60': 'Giro 60 y 90 días',
  '2X60X120': 'Giro 60 y 120 días',
  '2X90': 'Giro 90 y 120 días',
  '3X10': 'Giro 10, 20 y 30 días',
  '3X15': 'Giro 15, 30 y 45 días',
  '3X3': 'Giro 45, 75 y 105 días',
  '3X30': 'Giro 30, 60 y 90 días',
  '3X60': 'Giro 60, 90 y 120 días',
  '4X30': 'Giro 30, 60, 90 y 120 días',
  '4X3': 'Giro 15, 60 y 90 días',
  '4X4': 'Giro 15, 30, 60 y 90 días',
  '1X120': 'Giro 120 días',
  MAINTENANCE_MODE: 'El servidor está de mantenimiento, por favor sé paciente, en breve volverá a estar operativo',
  '503': 'MANTENIMIENTO',
  NO_PREVIOUS_BILLS: 'No se han encontrado facturas anteriores',
  NO_PREVIOUS_BILLS_REFUNDS: 'No se han encontrado abonos facturados anteriores',
  SEARCH_MENU: '¿Qué quieres hacer?',
  PENDING: 'Pendiente',
  PENDING_TO_SEND: 'Pendiente E.',
  PENDING_APPROVAL: 'Pend. Aprob.',
  PENDING_APPROVAL_DIS: 'Pend. Dis.',
  SENDED: 'Enviado',
  IMPORT_ERROR: 'Error Import.',
  RECEIVED: 'Recibido',
  ACCEPTED: 'Aceptado',
  CANCELED: 'Cancelado',
  UNKNOWN: 'Desconocido',
  STATUS: 'Estado',
  'ENERO': 'ENERO',
  'FEBRERO': 'FEBRERO',
  'MARZO': 'MARZO',
  'ABRIL': 'ABRIL',
  'MAYO': 'MAYO',
  'JUNIO': 'JUNIO',
  'JULIO': 'JULIO',
  'AGOSTO': 'AGOSTO',
  'SEPTIEMBRE': 'SEPTIEMBRE',
  'OCTUBRE': 'OCTUBRE',
  'NOVIEMBRE': 'NOVIEMBRE',
  'DICIEMBRE': 'DICIEMBRE',
  'Peluquería': 'Peluquería',
  'Estética': 'Estética',
  'Estética+Peluquería': 'Estética+Peluquería',
  'Barbería': 'Barbería',
  'Otros': 'Otros',
  NOT_EMAIL: 'El cliente no tiene email. Para poder facturarle tienes que añadirle un email y que el cliente lo valide, ¡Vamos a ello!',
  NOT_VALIDATED_EMAIL: 'El cliente tiene un email, pero aún no está confirmado. Por favor envíale un email de confirmación',
  MUST_ADD_OBJECTIVE: 'Recuerda que tienes que añadir el objetivo del año que viene para este cliente',
  VALIDATE_EMAIL: 'Validar Email',
  UPDATE_EMAIL: 'Actualizar Email',
  EMAIL_MORE_THAN_WEEK: 'El email lleva más de 7 días sin ser confirmado',
  WAITING_FOR_CONFIRM: 'Esperando confirmación del email',
  SEND_ORDER_CONFIRMATION: 'Confirmación de Envío',
  COOPERATIVA: 'COOPERATIVA',
  ASSOCIATED_CLIENT: 'Cliente Asociado',
  SELECT_ASSOCIATED_CLIENT: 'Selecciona el cliente asociado',
  NO_BILLS_FOUND: 'No se han encontrado facturas',
  ADD_BILL_MANUALLY: 'Añadir factura manualmente',
  BUY_AGAIN: 'Volver a hacer Pedido',
  REBUY: 'Recomprar',
  REFUND: 'Abonar',
  TYPE_CLIENT_EMPTY: 'Tienes que rellenar el campo Tipo Cliente',
  CIF_EMPTY: 'Tienes que rellenar el campo NIF/CIF',
  CIF_NOT_VALID: 'El CIF no es válido, comprueba el campo NIF/CIF',
  NAME_EMPTY: 'Tienes que rellenar el campo Nombre',
  NAME_TOO_LONG: 'El nombre y los apellidos juntos son demasiado largos, intenta acortarlos, o considera separarlo en el Nombre 2.',
  NAME2_TOO_LONG: 'El campo Nombre 2 es demasiado largo',
  SOCIAL_REASON_TOO_LONG: 'El campo razón social es demasiado largo',
  ADDRESS_EMPTY: 'Tienes que rellenar el campo Dirección',
  ADDRESS_TOO_LONG: 'El campo Dirección es demasiado largo, considera separarla en el campo Dirección 2',
  ADDRESS2_TOO_LONG: 'El campo Dirección 2 es demasiado largo, intenta acortarlo.',
  CITY_EMPTY: 'Tienes que rellenar el campo Población',
  CITY_TOO_LONG: 'El campo población es demasiado largo, intenta acortarlo',
  STATE_EMPTY: 'Tienes que rellenar el campo Provincia',
  STATE_TOO_LONG: 'El campo Provincia es demasiado largo, intenta acortarlo',
  COUNTRY_EMPTY: 'Tienes que seleccionar un País',
  POSTAL_CODE_EMPTY: 'Tienes que rellenar el campo Código Postal',
  POSTAL_CODE_WRONG: 'Has introducido mal el campo Código Postal, compruébalo',
  CONTACT_EMPTY: 'Tienes que rellenar el campo Pers.Contacto',
  CONTACT_TOO_LONG: 'El campo Contacto es demasiado largo, intenta acortarlo',
  CLOSE_DAYS_EMPTY: 'Tienes que rellenar el campo Días Cierre',
  CLOSE_DAYS_TOO_LONG: 'El campo Días cierre es demasiado largo, intenta acortarlo',
  EMAIL_EMPTY: 'Tienes que rellenar el campo Email',
  EMAIL_NOT_VALID: 'El email no tiene un formato váildo.',
  EMAIL_TOO_LONG: 'El email es demasiado largo',
  MOBILE_OR_PHONE_E: 'Tienes que rellenar el campo Teléfono o el campo Móvil',
  FAX_TOO_LONG: 'El campo Fax es demasiado largo',
  NOT_VALID_ACCOUNT: 'El campo nº de cuenta tiene que tener este formato SSXX XXXX XXXX XX XXXXXXXXXX',
  FILL_BANK_ACCOUNT: 'Si quieres girarle a un cliente nuevo tienes que enviar también el número de cuenta, por favor, rellénalo',
  BANKACCOUNT: 'Cuenta Bancaria',
  CHECK_NO: 'Nº Cheque',
  CHECK_NO_PLACEHOLDER: 'Introduce el número de cheque',
  NO_ORDER_FOUND: 'No se ha encontrado el pedido',
  NO_REPORT_DETAILS: 'No se han encontrado datos del reporte',
  NO_PENDING_TO_SHIP_FOR_NEW_CLIENT: 'No tienes seleccionado a ningún cliente existente, selecciona a un cliente para ver su pendiente de servir',
  NO_EXPEDITION_FOR_NEW_CLIENT: 'No tienes seleccionado a ningún cliente existente, selecciona a un cliente para ver sus expediciones',
  LINE_COPIED: 'Linea copiada en el pedido',
  COMPANY_NOT_MATCH: 'Las empresas de las lineas que quieres añadir al cobro no coinciden',
  DESCON_0: 'No mires la pantalla, ahora no es su momento, sino el tuyo. Relájate!',
  DESCON_1: 'Sol escondido, descanso merecido.',
  DESCON_2: 'Desconectar de todo y descansar es necesario para llevar una vida saludable.',
  DESCON_3: 'Momento de: Clientes ... Desplazamientos ... Pedidos ... ¡Desconectar! <icon class="icon f7-icons text-color-green" style="font-size: 16px">checkmark_alt</icon>',
  DESCON_4: 'Ahora es tiempo de estar con tu familia: ¡aprovecha y disfruta!',
  DESCON_5: 'Ahora empieza tu jornada familiar!! Disfrútala!!',
  DESCON_6: 'Para conseguir un rendimiento del 100% en tu jornada laboral necesitas una desconexión del 100%',
  DESCON_7: 'Para ser productivo hay que descansar!! Feliz jornada de descanso!!',
  DESCON_8: 'Tras el trabajo, disfrutar de tu familia, de una vida sana y una buena lectura te ayudará a sentirte mejor',
  DESCON_9: 'Alto! Este tiempo es para ti, dedícatelo!',
  SOLD_PRODUCTS: 'Productos Vendidos',
  NO_SOLD_PRODUCTS_FOR_NEW_CLIENT: 'No tienes seleccionado a ningún cliente existente, selecciona a un cliente para ver sus productos vendidos',
  DATE_RANGE: 'Rango de Fechas',
  SELECT_DATES: 'Seleccionar Fechas',
  GET_SOLD_PRODUCTS: 'Obtener Productos Vendidos',
  NO_SOLD_PRODUCTS_FOUND: 'No se han encontrado productos vendidos para ese cliente o ese rango de fechas',
  SEARCH_FAQ: 'Buscar en la ayuda',
  MY_CODES: 'Mis Códigos',
  VENDORS: 'Vendedores',
  SEARCH_REPORTS: 'Buscar en los partes',
  REPORTS_RESULTS: 'Resultados para la búsqueda {0}',
  NOT_FOUND_CLIENT: 'No lo encuentro',
  NOT_FOUND_CLIENT_INFO: 'Si te han añadido el cliente hoy, es normal que no salga ¡pero tranquilo! si sabes el código de cliente puedes añadirlo tu mismo desde aqui ' +
    'aunque recuerda que su información como los comentarios de contrato, direcciones adicionales, etc,... aparecerá mañana. Aún así, si necesitas hacerle un pedido, abono ' +
    'o cobro intenta añadirlo tu mismo ahora, y así no esperar a mañana',
  FIND_CLIENT: 'Encuentra al cliente',
  CLIENT_IMPORT_FAILED: 'Lo siento pero o el código que has introducido no existe, o ese cliente no está asociado con tu código de vendedor, consulta con administración y ' +
    'vuélvelo a intentar :)',
  CLIENT_IMPORT_SUCESFULL: '¡Bien! Ya tienes al cliente en tu selección',
  NO_PREVIOUS_PROPOSALS: 'No se han encontrado propuestas anteriores.',
  PRODUCT_ERROR: 'Error en un producto',
  PRODUCT_ERROR_INFO: '¿Hay un problema con algún producto? Tanto si hay un error en un producto existente como si no encuentras un producto este es tu sitio, dime que te pasa ' +
    'y te ayudaré a solucionarlo',
  FIND_PRODUCT_CODE: 'Encontrar el código',
  WHAT_PROBLEM: '¿Cuál es el problema?',
  APPLIES_TO_DOC: 'Casado con abono',
  SENDED_ERROR_MESSAGE: 'Se ha enviado un mensaje con el error detectado, ¡Muchas gracias!',
  COSMET_NOT_PRODUCT_FOUND: 'No se ha encontrado el producto en la base de datos, comprueba que el código es correcto y si lo es contacta con el administrador',
  CONTRACTS: 'Contratos',
  NO_EXPEDITIONS_CLIENT: 'El cliente no tiene expediciones recientes',
  NO_PENDING_BILLS_CLIENT: 'El cliente no tiene facturas pendientes',
  NO_LAST_BILLS: 'No se han encontrado facturas anteriores',
  UPDATE_PRODUCT: 'Añadir/Act. producto',
  UPDATE_CATEGORY: 'Añadir/Act. categoria',
  NO_LAST_BILLS_VENDOR: 'No hay información de últimas facturas',
  NO_LATE_BILLS_VENDOR: 'No tienes saldos atrasados de ningún cliente',
  NO_PENDING_BILLS_VENDOR: 'No hay Facturas pendientes de ningún cliente',
  SELL_CHANNEL_SELECTION: 'Selección de Canal de Venta',
  TAHE_OUTLET: 'Tahe Outlet',
  TH_OUTLET: 'Th Outlet',
  PROPOSAL_ADDED_TO_ORDER: 'Propuesta añadida correctamente al pedido',
  NUM_CHECK: 'Nº Cheque',
  NOT_EXIST_ONE_OR_MORE_PRODUCTS: 'Se han añadido los productos al abono, pero alguno de ellos no se ha podido añadir porque no existe en la base de datos',
  PRODUCT_BILL_ALREADY_ADDED: 'Parece que ya has abonado alguno de los productos seleccionados correspondientes a esta factura',
  DISTRIBUTORS: 'Distribuidores',
  NO_DISTRIBUTORS: 'No hay distribuidores',
  ADD_DISTRIBUTOR: 'Añadir Distribuidor',
  NEW_DISTRIBUTOR: 'Nuevo Distribuidor',
  PRODUCTS_SOLD_INFO: 'El cálculo de los productos vendidos se hace sacando los productos facturados y restandole los productos abonados',
  ADD_DIST_CLIENT: 'Añadir Cliente',
  SEE_MORE: 'Ver más',
  cif: 'CIF',
  socialReason: 'Razón Social',
  name: 'Nombre',
  name2: 'Nombre 2',
  surname: 'Apellidos',
  address: 'Dirección',
  address2: 'Dirección 2',
  city: 'Ciudad',
  state: 'Provincia',
  postalCode: 'Cód. Postal',
  contact: 'Contacto',
  closeDays: 'Días Cierre',
  email: 'Email',
  phone: 'Teléfono',
  mobile: 'Móvil',
  fax: 'Fax',
  bankAccount: 'Cuenta Bancaria',
  esxx: 'ESXX',
  bank: 'Banco',
  office: 'Oficina',
  digit: 'Dígito',
  numAccount: 'Nº Cuenta',
  'cosmet': 'Cosmet',
  'red': 'Rojo',
  'green': 'Verde',
  'blue': 'Azul',
  'pink': 'Rosa',
  'yellow': 'Amarillo',
  'orange': 'Naranja',
  'purple': 'Lila',
  'deeppurple': 'Índigo',
  'lightblue': 'Azul Claro',
  'teal': 'Turquesa',
  'lime': 'Lima',
  'deeporange': 'Naranja Oscuro',
  DESC_PP: '% Desc. P.P.',
  ACT_CANTIDAD: 'Actualizar cantidad',
  ACT_PRECIO_UNIDAD: 'Actualizar precio unidad',
  ACT_DESC: 'Actualizar descuento',
  ACT_GRATIS: 'Actualizar gratis',
  LINEAS_BONIFICADAS: 'Líneas bonificadas',
  CERRAR: 'Cerrar',
  GUARDAR: 'Guardar',
};
